import React from "react";
// import PropTypes from "prop-types";

import BLRatingWStars from "../../utils/components/BLRatingWStars";
import SingleComment from "./SingleComment";
import BLButton from "../../utils/components/BLButton";
import BLInfoArea from "../../utils/components/BLInfoArea";
import { Link } from "react-router-dom";
import { auth } from "../../App/routes";

const Comments = (props) => {
  const ratingCounts = [
    { id: 0, rating: 5, count: props.rates.rate_5.count },
    { id: 1, rating: 4, count: props.rates.rate_4.count },
    { id: 2, rating: 3, count: props.rates.rate_3.count },
    { id: 3, rating: 2, count: props.rates.rate_2.count },
    { id: 4, rating: 1, count: props.rates.rate_1.count },
  ];

  return (
    <div className="comments-page page-holder">
      <div className="ratings-info-holder d-flex flex-column flex-sm-row align-items-center">
        <div className="average-holder d-flex flex-column align-items-center">
          <h6 className="title">
            {props.translate("experience.average_customer_rating")}
          </h6>
          <div className="average-rating d-flex flex-column justify-content-start align-items-center">
            <div className="rating-average-content">{props.rating}</div>
            <div className="content">
              {props.translate("experience.count_reviews", {
                count: props.stats.review,
              })}
            </div>
            <div className="stars w-100 d-flex justify-content-start align-items-center">
              <BLRatingWStars rating={props.rating} />
            </div>
          </div>
        </div>
        <div className="rating-counts d-flex flex-column">
          {ratingCounts.map((data) => (
            <div
              key={data.id}
              className="count d-flex justify-content-start align-items-center"
            >
              <div className="stars d-flex justify-content-start align-items-center">
                <BLRatingWStars rating={data.rating} />
              </div>
              <div className="content">({data.count})</div>
            </div>
          ))}
        </div>
      </div>
      {props.pendingReviews || props.completedBookings < props.totalBookings ? (
        <div className="review-info-area mx-auto mt-4 mb-5">
          <BLInfoArea type="info">
            {props.pendingReviews
              ? props.translate("experience.reviews_waiting_info", {
                  link: (
                    <Link
                      to={auth.reservations.links[props.lang]}
                      className="font-weight-bold"
                    >
                      {props.translate("profile.reservations_page.title")}
                    </Link>
                  ),
                })
              : props.completedBookings < props.totalBookings
              ? props.translate("experience.reviews_incompleted_info")
              : ``}
          </BLInfoArea>
        </div>
      ) : (
        ""
      )}
      <div className="comments">
        {props.comments.map((data, i) => (
          <SingleComment key={i} {...data} translate={props.translate} />
        ))}
      </div>
      {props.reviewsHasNext ? (
        <BLButton
          type="pri"
          classes="px-4 mt-5 mx-auto"
          clicked={props.loadMoreComments}
          disabled={props.isLoadingMore}
        >
          {props.translate("global.load_more")}
        </BLButton>
      ) : (
        ""
      )}
      <div className="write-a-comment-area"></div>
    </div>
  );
};

Comments.propTypes = {
  // rating: PropTypes.number.isRequired,
  // comments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Comments;
