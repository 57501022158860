import Axios from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { CSSTransition } from "react-transition-group";
import NotFound from "../App/NotFound";
import { global } from "../App/routes";
import API, { headers } from "../utils/API";
import BLButton from "../utils/components/BLButton";
import BLDrawer from "../utils/components/BLDrawer";
import BLLoader from "../utils/components/BLLoader";
import Product from "../utils/components/Product";
import {
  getCitiesWithProduct,
  getCurrentRoute,
  handleErrors,
  isCrawler,
} from "../utils/helper";
import { Banner } from "./components/Banner";
import { MobileButtons } from "./components/MobileButtons";
import MobileFilters from "./components/MobileFilters";
import MobileSort from "./components/MobileSort";
import Sidebar from "./components/Sidebar";
import Sort from "./components/Sort";
import {
  dataLayer__filtered,
  dataLayer__itemListClick,
  dataLayer__itemListView,
  dataLayer__unfiltered,
} from "../utils/DataLayerEvents";
import PrerenderRedirect from "../utils/components/PrerenderRedirect";
import SEO from "../utils/components/SEO";

export const MetaLinks = ({ category }) => {
  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    if (category) {
      const { links } = category?.parent ? global.subCatPage : global.catPage;

      if (links && links["tr"])
        setUrl(
          links["tr"]
            .replace(
              ":main_cat",
              category?.parent
                ? category?.parent.translations["tr"].slug
                : category?.translations["tr"].slug
            )
            .replace(":sub_cat", category?.translations["tr"].slug)
        );
    }
  }, [category]);

  return (
    <Helmet>
      {url && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={process.env.REACT_APP_URL + url}
        />
      )}

      {category?.translations &&
        Object.keys(category?.translations).map((key) => {
          const { links } = category?.parent
            ? global.subCatPage
            : global.catPage;

          return (
            links[key] && (
              <link
                key={key}
                rel="alternate"
                hrefLang={key}
                href={`${process.env.REACT_APP_URL}${links[key]
                  .replace(
                    ":main_cat",
                    category?.parent
                      ? category?.parent.translations[key].slug
                      : category?.translations[key].slug
                  )
                  .replace(":sub_cat", category?.translations[key].slug)}`}
              />
            )
          );
        })}
    </Helmet>
  );
};

export default class Category extends Component {
  state = {
    category: {},
    products: [],
    locations: [],
    categories: [],
    breadcrumbs: [],
    catType: "",
    isLoading: true,
    hasMore: false,
    page: 1,
    isLoadingMore: false,
    filters: {
      locations: [],
      sort: "hot",
      keyword: "",
    },
    isMobileFiltersOn: false,
    isMobileSortOn: false,
    isNotFound: false,
    isProductsLoading: true,
    hasNewSlug: false,
  };

  productsLimit = 18;

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    this.handleGetCategory();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.isNotFound
    ) {
      this.setState({ isNotFound: false, isLoading: true });
    }

    if (
      (prevProps.location.pathname !== this.props.location.pathname &&
        JSON.stringify(prevProps.match.params) !==
          JSON.stringify(this.props.match.params) &&
        this.props.match.params.main_cat !== this.state.category.slug &&
        this.props.match.params.sub_cat !== this.state.category.slug) ||
      (prevState.isNotFound === true && this.state.isNotFound === false) ||
      prevProps.user.currencyPref.code.toLowerCase() !==
        this.props.user.currencyPref.code.toLowerCase()
    ) {
      this.setState({ page: 1 }, this.handleGetCategory);
    }

    if (prevProps.lang !== this.props.lang) {
      if (!this.state.isNotFound) {
        let url =
          this.state.catType === "subCatPage"
            ? global.subCatPage.links[this.props.lang]
            : global.catPage.links[this.props.lang];

        if (this.state.category.translations[this.props.lang]) {
          if (this.state.catType === "subCatPage") {
            url = url.replace(
              ":sub_cat",
              this.state.category.translations[this.props.lang].slug
            );
            url = url.replace(
              ":main_cat",
              this.state.category.parent.translations[this.props.lang].slug
            );
          } else {
            url = url.replace(
              ":main_cat",
              this.state.category.translations[this.props.lang].slug
            );
          }

          this.setState({ isNotFound: false });
          setTimeout(
            () => this.props.history.push(url + window.location.search),
            60
          );
        } else {
          this.setState({ isNotFound: true });
        }
      }
    }
  };

  handleGetCategory = () => {
    if (!window.location.search)
      this.setState({ filters: { locations: [], sort: "hot", keywords: "" } });

    this.setState(
      {
        isMobileSortOn: false,
        isMobileFiltersOn: false,
        isProductsLoading: true,
        hasNewSlug: false,
      },
      () => {
        getCurrentRoute(window.location.pathname, this.props.lang).then(
          ({ name: catType, match }) => {
            this.setState({ catType }, () => {
              let url = `categories/${
                this.state.catType === "subCatPage"
                  ? this.props.match.params.sub_cat
                  : this.props.match.params.main_cat
              }`;

              API.get(url, {
                headers: { ...headers, "Accept-Language": this.props.lang },
                cancelToken: this.cancelSource.token,
              })
                .then(({ data: r }) => {
                  const { data: category } = r;

                  this.setState({ category }, () => {
                    if (
                      !this.state.category.parent &&
                      this.state.catType === "subCatPage"
                    ) {
                      const url =
                        global.catPage.links[this.props.lang].replace(
                          ":main_cat",
                          this.state.category.slug
                        ) + window.location.search;

                      if (isCrawler())
                        this.setState({ wrongSlugRedirectedTo: url });
                      else {
                        this.props.history.push(url);

                        this.setState({ catType: "catPage" });
                      }
                    } else if (
                      this.state.category.parent &&
                      this.state.catType === "catPage" &&
                      this.state.category.parent.translations
                    ) {
                      const url =
                        global.subCatPage.links[this.props.lang]
                          .replace(
                            ":main_cat",
                            this.state.category.parent.translations[
                              this.props.lang
                            ].slug
                          )
                          .replace(":sub_cat", this.state.category.slug) +
                        window.location.search;

                      if (isCrawler())
                        this.setState({ wrongSlugRedirectedTo: url });
                      else {
                        this.props.history.push(url);

                        this.setState({ catType: "subCatPage" });
                      }
                    }

                    if (this.state.category.translations[this.props.lang]) {
                      this.setState({ isNotFound: false }, () => {
                        this.handleGetBreadcrumbs();
                        this.handleGetCategories();
                        this.handleGetLocations();
                        this.handleGetProducts();

                        if (this.state.catType === "subCatPage") {
                          if (
                            this.state.category.slug !==
                              this.props.match.params.sub_cat ||
                            this.state.category?.parent?.slug !==
                              this.props.match.params.main_cat
                          ) {
                            if (
                              this.state.category.translations[this.props.lang]
                            )
                              this.props.history.push(
                                global.subCatPage.links[this.props.lang]
                                  .replace(
                                    ":main_cat",
                                    this.state.category?.parent?.slug
                                  )
                                  .replace(
                                    ":sub_cat",
                                    this.state.category.slug
                                  ) + window.location.search
                              );
                          }
                        } else {
                          if (
                            this.state.category.slug !==
                            this.props.match.params.main_cat
                          ) {
                            if (
                              this.state.category.translations[this.props.lang]
                            )
                              this.props.history.push(
                                global.catPage.links[this.props.lang].replace(
                                  ":main_cat",
                                  this.state.category.slug
                                ) + window.location.search
                              );
                          }
                        }
                      });
                    } else {
                      this.setState({ isNotFound: true });
                    }

                    setTimeout(() => this.setState({ isLoading: false }), 20);
                  });
                })
                .catch((err) => {
                  if (err?.response?.status === 404)
                    this.setState({ isNotFound: true, isLoading: false });

                  if (err?.response?.status === 301) {
                    if (isCrawler())
                      this.setState({
                        wrongSlugRedirectedTo:
                          window.location.pathname.replace(
                            err.response?.data?.header?.previous_slug,
                            err.response?.data?.header?.slug
                          ) + window.location.search,
                      });
                    else
                      this.setState(
                        {
                          isNotFound: false,
                          isLoading: true,
                          page: 1,
                        },
                        () => {
                          if (
                            err.response?.data?.header?.slug ===
                            this.props.match.params.main_cat
                          )
                            this.handleGetCategory();
                        }
                      );
                  }
                });
            });
          }
        );
      }
    );
  };

  handleGetCategories = () => {
    if (this.state.catType === "subCatPage")
      API.get(`categories/${this.state.category?.parent?.slug}`, {
        headers: { ...headers, "Accept-Language": this.props.lang },
      })
        .then(({ data: r }) => {
          const { siblings: categories } = r.data;
          this.setState({ categories });
        })
        .catch((err) => handleErrors(err));
    else this.setState({ categories: this.state.category.siblings });
  };

  handleGetLocations = () => {
    getCitiesWithProduct(this.props.lang, 212, this.state.category.id)
      .then(({ data: r }) => {
        const { data: locations } = r;
        this.setState({ locations });
      })
      .catch((err) => handleErrors(err));
  };

  handleGetProducts = (next = false) => {
    this.setState({ isProductsLoading: !next, isLoadingMore: next }, () => {
      const catFilter = `&category_id=${this.state.category.id}`;

      let filtersRequestUrl = "";
      if (window.location.search) {
        const filtersInUrl = decodeURIComponent(window.location.search).split(
          ":"
        );

        if (filtersInUrl.length > 1) {
          const filters = filtersInUrl[1].split(";");

          const filter = filters.map((data) => {
            return data.split("=");
          });

          let locations = [];
          let sort = "";

          filter.forEach((data) => {
            if (data[0] === "locations") {
              data[1].split(",").forEach((data) => {
                locations.push(parseInt(data));
                filtersRequestUrl += "&location_ids[]=" + data;
              });
            }

            if (data[0] === "sort") {
              filtersRequestUrl += "&order_by=" + data[1];
              sort = data[1];
            }
          });

          this.setState({
            filters: { ...this.state.filters, locations, sort },
          });
        }
      }

      let reqHeaders = { ...headers, "Accept-Language": this.props.lang };
      if (this.props.user.isLoggedIn)
        reqHeaders = {
          ...reqHeaders,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        };

      API.get(
        `products?currency=${this.props.user.currencyPref.code}${catFilter}${filtersRequestUrl}&limit=${this.productsLimit}&page=${this.state.page}`,
        {
          headers: reqHeaders,
          cancelToken: this.cancelSource.token,
        }
      )
        .then(({ data: r }) => {
          const { data, meta } = r;

          const products = next ? [...this.state.products, ...data] : data;

          const hasMore = meta.current_page < meta.last_page;

          this.setState(
            {
              products,
              hasMore,
              isProductsLoading: false,
              isLoadingMore: false,
            },
            () => {
              const dataLayerProducts = data.map((product, index) => ({
                ...product,
                index: index + 1,
              }));

              const sortTypes = {
                hot: "Çok sevilenler",
                newest: "En yeniler",
                lowest: "En düşük fiyat",
                highest: "En yüksek fiyat",
              };

              const listInfo = {
                pageType: global.catPage.type,
                listType: this.state.filters.locations.length
                  ? `Filtre`
                  : `Genel`,
                pageName: document.title,
                sortType: sortTypes[this.state.filters.sort],
              };

              dataLayer__itemListView(
                dataLayerProducts,
                listInfo,
                this.props.user.currencyPref.code
              );
            }
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isProductsLoading: false, isLoadingMore: false });
        });
    });
  };

  handleGetBreadcrumbs = () => {
    const breadcrumbs = [
      {
        id: 0,
        name: this.props.translate("global.homepage"),
        route: global.home.links[this.props.lang],
      },
    ];

    if (this.state.catType === "catPage") {
      breadcrumbs.push({
        id: 1,
        name: this.state.category.name,
      });
    }

    if (this.state.catType === "subCatPage") {
      breadcrumbs.push({
        id: 1,
        name: this.state.category?.parent?.name || "",
        route: global.catPage.links[this.props.lang].replace(
          ":main_cat",
          this.state.category?.parent?.slug
        ),
      });

      breadcrumbs.push({
        id: 2,
        name: this.state.category.name,
      });
    }

    this.setState({ breadcrumbs });
  };

  handleSelectCat = false;

  handleApplyFilter = (e, filters) => {
    if (e) e.preventDefault();

    let locations = this.state.filters.locations;
    let sort = this.state.filters.sort;
    let keyword = this.state.filters.keyword;

    if (filters && filters.length) {
      this.setState({ isMobileFiltersOn: false, isMobileSortOn: false });
      filters.forEach((data) => {
        if (data.type === "locations") {
          locations = data.value && data.value.length ? data.value : [];
        }

        if (data.type === "sort") {
          sort = data.value;
        }
      });

      let filtersUrl = "?filters:";
      if (locations.length) {
        filtersUrl += "locations=";
        locations.forEach((data, index) => {
          filtersUrl += index === 0 ? data : `,${data}`;
        });
      }

      if (sort) {
        filtersUrl += locations.length ? `;sort=${sort}` : `sort=${sort}`;
      }

      if (keyword) {
        filtersUrl +=
          locations.length || sort
            ? `;keyword=${keyword}`
            : `keyword=${keyword}`;
      }

      if (!locations.length && !sort && !keyword) filtersUrl = "";

      this.props.history.push(this.props.location.pathname + filtersUrl);

      this.setState(
        {
          isProductsLoading: true,
          filters: { locations, sort, keyword },
          page: 1,
        },
        this.handleGetProducts
      );
    }
  };

  handleShowMobileFilter = (type) => {
    if (type === "sort") this.setState({ isMobileSortOn: true });
    if (type === "filters") this.setState({ isMobileFiltersOn: true });
  };

  handleCloseMobileFilter = (type) => {
    if (type === "sort") this.setState({ isMobileSortOn: false });
    if (type === "filters") this.setState({ isMobileFiltersOn: false });
  };

  handleLoadMore = () =>
    this.setState(
      (prevState) => ({ page: prevState.page + 1 }),
      () => this.handleGetProducts(true)
    );

  handleItemClick = (product) => {
    const sortTypes = {
      hot: "Çok sevilenler",
      newest: "En yeniler",
      lowest: "En düşük fiyat",
      highest: "En yüksek fiyat",
    };

    const listInfo = {
      pageType: global.catPage.type,
      listType: this.state.filters.locations.length ? `Filtre` : `Genel`,
      pageName: document.title,
      sortType: sortTypes[this.state.filters.sort],
    };

    dataLayer__itemListClick(product, listInfo);
  };

  handleFilterDataLayers = (filterName, selected) => {
    const filter = {
      type: "Lokasyonlar",
      value: filterName,
    };

    const {
      langPref,
      currencyPref,
      isLoggedIn,
      hashed_email,
      id,
      gender,
      has_bought_before,
    } = this.props.user;

    const page = {
      title: document.title,
      type: global.catPage.type,
      lang: langPref,
      currency: currencyPref.code,
    };

    const user = {
      isLoggedIn,
      hashed_email,
      id,
      gender: this.props.translate(`global.${gender}`, null, {
        defaultLanguage: "tr",
      }),
      has_bought_before,
    };

    if (selected) dataLayer__filtered(filter, page, user);
    else dataLayer__unfiltered(filter, page, user);
  };

  render() {
    return this.state.wrongSlugRedirectedTo ? (
      <PrerenderRedirect to={this.state.wrongSlugRedirectedTo} />
    ) : this.state.isLoading ? (
      ``
    ) : this.state.isNotFound ? (
      <NotFound translate={this.props.translate} lang={this.props.lang} />
    ) : (
      <div className="cat-page">
        <SEO
          title={this.props.translate(`page_title.${this.state.catType}`, {
            cat: this.state.category?.name,
          })}
          description={this.props.translate(
            `page_description.${this.state.catType}`,
            {
              cat: this.state.category?.name,
            }
          )}
          image={this.state.category?.cover_image}
        />

        <MetaLinks category={this.state.category} />
        <Banner
          name={this.state.category?.name}
          cover={this.state.category?.cover_image}
          breadcrumbs={this.state.breadcrumbs}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <section className="cat-content d-flex flex-column flex-lg-row justify-content-between align-items-start">
                <div className="d-none d-lg-flex">
                  <Sidebar
                    translate={this.props.translate}
                    subCats={
                      this.state.catType === "subCatPage"
                        ? this.state.category?.siblings
                        : this.state.category?.children
                    }
                    locations={this.state.locations}
                    categories={this.state.categories}
                    slug={this.state.category?.slug}
                    handleApplyFilter={this.handleApplyFilter}
                    handleSelectCat={this.handleSelectCat}
                    cat={{
                      ...this.state.category,
                      name: this.state.category?.name,
                      slug: this.state.category?.slug,
                      type:
                        this.state.catType === "subCatPage" ? "subCat" : "cat",
                    }}
                    filters={this.state.filters}
                    lang={this.props.lang}
                    handleFilterDataLayers={this.handleFilterDataLayers}
                  />
                </div>
                <section className="list-side">
                  <div className="d-none d-lg-flex">
                    <Sort
                      handleApplyFilter={this.handleApplyFilter}
                      selectedSort={this.state.filters.sort}
                      translate={this.props.translate}
                      location={this.props.location}
                    />
                  </div>
                  <div className="row">
                    <MobileButtons
                      translate={this.props.translate}
                      handleShowMobileFilter={this.handleShowMobileFilter}
                    />
                  </div>
                  <div className="results d-flex justify-content-start align-items-start flex-wrap">
                    {this.state.isLoading || this.state.isProductsLoading ? (
                      <div className="mx-auto">
                        <BLLoader />
                      </div>
                    ) : this.state.products && this.state.products.length ? (
                      this.state.products.map((data, index) => (
                        <Product
                          key={data.id}
                          product={data}
                          currency={this.props.user.currencyPref}
                          onClick={(product) =>
                            this.handleItemClick({
                              ...product,
                              index: index + 1,
                            })
                          }
                        />
                      ))
                    ) : (
                      <h4 className="text-center w-100">
                        {this.props.translate(
                          "global.no_product_category_text"
                        )}
                      </h4>
                    )}
                  </div>
                  <CSSTransition
                    in={
                      !this.state.isLoading &&
                      !this.state.isProductsLoading &&
                      this.state.hasMore
                    }
                    timeout={0}
                    unmountOnExit
                  >
                    <div className="load-more-button-holder d-flex justify-content-center mt-4 mb-4">
                      {this.state.isLoadingMore ? (
                        <BLLoader />
                      ) : (
                        <BLButton
                          type="pri"
                          classes="px-5 mx-auto"
                          clicked={this.handleLoadMore}
                        >
                          {this.props.translate("global.load_more")}
                        </BLButton>
                      )}
                    </div>
                  </CSSTransition>

                  {(!!this.state.category?.seo_content ||
                    !!this.state.category?.seo_images?.length) && (
                    <div className="category-seo-content overflow-auto">
                      {!!this.state.category.seo_images?.length && (
                        <div className="d-flex flex-column w-100">
                          {this.state.category.seo_images.map((img, index) => (
                            <img
                              key={index}
                              src={img?.images}
                              alt={`${this.state.category?.name} SEO`}
                              className="mw-100 mb-1"
                            />
                          ))}
                        </div>
                      )}
                      {!!this.state.category.seo_content && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.category?.seo_content,
                          }}
                          className="py-3 m-0 mw-100"
                        />
                      )}
                    </div>
                  )}
                </section>
              </section>
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.isMobileSortOn}
          timeout={300}
          unmountOnExit
          classNames="bl-drawer"
        >
          <BLDrawer
            title={this.props.translate("category.sort")}
            handleCloseDrawer={() => this.handleCloseMobileFilter("sort")}
          >
            <MobileSort
              handleApplyFilter={this.handleApplyFilter}
              selectedSort={this.state.filters.sort}
              translate={this.props.translate}
              handleCloseMobileFilter={this.handleCloseMobileFilter}
            />
          </BLDrawer>
        </CSSTransition>
        <CSSTransition
          in={this.state.isMobileFiltersOn}
          timeout={300}
          unmountOnExit
          classNames="bl-drawer"
        >
          <BLDrawer
            title={this.props.translate("category.filter")}
            handleCloseDrawer={() => this.handleCloseMobileFilter("filters")}
          >
            <MobileFilters
              translate={this.props.translate}
              subCats={
                this.state.catType === "subCatPage"
                  ? this.state.category?.siblings
                  : this.state.category?.children
              }
              locations={this.state.locations}
              categories={this.state.categories}
              slug={this.state.slug}
              handleApplyFilter={this.handleApplyFilter}
              handleSelectCat={this.handleSelectCat}
              cat={{
                ...this.state.category,
                name: this.state.category?.name,
                slug: this.state.category?.slug,
                type: this.state.catType === "subCatPage" ? "subCat" : "cat",
              }}
              filters={this.state.filters}
              handleCloseMobileFilter={this.handleCloseMobileFilter}
              lang={this.props.lang}
              handleFilterDataLayers={this.handleFilterDataLayers}
            />
          </BLDrawer>
        </CSSTransition>
      </div>
    );
  }
}
