import React, { Component } from "react";
import PropTypes from "prop-types";

import BLButton from "../../utils/components/BLButton";
import { validateInput, handleErrors } from "../../utils/helper";
import API, { headers } from "../../utils/API";
import BLLoader from "../../utils/components/BLLoader";
import toastr from "toastr";
import BLInput from "../../utils/components/BLInput";
import BLDropdown from "../../utils/components/BLDropdown";

export default class Contact extends Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
  };

  initialState = {
    fullname: "",
    email: "",
    phone: "",
    phoneCountryCode: "TR",
    areaCode: 90,
    companyName: "",
    number_of_employee: "",
    message: "",
  };

  state = {
    fullname: "",
    email: "",
    phone: "",
    phoneCountryCode: "TR",
    areaCode: 90,
    companyName: "",
    number_of_employee: "",
    message: "",
    isOkayToSend: false,
    isSending: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.fullname !== this.state.fullname ||
      prevState.email !== this.state.email ||
      prevState.phone !== this.state.phone ||
      prevState.companyName !== this.state.companyName ||
      prevState.number_of_employee !== this.state.number_of_employee ||
      prevState.message !== this.state.message
    ) {
      if (
        this.state.fullname &&
        this.state.email &&
        validateInput("email", this.state.email).success &&
        this.state.phone &&
        validateInput("phone", this.state.phone).success &&
        this.state.companyName &&
        this.state.number_of_employee &&
        this.state.message
      ) {
        this.setState({ isOkayToSend: true });
      } else {
        this.setState({ isOkayToSend: false });
      }
    }
  };

  handleChange = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  };

  setEmployees = (number_of_employee) => this.setState({ number_of_employee });

  handleValidateInput = (type, value, name) => {
    if (!validateInput(type, value)) {
      this.setState({ [name]: "" });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSending: true });

    const config = { headers: { ...headers } };

    const params = {
      name: this.state.fullname,
      email: this.state.email,
      phone: this.state.areaCode + "-" + this.state.phone,
      phone_cc: this.state.phoneCountryCode,
      company_name: this.state.companyName,
      number_of_employee: this.state.number_of_employee,
      description: this.state.message,
    };

    API.post("gift_card/enterprise/contact", params, config)
      .then((response) => {
        toastr.clear();
        toastr.success(response.data.info.message);

        this.setState({ ...this.initialState, isSending: false });
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isSending: false });
      });
  };

  handleUpdateCountryCode = (value) => {
    const valueArr = value.split("+");

    const phoneCountryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    this.setState({ phoneCountryCode, areaCode });
  };

  render() {
    return (
      <section className="contact" id="contact" ref={this.props.myRef}>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <h6 className="page-title">
                {this.props.translate("corporate_voucher.contact_us.title")}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <p className="desc text-center">
                {this.props.translate(
                  "corporate_voucher.contact_us.call_the_number_for_support"
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <span className="form-info d-flex text-center">
                {this.props.translate("corporate_voucher.contact_us.form_info")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <form className="contact-form" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12 d-flex flex-column align-items-start">
                    <BLInput
                      type="text"
                      name="fullname"
                      label={this.props.translate(
                        "corporate_voucher.contact_us.fullname"
                      )}
                      value={this.state.fullname}
                      changed={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                    <BLInput
                      type="email"
                      name="email"
                      label={this.props.translate(
                        "corporate_voucher.contact_us.email_address"
                      )}
                      value={this.state.email}
                      changed={this.handleChange}
                      validationType="email"
                      inputProps={{ inputMode: "email" }}
                    />
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                    <BLInput
                      type="tel"
                      name="phone"
                      label={this.props.translate(
                        "corporate_voucher.contact_us.mobile_phone"
                      )}
                      value={this.state.phone}
                      changed={this.handleChange}
                      validationType="phone"
                      setCountryCode={this.handleUpdateCountryCode}
                      countryCode={
                        this.state.phoneCountryCode + " +" + this.state.areaCode
                      }
                      inputProps={{ inputMode: "tel" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                    <BLInput
                      type="text"
                      name="companyName"
                      label={this.props.translate(
                        "corporate_voucher.contact_us.company_name"
                      )}
                      value={this.state.companyName}
                      changed={this.handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column align-items-start">
                    <BLDropdown
                      placeholder={this.props.translate(
                        "corporate_voucher.contact_us.number_of_employee"
                      )}
                      name="number_of_employee"
                      default={this.state.number_of_employee}
                      setValue={(value) => this.setEmployees(value)}
                      options={[
                        { name: "1-9", value: "1-9" },
                        { name: "10-24", value: "10-24" },
                        { name: "25-49", value: "25-49" },
                        { name: "50-99", value: "50-99" },
                        { name: "100+", value: "100+" },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex flex-column align-items-start">
                    <BLInput
                      type="textarea"
                      name="message"
                      label={this.props.translate(
                        "corporate_voucher.contact_us.proposal_desc"
                      )}
                      value={this.state.message}
                      changed={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    {this.state.isSending ? (
                      <span className="mx-auto">
                        <BLLoader />
                      </span>
                    ) : (
                      <BLButton
                        type="sec"
                        classes="send-button"
                        action="submit"
                        disabled={!this.state.isOkayToSend}
                      >
                        {this.props.translate(
                          "corporate_voucher.contact_us.ask_for_a_proposal"
                        )}
                      </BLButton>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
