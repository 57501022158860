import React from "react";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";

const HelpBox = ({ cat, lang }) => {
  return (
    <div
      className={`d-flex flex-column align-items-center align-items-md-start justify-content-between w-100 position-relative help-box ${
        !cat.contents.length ? `coming-soon` : ``
      }`}
    >
      <div
        className="d-flex justify-content-center align-items-center icon"
        dangerouslySetInnerHTML={{ __html: cat.icon }}
      ></div>
      <div className="d-flex flex-column align-items-start content-holder">
        <h3 className="mt-0 mb-0 mb-md-2 title">
          <Link
            to={
              cat.contents.length
                ? global.helpDetail.links[lang]
                    .replace(":cat", cat.slug)
                    .replace(":name", cat.contents[0].slug)
                : "#!"
            }
            className="text-reset text-decoration-none stretched-link"
            onClick={
              !cat.contents.length ? (e) => e.preventDefault() : () => {}
            }
          >
            {cat.title}
          </Link>
        </h3>
        <p
          className="d-none d-md-block desc"
          dangerouslySetInnerHTML={{ __html: cat.description }}
        ></p>
      </div>
    </div>
  );
};

export default HelpBox;
