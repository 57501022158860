import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NotFound from "../App/NotFound";
import API from "../utils/API";
import { handleErrors, isCrawler } from "../utils/helper";
import Sidebar from "./components/Sidebar";

import { global } from "../App/routes";
import MobileNav from "./components/MobileNav";
import PrerenderRedirect from "../utils/components/PrerenderRedirect";

const HelpDetail = ({ translate, lang, match, history, location }) => {
  const [notFound, setNotFound] = useState(false);
  const [cats, setCats] = useState([]);
  const [content, setContent] = useState("");
  const [wrongSlugRedirectedTo, setWrongSlugRedirectedTo] = useState(false);

  useEffect(() => {
    API.get("content/categories")
      .then(({ data: r }) => {
        const { data: cats } = r;
        setCats(cats);
      })
      .catch((err) => handleErrors(err));

    const { name: slug } = match.params;

    API.get(`content/detail/${slug}`)
      .then(({ data: r }) => {
        const { data: content } = r;

        setContent(content);

        if (match.params.cat !== content.categories[0].slug) {
          const url = global.helpDetail.links[lang]
            .replace(":cat", content.categories[0].slug)
            .replace(":name", content.slug);

          history.push(url);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.code === 404)
          setNotFound(true);

        if (err?.response?.status === 301)
          if (isCrawler())
            setWrongSlugRedirectedTo(
              window.location.pathname.replace(
                err.response?.data?.header?.previous_slug,
                err.response?.data?.header?.slug
              ) + window.location.search
            );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.name]);

  useEffect(() => {
    if (!notFound) {
      if (
        content.translations &&
        Object.keys(content.translations).length &&
        content.categories &&
        content.categories.length &&
        content.categories[0].translations &&
        Object.keys(content.categories[0].translations).length
      ) {
        const { slug: name } = content.translations[lang],
          { slug: cat } = content.categories[0].translations[lang];

        const url = global.helpDetail.links[lang]
          .replace(":cat", cat)
          .replace(":name", name);

        history.push(url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return notFound ? (
    <NotFound translate={translate} lang={lang} />
  ) : wrongSlugRedirectedTo ? (
    <PrerenderRedirect to={wrongSlugRedirectedTo} />
  ) : (
    <div className="HelpDetail">
      <Helmet>
        <title>{translate("nav.help_center")}</title>
        <meta name="description" content={content.meta_description} />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-3 d-none d-md-block">
            <Sidebar cats={cats} lang={lang} match={match} />
          </div>
          <div className="col-12 d-block d-md-none">
            <MobileNav
              translate={translate}
              cats={cats}
              content={content}
              lang={lang}
              location={location}
            />
          </div>
          <div className="col col-12 col-md-9">
            <div className="Detail">
              <h2 className="detail-title">{content.title}</h2>
              <div
                className="detail-content"
                dangerouslySetInnerHTML={{ __html: content.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpDetail;
