import React from "react";

const rightArrow = (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5 5L1 9"
      stroke="black"
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MediaSidebar = (props) => {
  return (
    <div className="sidebar-menu-holder d-flex justify-content-center align-items-center justify-content-md-start">
      <select
        name="selectedPage"
        id="selectedPage"
        value={props.selectedPage}
        onChange={(e) => props.setSelectedPage(e.target.value)}
        className="d-block d-md-none w-100"
      >
        {props.menuItems.map((data) =>
          data.isHidden ? (
            false
          ) : (
            <option key={data.id} value={data.id}>
              {props.translate(data.title)}
            </option>
          )
        )}
      </select>
      <ul className="d-none d-md-flex flex-column w-100">
        {props.menuItems.map((data) =>
          data.isHidden ? (
            false
          ) : (
            <li
              key={data.id}
              className="w-100 d-flex justify-content-between align-items-center position-relative"
              data-active={String(props.selectedPage) === String(data.id)}
              onClick={() => props.setSelectedPage(String(data.id))}
            >
              {props.translate(data.title)}
              {rightArrow}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default MediaSidebar;
