import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import BLTabs from "./BLTabs";

const BLBannerWTabs = (props) => {
  const lang = props.activeLanguage
    ? props.activeLanguage.code
    : props.defaultLanguage;

  return (
    <section
      className={`bl-banner d-flex flex-column justify-content-between align-items-start ${props.classes}`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="banner-holder w-100">
              <>{props.children}</>
              <BLTabs lang={lang} tabs={props.tabs} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BLBannerWTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      links: PropTypes.object.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      order: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  children: PropTypes.any.isRequired,
  classes: PropTypes.string,
};

export default withLocalize(BLBannerWTabs);
