import React, { useEffect } from "react";
import CatThumb from "../../utils/components/CatThumb";
import { global } from "../../App/routes";

import { Link } from "react-router-dom";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder/lib";
import API from "../../utils/API";
import ProductSlider from "./PopularActivities/ProductSlider";

const ProductPlaceholder = () => (
  <div className="product-placeholder">
    <RectShape
      className="cover-placeholder placeholder-item"
      style={{ height: "auto", margin: 0, marginBottom: 10 }}
    />
    <TextBlock
      className="cat-placeholder placeholder-item"
      style={{ width: "20%", marginBottom: 8 }}
    />
    <TextBlock
      className="name-placeholder placeholder-item"
      rows={2}
      style={{ width: "75%", marginBottom: 5 }}
    />
    <div className="stats-placeholder d-flex justify-content-start align-items-center">
      <RectShape
        className="stat-placeholder placeholder-item"
        style={{
          width: 49,
          height: 22,
          borderRadius: 2,
          marginRight: 12,
          marginTop: 10,
          marginBottom: 15,
        }}
      />
      <TextBlock
        className="price-placeholder placeholder-item"
        row={1}
        style={{ width: 50 }}
      />
    </div>
    <TextBlock
      className="price-placeholder placeholder-item"
      row={1}
      style={{ width: 50, marginBottom: 15 }}
    />
    <TextBlock
      className="availability-placeholder placeholder-item"
      row={1}
      style={{ width: 75, marginBottom: 15 }}
    />
  </div>
);

const locationProductPlaceholder = (
  <div className="product-slider-placeholder w-100 d-flex justify-content-center align-items-center overflow-hidden">
    <div className="products-holder d-flex d-md-none justify-content-center align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
    <div className="products-holder d-none d-md-flex justify-content-start align-items-center">
      {Array.from(Array(4).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
  </div>
);

const placeholder = (
  <div className="cat-thumb-placeholder overflow-auto w-100">
    {Array.from(Array(8).keys()).map((data) => (
      <RectShape
        key={data}
        className="placeholder-item m-0"
        style={{ width: "inherit", height: "inherit", margin: "inherit" }}
      />
    ))}
  </div>
);

export default function TrendLocations(props) {
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [isProductsLoading, setIsProductsLoading] = React.useState(false);

  useEffect(() => {
    if (props.trendLocations.length && selectedLocation === null)
      setSelectedLocation(props.trendLocations[0]);
  }, [props.trendLocations, selectedLocation]);

  useEffect(() => {
    if (selectedLocation?.id) getSelectedLocationProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.currencyPref?.code, props.user?.langPref, selectedLocation]);

  const getSelectedLocationProducts = () => {
    setIsProductsLoading(true);
    API.get(
      `products?currency=${props.user?.currencyPref?.code}&location_ids[]=${selectedLocation?.id}&limit=8&page=1`
    )
      .then(({ data: res }) => {
        setProducts(res.data);
        setIsProductsLoading(false);
      })
      .catch(() => setIsProductsLoading(false));
  };

  return (
    <section className="trend-locations" ref={props.propRef}>
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center flex-column">
            <h4 className="section-title text-center">
              {props.translate("home.trend_locations.title")}
            </h4>
            <p className="section-desc text-center">
              {props.translate("home.trend_locations.desc")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div
              className={`cats-holder locations mx-auto overflow-auto mw-100 ${
                !props.isReady
                  ? `d-flex justify-content-center overflow-hidden w-100`
                  : ``
              }`}
            >
              <ReactPlaceholder
                ready={props.isReady}
                customPlaceholder={placeholder}
                showLoadingAnimation
              >
                {props.trendLocations &&
                  props.trendLocations.map((data, index) => {
                    const url = global.activity.links[props.lang].replace(
                      ":location",
                      data.slug
                    );
                    return (
                      <CatThumb
                        key={data.id}
                        link={url}
                        name={data.name}
                        image={data.thumb_image}
                        handlePromotionClick={
                          props.handlePromotionClick
                            ? () =>
                                props.handlePromotionClick(
                                  { title: data.name, index: index + 1 },
                                  `En Favori Lokasyonlar`
                                )
                            : null
                        }
                        homeFix
                        className={
                          selectedLocation?.id === data.id ? `active` : ``
                        }
                        onClick={() => setSelectedLocation(data)}
                      />
                    );
                  })}
              </ReactPlaceholder>
            </div>
          </div>
        </div>
        {selectedLocation && (
          <div className="row mt-4">
            <div className="col">
              <ReactPlaceholder
                ready={!isProductsLoading}
                customPlaceholder={locationProductPlaceholder}
                showLoadingAnimation
              >
                <ProductSlider
                  currency={props.user?.currencyPref}
                  products={products}
                  user={props.user}
                  slidesPerView={props.slidesPerView}
                />
              </ReactPlaceholder>
            </div>
          </div>
        )}
        {!props.hideButton && (
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Link
                to={global.activities.links[props.lang]}
                className="show-all-button d-flex align-items-center"
              >
                {props.translate("global.view_all_locations")}
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 6.5L1 12"
                    stroke="#E75B2B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
