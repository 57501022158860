import React from "react";
import PropTypes from "prop-types";
import BLRating from "../../utils/components/BLRating";
import BLLoader from "../../utils/components/BLLoader";
import { global } from "../../App/routes";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <>
      {
        <BLBreadcrumbs
          breadcrumbs={[
            {
              id: 0,
              name: props.translate("global.homepage"),
              route: global.home.links[props.lang],
            },
            {
              id: 1,
              name: props.product.category[0].name,
              route: global.catPage.links[props.lang].replace(
                ":main_cat",
                props.product.category[0].slug
              ),
            },
            {
              id: 2,
              name: props.product.category[1].name,
              route: global.subCatPage.links[props.lang]
                .replace(":main_cat", props.product.category[0].slug)
                .replace(":sub_cat", props.product.category[1].slug),
            },
          ]}
          color="black"
        />
      }
      <h1 className="experience-title selectable w-100 text-left">
        {props.product.title}
      </h1>
      {Boolean(props.provider.slug) && (
        <h6 className="supplier">
          <span>{props.translate("global.supplier")}:</span>{" "}
          <Link
            to={global.supplier.links[props.lang].replace(
              ":slug",
              props.provider.slug
            )}
          >
            {props.provider.name}
          </Link>
        </h6>
      )}
      <div className="experience-extra d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
        <div className="left-side d-flex align-items-center">
          <BLRating
            translate={props.translate}
            rating={props.product.stats.rate}
            tooltip={true}
          />
          <div className="comments info-holder d-flex justify-content-center align-items-center">
            <span>
              {props.product.stats.review} {props.translate("product.comment")}
            </span>
          </div>
        </div>
        <div className="right-side">
          {props.isAddingToBucketlist ? (
            <BLLoader />
          ) : (
            <button
              className={`add-to-your-list-button ${
                props.inBucketlist ? `added` : ``
              }`}
              onClick={
                props.isAddingToBucketlist
                  ? () => {}
                  : props.handleToggleBucketlistStatus
              }
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="26" height="26" rx="13" fill="#D4D4D4" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2323 6.59945L10.9672 15.8021L8.35976 12.1626L3.25586 12.3461C4.80057 13.9868 5.84078 14.9565 7.41416 16.5708C8.59724 17.786 9.53614 19.4457 11.5089 18.802C13.1008 18.2824 19.1461 10.9274 21.0686 8.78999L16.2323 6.59945Z"
                  fill="white"
                />
              </svg>
              <span>
                {props.isAddingToBucketlist
                  ? props.inBucketlist
                    ? props.translate(
                        "experience.removing_from_your_bucketlist"
                      )
                    : props.translate("experience.adding_to_your_bucketlist")
                  : props.inBucketlist
                  ? props.translate("experience.in_your_bucketlist")
                  : props.translate("experience.add_to_your_bucketlist")}
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Header;
