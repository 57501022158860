import React, { Component } from "react";
import Swiper from "swiper";

import { bankInfo } from "../../../../utils/db/bankInformation";
import BLInfoArea from "../../../../utils/components/BLInfoArea";

export default class MoneyTransfer extends Component {
  state = {
    isLeftButtonActive: false,
    isRightButtonActive: true,
    selectedBank: 0,
    activeIndex: 0,
  };

  swiper = "";
  slidesPerView = 3;

  componentDidMount = () => {
    this.swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 15,
      freeMode: true,
      freeModeSticky: false,
      on: {
        slideChange: () => this.handleCheckButtons(),
      },
      breakpoints: {
        // when window width is >= ?
        767: {
          slidesPerView: 3,
        },
      },
    });
  };

  handleCheckButtons = () => {
    if (this.swiper.isBeginning && this.state.isLeftButtonActive)
      this.setState({ isLeftButtonActive: false });
    else if (!this.swiper.isBeginning && !this.state.isLeftButtonActive)
      this.setState({ isLeftButtonActive: true });

    if (this.swiper.isEnd && this.state.isRightButtonActive)
      this.setState({ isRightButtonActive: false });
    else if (!this.swiper.isEnd && !this.state.isRightButtonActive)
      this.setState({ isRightButtonActive: true });
  };

  handleChangeSlide = (dir) => {
    if (dir === "prev") {
      this.swiper.slidePrev();
    } else {
      this.swiper.slideNext();
    }

    this.handleCheckButtons();
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="banks-header position-relative">
              <button
                className="slide-button left d-flex justify-content-center align-items-center position-absolute"
                data-active={this.state.isLeftButtonActive}
                onClick={() => this.handleChangeSlide("prev")}
              >
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.30289 1.5L1 8.34976L8.30289 15.1995"
                    stroke="#E75B2B"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="banks-holder">
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {bankInfo.map((data) => (
                      <div
                        key={data.id}
                        className="swiper-slide bank d-flex justify-content-center align-items-center position-relative"
                        data-active={this.state.selectedBank === data.id}
                        onClick={() => this.setState({ selectedBank: data.id })}
                      >
                        <img src={data.logo} alt={`${data.name} Logo`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                className="slide-button right d-flex justify-content-center align-items-center position-absolute"
                data-active={this.state.isRightButtonActive}
                onClick={() => this.handleChangeSlide("next")}
              >
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.99984 1.5L8.30273 8.34976L0.99984 15.1995"
                    stroke="#E75B2B"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="bank-info-holder">
              <div className="info">
                <h6 className="title">
                  {this.props.translate(
                    "payment.pick_a_payment_method.bank_or_branch"
                  )}
                  :
                </h6>
                <p className="content">
                  {bankInfo[this.state.selectedBank].branch}
                </p>
              </div>
              <div className="info">
                <h6 className="title">
                  {this.props.translate(
                    "payment.pick_a_payment_method.branch_code"
                  )}
                  :
                </h6>
                <p className="content">
                  {bankInfo[this.state.selectedBank].branchCode}
                </p>
              </div>
              <div className="info">
                <h6 className="title">
                  {this.props.translate("payment.pick_a_payment_method.iban")}:
                </h6>
                <p className="content">
                  {bankInfo[this.state.selectedBank].iban}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <BLInfoArea type="info">
              <div className="info-content-holder w-100 d-flex flex-column align-items-start">
                <h6 className="title d-flex align-items-center">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.085 0C3.62693 0 0 3.62698 0 8.08505C0 12.5431 3.62693 16.17 8.085 16.17C12.5431 16.17 16.17 12.5431 16.17 8.08505C16.17 3.62698 12.5431 0 8.085 0ZM8.0849 3.43C7.54463 3.43 7.1051 3.86982 7.1051 4.41044C7.1051 4.95057 7.54463 5.39 8.0849 5.39C8.62518 5.39 9.06471 4.95057 9.06471 4.41044C9.06471 3.86982 8.62518 3.43 8.0849 3.43ZM7.35 7.595C7.35 7.18908 7.67908 6.86 8.085 6.86C8.49092 6.86 8.82 7.18908 8.82 7.595V12.005C8.82 12.4109 8.49092 12.74 8.085 12.74C7.67908 12.74 7.35 12.4109 7.35 12.005V7.595Z"
                      fill="#C59100"
                    />
                  </svg>
                  <span>
                    {this.props.translate(
                      "payment.pick_a_payment_method.info_title"
                    )}
                  </span>
                </h6>
                <ul className="content">
                  <li>
                    {this.props.translate(
                      "payment.pick_a_payment_method.info_content_1"
                    )}
                  </li>
                  <li>
                    {this.props.translate(
                      "payment.pick_a_payment_method.info_content_2"
                    )}
                  </li>
                  <li>
                    {this.props.translate(
                      "payment.pick_a_payment_method.info_content_3"
                    )}
                  </li>
                </ul>
              </div>
            </BLInfoArea>
          </div>
        </div>
      </>
    );
  }
}
