import React from "react";
import PropTypes from "prop-types";
import BLCollapsible from "../../utils/components/BLCollapsible";
import HorizontalReservedProduct from "./HorizontalReservedProduct";
import { Link } from "react-router-dom";
import { auth } from "../../App/routes";
import { copyCode } from "../../utils/helper";
import BOOKING_MODE from "../../utils/constants";

const CommentButton = (props) => {
  return props.enabled ? (
    <a
      href="#!"
      className="make-review-button d-flex justify-content-center align-items-center"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.showReview();
      }}
    >
      <svg
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8379 0H3.16208C1.41855 0 0 1.40751 0 3.1381V13.266C0 14.9927 1.41237 16.398 3.15085 16.4041V21L9.80616 16.4041H19.8379C21.5815 16.4041 23 14.9963 23 13.266V3.1381C23 1.40751 21.5815 0 19.8379 0ZM21.6523 13.266C21.6523 14.2588 20.8384 15.0667 19.8379 15.0667H9.38334L4.4985 18.4401V15.0667H3.16208C2.16158 15.0667 1.34766 14.2588 1.34766 13.266V3.1381C1.34766 2.14505 2.16158 1.33744 3.16208 1.33744H19.8379C20.8384 1.33744 21.6523 2.14505 21.6523 3.1381V13.266ZM16.8442 4.72575H6.15584V6.06319H16.8442V4.72575ZM6.15584 7.57895H16.8442V8.91639H6.15584V7.57895ZM16.8442 10.4322H6.15584V11.7696H16.8442V10.4322Z"
          fill="black"
        />
      </svg>
      <span>{props.translate("profile.reservations_page.review_button")}</span>
    </a>
  ) : (
    ""
  );
};

const SingleReservation = (props) => {
  const showReview = () => props.handleShowReview(props.oid);

  return (
    <>
      <BLCollapsible
        classes="single-reservation-holder"
        summaryContents={[
          props.product.title,
          props.couponCode ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                copyCode(e);
              }}
              className="copy-on-click position-relative selectable user-select-all"
              data-copied-text={props.translate("global.copied")}
            >
              {props.couponCode}
            </div>
          ) : (
            ""
          ),
          props.couponStatus,
          <CommentButton
            classes="d-none d-md-flex"
            enabled={props.isCompleted && !props.isReviewed}
            showReview={showReview}
            translate={props.translate}
          />,
        ]}
      >
        <>
          <div className="products-list d-flex flex-column">
            <div className="product-holder d-flex flex-column flex-md-row justify-content-between align-items-start">
              <HorizontalReservedProduct
                translate={props.translate}
                product={props.product}
                lang={props.lang}
                currency={props.currency}
              />
              <div className="extra-info-area d-md-flex flex-column align-items-end">
                {props.bookingMode !== BOOKING_MODE.TICKET && (
                  <div className="infos d-flex flex-column align-items-start">
                    <h6 className="sec-title">
                      {props.translate(
                        "profile.reservations_page.reservation_info"
                      )}
                    </h6>
                    {props.reservationCode ? (
                      <>
                        <div className="info-holder d-flex justify-content-start align-items-center">
                          <div className="info-title">
                            {props.translate(
                              "profile.reservations_page.reservation_status"
                            )}
                          </div>
                          <div className="info-content selectable">
                            {props.reservationStatus}
                          </div>
                        </div>
                        <div className="info-holder d-flex justify-content-start align-items-center">
                          <div className="info-title">
                            {props.translate(
                              "profile.reservations_page.reservation_code"
                            )}
                          </div>
                          <div className="info-content selectable">
                            {props.reservationCode}
                          </div>
                        </div>
                        <div className="info-holder d-flex justify-content-start align-items-center">
                          <div className="info-title">
                            {props.translate(
                              "profile.reservations_page.preferred_date"
                            )}
                          </div>
                          <div className="info-content selectable">
                            {props.product.booking[0].preferred_date}
                          </div>
                        </div>
                        <CommentButton
                          enabled={props.isCompleted && !props.isReviewed}
                          showReview={showReview}
                          translate={props.translate}
                        />
                      </>
                    ) : (
                      <>
                        <div className="info-area">
                          {props.translate(
                            "profile.reservations_page.no_reservation_for_this_coupon"
                          )}
                        </div>
                        <Link
                          className="use-coupon-link d-flex justify-content-center align-items-center w-auto"
                          to={auth.redeemedReservation.links[
                            props.lang
                          ].replace(":id", props.couponCode)}
                        >
                          {props.translate(
                            "profile.reservations_page.book_now"
                          )}
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </BLCollapsible>
    </>
  );
};

SingleReservation.propTypes = {
  reservationCode: PropTypes.string,
  couponCode: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  date: PropTypes.string,
};

export default SingleReservation;
