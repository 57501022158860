import React, { Component } from "react";

import BLLoader from "../../../utils/components/BLLoader";
import { currencyFormatter } from "../../../utils/helper";
import BLDropdown from "../../../utils/components/BLDropdown";
import Amounts from "./components/Amounts";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

export default class OpenEnded extends Component {
  state = {
    counts: {},
    preferredDate: "",
    preferredHour: "",
    alternativeDate: "",
    alternativeHour: "",
    totalPrice: parseFloat(0.0).toFixed(2),
    isLoading: true,
    isFilled: false,
  };

  componentDidUpdate = () => {
    if (this.state.isFilled !== this.checkIfFilled()) {
      this.setState({ isFilled: this.checkIfFilled() });
    }
  };

  checkIfFilled = () => {
    const addedCount = Object.keys(this.state.counts).filter(
      (key) => this.state.counts[key].count > 0
    );

    if (!addedCount.length) return false;

    return true;
  };

  render() {
    return (
      <div className="option-holder pick-a-date">
        <p className="desc">
          {this.props.translate("package_options.open_ended_tab.answer")}
        </p>
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <BLDropdown
                name="package"
                options={this.props.product.packages?.map((item) => ({
                  name: item.title,
                  value: item.id,
                }))}
                placeholder={this.props.translate("experience.select_package")}
                default={this.props.selectedPackage?.id}
                setValue={(value) => {
                  this.props.handleSetSelectedPackage(
                    this.props.product.packages.find(
                      (item) => String(item.id) === String(value)
                    )
                  );
                }}
              />
            </div>
          </div>

          <Amounts
            isLoading={this.state.isLoading}
            units={this.props.units}
            isUpdate={this.props.isUpdate}
            isVoucher={this.props.isVoucher}
            currency={this.props.currency}
            selectedPackage={this.props.selectedPackage}
            handleUpdateCounts={(counts) =>
              this.setState({ counts, isLoading: false })
            }
            handleCartDataLayers={this.props.handleCartDataLayers}
            preferredDate={this.state.preferredDate}
            handleUpdateTotalPrice={(totalPrice) =>
              this.setState({ totalPrice })
            }
            packageType={PACKAGE_TYPES.OPENENDED}
          />

          <div className="row">
            <div className="col d-flex justify-content-end align-items-end">
              <div className="total selectable">
                {this.props.translate("global.total")}{" "}
                <span>
                  {currencyFormatter(
                    this.props.currency,
                    this.state.totalPrice,
                    this.props.lang
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="disclaimer">
                {this.props.translate(
                  "package_options.fill_all_fields_to_continue"
                )}
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 offset-sm-6 offset-md-2 d-flex justify-content-end align-items-center">
              {this.props.isAddingToCart ? (
                <BLLoader />
              ) : this.props.isUpdate ? (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleUpdatePackage(this.props.itemId, {
                      priceOptions: { ...this.state.counts },
                      packageType: PACKAGE_TYPES.OPENENDED,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled || this.props.isUpdating}
                >
                  {this.props.isUpdating ? (
                    <div className="loader w-100 h-100 d-flex justify-content-center align-items-center">
                      <BLLoader loaderType="dots" />
                    </div>
                  ) : (
                    this.props.translate("global.update")
                  )}
                </button>
              ) : (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleBuyNow({
                      priceOptions: { ...this.state.counts },
                      packageType: PACKAGE_TYPES.OPENENDED,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled}
                >
                  {this.props.translate("global.buy_it_now")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
