import React, { Component } from "react";

import BLInput from "../../../utils/components/BLInput";
import BLLoader from "../../../utils/components/BLLoader";
import BLDatePicker from "../../../utils/components/BLDatePicker";
import dayjs from "dayjs";
import { currencyFormatter, validateInput } from "../../../utils/helper";
import BLDropdown from "../../../utils/components/BLDropdown";
import Amounts from "./components/Amounts";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

export default class Gift extends Component {
  state = {
    counts: {},
    firstName: this.props.isUpdate
      ? this.props.item.delivery_recipient_first_name
      : "",
    lastName: this.props.isUpdate
      ? this.props.item.delivery_recipient_last_name
      : "",
    email: this.props.isUpdate ? this.props.item.delivery_recipient_email : "",
    phone: this.props.isUpdate
      ? this.props.item.delivery_recipient_phone_w_out_code
      : "",
    areaCode: this.props.isUpdate
      ? this.props.item.delivery_recipient_area_code
      : "90",
    countryCode: this.props.isUpdate
      ? this.props.item.delivery_recipient_phone_cc
      : "TR",
    deliveryDate: this.props.isUpdate
      ? this.props.item.delivery_recipient_send_date
      : "",
    message: this.props.isUpdate ? this.props.item.gift_message : "",
    totalPrice: parseFloat(0.0).toFixed(2),
    isLoading: true,
    isFilled: false,
  };

  componentDidMount = () => {
    if (this.props.isVoucher) this.setState({ totalPrice: this.props.amount });
  };

  componentDidUpdate = () => {
    if (this.state.isFilled !== this.checkIfFilled()) {
      this.setState({ isFilled: this.checkIfFilled() });
    }
  };

  checkIfFilled = () => {
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.email === "" ||
      !validateInput("email", this.state.email).success ||
      this.state.phone === "" ||
      !validateInput("phone", this.state.phone).success ||
      this.state.deliveryDate === "" ||
      this.state.deliveryDate === "Invalid date" ||
      this.state.message === ""
    )
      return false;

    if (!this.props.isVoucher) {
      const addedCount = Object.keys(this.state.counts).filter(
        (key) => this.state.counts[key].count > 0
      );

      if (!addedCount.length) return false;
    }

    return true;
  };

  handleSetCountryCode = (value) => {
    const valueArr = value.split("+");

    const countryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    this.setState({ areaCode, countryCode });
  };

  render() {
    return (
      <div className="option-holder pick-a-date">
        <p className={`desc ${this.props.isVoucher ? `mb-3` : ``}`}>
          {this.props.isVoucher
            ? this.props.translate("voucher.package_options_desc")
            : this.props.translate("package_options.gift_tab.answer")}
        </p>
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row">
            <div className="col-12 col-sm-6">
              <BLInput
                label={this.props.translate("global.name")}
                type="text"
                name="firstName"
                value={this.state.firstName}
                changed={(e) => this.setState({ firstName: e.target.value })}
                limitCharacters={["text"]}
              />
            </div>
            <div className="col-12 col-sm-6">
              <BLInput
                label={this.props.translate("global.last_name")}
                type="text"
                name="lastName"
                value={this.state.lastName}
                changed={(e) => this.setState({ lastName: e.target.value })}
                limitCharacters={["text"]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BLInput
                label={this.props.translate("global.email")}
                type="email"
                name="email"
                value={this.state.email}
                changed={(e) => this.setState({ email: e.target.value })}
                validationType="email"
                inputProps={{ inputMode: "email" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <BLInput
                label={this.props.translate("global.phone")}
                type="tel"
                name="phone"
                value={this.state.phone}
                changed={(e) => this.setState({ phone: e.target.value })}
                validationType="phone"
                setCountryCode={(value) => this.handleSetCountryCode(value)}
                countryCode={
                  this.state.countryCode + " +" + this.state.areaCode
                }
                inputProps={{ inputMode: "tel" }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <BLDatePicker
                lang={this.props.lang}
                type="single"
                placeholder={this.props.translate("global.delivery_date")}
                setSelected={(value) =>
                  this.setState({
                    deliveryDate: dayjs(value).format("DD/MM/YYYY"),
                  })
                }
                selected={
                  this.state.deliveryDate
                    ? new Date(dayjs(this.state.deliveryDate, "DD/MM/YYYY"))
                    : null
                }
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BLInput
                label={this.props.translate(
                  "package_options.gift_tab.your_message"
                )}
                type="textarea"
                name="message"
                value={this.state.message}
                changed={(e) => this.setState({ message: e.target.value })}
                charLimit={
                  process.env.REACT_APP_MAX_GIFT_MESSAGE_CHAR
                    ? process.env.REACT_APP_MAX_GIFT_MESSAGE_CHAR
                    : 150
                }
              />
            </div>
          </div>

          {!this.props.isVoucher && (
            <div className="row">
              <div className="col-12 col-md-6">
                <BLDropdown
                  name="package"
                  options={this.props.product?.packages?.map((item) => ({
                    name: item.title,
                    value: item.id,
                  }))}
                  placeholder={this.props.translate(
                    "experience.select_package"
                  )}
                  default={this.props.selectedPackage?.id}
                  setValue={(value) => {
                    this.props.handleSetSelectedPackage(
                      this.props.product.packages.find(
                        (item) => String(item.id) === String(value)
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}

          {!this.props.isVoucher && (
            <Amounts
              isLoading={this.state.isLoading}
              units={this.props.units}
              isUpdate={this.props.isUpdate}
              isVoucher={this.props.isVoucher}
              currency={this.props.currency}
              selectedPackage={this.props.selectedPackage}
              handleUpdateCounts={(counts) =>
                this.setState({ counts, isLoading: false })
              }
              handleCartDataLayers={this.props.handleCartDataLayers}
              preferredDate={this.state.preferredDate}
              handleUpdateTotalPrice={(totalPrice) =>
                this.setState({ totalPrice })
              }
              packageType={PACKAGE_TYPES.GIFT}
            />
          )}

          <div className="row">
            <div className="col d-flex justify-content-end align-items-end">
              <div className="total selectable">
                {this.props.translate("global.total")}{" "}
                <span>
                  {currencyFormatter(
                    this.props.currency,
                    this.state.totalPrice,
                    this.props.lang
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="disclaimer">
                {this.props.translate(
                  "package_options.fill_all_fields_to_continue"
                )}
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 offset-sm-6 offset-md-2 d-flex justify-content-end align-items-center">
              {this.props.isAddingToCart ? (
                <BLLoader />
              ) : this.props.isUpdate ? (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleUpdatePackage(this.props.itemId, {
                      priceOptions: { ...this.state.counts },
                      packageType: this.props.isVoucher
                        ? PACKAGE_TYPES.GIFTCARD
                        : PACKAGE_TYPES.GIFT,
                      firstName: this.state.firstName,
                      lastName: this.state.lastName,
                      email: this.state.email,
                      areaCode: this.state.areaCode,
                      countryCode: this.state.countryCode,
                      phone: this.state.phone,
                      deliveryDate: this.state.deliveryDate,
                      message: this.state.message,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled || this.props.isUpdating}
                >
                  {this.props.isUpdating ? (
                    <div className="loader w-100 h-100 d-flex justify-content-center align-items-center">
                      <BLLoader loaderType="dots" />
                    </div>
                  ) : (
                    this.props.translate("global.update")
                  )}
                </button>
              ) : (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleBuyNow({
                      priceOptions: { ...this.state.counts },
                      packageType: this.props.isVoucher
                        ? PACKAGE_TYPES.GIFTCARD
                        : PACKAGE_TYPES.GIFT,
                      firstName: this.state.firstName,
                      lastName: this.state.lastName,
                      email: this.state.email,
                      areaCode: this.state.areaCode,
                      countryCode: this.state.countryCode,
                      phone: this.state.phone,
                      deliveryDate: this.state.deliveryDate,
                      message: this.state.message,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled}
                >
                  {this.props.translate("global.buy_it_now")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
