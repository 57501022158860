import PickADate from "../../Experience/components/PackageOptions/PickADate";
import OpenEnded from "../../Experience/components/PackageOptions/OpenEnded";
import Gift from "../../Experience/components/PackageOptions/Gift";
import Pass from "../../Experience/components/PackageOptions/Pass";
import PassGift from "../../Experience/components/PackageOptions/PassGift";
import Voucher from "../../Voucher/Voucher";
import BOOKING_MODE from "../constants";

export const PACKAGE_TYPES = {
  PICKADATE: 1,
  OPENENDED: 2,
  GIFT: 3,
  GIFTCARD: 4,
  PASS: 5,
  PASSGIFT: 6,
};

const packageType = {
  [PACKAGE_TYPES.PICKADATE]: {
    id: 0,
    name: "pick_a_date",
    property: PickADate,
    bookingModes: [BOOKING_MODE.DATEANDTIME, BOOKING_MODE.DATEONLY],
    index: 0,
  },
  [PACKAGE_TYPES.OPENENDED]: {
    id: 1,
    name: "open_ended",
    property: OpenEnded,
    bookingModes: [BOOKING_MODE.DATEANDTIME, BOOKING_MODE.DATEONLY],
    index: 1,
    disabled: true,
  },
  [PACKAGE_TYPES.GIFT]: {
    id: 2,
    name: "gift",
    property: Gift,
    bookingModes: [BOOKING_MODE.DATEANDTIME, BOOKING_MODE.DATEONLY],
    index: 2,
  },
  [PACKAGE_TYPES.GIFTCARD]: {
    id: 3,
    name: "gift_card",
    property: Voucher,
    bookingModes: [],
    index: 0,
  },
  [PACKAGE_TYPES.PASS]: {
    id: 4,
    name: "pass",
    property: Pass,
    bookingModes: [BOOKING_MODE.TICKET],
    index: 0,
  },
  [PACKAGE_TYPES.PASSGIFT]: {
    id: 5,
    name: "pass_gift",
    property: PassGift,
    bookingModes: [BOOKING_MODE.TICKET],
    index: 1,
  },
};

export default packageType;
