import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { global } from "../../App/routes";
import { connect } from "react-redux";
import API, { headers } from "../API.js";
import { currencyFormatter, getCurrentRoute, handleErrors } from "../helper";
import { toggleAuthModal } from "../../Auth/authActions";
import {
  dataLayer__addToBucketlist,
  dataLayer__removeFromBucketlist,
} from "../DataLayerEvents";

class Product extends Component {
  state = {
    inBucketlist: false,
  };

  componentDidMount = () => {
    if (this.props.product.bucket_list) this.setState({ inBucketlist: true });
  };

  handleAddToBucketlist = (e, pid) => {
    if (e) e.preventDefault();

    if (!this.props.user.isLoggedIn) {
      this.props.toggleAuthModal();
      return false;
    }

    const params = { pid };

    API.post("bucket_list", params, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.setState(
            (prevState) => {
              return {
                inBucketlist: !prevState.inBucketlist,
              };
            },
            () => {
              getCurrentRoute(
                window.location.pathname,
                this.props.user.langPref
              ).then(({ route }) => {
                const {
                  title,
                  code,
                  discounted_price,
                  price,
                  provider,
                  category,
                  participant_count,
                  stats,
                  video_url,
                  location,
                } = this.props.product;

                const product = {
                  title,
                  code,
                  discounted_price,
                  price,
                  provider,
                  category,
                  participant_count,
                  stats,
                  video_url,
                  location,
                };

                const {
                  hashed_email,
                  id,
                  gender,
                  langPref,
                  currencyPref,
                  has_bought_before,
                } = this.props.user;

                const user = {
                  hashed_email,
                  id,
                  gender: this.props.translate(`global.${gender}`, null, {
                    defaultLanguage: "tr",
                  }),
                  has_bought_before,
                };

                const page = {
                  title: document.title,
                  type: route.type,
                  lang: langPref,
                  currency: currencyPref.code,
                };

                if (this.state.inBucketlist)
                  dataLayer__addToBucketlist(product, page, user);
                else dataLayer__removeFromBucketlist(product, page, user);
              });
            }
          );
        }
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    const lang = this.props.user.langPref;

    let catUrl = "#!";

    if (lang) {
      if (this.props.product.category.length > 1)
        catUrl = global.subCatPage.links[lang]
          .replace(":main_cat", this.props.product.category[0].slug)
          .replace(":sub_cat", this.props.product.category[1].slug);
      else
        catUrl = global.catPage.links[lang].replace(
          ":main_cat",
          this.props.product.category[0].slug
        );
    }

    return (
      <>
        <div
          className="bl-single-product position-relative overflow-hidden"
          data-product={this.props.product.slug}
        >
          <Link
            to={
              lang
                ? global.experience.links[lang].replace(
                    ":name",
                    this.props.product.slug
                  )
                : "#!"
            }
            onClick={
              `onClick` in this.props
                ? () => this.props.onClick(this.props.product)
                : () => {}
            }
          >
            <div
              className="image-holder position-relative overflow-hidden d-flex justify-content-center align-items-center"
              style={{
                backgroundImage: `url("${
                  this.props.product.cover_image_url ||
                  `//via.placeholder.com/284x203`
                }")`,
                backgroundSize: `cover`,
              }}
            >
              <button
                onClick={(e) =>
                  this.handleAddToBucketlist(e, this.props.product.id)
                }
                className={`add-to-bucket-button position-absolute ${
                  this.state.inBucketlist ? `added` : ``
                }`}
                id={`add-p${this.props.product.id}-to-bucket`}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.2"
                    width="26"
                    height="26"
                    rx="13"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.2323 6.59884L10.9672 15.8015L8.35976 12.162L3.25586 12.3455C4.80057 13.9862 5.84078 14.9559 7.41416 16.5702C8.59724 17.7853 9.53614 19.445 11.5089 18.8013C13.1008 18.2818 19.1461 10.9268 21.0686 8.78938L16.2323 6.59884Z"
                    fill="white"
                  />
                </svg>
              </button>
              <img
                src={this.props.product.cover}
                alt={`${this.props.product.title} Cover`}
                className="d-none"
              />
              {this.props.product.discounted_price && (
                <span className="discount-overlay position-absolute d-flex justify-content-center align-items-center">
                  %{this.props.product.discount_percent}{" "}
                  {this.props.translate("product.discount")}
                </span>
              )}
            </div>
          </Link>
          <div className="info-area d-flex flex-column justify-content-between">
            <div className="top-info d-flex flex-column">
              <p className="cat text-uppercase">
                <Link to={catUrl}>
                  {this.props.product.category.length > 1
                    ? this.props.product.category[1].name
                    : this.props.product.category[0].name}
                </Link>
              </p>
              <Link
                to={
                  lang
                    ? global.experience.links[lang].replace(
                        ":name",
                        this.props.product.slug
                      )
                    : "#!"
                }
                onClick={
                  `onClick` in this.props
                    ? () => this.props.onClick(this.props.product)
                    : () => {}
                }
                className="product-name position-relative d-flex"
                title={this.props.product.title}
              >
                <h3 className="overflow-hidden m-0">
                  {this.props.product.title}
                </h3>
              </Link>
              <div className="d-flex justify-content-start align-items-center">
                <p className="location d-flex justify-content-start align-items-center">
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 3.62161C0 1.62465 1.76235 0 3.92857 0C6.09478 0 7.8571 1.62465 7.85714 3.62161C7.85714 6.09989 4.34146 9.73816 4.19177 9.89183C4.05143 10.0359 3.80596 10.0362 3.66537 9.89183C3.51569 9.73816 0 6.09989 0 3.62161ZM1.95202 3.62161C1.95202 4.62634 2.83868 5.44374 3.92857 5.44374C5.01843 5.44374 5.90509 4.62636 5.90509 3.62163C5.90509 2.6169 5.01843 1.7995 3.92857 1.7995C2.8387 1.7995 1.95202 2.61688 1.95202 3.62161Z"
                      fill="#979797"
                    />
                  </svg>
                  {`${this.props.product?.location?.town_name}, ${this.props.product?.location?.city_name}`}
                </p>
                {this.props.product.stats.rate > 0 && (
                  <div className="rating-info position-relative d-flex justify-content-start align-items-center">
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1_8"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="1"
                        width="12"
                        height="11"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.94843 1.3599L4.37477 4.5477L0.857147 5.05876L3.40279 7.54L2.80157 11.0438L5.94843 9.38966L9.09483 11.0438L8.49407 7.54L11.0392 5.05876L7.52163 4.5477L5.94843 1.3599Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1_8)">
                        <rect
                          x="0.857147"
                          y="0.899994"
                          width="10"
                          height="10"
                          fill="#F5C247"
                        />
                      </g>
                    </svg>
                    <span className="rating">
                      {this.props.product.stats.rate}
                    </span>
                    <div className="reviews">
                      ({this.props.product.stats.review})
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom-info d-flex justify-content-between align-items-end">
              <div className="price-info d-flex justify-content-start align-items-center">
                {this.props.product.discounted_price && (
                  <div className="old-price text-uppercase">
                    <s>
                      {currencyFormatter(
                        this.props.currency,
                        this.props.product.price,
                        lang
                      )}
                    </s>
                  </div>
                )}
                <div className="price d-flex flex-column align-items-start">
                  {this.props.product.has_multiple_units ? (
                    this.props.translate("product.price_from", {
                      price: (
                        <strong>
                          {currencyFormatter(
                            this.props.currency,
                            this.props.product.discounted_price
                              ? parseFloat(
                                  this.props.product.price -
                                    (this.props.product.price / 100) *
                                      this.props.product.discount_percent
                                ).toFixed(2)
                              : this.props.product.price,
                            lang
                          )}
                        </strong>
                      ),
                    })
                  ) : (
                    <strong>
                      {currencyFormatter(
                        this.props.currency,
                        this.props.product.discounted_price
                          ? parseFloat(
                              this.props.product.price -
                                (this.props.product.price / 100) *
                                  this.props.product.discount_percent
                            ).toFixed(2)
                          : this.props.product.price,
                        lang
                      )}
                    </strong>
                  )}
                </div>
              </div>
              <Link
                to={{
                  pathname: lang
                    ? global.experience.links[lang].replace(
                        ":name",
                        this.props.product.slug
                      )
                    : "#!",
                  state: {
                    type: "book-now",
                  },
                }}
                className="book-now-button text-nowrap"
              >
                {this.props.translate("product.book_now")}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: () => dispatch(toggleAuthModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Product));
