import React from "react";

import SocialMediaButton from "./SocialMediaButton";

import google from "../../assets/images/btn_google_dark_normal.svg";
import API, { headers } from "../../utils/API";
import {
  handleErrors,
  getParamsFromLink,
  getCurrentRouteName,
} from "../../utils/helper";
import { withRouter } from "react-router";
import { Translate } from "react-localize-redux";

function SocialMediaLogins(props) {
  const handleLogin = async (e, type) => {
    e.preventDefault();

    let prevLocation = null;

    const currRoute = await getCurrentRouteName(
      window.location.pathname,
      props.lang
    );

    if (currRoute !== "login" && currRoute !== "register") {
      const currParams = getParamsFromLink(
        window.location.pathname,
        currRoute,
        props.lang
      );

      prevLocation = { routeName: currRoute, params: currParams };
    } else if (props.redirectTo) {
      const currRoute = await getCurrentRouteName(props.redirectTo, props.lang);

      const currParams = getParamsFromLink(
        props.redirectTo,
        currRoute,
        props.lang
      );

      prevLocation = { routeName: currRoute, params: currParams };
    }

    API.get(`auth/${type}`, {
      headers: {
        ...headers,
        "Accept-Language": props.lang,
      },
    })
      .then((response) => {
        if (prevLocation)
          localStorage.setItem("prevLocation", JSON.stringify(prevLocation));

        window.location = response.data.info.getTargetUrl;
      })
      .catch((err) => handleErrors(err));
  };
  return (
    <div className="sm-login d-flex justify-content-center align-items-center">
      <SocialMediaButton
        type="google"
        name={<Translate id="auth.sign_in_w_google" />}
        icon={google}
        handleLogin={handleLogin}
      />
    </div>
  );
}

export default withRouter(SocialMediaLogins);
