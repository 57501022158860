import React from "react";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

const MobileNavCat = ({ lang, cat, isActive, id }) => {
  return (
    <li className="nav-menu-cat-title">
      <input
        type="checkbox"
        name={`cat-${id}`}
        id={`cat-${id}`}
        defaultChecked={isActive}
      />
      <label
        htmlFor={`cat-${id}`}
        className="d-flex justify-content-between align-items-center"
      >
        <span className="d-flex align-items-center">
          <span
            className="cat-icon d-flex justify-content-center"
            dangerouslySetInnerHTML={{ __html: cat.icon }}
          ></span>
          {cat.title}
        </span>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="down-arrow"
        >
          <path
            d="M9 1L5 5L1 1"
            stroke="black"
            strokeWidth="1.44"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </label>
      <ul className="nav-menu-cat-content">
        {cat.contents.map((data) => {
          return (
            <li key={data.slug} className="nav-menu-item">
              <NavLink
                to={global.helpDetail.links[lang]
                  .replace(":cat", cat.slug)
                  .replace(":name", data.slug)}
                className="nav-menu-link"
              >
                {data.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default MobileNavCat;
