import React from "react";

import BLInput from "../../../../utils/components/BLInput";
import BLDropdown from "../../../../utils/components/BLDropdown";

import worldCard from "../../../../assets/images/world-card.png";
import BLCheckbox from "../../../../utils/components/BLCheckbox";

import { getCurrentYear } from "../../../../utils/helper";

const CreditCard = (props) => {
  const [errors, setErrors] = React.useState({
    month: null,
    year: null,
    cvc: null,
  });
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-9">
          <BLInput
            name="name"
            type="text"
            label={props.translate(
              "payment.pick_a_payment_method.name_on_the_card"
            )}
            value={props.cc.name}
            changed={props.handleEnterInfo}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-9">
          <BLInput
            name="creditcard"
            type="text"
            label={props.translate("payment.pick_a_payment_method.card_number")}
            value={props.cc.creditcard}
            changed={props.handleEnterInfo}
            processing={props.isGettingCCInfo}
            error={props.cardError || false}
            validationType="creditCard"
            isMasked
            mask={"9999 9999 9999 9999"}
            inputProps={{
              inputMode: "numeric",
              alwaysShowMask: false,
              maskChar: null,
            }}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <div className="cc-logo d-none">
            <img src={worldCard} alt="World Card Logo" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-9">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col">
                  <BLInput
                    name="month"
                    type="text"
                    label={props.translate("global.month")}
                    value={props.cc.expiry.month}
                    changed={props.handleEnterExpiry}
                    isMasked
                    error={
                      props.cc.expiry.month &&
                      (props.cc.expiry.month <= 0 ||
                        props.cc.expiry.month > 12) &&
                      props.translate(
                        "payment.pick_a_payment_method.expiry_month_error"
                      )
                    }
                    mask={"99"}
                    inputProps={{
                      inputMode: "numeric",
                      alwaysShowMask: false,
                      maskChar: null,
                      placeholder:
                        props.translate("global.month")[0] +
                        props.translate("global.month")[0],
                    }}
                    onBlur={
                      props.cc.expiry.month.length === 1
                        ? (e) => {
                            e.target.value = `${0}${e.target.value}`;
                            props.handleEnterExpiry(e);
                          }
                        : () => {}
                    }
                  />
                </div>
                <div className="col">
                  <BLInput
                    name="year"
                    type="text"
                    label={props.translate("global.year")}
                    value={props.cc.expiry.year}
                    changed={props.handleEnterExpiry}
                    onBlur={(e) => {
                      setErrors({
                        ...errors,
                        year:
                          e.target.value.length < 2
                            ? props.translate(
                                "payment.pick_a_payment_method.expiry_year_error"
                              )
                            : null,
                      });
                    }}
                    isMasked
                    error={
                      (props.cc.expiry.year.length === 2 &&
                        props.cc.expiry.year < getCurrentYear(2) &&
                        props.translate(
                          "payment.pick_a_payment_method.expiry_year_error"
                        )) ||
                      errors.year ||
                      false
                    }
                    mask={"99"}
                    inputProps={{
                      inputMode: "numeric",
                      alwaysShowMask: false,
                      maskChar: null,
                      placeholder:
                        props.translate("global.year")[0] +
                        props.translate("global.year")[0],
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <BLInput
                name="cvc"
                type="text"
                value={props.cc.cvc}
                changed={(e) => {
                  props.handleEnterInfo(e);
                  setErrors({
                    ...errors,
                    cvc: e.target.value.length >= 3 ? null : errors.cvc,
                  });
                }}
                onBlur={(e) =>
                  setErrors({
                    ...errors,
                    cvc:
                      e.target.value.length && e.target.value.length < 3
                        ? props.translate(
                            "payment.pick_a_payment_method.expiry_cvc_error"
                          )
                        : null,
                  })
                }
                label={props.translate("payment.pick_a_payment_method.cvc")}
                isMasked
                error={errors.cvc || false}
                mask={"9999"}
                inputProps={{
                  inputMode: "numeric",
                  alwaysShowMask: false,
                  maskChar: null,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="installments-label">
                {props.translate(
                  "payment.pick_a_payment_method.installment_choices"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BLDropdown
                name="installmentChoice"
                placeholder={props.translate(
                  "payment.pick_a_payment_method.pick_an_installment"
                )}
                options={props.installmentOptions}
                default={props.installmentChoice}
                setValue={(value) => props.handleChooseInstallment(value)}
                disabled={
                  !Boolean(props.installmentOptions.length) ||
                  Boolean(props.installmentOptions.length === 1)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <BLCheckbox
                label={props.translate(
                  "payment.pick_a_payment_method.pay_with_3d_secure"
                )}
                name="is3DSecure"
                checked={props.is3DSecure}
                changed={(e) => props.handleCheckIs3DSecure(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCard;
