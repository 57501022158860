import React from "react";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import GiftCard from "../GiftCard";

import bl_gift_card from "../../assets/images/bl-gift-card.png";
import { global } from "../../App/routes";

const HowTo = (props) => {
  const list = [];

  for (let i = 1; i <= 3; i++) {
    list.push({
      item: (
        <div className="list" key={i}>
          <h6 className="title">
            {`${i})`} {props.translate(`gift_card.how_to_page.list_${i}_title`)}
          </h6>
          <p className="content">
            {props.translate(`gift_card.how_to_page.list_${i}_content`)}
          </p>
        </div>
      ),
    });
  }
  return (
    <GiftCard>
      <section className="page-sec how-to">
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <h4 className="page-title text-center">
                {props.translate("gift_card.how_to_page.page_title")}
              </h4>
              <div className="page-holder d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <img
                  src={bl_gift_card}
                  alt={props.translate("gift_card.bucketlist_coupon")}
                  className="coupon"
                />
                <div className="info-area">
                  <div className="list-holder">
                    {list.map((data) => data.item)}
                  </div>
                  <div className="buttons d-flex justify-content-between align-items-center">
                    <Link
                      to={global.experiences.links[props.lang]}
                      className="see-all-experiences-button"
                    >
                      {props.translate("global.show_all_products")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </GiftCard>
  );
};

export default withLocalize(HowTo);
