import isbank from "../../assets/images/payment-methods/tib_logo.png";
import garanti from "../../assets/images/payment-methods/garanti_logo.png";

export const bankInfo = [
  {
    id: 0,
    name: "İş Bankası",
    branch: "İş Bankası / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: isbank,
  },
  {
    id: 1,
    name: "Garanti BBVA",
    branch: "Garanti BBVA / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: garanti,
  },
  {
    id: 2,
    name: "İş Bankası",
    branch: "İş Bankası / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: isbank,
  },
  {
    id: 3,
    name: "Garanti BBVA",
    branch: "Garanti BBVA / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: garanti,
  },
  {
    id: 4,
    name: "İş Bankası",
    branch: "İş Bankası / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: isbank,
  },
  {
    id: 5,
    name: "Garanti BBVA",
    branch: "Garanti BBVA / Osmanbey",
    branchCode: "(443) - 4665757",
    iban: "TR077 4434 0004 6700 6868",
    logo: garanti,
  },
];
