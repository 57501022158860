const Video = ({ item, playVideo }) => {
  return (
    <>
      <a
        href={item.link}
        onClick={(e) => {
          e.preventDefault();
          playVideo();
        }}
        target="_blank"
        rel="noopener noreferrer"
        className="video-feed-holder w-100 d-flex flex-column justify-content-center align-items-center position-relative"
      >
        <div className="video-area position-relative">
          <div className="video-play-icon w-100 h-100 d-flex justify-content-center align-items-center position-absolute">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM25 47.5C13.075 47.5 3.5 37.925 3.5 25C3.5 12.075 13.075 2.5 25 2.5C36.925 2.5 46.5 12.075 46.5 25C46.5 37.925 36.925 47.5 25 47.5Z"
                fill="white"
              />
              <path d="M19.5 15.75L34.5 25L19.5 34.25V15.75Z" fill="white" />
            </svg>
          </div>

          <img
            src={item.image}
            alt={item.title}
            className="w-100 h-100 video-feed-item"
          />
        </div>
      </a>
    </>
  );
};

export default Video;
