import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

import Banner from "./components/Banner";
import { getCurrentRouteName } from "../utils/helper";
import { withRouter } from "react-router";
import SEO from "../utils/components/SEO";

class GiftCard extends Component {
  state = {
    routeName: "",
  };
  componentDidMount() {
    this.getRouteName();
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.activeLanguage !== this.props.activeLanguage ||
      prevProps.location !== this.props.location
    ) {
      this.getRouteName();
    }
  };

  getRouteName = () => {
    const lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : this.props.defaultLanguage;

    getCurrentRouteName(this.props.location?.pathname, lang).then(
      (routeName) => {
        this.setState({ routeName });
      }
    );
  };

  render() {
    return (
      <div className="gift-card">
        <Banner
          translate={this.props.translate}
          lang={this.props.activeLanguage?.code || this.props.defaultLanguage}
        />
        <SEO
          title={this.props.translate(`page_title.${this.state.routeName}`)}
          description={this.props.translate(
            `page_description.${this.state.routeName}`
          )}
        />

        {this.props.children}
      </div>
    );
  }
}

export default withLocalize(withRouter(GiftCard));
