import React from "react";
import { withLocalize } from "react-localize-redux";
import { currencyFormatter } from "../../utils/helper";

const AmountCounter = ({
  icon,
  label,
  name,
  currency,
  isDiscounted,
  discountedPrice,
  price,
  lang,
  limits,
  amount,
  handleUpdateAmount,
  translate,
}) => {
  return (
    <div className="amount-counter d-flex justify-content-between align-items-center">
      <div className="left-side d-flex align-items-center">
        {icon ? (
          <div className="icon">
            <img src={icon} alt={`${label} Icon`} />
          </div>
        ) : (
          ""
        )}
        <div className="label d-flex flex-column">
          {label}
          <div className="prices d-flex d-sm-none align-items-center">
            <div className="price">
              {currencyFormatter(
                currency,
                isDiscounted ? discountedPrice : price,
                lang
              )}
            </div>
            {isDiscounted ? (
              <div className="old-price">
                {currencyFormatter(currency, price, lang)}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="right-side d-flex align-items-center">
        <div className="price d-none d-sm-flex">
          {currencyFormatter(
            currency,
            isDiscounted ? discountedPrice : price,
            lang
          )}
        </div>
        {isDiscounted ? (
          <div className="old-price d-none d-sm-flex">
            {currencyFormatter(currency, price, lang)}
          </div>
        ) : (
          ""
        )}
        <button
          className="control-button d-flex justify-content-center align-items-center decrease"
          onClick={() => {
            if (amount === limits.start) handleUpdateAmount(0, name);
            else if (amount === 0 || amount < limits.start)
              handleUpdateAmount(0, name);
            else handleUpdateAmount(amount - 1, name);
          }}
        >
          <svg
            width="12"
            height="3"
            viewBox="0 0 12 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9811 2.03774H1.01887C0.45283 2.03774 0 1.58491 0 1.01887C0 0.45283 0.45283 0 1.01887 0H10.9811C11.5472 0 12 0.45283 12 1.01887C12 1.58491 11.5472 2.03774 10.9811 2.03774Z"
              fill="white"
            />
          </svg>
        </button>
        <div className="amount text-center">
          {amount > 0
            ? `${amount} ${translate(
                `global.${amount > 1 ? `pieces` : `piece`}`
              )}`
            : 0}
        </div>
        <button
          className="control-button d-flex justify-content-center align-items-center increase"
          onClick={() => {
            if (amount === limits.end) handleUpdateAmount(limits.end, name);
            else if (amount === 0 || amount < limits.start)
              handleUpdateAmount(limits.start, name);
            else handleUpdateAmount(amount + 1, name);
          }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 6.11321C12 6.67924 11.5472 7.13208 10.9811 7.13208H6.90566V10.9811C6.90566 11.5472 6.45283 12 5.88679 12C5.32076 12 4.86792 11.5472 4.86792 10.9811V7.13208H1.01887C0.45283 7.13208 0 6.67924 0 6.11321C0 5.54717 0.45283 5.09434 1.01887 5.09434H4.86792V1.01887C4.86792 0.45283 5.32076 0 5.88679 0C6.45283 0 6.90566 0.45283 6.90566 1.01887V5.09434H10.9811C11.5472 5.09434 12 5.54717 12 6.11321Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default withLocalize(AmountCounter);
