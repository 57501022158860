import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import BLInput from "../utils/components/BLInput";
import BLButton from "../utils/components/BLButton";
import API, { headers } from "../utils/API";
import BLInfoArea from "../utils/components/BLInfoArea";
import { Link } from "react-router-dom";
import { guest } from "../App/routes";
import Helmet from "react-helmet";
import { dataLayer__passwordUpdated } from "../utils/DataLayerEvents";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [errMessages, setErrMessages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const { token, email } = props.match.params;

    setIsSending(true);

    const data = {
      token,
      email,
      password,
    };

    API.post("password/reset", data, {
      headers: { ...headers, "Accept-Language": props.activeLanguage.code },
    })
      .then((response) => {
        setSuccess(true);
        setIsSending(false);

        const page = {
          title: document.title,
          type: guest.changePassword.type,
          lang: props.user.langPref,
          currenct: props.user.currencyPref.code,
        };

        dataLayer__passwordUpdated(page);
      })
      .catch((err) => {
        const { errors } = err.response.data;

        setIsSending(false);

        setErrMessages({
          ...errors,
          generalError: err.response.data.info.message,
        });
      });
  };

  return (
    <div className="auth reset-password">
      <Helmet>
        <title>{props.translate("auth.reset_password_form_title")}</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center">
            <div className="auth-holder d-flex justify-content-center align-items-center flex-column">
              {success ? (
                <>
                  <h4 className="title">
                    {props.translate("auth.reset_password_successful_title")}
                  </h4>
                  <p className="desc text-center">
                    {props.translate("auth.reset_password_successful_content")}
                  </p>
                  <Link
                    to={guest.login.links[props.activeLanguage.code]}
                    className="login-button w-100 bl-button d-flex justify-content-center align-items-center bl-pri-button"
                  >
                    {props.translate("auth.login_button")}
                  </Link>
                </>
              ) : (
                <>
                  <h4 className="title">
                    {props.translate("auth.reset_password_form_title")}
                  </h4>
                  <p className="desc text-center">
                    {props.translate("auth.reset_password_form_desc")}
                  </p>
                  <form
                    action="reset-password"
                    className="d-flex flex-column w-100 mt-4"
                    onSubmit={handleSubmit}
                  >
                    <BLInput
                      type="password"
                      name="password"
                      value={password}
                      changed={(e) => setPassword(e.target.value)}
                      label={props.translate("auth.new_password")}
                      error={
                        "password" in errMessages && errMessages.password[0]
                      }
                      showPassInfo
                    />
                    <BLButton type="pri" action="submit" disabled={isSending}>
                      {props.translate("auth.change_password")}
                    </BLButton>
                    {errMessages.generalError && (
                      <BLInfoArea type="error">
                        {errMessages.generalError}
                      </BLInfoArea>
                    )}
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(ChangePassword);
