import React, { Component } from "react";
import PropTypes from "prop-types";
import Swiper from "swiper";
import crypto from "crypto";

import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import { BLLightbox } from "../../utils/components/BLLightbox";
import YouTube from "react-youtube";

export default class sSlider extends Component {
  static propTypes = {
    slides: PropTypes.array.isRequired,
  };

  id = crypto.randomBytes(6).toString("hex");

  state = {
    activeSlide: "",
    isVideoPlaying: false,
    isLightboxOn: false,
    lightboxIndex: 0,
    video: "",
  };

  swiper = "";

  youtubeOptions = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 1,
      loop: 1,
      modestbranding: 1,
      playsinline: 1,
      allowFullScreen: 1,
      hl: this.props.lang,
      iv_load_policy: 3,
      rel: 0,
    },
  };

  indexOffset = this.props.video_url ? 1 : 0;

  componentDidMount = () => {
    this.constructSlider();

    if (this.props.video_url) {
      let video = this.props.video_url.includes("?v=")
        ? this.props.video_url.split("?v=")[1].split("&")[0]
        : this.props.video_url.includes(".be/")
        ? this.props.video_url.split(".be/")[1].split("&")[0]
        : this.props.video_url;

      this.setState({ video });
    }
  };

  componentWillUnmount = () => {
    this.swiper.destroy(true, true);
  };

  constructSlider = () => {
    this.swiper = new Swiper("#swiper-container-" + this.id, {
      slidesPerView: "auto",
      spaceBetween: 15,
      loop: true,
      loopedSlides: 6,
      grabCursor: this.props.slides.length > 1,
      centeredSlides: true,
      keyboard: {
        enabled: true,
      },
      allowTouchMove: this.props.slides.length > 1,
      autoplay: this.props.slides.length > 1 ? true : false,
      on: {
        slideChange: () => {
          this.handleSetActiveSlide();
        },
        init: (swiper) => {
          const thumbs = document.querySelectorAll(
            `#swiper-container-${this.id} .thumbs .thumb`
          );
          if (thumbs.length) {
            thumbs.forEach((el) => {
              el.childNodes[0].addEventListener("animationend", () => {
                swiper.slideNext();
              });
            });
          }
        },
      },
    });
  };

  handleControlSlider = (e, direction) => {
    if (e) {
      e.preventDefault();
    }

    if (direction === "next") {
      this.swiper.slideNext();
    } else if (direction === "prev") {
      this.swiper.slidePrev();
    }
  };

  handleSetActiveSlide = () => {
    setTimeout(() =>
      this.setState({
        activeSlide: this.swiper.realIndex,
      })
    );
  };

  openLightboxOnSlide(index) {
    this.setState({ lightboxIndex: index, isLightboxOn: true });
  }

  render() {
    const lightboxImages =
      this.props.slides && this.props.slides.length
        ? this.props.slides.map((data) => data.url)
        : [];

    return (
      <section
        className="slider d-flex justify-content-center position-relative"
        data-is-active={this.props.slides.length > 1}
      >
        <div
          className="slider-controls position-absolute h-100"
          onClick={(e) => this.handleControlSlider(e, "prev")}
        >
          <button className="control prev position-absolute d-flex justify-content-center align-items-center">
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0001 2L1.56738 11.7854L12.0001 21.5707"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          className="slider-holder swiper-container position-relative"
          id={`swiper-container-${this.id}`}
        >
          <div className="slides swiper-wrapper align-items-center">
            {this.props.video_url && (
              <div
                className={`slide swiper-slide d-flex align-items-center position-relative h-100`}
              >
                <div
                  className="cover-holder d-flex w-100 h-100 video-cover"
                  style={{
                    background: `url("${this.props.cover}")`,
                  }}
                  onClick={() => this.setState({ isVideoPlaying: true })}
                ></div>
                <img
                  src={this.props.cover}
                  alt="Product Slider"
                  data-index={0}
                  className="d-none w-100"
                />
              </div>
            )}
            {this.props.slides.map((data, index) => (
              <div
                key={data.id}
                className={`slide swiper-slide d-flex align-items-center position-relative h-100`}
                onClick={
                  index + this.indexOffset === this.swiper.realIndex
                    ? () =>
                        this.openLightboxOnSlide(index + 1 + this.indexOffset)
                    : () => {}
                }
              >
                <div
                  className="cover-holder d-flex w-100 h-100"
                  style={{
                    background: `url("${data.url}")`,
                  }}
                ></div>
                <img
                  src={data.url}
                  alt="Product Slider"
                  data-index={index + 1 + this.indexOffset}
                  className="d-none w-100"
                />
              </div>
            ))}
          </div>

          <div
            className="thumbs d-flex justify-content-center align-items-center position-absolute"
            data-is-paused={
              this.state.isVideoPlaying || this.state.isLightboxOn
            }
          >
            {this.props.video_url ? (
              <div
                className={`thumb play-thumb progress position-relative ${
                  this.state.activeSlide === 0 || this.state.activeSlide > 0
                    ? `active`
                    : ``
                } ${this.state.activeSlide > 0 ? `filled` : ``}`}
              >
                <div className="process position-absolute"></div>
                <button
                  className={`play-button overflow-hidden d-flex justify-content-center align-items-center ${
                    this.state.activeSlide === 0 ? `active` : ``
                  }`}
                  onClick={() =>
                    this.setState({ isVideoPlaying: true }, () =>
                      this.swiper.slideToLoop(0)
                    )
                  }
                >
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.104 7.31268L1.47928 12.8802C1.2868 12.9913 1.04965 12.9912 0.85726 12.88C0.664874 12.7687 0.546533 12.5632 0.546876 12.3409V1.20756C0.546508 0.985346 0.664977 0.779895 0.857483 0.668901C1.04999 0.557907 1.28715 0.55831 1.47928 0.669957L11.104 6.2358C11.2469 6.31835 11.3511 6.45435 11.3937 6.61381C11.4362 6.77327 11.4136 6.94311 11.3308 7.08588C11.2767 7.1803 11.1984 7.25858 11.104 7.31268Z"
                      fill="white"
                    />
                  </svg>
                  {this.props.translate("global.video")}
                </button>
              </div>
            ) : (
              ""
            )}
            {this.props.slides.map((data, index) => (
              <div
                key={data.id}
                className={`thumb d-none d-sm-flex progress position-relative ${
                  this.state.activeSlide === index + this.indexOffset ||
                  this.state.activeSlide > index + this.indexOffset
                    ? `active`
                    : ``
                } ${
                  this.state.activeSlide > index + this.indexOffset
                    ? `filled`
                    : ``
                }`}
              >
                <span className="process position-absolute"></span>
              </div>
            ))}
          </div>
        </div>
        <div
          className="slider-controls position-absolute h-100"
          onClick={(e) => this.handleControlSlider(e, "next")}
        >
          <button className="control next position-absolute d-flex justify-content-center align-items-center">
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99991 2L12.4326 11.7854L1.99991 21.5707"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <CSSTransition
          in={this.state.isVideoPlaying}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() => this.setState({ isVideoPlaying: false })}
            headerFix
          >
            <YouTube
              videoId={this.state.video}
              opts={this.youtubeOptions}
              containerClassName="video-player-holder"
              onEnd={() => this.setState({ isVideoPlaying: false })}
            />
          </BLModal>
        </CSSTransition>
        {this.props.slides &&
          this.props.slides.length &&
          this.state.isLightboxOn && (
            <BLLightbox
              images={
                this.state.video
                  ? [this.state.video, ...lightboxImages]
                  : lightboxImages
              }
              index={this.state.lightboxIndex}
              isOn={this.state.isLightboxOn}
              handleCloseLightbox={() => this.setState({ isLightboxOn: false })}
            />
          )}
      </section>
    );
  }
}
