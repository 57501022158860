import React from "react";

const ConsentText = (props) => {
  return (
    <div className="page-holder about-us" data-section="consentText">
      <h1 className="p-title">
        {props.translate("static_pages.consent_text.title")}
      </h1>
      <p>{props.translate("static_pages.consent_text.line_1")}</p>
    </div>
  );
};

export default ConsentText;
