import React, { Component } from "react";
import { Helmet } from "react-helmet";
import StaticPages from "../StaticPages";
import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import { global } from "../../App/routes";

export default class PrivacyPolicy extends Component {
  state = {
    legals: [],
    selectedLegal: null,
    isLoading: true,
  };

  componentDidMount = () => this.getContent();

  componentDidUpdate = (prevProps) => {
    if (prevProps.lang !== this.props.lang) {
      this.getContent();
    }

    if (prevProps.match.params.section !== this.props.match.params.section)
      this.setSelectedLegal();
  };

  setSelectedLegal = () => {
    const selectedLegal = this.state.legals.find(
      (legal) => legal.slug === this.props.match.params.section
    );

    if (selectedLegal) this.setState({ selectedLegal });
  };

  getContent = () => {
    this.setState({ isLoading: true });
    API.get("legals", {
      headers: { ...headers, "Accept-Language": this.props.lang },
    })
      .then(({ data: r }) => {
        this.setState(
          {
            legals: r.data
              .filter((legal) => !legal.not_listed)
              .map((legal) => ({
                ...legal,
                link: global.privacyPolicy.links[this.props.lang].replace(
                  ":section?",
                  legal.slug
                ),
              })),
          },
          () => {
            this.setSelectedLegal();
            setTimeout(() => this.setState({ isLoading: false }));
          }
        );
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    return (
      <StaticPages
        lang={this.props.lang}
        menuItems={this.state.legals}
        isLoading={this.state.isLoading}
      >
        <Helmet>
          <title>
            {this.props.translate("static_pages.privacy_policy.browser_title")}
          </title>

          {this.state.selectedLegal &&
            this.state.selectedLegal.translations &&
            Object.keys(this.state.selectedLegal.translations).map((key) => (
              <link
                key={key}
                rel="alternate"
                hrefLang={key}
                href={`${process.env.REACT_APP_URL}${global.privacyPolicy.links[
                  key
                ].replace(
                  ":section?",
                  this.state.selectedLegal.translations[key].slug
                )}`}
              />
            ))}
        </Helmet>
        {this.state.legals.map(({ slug, content }) => (
          <div
            key={slug}
            className="page-holder cookie-policy selectable"
            data-section={slug}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ))}
      </StaticPages>
    );
  }
}
