import React, { Component } from "react";
import Profile from "./Profile";
import ContentTabs from "./components/ContentTabs";
import SingleReservation from "./components/SingleReservation";
import { handleErrors } from "../utils/helper";
import API, { headers } from "../utils/API";
import BLInfoArea from "../utils/components/BLInfoArea";
import Helmet from "react-helmet";
import BLLoader from "../utils/components/BLLoader";
import BLModal from "../utils/components/BLModal";
import { CSSTransition } from "react-transition-group";
import Review from "./components/Review";
import toastr from "toastr";
import BLButton from "../utils/components/BLButton";

export default class Reservations extends Component {
  state = {
    selectedTab: 0,
    selectedBookings: [],
    bookings: [],
    deniedBookings: [],
    deniedBookingsFetched: false,
    isLoading: true,
    reviewedRes: "",
    isReviewOn: false,
    hasMore: false,
    isLoadingMore: false,
    page: 1,
    deniedHasMore: false,
    deniedPage: 1,
  };

  limit = 10;

  componentDidMount = () => this.getBookings();

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.langPref !== this.props.user.langPref)
      this.setState(
        { bookings: [], isLoading: true, page: 1, deniedPage: 1 },
        this.getBookings
      );
  };

  getBookings = () => {
    API.get("account/bookings?limit=" + this.limit, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        "Accept-Language": this.props.lang,
      },
    })
      .then((response) => {
        const { data, meta } = response.data;
        const { current_page, last_page } = meta;

        const hasMore = current_page < last_page;

        this.setState({ bookings: data, isLoading: false, hasMore });
        this.filterBookings();
      })
      .catch((err) => handleErrors(err));
  };

  handleLoadMore = () => {
    this.setState({ isLoadingMore: true }, () => {
      const page = this.state.page + 1;
      API.get(`account/bookings?limit=${this.limit}&page=${page}`, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data, meta } = response.data;
          const { current_page, last_page } = meta;

          const hasMore = current_page < last_page;

          const bookings = [...this.state.bookings, ...data];

          this.setState(
            { bookings, isLoadingMore: false, hasMore, page },
            this.filterBookings
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isLoadingMore: false });
        });
    });
  };

  getDeniedBookings = (next = false) => {
    let req = `account/bookings?limit=${this.limit}&status=1`;
    req += next ? `&page=${this.state.deniedPage + 1}` : req;

    this.setState({ [next ? "isLoadingMore" : "isLoading"]: true }, () => {
      API.get(req, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data, meta } = response.data;
          const { current_page: deniedPage, last_page } = meta;

          const deniedHasMore = deniedPage < last_page;

          const deniedBookings = next
            ? [...this.state.deniedBookings, ...data]
            : data;

          this.setState(
            {
              deniedBookings,
              isLoadingMore: false,
              isLoading: false,
              deniedHasMore,
              deniedPage,
              deniedBookingsFetched: true,
            },
            this.filterBookings
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isLoadingMore: false });
        });
    });
  };

  handleLoadMoreDenied = () => this.getDeniedBookings(true);

  handleSelectTab = (selectedTab) => {
    this.setState({ selectedTab }, () => {
      if (!this.state.deniedBookingsFetched) this.getDeniedBookings();
      else this.filterBookings();
    });
  };

  filterBookings = () => {
    const selectedBookings =
      this.state.selectedTab === 0
        ? this.state.bookings
        : this.state.selectedTab === 1
        ? this.state.deniedBookings
        : [];

    this.setState({ selectedBookings });
  };

  handleShowReview = (res) => {
    if (res) {
      this.setState({ reviewedRes: res }, () =>
        this.setState({ isReviewOn: true })
      );
    }
  };

  handleMakeAReview = ({
    topic,
    review,
    rating,
    requestsContact,
    isNameHidden,
  }) => {
    const params = {
      opid: this.state.reviewedRes,
      title: topic,
      comment: review,
      rate: rating,
      requests_contact: requestsContact,
      hide_my_name: isNameHidden,
    };

    const configs = {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    };

    API.post("review/add", params, configs)
      .then((response) => {
        const bookings = this.state.bookings;
        const booking = bookings.filter(
          (data) => data.id === this.state.reviewedRes
        );

        if (booking.length) booking[0].is_reviewed = true;

        this.setState(
          {
            reviewedRes: "",
            isReviewOn: false,
            bookings: [...bookings, ...booking],
          },
          () => {
            toastr.clear();
            toastr.success(response.data.info.message);
          }
        );
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    return (
      <Profile>
        <Helmet>
          <title>{this.props.translate("profile.reservations")}</title>
        </Helmet>
        <div className="page-content reservations">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="page-holder w-100">
                  <div className="row">
                    <div className="col">
                      <h4 className="page-title">
                        {this.props.translate(
                          "profile.reservations_page.title"
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <section className="page-sec">
                        {this.state.isLoading ? (
                          <div className="mt-3">
                            <BLLoader />
                          </div>
                        ) : !this.state.bookings.length ? (
                          <BLInfoArea type="info">
                            {this.props.translate(
                              "profile.reservations_page.empty_text"
                            )}
                          </BLInfoArea>
                        ) : (
                          <div className="products-holder">
                            <ContentTabs
                              setSelectedTab={this.handleSelectTab}
                              selectedTab={this.state.selectedTab}
                              translate={this.props.translate}
                              tabs={[this.props.translate("global.all")]}
                            />

                            <div className="reservations-holder w-100">
                              {this.state.selectedBookings.length ? (
                                <>
                                  <div className="header w-100">
                                    <div className="title">
                                      {this.props.translate(
                                        "profile.reservations_page.experience_name"
                                      )}
                                    </div>
                                    <div className="title">
                                      {this.props.translate(
                                        "profile.reservations_page.coupon_code"
                                      )}
                                    </div>
                                    <div className="title">
                                      {this.props.translate("global.status")}
                                    </div>
                                  </div>
                                  <div className="reservations w-100 d-flex flex-column">
                                    {this.state.selectedBookings.map(
                                      (data, index) => (
                                        <SingleReservation
                                          key={index}
                                          translate={this.props.translate}
                                          couponCode={data.coupon_code}
                                          date={data.created_at}
                                          product={data.product}
                                          currency={
                                            this.props.user.currencyPref
                                          }
                                          lang={this.props.lang}
                                          reservationStatus={data.status}
                                          reservationStatusId={data.status_id}
                                          reservationCode={data.booking_code}
                                          couponStatus={data.coupon_status}
                                          oid={data.id}
                                          handleShowReview={
                                            this.handleShowReview
                                          }
                                          isCompleted={data.is_completed}
                                          isReviewed={data.is_reviewed}
                                          bookingMode={data.booking_mode}
                                        />
                                      )
                                    )}
                                    {(this.state.selectedTab === 0 &&
                                      this.state.hasMore) ||
                                    (this.state.selectedTab === 1 &&
                                      this.state.deniedHasMore) ? (
                                      <BLButton
                                        type="pri"
                                        classes="px-4 mt-5 mx-auto"
                                        clicked={
                                          this.state.selectedTab === 1
                                            ? this.handleLoadMoreDenied
                                            : this.handleLoadMore
                                        }
                                        disabled={this.state.isLoadingMore}
                                      >
                                        {this.props.translate("global.more")}
                                      </BLButton>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ) : (
                                <BLInfoArea type="info">
                                  {this.props.translate(
                                    `profile.reservations_page.${
                                      this.state.selectedTab === 0
                                        ? `empty_text`
                                        : `empty_text_denied`
                                    }`
                                  )}
                                </BLInfoArea>
                              )}
                            </div>
                          </div>
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.isReviewOn && Boolean(this.state.reviewedRes)}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() => {
              this.setState({ isReviewOn: false, reviewedRes: false });
            }}
            headerFix={true}
          >
            <Review
              resCode={this.state.reviewedRes}
              translate={this.props.translate}
              user={this.props.user}
              handleMakeAReview={this.handleMakeAReview}
            />
          </BLModal>
        </CSSTransition>
      </Profile>
    );
  }
}
