import React, { Component } from "react";
import BLLoader from "../../utils/components/BLLoader";
import API, { headers } from "../../utils/API";
import { handleErrors, getUserData } from "../../utils/helper";
import { login } from "../authActions";
import { connect } from "react-redux";
import { update } from "../../Cart/cartActions";
import { global, auth } from "../../App/routes";

class SocialMediaCallback extends Component {
  componentDidMount = () => {
    const code = this.props.location.search;
    const type = this.props.match.params.sm;

    const config = {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
      },
    };

    API.get(`auth/${type}/callback${code}`, config)
      .then((response) => {
        const { token_type, access_token, expires_in } = response.data.data,
          login_updated_at = Date.now();

        const token = `${token_type} ${access_token}`;

        API.get("account", {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: token,
          },
        }).then((r) => {
          const { data } = r.data;

          const user = getUserData(
            token_type,
            access_token,
            null,
            expires_in,
            false,
            data
          );

          user["login_updated_at"] = login_updated_at;

          this.props.login(user);

          // if (user.langPref !== this.props.activeLanguage.code) {
          //   this.props.setActiveLanguage(user.langPref);
          // }

          this.handleGetCart(user);

          const prevLocation = localStorage.getItem("prevLocation");

          if (prevLocation) {
            const { routeName, params } = JSON.parse(prevLocation);
            localStorage.removeItem("prevLocation");

            const allRoutes = { ...global, ...auth };
            const route = allRoutes[routeName];

            let redirectToRoute = route.links[this.props.lang];

            Object.keys(params.params).forEach((key) => {
              redirectToRoute = redirectToRoute.replace(
                `:${key}`,
                params.params[key]
              );
            });

            this.props.history.push(redirectToRoute);
          } else {
            this.props.history.push("/");
          }
        });
      })
      .catch((err) => handleErrors(err));
  };

  handleGetCart = async (user) => {
    if (this.props.cart && this.props.cart.items.length) {
      const params = {
        ccy: this.props.user.currencyPref.code,
        items: this.props.cart.items.map((item) => ({
          ...item,
          selectedPackage: undefined,
          productPackages: undefined,
          product: undefined,
          temp_id: undefined,
          id: undefined,
        })),
        new_basket: true,
      };
      await API.post("cart", params, {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      })
        .then((response) => {
          const { data } = response.data;

          const cart = data;

          cart.forEach((data) => {
            if (data.package_type === 3 || data.package_type === 4) {
              data.delivery_recipient_area_code =
                data.delivery_recipient_phone.area_code;
              data.delivery_recipient_phone_cc =
                data.delivery_recipient_phone.country_code;
              data.delivery_recipient_phone_w_out_code =
                data.delivery_recipient_phone.number;
            }
          });

          this.props.updateCart(cart);
        })
        .catch((err) => handleErrors(err));
    } else {
      await API.get("cart?ccy=" + this.props.user.currencyPref.code, {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      })
        .then((response) => {
          const { data } = response.data;

          const cart = data;

          cart.forEach((data) => {
            if (data.package_type === 3 || data.package_type === 4) {
              data.delivery_recipient_area_code =
                data.delivery_recipient_phone.area_code;
              data.delivery_recipient_phone_cc =
                data.delivery_recipient_phone.country_code;
              data.delivery_recipient_phone_w_out_code =
                data.delivery_recipient_phone.number;
            }
          });

          this.props.updateCart(cart);
        })
        .catch((err) => handleErrors(err));
    }
  };

  render() {
    return (
      <div className="logging-in-info d-flex flex-column align-items-center mx-auto mt-2">
        <BLLoader />
        <h4 className="text mt-4">
          {this.props.translate("auth.sm_callback_waiting_text")}.
        </h4>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    updateCart: (items) => dispatch(update(items)),
  };
};

export default connect(null, mapDispatchToProps)(SocialMediaCallback);
