import React from "react";
import PropTypes from "prop-types";

import corporateVoucher from "../../assets/images/corporate-voucher.svg";
import BLButton from "../../utils/components/BLButton";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";

const Banner = (props) => {
  return (
    <section className="banner">
      <div className="container">
        <div className="row">
          <div className="col">
            <BLBreadcrumbs
              breadcrumbs={[
                {
                  id: 0,
                  name: props.translate("global.homepage"),
                  route: global.home.links[props.lang],
                },
                {
                  id: 1,
                  name: props.translate("corporate_voucher.corporate_voucher"),
                },
              ]}
            />
          </div>
        </div>
        <div className="row d-flex flex-column-reverse flex-md-row align-items-center">
          <div className="col-12 col-md-7">
            <div className="content-area">
              <h3 className="title">
                {props.translate("corporate_voucher.banner.title")}
              </h3>
              <p className="content">
                {props.translate("corporate_voucher.banner.content")}
              </p>
              <div className="buttons d-flex flex-column flex-sm-row justify-content-start align-items-center">
                <BLButton
                  type="sec"
                  classes="button"
                  clicked={props.scrollToContact}
                >
                  {props.translate("corporate_voucher.banner.contact_now")}
                </BLButton>
                <Link
                  to={global.experiences.links[props.lang]}
                  className="see-all-experiences-button button opposite text-center d-none"
                >
                  {props.translate("global.view_products")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex">
            <img
              src={corporateVoucher}
              alt={props.translate("corporate_voucher.corporate_voucher")}
              className="voucher-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Banner;
