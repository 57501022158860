import React, { useRef, useEffect } from "react";
import { global, guest, auth, callback } from "../App/routes";
import { matchPath } from "react-router";
import API, { headers } from "./API";
import toastr from "toastr";
import { Translate } from "react-localize-redux";
import { logout } from "../Auth/authActions";
import store from "../store";

export const MAX_GIFT_AMOUNT = 5000;
export const MIN_GIFT_AMOUNT = 100;

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...

  // ... and can hold any value, similar to an instance property on a class

  const ref = useRef();

  // Store current value in ref

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)

  return ref.current;
};

export const ratingStars = (rating) => {
  const full = (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0952 0.0625L7.69509 6.97538L0.0947266 8.08364L5.59497 13.4643L4.29595 21.0625L11.0952 17.4754L17.8935 21.0625L16.5955 13.4643L22.0947 8.08364L14.4944 6.97538L11.0952 0.0625Z"
        fill="#F5C247"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0952 0.0625L7.69509 6.97538L0.0947266 8.08364L5.59497 13.4643L4.29595 21.0625L11.0952 17.4754L17.8935 21.0625L16.5955 13.4643L22.0947 8.08364L14.4944 6.97538L11.0952 0.0625Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  );

  const half = (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0005 0L7.60036 6.91288L0 8.02114L5.50025 13.4018L4.20122 21L11.0005 17.4129L17.7988 21L16.5007 13.4018L22 8.02114L14.3996 6.91288L11.0005 0Z"
        fill="#F5C247"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.5135L11.1875 17.4129L17.8702 21L16.5942 13.4018L22 8.02114L14.5288 6.91288L11.1875 0L11 0.387711V17.5135Z"
        fill="#D1D1D1"
      />
    </svg>
  );

  const empty = (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0952 0.0625L7.69509 6.97538L0.0947266 8.08364L5.59497 13.4643L4.29595 21.0625L11.0952 17.4754L17.8935 21.0625L16.5955 13.4643L22.0947 8.08364L14.4944 6.97538L11.0952 0.0625Z"
        fill="#D1D1D1"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0952 0.0625L7.69509 6.97538L0.0947266 8.08364L5.59497 13.4643L4.29595 21.0625L11.0952 17.4754L17.8935 21.0625L16.5955 13.4643L22.0947 8.08364L14.4944 6.97538L11.0952 0.0625Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  );

  let stars = [];
  for (let i = 0; i < Math.floor(rating); i++)
    stars.push({ key: i, star: full });

  if (Math.floor(rating) !== rating)
    stars.push({ key: rating + 1, star: half });

  if (stars.length < 5) {
    for (let i = stars.length; i < 5; i++) {
      stars.push({ key: i, star: empty });
    }
  }

  return stars;
};

export const getCurrentRouteName = async (link, lang) => {
  const allRoutes = { ...global, ...guest, ...auth };

  let currLocation = "";

  Object.keys(allRoutes).some((key) => {
    const match = matchPath(link, {
      path: allRoutes[key].links[lang],
      exact: allRoutes[key].exact,
      strict: false,
    });

    if (match) {
      currLocation = key;
      return true;
    }
    return false;
  });

  return currLocation && currLocation;
};

export const getCurrentRoute = async (link, _lang) => {
  const allRoutes = { guest, auth, global, callback };

  let currLocation = {};
  let routeName = "";
  let routeType = "";

  Object.keys(allRoutes).some((route) => {
    return Object.keys(allRoutes[route]).some((key) => {
      const match = matchPath(link, {
        path: _lang
          ? allRoutes[route][key].links[_lang]
          : allRoutes[route][key].links,
        exact: allRoutes[route][key].exact,
        strict: false,
      });

      if (match) {
        routeType = route;
        routeName = key;
        currLocation = match;
        return true;
      }
      return false;
    });
  });

  return (
    currLocation &&
    routeName && {
      name: routeName,
      route: allRoutes[routeType][routeName],
      match: currLocation,
    }
  );
};

export const getParamsFromLink = (link, routeName, lang) => {
  const allRoutes = { ...global, ...guest, ...auth };

  return matchPath(link, {
    path: allRoutes[routeName].links[lang],
    exact: allRoutes[routeName].exact,
    strict: false,
  });
};

export const getLanguages = (lang) =>
  API.get("languages", {
    headers: { ...headers, "Accept-Language": lang },
  }).catch((err) => handleErrors(err));

export const getCurrencies = (lang) =>
  API.get("currencies", {
    headers: { ...headers, "Accept-Language": lang },
  }).catch((err) => handleErrors(err));

export const getCategories = (lang, parent = null) =>
  parent
    ? API.get(`categories?parent_id=${parent}`, {
        headers: { ...headers, "Accept-Language": lang },
      }).catch((err) => handleErrors(err))
    : API.get("categories", {
        headers: { ...headers, "Accept-Language": lang },
      }).catch((err) => handleErrors(err));

export const getAccountDetails = async (tokenType, token) =>
  await API.get("account", {
    headers: { ...headers, Authorization: `${tokenType} ${token}` },
  }).catch((err) => handleErrors(err));

export const getCountries = (lang) =>
  API.get("locations?type=0&parent_id=0", {
    headers: { ...headers, "Accept-Language": lang },
  }).catch((err) => handleErrors(err));

export const getCities = (lang, country_id) =>
  API.get(`locations?type=1&parent_id=${country_id}`, {
    headers: { ...headers, "Accept-Language": lang },
  }).catch((err) => handleErrors(err));

export const getDistricts = (lang, city_id) =>
  API.get(`locations?type=2&parent_id=${city_id}`, {
    headers: { ...headers, "Accept-Language": lang },
  }).catch((err) => handleErrors(err));

export const getCitiesWithProduct = (lang, country_id, category_id = null) =>
  API.get(
    `locations?type=1&parent_id=${country_id}&display=1${
      category_id !== null ? `&category_id=${category_id}` : ``
    }`,
    {
      headers: { ...headers, "Accept-Language": lang },
    }
  ).catch((err) => handleErrors(err));

export const handleErrors = (err) => {
  if (err.response) {
    toastr.clear();
    if (err.response.status || err.response.code) {
      if (err.response.status === 401 || err.response.code === 401) {
        const { dispatch } = store;
        dispatch(logout());
      }
    }
    if (err.response.data) {
      if (
        err.response.data.errors &&
        Object.keys(err.response.data.errors).length
      ) {
        Object.keys(err.response.data.errors).forEach((key) => {
          toastr.error(err.response.data.errors[key][0]);
        });
      }
    }

    if (err.response.data.info) {
      toastr.error(err.response.data.info.message);
    }
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  const compArr = arr1.filter((data) => arr2.indexOf(data) === -1);
  return !Boolean(compArr.length);
};

export const getOrientation = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;

    img.onloadend = () => {
      resolve(img.naturalWidth > img.naturalHeight ? "landscape" : "portrait");
    };
  });
};

export const validateInput = (type, value) => {
  let regexp;
  let error = null;

  if (type === "phone")
    regexp = /^[(]?[0-9]{3}[)]?[\s]?[0-9]{3}[\s]?[0-9]{2}[\s]?[0-9]{0,2}$/im;

  if (type === "website")
    regexp =
      /^(?:(?:(?:https?|ftp)?:)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  if (type === "email")
    // eslint-disable-next-line no-useless-escape
    regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (type === "number") regexp = /^([0-9])+$/;

  if (type === "numberFormat") regexp = /^([0-9 .,])+$/;

  if (type === "creditCard") {
    const number = value.replace(/ /g, "");
    const check = number && luhnCheck(number);

    return {
      success: check,
      error: !check ? (
        <Translate id={`global.validation_errors.${type}`} />
      ) : null,
    };
  }

  if (type === "idNo") regexp = /^[1-9]{1}[0-9]{9}[02468]{1}$/;

  const success = regexp ? regexp.test(String(value).trim()) : false;

  if (!success) error = <Translate id={`global.validation_errors.${type}`} />;

  return { success, error };
};

export const makeURL = (text) =>
  text
    .replace(/Ğ/gim, "g")
    .replace(/Ü/gim, "u")
    .replace(/Ş/gim, "s")
    .replace(/I/gim, "i")
    .replace(/İ/gim, "i")
    .replace(/Ö/gim, "o")
    .replace(/Ç/gim, "c")
    .replace(/ğ/gim, "g")
    .replace(/ü/gim, "u")
    .replace(/ş/gim, "s")
    .replace(/ı/gim, "i")
    .replace(/ö/gim, "o")
    .replace(/ç/gim, "c")
    .replace(/[^a-z0-9]/gi, "_")
    .toLowerCase();

export const getScrollY = () =>
  typeof window.pageYOffset == "number"
    ? window.pageYOffset
    : document.body && (document.body.scrollLeft || document.body.scrollTop)
    ? document.body.scrollTop
    : document.documentElement &&
      (document.documentElement.scrollLeft ||
        document.documentElement.scrollTop)
    ? document.documentElement.scrollTop
    : "";

export const getTurkeyOnTop = (unsortedCountries, id = 212) => {
  const turkey = unsortedCountries.filter((data) => data.id === id);
  const otherCountries = unsortedCountries.filter((data) => data.id !== id);

  const sortedCountries = turkey.concat(otherCountries);

  return sortedCountries;
};

export const currencyFormatter = (currency = {}, amount, lang = "tr") => {
  amount = new Intl.NumberFormat(lang).format(parseFloat(amount).toFixed(2));
  let formatted = amount;

  switch (currency.code.trim().toLowerCase()) {
    case "try":
      formatted = currency.symbol + amount;
      break;

    case "usd":
      formatted = currency.symbol + amount;
      break;

    case "eur":
      formatted = amount + currency.symbol;
      break;

    case "gbp":
      formatted = currency.symbol + amount;
      break;

    default:
      return false;
  }

  return formatted;
};

export const getUserData = (
  tokenType,
  token,
  refresh_token,
  expires_in,
  rememberMe,
  user
) => {
  return {
    _tokenType: tokenType,
    _token: token,
    _rToken: refresh_token,
    _expires: expires_in * 1000,
    id: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    avatar: user.avatar_url,
    isPasswordCreated: user.is_password_created,
    gender: user.gender,
    hashed_email: user.hashed_email,
    has_bought_before: user.has_bought_before,
    rememberMe,
  };
};

export const setViewportHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export const isAuthExpired = (user) =>
  user.login_updated_at + user._expires <= Date.now();

export const copyCode = (e = null) => {
  const el = e ? e.target : document.querySelector(".copy-on-click");
  document.execCommand("copy");
  el.classList.add("copied");
  setTimeout(() => el.classList.add("exiting"), 400);

  setTimeout(() => {
    el.classList.remove("exiting");
    el.classList.remove("copied");
  }, 600);
};

export const loginRedirectUrl = (lang) => {
  const redirects = JSON.parse(localStorage.getItem("login-redirects"));

  if (redirects) {
    let url = redirects.links[lang];

    const { params } = redirects;

    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.replace(`:${key}`, params[key]);
      });
    }

    localStorage.removeItem("login-redirects");

    return url;
  }

  return false;
};

export const reformatText = (content, newAreas) => {
  let newContent = content;

  if (newContent) {
    Object.keys(newAreas).forEach((key) => {
      newContent = newContent.replaceAll(key, newAreas[key]);
    });
  }

  return newContent;
};

export const componentLoader = (lazyComponent, attemptsLeft) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
};

const botPattern =
  "(Prerender|googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
export const isCrawler = () =>
  new RegExp(botPattern, "i").test(navigator.userAgent);

export const checkIfOnScreen = (el) => {
  if (!el) return false;

  const { top, bottom, height } = el.getBoundingClientRect();

  return top <= window.innerHeight - height / 2 && bottom - 50 > 0
    ? true
    : false;
};

export const getCurrentYear = (digits = 4) =>
  String(new Date().getFullYear()).substring(4 - digits, 4);

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,4})$/);
  if (match) {
    return ["(", match[1], ") ", match[2], " ", match[3]].join("");
  }
  return null;
};

export const formatString = (string, format = "#") => {
  let i = 0,
    str = String(string);
  return format.replace(/#/g, () => str[i++]);
};

const luhnCheck = (cardNo) => {
  let s = 0;
  let doubleDigit = false;
  for (let i = cardNo.length - 1; i >= 0; i--) {
    let digit = +cardNo[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
};

export const validatePassword = (value) => {
  value = value.trim();

  if (value.length < 8) return false;
  if (!/[A-ZİĞÜŞÖÇ]/.test(value)) return false;
  if (!/[a-zığüşöç]/.test(value)) return false;
  if (!/[0-9]/.test(value)) return false;

  return true;
};

export const passwordRequirementsStepsCheck = (value) => {
  let completedSteps = [];

  if (value.length >= 8) completedSteps.push("length");

  if (/[A-ZİĞÜŞÖÇ]/.test(value)) completedSteps.push("uppercase");

  if (/[a-zığüşöç]/.test(value)) completedSteps.push("lowercase");

  if (/[0-9]/.test(value)) completedSteps.push("number");

  return completedSteps;
};

if (typeof String.prototype.replaceAll === "undefined") {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (match, replace) {
    return this.replace(new RegExp(match, "g"), () => replace);
  };
}

export const isElementVisible = (selector) => {
  const el = document.querySelector(selector);
  if (!el) return false;

  const { top, bottom, height } = el.getBoundingClientRect();

  return top <= window.innerHeight - height / 2 && bottom - 50 > 0
    ? true
    : false;
};
