import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { Link, withRouter } from "react-router-dom";

import { login, updateLanguage, toggleAuthModal } from "../authActions";

import BLInput from "../../utils/components/BLInput";
import BLCheckbox from "../../utils/components/BLCheckbox";
import BLButton from "../../utils/components/BLButton";
import { guest } from "../../App/routes";
import API, { headers } from "../../utils/API";
import BLInfoArea from "../../utils/components/BLInfoArea";
import {
  getAccountDetails,
  handleErrors,
  getUserData,
  loginRedirectUrl,
  getCurrentRoute,
  validateInput,
} from "../../utils/helper";
import { update } from "../../Cart/cartActions";
import SocialMediaLogins from "./SocialMediaLogins";
import BLLoader from "../../utils/components/BLLoader";
import is from "is_js";
import { dataLayer__login } from "../../utils/DataLayerEvents";
import SEO from "../../utils/components/SEO";

class Login extends Component {
  state = {
    email: "",
    password: "",
    rememberMe: false,
    errorMessages: { email: "", password: "" },
    error: "",
    isSending: false,
    isOkayToLogin: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleLogin = (e) => {
    if (e) e.preventDefault();

    const { email, password, rememberMe } = this.state;

    const data = {
      email,
      password,
    };

    this.setState({ isSending: true });

    API.post("user/login", data, {
      headers: { ...headers, "Accept-Language": this.props.user.langPref },
    })
      .then((response) => {
        const { token_type, access_token, refresh_token, expires_in } =
            response.data.data,
          login_updated_at = Date.now();

        getAccountDetails(token_type, access_token).then(async (r) => {
          const { data } = r.data;

          const user = getUserData(
            token_type,
            access_token,
            refresh_token,
            expires_in,
            rememberMe,
            data
          );

          user["login_updated_at"] = login_updated_at;

          await this.props.login(user);

          getCurrentRoute(
            window.location.pathname,
            this.props.user.langPref
          ).then(({ route }) => {
            const {
              langPref: lang,
              currencyPref,
              hashed_email,
              id,
            } = this.props.user;
            const page = {
              title: document.title,
              type: route.type,
              lang,
              currency: currencyPref.code,
            };

            const user = {
              hashed_email,
              id,
            };

            dataLayer__login(page, user);
          });

          if (user.langPref !== this.props.activeLanguage.code) {
            this.props.setActiveLanguage(data.account_language);
            this.props.updateLanguage(data.account_language);
          }

          if (this.props.cart && this.props.cart.items.length) {
            const params = {
              ccy: this.props.user.currencyPref.code,
              new_basket: true,
              items: this.props.cart.items.map((item) => ({
                ...item,
                selectedPackage: undefined,
                productPackages: undefined,
                product: undefined,
                temp_id: undefined,
                id: undefined,
              })),
            };
            await API.post("cart", params, {
              headers: {
                ...headers,
                Authorization: `${user._tokenType} ${user._token}`,
              },
            })
              .then((response) => {
                const { data: cart } = response.data;

                cart.forEach((data) => {
                  if (data.package_type === 3 || data.package_type === 4) {
                    data.delivery_recipient_area_code =
                      data.delivery_recipient_phone.area_code;
                    data.delivery_recipient_phone_cc =
                      data.delivery_recipient_phone.country_code;
                    data.delivery_recipient_phone_w_out_code =
                      data.delivery_recipient_phone.number;
                  }
                });
                this.props.updateCart([
                  {
                    ...this.props.cart.items[0],
                    ...cart[0],
                  },
                ]);
              })
              .catch((err) => handleErrors(err));
          } else {
            await API.get("cart?ccy=" + this.props.user.currencyPref.code, {
              headers: {
                ...headers,
                Authorization: `${user._tokenType} ${user._token}`,
              },
            })
              .then((response) => {
                const { data } = response.data;

                const cart = data;

                cart.forEach((data) => {
                  if (data.package_type === 3 || data.package_type === 4) {
                    data.delivery_recipient_area_code =
                      data.delivery_recipient_phone.area_code;
                    data.delivery_recipient_phone_cc =
                      data.delivery_recipient_phone.country_code;
                    data.delivery_recipient_phone_w_out_code =
                      data.delivery_recipient_phone.number;
                  }
                });

                this.props.updateCart([
                  { ...this.props.cart.items[0], ...cart[0] },
                ]);
              })
              .catch((err) => handleErrors(err));
          }

          if (is.mobile()) {
            const url = loginRedirectUrl(this.props.user.langPref);

            if (url) this.props.history.push(url);
            else this.props.history.push("/");
          }

          setTimeout(() => this.props.toggleAuthModal(), 20);
        });
      })
      .catch((err) => {
        if (err.response) {
          const { errors, info } = err.response.data;

          if (errors) this.setState({ errorMessages: { ...errors } });

          if (err.response.data.code) this.setState({ error: info.message });

          this.setState({ isSending: false });
        }
      });
  };

  resetError = (name) => {
    if (name in this.state.errorMessages) {
      const errorMessages = { ...this.state.errorMessages };
      delete errorMessages[name];
      this.setState({ errorMessages });
    }
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, () => {
      this.resetError(target.name);
      this.checkIfOkayToLogin();
    });

  checkIfOkayToLogin = () => {
    const inputs = ["email", "password"];

    const check = inputs.filter((key) => {
      const validate =
        key === "email"
          ? validateInput(key, this.state[key])
          : { success: true };

      return !this.state[key] || !this.state[key].length || !validate.success;
    });

    let isOkayToLogin = true;
    if (check.length) {
      isOkayToLogin = false;
    }

    this.setState({ isOkayToLogin });
  };

  render() {
    return (
      <div className="tab-page login-page d-flex align-items-center flex-column">
        <SEO
          title={this.props.translate("auth.login")}
          description={this.props.translate("page_description.login")}
        />
        <form
          action="login"
          className="login-form d-flex align-items-center flex-column w-100"
          onSubmit={this.handleLogin}
        >
          <BLInput
            type="email"
            name="email"
            label={this.props.translate("auth.email_address")}
            value={this.state.email}
            validationType="email"
            changed={this.handleChange}
            error={
              "email" in this.state.errorMessages &&
              this.state.errorMessages.email
            }
            inputProps={{ inputMode: "email" }}
          />
          <BLInput
            type="password"
            name="password"
            label={this.props.translate("auth.password")}
            value={this.state.password}
            changed={this.handleChange}
            error={
              "password" in this.state.errorMessages &&
              this.state.errorMessages.password
            }
            inputProps={{ inputMode: "password" }}
          />
          <BLCheckbox
            name="remember-me"
            label={this.props.translate("auth.remember_me")}
            value={this.state.rememberMe}
            changed={(e) => this.setState({ rememberMe: e.target.checked })}
          />
          <Link
            to={guest.resetPassword.links[this.props.activeLanguage.code]}
            className="forgot-password-link"
          >
            {this.props.translate("auth.forgot_password")}
          </Link>
          {this.state.error && (
            <BLInfoArea type="error">{this.state.error}</BLInfoArea>
          )}
          <BLButton
            type="pri"
            action="submit"
            disabled={!this.state.isOkayToLogin}
            classes={`auth-button position-relative ${
              this.state.isSending ? `disabled` : ``
            }`}
          >
            {this.state.isSending ? (
              <BLLoader loaderType="dots" />
            ) : (
              <>
                {this.props.translate("auth.login_button")}
                {!this.state.isOkayToLogin && (
                  <span className="disabled-tooltip bottom position-absolute w-100">
                    {this.props.translate("auth.disabled_login_button_tooltip")}
                  </span>
                )}
              </>
            )}
          </BLButton>
        </form>
        <SocialMediaLogins
          lang={this.props.lang}
          redirectTo={this.props.redirectTo}
        />
        <div className="link-to-other text-center">
          {this.props.translate("auth.you_dont_have_an_account")}
          {this.props.translate("auth.do_now", {
            link: (
              <Link
                to={guest.register.links[this.props.lang]}
                onClick={(e) => {
                  this.props.handlePageChange(e, "register");
                }}
              >
                {this.props.translate("auth.do_register")}
              </Link>
            ),
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    updateLanguage: (lang) => dispatch(updateLanguage(lang)),
    updateCart: (cart) => dispatch(update(cart)),
    toggleAuthModal: () => dispatch(toggleAuthModal()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withLocalize(withRouter(Login)));
