import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";
import { currencyFormatter } from "../../utils/helper";

const HorizontalProduct = (props) => {
  let catUrl;
  if (props.lang) {
    if (props.product.type !== "gift_card" && props.product.category.length > 1)
      catUrl = global.subCatPage.links[props.lang]
        .replace(":main_cat", props.product.category[0].slug)
        .replace(":sub_cat", props.product.category[1].slug);
    else
      catUrl = global.catPage.links[props.lang].replace(
        ":main_cat",
        props.product.category[0].slug
      );
  }

  return (
    <div className="product horizontal d-flex justify-content-start align-items-start">
      <div className="image d-flex justify-content-center align-items-center overflow-hidden">
        <img src={props.product.cover_image_url} alt="Product Cover" />
      </div>
      <div className="info-area-holder">
        <h5 className="cat">
          <Link to={catUrl}>
            {props.product.category.length > 1
              ? props.product.category[1].name
              : props.product.category.name[0]}
          </Link>
        </h5>
        <h4 className="title">
          <Link
            to={global.experience.links[props.lang].replace(
              ":name",
              props.product.slug
            )}
          >
            {props.product.title}
          </Link>
        </h4>
        <div className="product-code">
          {props.translate("product.product_code")}:{" "}
          <span className="code selectable ml-1">{props.product.code}</span>
        </div>

        <div className="extra-info-area">
          <div className="infos">
            <div className="units">
              {props.product.units &&
                props.product.units.map((unit) => (
                  <div className="unit" key={unit.name}>
                    <div className="name">{unit.name}</div>
                    <div className="count">
                      x{unit.quantity}{" "}
                      {props.translate(
                        `global.piece${unit.quantity > 1 ? `s` : ``}`
                      )}
                    </div>
                    <div className="price">
                      {currencyFormatter(
                        props.currency,
                        parseFloat(
                          unit.is_discounted
                            ? unit.discounted_price
                            : unit.amount
                        ).toFixed(2),
                        props.lang
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HorizontalProduct.propTypes = {
  translate: PropTypes.func.isRequired,
  product: PropTypes.shape({
    cover_image_url: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    stats: PropTypes.shape({
      rate: PropTypes.number.isRequired,
      review: PropTypes.number.isRequired,
      booking: PropTypes.number.isRequired,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    availability: PropTypes.any,
  }),
};

export default HorizontalProduct;
