import React, { Component } from "react";
import { Gift } from "./couponDetailTypes/Gift";
import { DatePicked } from "./couponDetailTypes/DatePicked";
import { getOrientation, currencyFormatter } from "../../../utils/helper";

import crypto from "crypto";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

export default class SingleExperience extends Component {
  state = {
    isDetailOn: false,
    coverType: "",
  };

  id = crypto.randomBytes(6).toString("hex");

  componentDidMount = () => {
    document
      .querySelector(`#cie-${this.id} .cover img`)
      .addEventListener("load", this.scrollEvent, false);

    this.getCoverType();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.calculateHeight, false);
  };

  getCoverType = async () =>
    await getOrientation(this.props.item.product.cover_image_url).then((type) =>
      this.setState({ coverType: type })
    );

  calculateHeight = () => {
    const expHolder = document.querySelector(`#cie-${this.id}`);

    if (expHolder) {
      const coverHeight = document.querySelector(
          `#cie-${this.id} .cover`
        ).clientHeight,
        summaryHeight = document.querySelector(
          `#cie-${this.id} .info-area`
        ).clientHeight,
        mobileAmountsHeight = document.querySelector(
          `#cie-${this.id} .mobile-amounts`
        ).clientHeight;

      let height = coverHeight > summaryHeight ? coverHeight : summaryHeight;

      height += mobileAmountsHeight;

      height += mobileAmountsHeight ? 30 : 15;

      expHolder.setAttribute("style", `max-height: ${height}px`);
    }
  };
  scrollEvent = () => {
    this.calculateHeight();
    window.addEventListener("resize", this.calculateHeight, false);
  };
  render() {
    return (
      <div
        className="exp-holder d-flex align-items-start overflow-hidden"
        data-details={this.state.isDetailOn ? `on` : `off`}
        id={`cie-${this.id}`}
      >
        <div className="cover d-flex justify-content-center align-items-center overflow-hidden">
          <img
            src={this.props.item.product.cover_image_url}
            alt="Cover"
            className={this.state.coverType === "landscape" ? `h-100` : `w-100`}
          />
        </div>
        <div className="info-area">
          <div className="summary-area d-flex flex-column justify-content-between position-relative">
            <div className="exp-info">
              <h3 className="exp-title m-0 selectable">
                {this.props.item.product.title}
              </h3>
              <p className="exp-code">
                {this.props.translate("product.product_code")}:{" "}
                <span className="selectable">
                  {this.props.item.product.code}
                </span>
              </p>
              <p className="location">
                {this.props.item.product.location
                  ? `${this.props.item.product.location.town_name}, ${this.props.item.product.location.city_name}`
                  : ``}
              </p>
            </div>
            <div className="amounts d-none d-md-flex flex-column justify-content-between">
              <div className="amounts-holder">
                {this.props.item?.units
                  ?.filter((unit) => unit.quantity > 0)
                  ?.map((data) => (
                    <div className="amount selectable" key={data.id}>
                      <span className="type">{data.name}</span>
                      <span className="how-many">
                        x{data.quantity}{" "}
                        {this.props.translate(
                          `global.piece${data.quantity > 1 ? `s` : ``}`
                        )}
                      </span>
                      <span className="price text-right">
                        {currencyFormatter(
                          this.props.currency,
                          parseFloat(data.price).toFixed(2),
                          this.props.lang
                        )}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="total-price text-right">
                {this.props.translate("global.total")}:{" "}
                <span className="selectable">
                  {currencyFormatter(
                    this.props.currency,
                    parseFloat(this.props.item.amount).toFixed(2),
                    this.props.lang
                  )}
                </span>
              </div>
            </div>
            {this.props.item.package_type !== PACKAGE_TYPES.OPENENDED ? (
              <div className="down-arrow position-absolute">
                <button
                  onClick={() =>
                    this.setState((prevState) => {
                      return { isDetailOn: !prevState.isDetailOn };
                    })
                  }
                >
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1836 1.62074L6.59955 7.98438L1.0155 1.62074"
                      stroke="black"
                      strokeWidth="1.89"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="mobile-amounts d-flex d-md-none flex-column justify-content-between">
          <div className="amounts-holder">
            {this.props.item.units && this.props.item.units.length
              ? this.props.item.units.map((data) => (
                  <div className="amount selectable" key={data.id}>
                    <span className="type">{data.name}</span>
                    <span className="how-many">
                      x{data.quantity}{" "}
                      {this.props.translate(
                        `global.piece${data.quantity > 1 ? `s` : ``}`
                      )}
                    </span>
                    <span className="price text-right">
                      {currencyFormatter(
                        this.props.currency,
                        parseFloat(data.price).toFixed(2),
                        this.props.lang
                      )}
                    </span>
                  </div>
                ))
              : ""}
          </div>
          <div className="total-price text-right">
            {this.props.translate("global.total")}:{" "}
            <span className="selectable">
              {currencyFormatter(
                this.props.currency,
                parseFloat(this.props.item.amount).toFixed(2),
                this.props.lang
              )}
            </span>
          </div>
        </div>

        {this.props.item.package_type === PACKAGE_TYPES.GIFT ||
        this.props.item.package_type === PACKAGE_TYPES.PASSGIFT ? (
          <Gift
            translate={this.props.translate}
            email={this.props.item.delivery_recipient_email}
            firstName={this.props.item.delivery_recipient_first_name}
            lastName={this.props.item.delivery_recipient_last_name}
            phone={
              "+" +
              this.props.item.delivery_recipient_area_code +
              this.props.item.delivery_recipient_phone_w_out_code
            }
            date={this.props.item.delivery_recipient_send_date}
            message={this.props.item.gift_message}
          />
        ) : (
          ""
        )}
        {this.props.item.package_type === PACKAGE_TYPES.PICKADATE ? (
          <DatePicked
            translate={this.props.translate}
            date={this.props.item.preferred_date}
            time={this.props.item.preferred_time}
            selectedPackage={this.props.item.selectedPackage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
