import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import crypto from "crypto";

const BLDropdown = (props) => {
  const [selected, setSelected] = useState(props.default ? props.default : "");

  const id = crypto.randomBytes(6).toString("hex");

  useEffect(() => {
    if (props.default) {
      const defaultSelected = props.options?.filter((data) => {
        return data.value.toString() === props.default.toString();
      });

      if (defaultSelected?.length) setSelected(defaultSelected[0].name);
      else setSelected("");
    } else setSelected("");
  }, [props.default, props.options]);

  const handleSelectOption = (e) => {
    if (e) e.preventDefault();

    setSelected(e.target.textContent);
    props.setValue(e.target.value);
  };

  return (
    <div
      className={`bl-dropdown position-relative ${
        props.classes ? props.classes : ``
      } ${props.disabled ? `disabled` : ``} ${selected !== "" ? `filled` : ``}`}
      data-placeholder={props.placeholder}
    >
      <select
        name={props.name}
        id={`bl-dropdown-${id}`}
        onChange={(e) => handleSelectOption(e)}
        value={props.default ? props.default : "empty-placeholder-value"}
      >
        <option value="empty-placeholder-value" hidden></option>
        {props.options?.map((data) => (
          <option
            key={data.value}
            value={data.value}
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></option>
        ))}
      </select>
    </div>
  );
};

BLDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BLDropdown;
