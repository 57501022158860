import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";

const HorizontalReservedProduct = (props) => {
  return (
    <div className="product horizontal d-flex justify-content-start align-items-start">
      <div className="image d-flex justify-content-center align-items-center overflow-hidden">
        <img src={props.product.cover_image_url} alt="Product Cover" />
      </div>
      <div className="info-area-holder">
        <h6 className="coupon-info-title">
          {props.translate("profile.reservations_page.coupon_info")}
        </h6>
        <h4 className="title">
          <Link
            to={global.experience.links[props.lang].replace(
              ":name",
              props.product.slug
            )}
            className="selectable"
          >
            {props.product.title}
          </Link>
        </h4>
        <div className="product-code">
          {props.translate("product.product_code")}:{" "}
          <span className="code selectable ml-1">{props.product.code}</span>
        </div>
        <div className="units-holder d-flex flex-column">
          {props.product.units.map((data, index) => (
            <div className="unit" key={index}>
              <div className="name">{data.name}</div>
              <div className="count">
                x{data.quantity}{" "}
                {props.translate(`global.piece${data.quantity > 1 ? `s` : ``}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HorizontalReservedProduct.propTypes = {
  translate: PropTypes.func.isRequired,
  product: PropTypes.shape({
    cover_image_url: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    stats: PropTypes.shape({
      rate: PropTypes.number.isRequired,
      review: PropTypes.number.isRequired,
      booking: PropTypes.number.isRequired,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    availability: PropTypes.any,
  }),
};

export default HorizontalReservedProduct;
