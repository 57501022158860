import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../App/routes";
import HelpBox from "./components/HelpBox";
import API, { headers } from "../utils/API";
import { handleErrors } from "../utils/helper";
import AutoSuggest from "./components/AutoSuggest";
import SEO from "../utils/components/SEO";

const HelpCenter = ({ translate, lang }) => {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    API.get("content/categories", {
      headers: { ...headers, "Accept-Language": lang },
    })
      .then(({ data: r }) => {
        const { data: cats } = r;

        setCats(cats);
      })
      .catch((err) => handleErrors(err));
  }, [lang]);

  return (
    <div className="HelpCenter">
      <SEO
        title={translate("page_title.helpCenter")}
        description={translate("page_description.help_center")}
      />
      <div className="row">
        <div className="col col-md-8 offset-md-2">
          <h4 className="page-title text-center">
            {translate("help_center.page_title")}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-8 offset-md-2">
          <AutoSuggest
            placeholder={translate("help_center.search_placeholder")}
            translate={translate}
            lang={lang}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <section className="help-box-holder mt-3 d-flex align-items-center justify-content-between flex-wrap">
            {cats.map((cat) => (
              <HelpBox key={cat.slug} cat={cat} lang={lang} />
            ))}
          </section>
        </div>
      </div>
      <div className="row d-none d-md-block">
        <div className="col">
          <div className="buttons-area">
            <p className="text-center">
              {translate("help_center.contact_us_text")}
            </p>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Link
                to={global.contact.links[lang]}
                className="text-center white-button"
              >
                {translate("help_center.contact_button")}
              </Link>
              <Link
                to={global.experiences.links[lang]}
                className="text-center white-button opposite"
              >
                {translate("help_center.explore_button")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
