import { useEffect, useState } from "react";
import AmountCounter from "../../AmountCounter";

const Amounts = ({
  packageType,
  isLoading: propsLoading,
  units,
  isUpdate,
  isVoucher,
  currency,
  selectedPackage,
  handleUpdateCounts,
  handleCartDataLayers,
  preferredDate,
  handleUpdateTotalPrice,
}) => {
  const [counts, setCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleSetCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, packageType]);

  useEffect(() => {
    calculatePrice();
    setIsLoading(false);
    handleUpdateCounts && handleUpdateCounts(counts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);

  const handleSetCounts = () => {
    const newCounts = {};

    if (isUpdate && !isVoucher) {
      units?.forEach((data) => {
        const price = data.is_discounted ? data.discounted_price : data.price;
        const q = data.quantity;

        newCounts[`po-${data.id}`] = {
          count: q,
          price: parseFloat(price).toFixed(2),
          label: data.name,
          id: data.id,
          price_id: data.price_id,
          participant_count: data.participant_count,
        };
      });
    } else {
      units?.forEach((data) => {
        const price = data.is_discounted ? data.discounted_price : data.price;

        newCounts[`po-${data.id}`] = {
          count: 0,
          price: parseFloat(price).toFixed(2),
          label: data.label,
          id: data.id,
          price_id: data.price_id,
          participant_count: data.participant_count,
        };
      });
    }

    setCounts(newCounts);
  };

  const handleUpdateAmount = (count, name) => {
    if (typeof count === "number") {
      const newCounts = JSON.parse(JSON.stringify(counts));

      let isAdding = count > newCounts[name].count;

      newCounts[name].count = count;

      setCounts(newCounts);

      handleCartDataLayers &&
        handleCartDataLayers(
          {
            ...newCounts[name],
            name: newCounts[name].label,
            index: Object.keys(newCounts).indexOf(name) + 1,
          },
          isAdding,
          1,
          preferredDate
        );
    }
  };

  const calculatePrice = () => {
    let totalPrice = 0.0;
    const newCounts = JSON.parse(JSON.stringify(counts));

    Object.keys(newCounts).forEach((key) => {
      const { count, price } = newCounts[key];

      if (count > 0) {
        const unitPrice = parseFloat(count * price).toFixed(2);

        totalPrice = parseFloat(totalPrice) + parseFloat(unitPrice);
      }
    });

    handleUpdateTotalPrice && handleUpdateTotalPrice(totalPrice);
  };

  return !propsLoading &&
    !isLoading &&
    units?.length &&
    Object.values(counts).length
    ? units.map((data) => (
        <div className="row" key={data.id}>
          <div className="col">
            <AmountCounter
              key={data.id}
              name={`po-${data.id}`}
              label={isUpdate ? data.name : data.label}
              amount={counts["po-" + data.id]?.count}
              price={parseFloat(data.price).toFixed(2)}
              discountedPrice={data.discounted_price}
              isDiscounted={data.is_discounted}
              discountedPercent={data.discounted_percent}
              currency={currency}
              limits={{
                start: selectedPackage?.min_pax_per_booking || 0,
                end: selectedPackage?.max_pax_per_booking || 10,
              }}
              handleUpdateAmount={handleUpdateAmount}
            />
          </div>
        </div>
      ))
    : "";
};

export default Amounts;
