import React from "react";
import PropTypes from "prop-types";
import couponStatus from "../../utils/db/couponStatuses";
import BLCollapsible from "../../utils/components/BLCollapsible";
import { currencyFormatter, formatString } from "../../utils/helper";
import dayjs from "dayjs";

const SingleGiftCard = (props) => {
  return (
    <>
      <div className="gift-card-holder">
        <div className="info">
          {formatString(props.id, "#### #### #### ####")}
        </div>
        <div className={`info status-${couponStatus[props.status_id]}`}>
          {props.status}
        </div>
        <div className="info">
          {currencyFormatter(props.currency, props.amount, props.lang)}
        </div>
        <div className="info">
          {dayjs(props.date).format("DD MMMM YYYY HH:mm")}
        </div>
      </div>
      <BLCollapsible
        classes="gift-cart-holder-w-details d-block d-lg-none"
        summaryContents={[
          <span className={`status-${couponStatus[props.status_id]}`}>
            {props.status}
          </span>,
          currencyFormatter(props.currency, props.amount, props.lang),
        ]}
      >
        <div className="details-holder">
          <div className="detail">
            <span className="title">
              {props.translate("profile.gift_cards_page.gift_card_num")}
            </span>
            <span className="content">
              {formatString(props.id, "#### #### #### ####")}
            </span>
          </div>
          <div className="detail">
            <span className="title">
              {" "}
              {props.translate("profile.gift_cards_page.card_date")}
            </span>
            <span className="content">
              {dayjs(props.date).format("DD MMM YYYY HH:mm")}
            </span>
          </div>
        </div>
      </BLCollapsible>
    </>
  );
};

SingleGiftCard.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  status_id: PropTypes.number.isRequired,
};

export default SingleGiftCard;
