import React, { Component } from "react";
import BLInput from "../../../utils/components/BLInput";
import BLDropdown from "../../../utils/components/BLDropdown";
import { BLRadio } from "../../../utils/components/BLRadio";
import { Personal } from "./invoiceTypes/Personal";
import { Corporate } from "./invoiceTypes/Corporate";
import {
  getCities,
  getDistricts,
  getCountries,
  getTurkeyOnTop,
} from "../../../utils/helper";

export default class InvoiceDetails extends Component {
  state = {
    country: this.props.invoiceInfo.country,
    city: this.props.invoiceInfo.city,
    district: this.props.invoiceInfo.district,
    countries: [],
    cities: [],
    districts: [],
  };

  componentDidMount = async () => {
    await getCountries(this.props.lang).then((response) => {
      const countries = getTurkeyOnTop(response.data.data);
      this.setState({ countries });
    });

    setTimeout(async () => {
      if (this.props.invoiceInfo.country) {
        await getCities(this.props.lang, this.state.country).then(
          (response) => {
            this.setState({ cities: response.data.data });
          }
        );
      }
    });

    setTimeout(async () => {
      if (this.props.invoiceInfo.city) {
        await getDistricts(this.props.lang, this.state.city).then(
          (response) => {
            this.setState({ districts: response.data.data });
          }
        );
      }
    });

    const { country, city, district } = this.props.invoiceInfo;

    this.setState({ country, city, district });
  };

  componentDidUpdate = async (prevProps) => {
    if (this.state.country !== this.props.invoiceInfo.country) {
      let countryName = "";
      this.state.countries.some((country) => {
        if (String(this.state.country) === String(country.id)) {
          countryName = country.name;
          return true;
        }
        return false;
      });

      this.props.handleUpdateLocationInfo({
        countryName,
        country: this.state.country,
      });

      if (this.state.country) {
        await getCities(this.props.lang, this.state.country).then(
          (response) => {
            this.setState({ cities: response.data.data });
          }
        );
      } else {
        this.setState({ cities: [] });
      }

      this.setState({ city: "" });
    }
    if (this.state.city !== this.props.invoiceInfo.city) {
      let cityName = "";
      this.state.cities.some((city) => {
        if (String(this.state.city) === String(city.id)) {
          cityName = city.name;
          return true;
        }
        return false;
      });

      this.props.handleUpdateLocationInfo({ cityName, city: this.state.city });

      if (this.state.city) {
        await getDistricts(this.props.lang, this.state.city).then(
          (response) => {
            this.setState({ districts: response.data.data });
          }
        );
      } else {
        this.setState({ districts: [] });
      }

      this.setState({ district: "" });
    }
    if (this.state.district !== this.props.invoiceInfo.district) {
      let districtName = "";
      this.state.districts.some((district) => {
        if (String(this.state.district) === String(district.id)) {
          districtName = district.name;
          return true;
        }
        return false;
      });

      this.props.handleUpdateLocationInfo({
        districtName,
        district: this.state.district,
      });
    }
  };

  render() {
    return (
      <div className="invoice-details">
        <div className="row mt-4">
          <div className="col d-flex align-items-center">
            <h5 className="invoice-type-label mr-md-4 mb-0">
              {this.props.translate("profile.billing_info_page.invoice_type")}:
            </h5>
            <div className="d-flex align-items-center mr-3">
              <BLRadio
                label={this.props.translate("payment.billing_info.personal")}
                name="invoiceType"
                checked={this.props.invoiceInfo.type === 1}
                changed={() => this.props.handleUpdateInvoiceInfo("type", 1)}
              />
            </div>
            <div className="d-flex align-items-center">
              <BLRadio
                label={this.props.translate("payment.billing_info.corporate")}
                name="invoiceType"
                checked={this.props.invoiceInfo.type === 2}
                changed={() => this.props.handleUpdateInvoiceInfo("type", 2)}
              />
            </div>
          </div>
        </div>
        {this.props.invoiceInfo.type === 1 ? (
          <Personal
            invoiceInfo={this.props.invoiceInfo}
            translate={this.props.translate}
            handleUpdateInvoiceInfo={this.props.handleUpdateInvoiceInfo}
          />
        ) : (
          <Corporate
            invoiceInfo={this.props.invoiceInfo}
            translate={this.props.translate}
            handleUpdateInvoiceInfo={this.props.handleUpdateInvoiceInfo}
          />
        )}
        <div className="row">
          <div className="col">
            <BLInput
              label={this.props.translate("global.address")}
              type="text"
              name="address"
              value={this.props.invoiceInfo.address}
              changed={(e) =>
                this.props.handleUpdateInvoiceInfo(
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <BLDropdown
              name="country"
              options={
                this.state.countries.length
                  ? this.state.countries.map((data) => {
                      return { name: data.name, value: data.id };
                    })
                  : []
              }
              placeholder={this.props.translate("global.country")}
              default={this.props.invoiceInfo.country}
              setValue={(value) => this.setState({ country: value })}
            />
          </div>
          <div className="ol-12 col-md-6">
            <BLDropdown
              name="city"
              options={
                this.state.cities.length
                  ? this.state.cities.map((data) => {
                      return { name: data.name, value: data.id };
                    })
                  : []
              }
              placeholder={this.props.translate("global.city")}
              default={this.props.invoiceInfo.city}
              disabled={!this.state.cities.length}
              setValue={(value) => this.setState({ city: value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="ol-12 col-md-6">
            <BLDropdown
              name="district"
              options={
                this.state.districts.length
                  ? this.state.districts.map((data) => {
                      return { name: data.name, value: data.id };
                    })
                  : []
              }
              placeholder={this.props.translate("global.district")}
              default={this.props.invoiceInfo.district}
              disabled={!this.state.districts.length}
              setValue={(value) => this.setState({ district: value })}
            />
          </div>
          <div className="ol-12 col-md-6">
            <BLInput
              label={this.props.translate("global.postal_code")}
              type="text"
              name="postalCode"
              value={this.props.invoiceInfo.postalCode}
              validationType="number"
              changed={(e) =>
                this.props.handleUpdateInvoiceInfo(
                  e.target.name,
                  e.target.value
                )
              }
              inputProps={{ inputMode: "numeric" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
