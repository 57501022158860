import React, { Component } from "react";
import CartItem from "../components/CartItem";
import BLLoader from "../../utils/components/BLLoader";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import Auth from "../../Auth/Auth";
import { auth, global } from "../../App/routes";
import Helmet from "react-helmet";
import { currencyFormatter } from "../../utils/helper";
import { Link } from "react-router-dom";
import { dataLayer__cartView } from "../../utils/DataLayerEvents";
import packageTypes, { PACKAGE_TYPES } from "../../utils/db/packageTypes";

import { copyCode } from "../../utils/helper";

class Cart extends Component {
  componentDidMount = () => {
    if (this.props.cart.items.length) {
      const { product, units, package_type, preferred_date } =
        this.props.cart.items[0];

      const isGiftCard = package_type === PACKAGE_TYPES.GIFTCARD;

      const dataLayerProduct = {
        title: isGiftCard ? `Hediye Kartı` : product.title,
        code: isGiftCard ? `Hediye Kartı` : product.code,
        units,
        category: isGiftCard ? `` : product.category,
        package_type: isGiftCard
          ? `Hediye Kartı`
          : this.props.translate(
              `package_options.${packageTypes[package_type].name}`
            ),
        video_url: product?.video_url,
        location: product?.location,
        stats: product?.stats,
        provider: product?.provider,
        preferred_date,
      };

      dataLayer__cartView(dataLayerProduct);
    }
  };

  render() {
    const cartItems =
      this.props.cart && this.props.cart.items && this.props.cart.items.length
        ? this.props.cart.items.map((data) => {
            return (
              <CartItem
                key={data.id}
                item={data}
                handleUpdateAmount={this.props.handleUpdateAmount}
                currency={this.props.currency}
                translate={this.props.translate}
                handleRemoveFromCart={this.props.handleRemoveFromCart}
                isUpdatingPackage={this.props.isUpdatingPackage}
                isUpdatingSuccessful={this.props.isUpdatingSuccessful}
                handleUpdateCart={this.props.handleUpdateCart}
                handleUpdateGiftPackage={this.props.handleUpdateGiftPackage}
                lang={this.props.lang}
                user={this.props.user}
                isUpdating={this.props.isUpdating}
              />
            );
          })
        : [];

    return (
      <div className="page cart-page">
        <Helmet>
          <title>{this.props.translate("cart.my_summary")}</title>
        </Helmet>
        <h1 className="page-title">
          {this.props.translate("cart.my_summary")}
        </h1>
        {/**
         * Remove comments when cart system is activated
         * 
         * <p className="cart-info-desc">
          {this.props.translate(
            `cart.${
              this.props.cart &&
              this.props.cart.items &&
              this.props.cart.items.length > 1
                ? `you_have_product_count_items_in_your_cart`
                : `you_have_product_count_item_in_your_cart`
            }`,
            {
              count:
                this.props.cart &&
                this.props.cart.items &&
                this.props.cart.items.length
                  ? this.props.cart.items.length
                  : 0,
            }
          )}
        </p> */}
        {this.props.cart.items.length ? (
          <div className="cart-summary-holder">
            <div className="cart-summary-header w-100">
              <div className="title">
                {this.props.translate("cart.product")}
              </div>
              <div className="title text-center">
                {this.props.translate(
                  this.props.cart.items[0].package_type ===
                    PACKAGE_TYPES.GIFTCARD
                    ? "cart.amount"
                    : "cart.unit_type"
                )}
              </div>
              <div className="title text-center">
                {this.props.translate(
                  this.props.cart.items[0].package_type ===
                    PACKAGE_TYPES.GIFTCARD
                    ? "cart.balance"
                    : "cart.amount"
                )}
              </div>
              <div className="title text-center">
                {this.props.translate("cart.subtotal")}
              </div>
            </div>
            <div className="cart-summary-content w-100">{cartItems}</div>
            <div className="cart-final-info d-flex flex-column flex-md-row justify-content-between align-items-end align-items-md-center">
              {this.props.isTotalCalculating ? (
                <BLLoader />
              ) : (
                <>
                  <div className="promotion-area position-relative">
                    {this.props.promoCodeApplied && (
                      <>
                        <div className="code-holder d-flex align-items-center">
                          <div
                            className="code copy-on-click user-select-all position-relative"
                            onClick={copyCode}
                            data-copied-text={this.props.translate(
                              "global.copied"
                            )}
                          >
                            {this.props.promoCodeApplied.code}
                          </div>
                          <p className="text mb-0">
                            {this.props.promoCodeApplied.title}
                          </p>
                        </div>
                        <div className="disclaimer">
                          <span>*</span>
                          {this.props.translate(
                            "cart.promo_code_is_applied_automatically"
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="button-area d-flex flex-column flex-md-row justify-content-end align-items-center">
                    <div className="price-info d-flex justify-content-between justify-content-md-center align-items-center align-items-md-end">
                      <div className="label d-flex flex-column align-items-end selectable">
                        {this.props.translate(`cart.subtotal`)}:
                        <span className="info">
                          {this.props.translate("cart.tax_included")}
                        </span>
                      </div>
                      <div className="price selectable">
                        {currencyFormatter(
                          this.props.currency,
                          this.props.cartTotal,
                          this.props.lang
                        )}
                      </div>
                    </div>
                    <button
                      className="pay-button text-uppercase"
                      onClick={this.props.handleRedirectToPayment}
                      disabled={
                        !this.props.cart ||
                        !this.props.cart.items ||
                        !this.props.cart.items.length
                      }
                    >
                      {this.props.translate("cart.pay")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="cart-summary-holder empty">
            <div className="empty-info d-flex flex-column align-items-center pt-5 pb-5 pb-md-0 mt-3 mt-md-5">
              <div className="icon">
                <svg
                  width="89"
                  height="89"
                  viewBox="0 0 89 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M75.967 75.967C93.3443 58.5897 93.3443 30.4103 75.967 13.033C58.5897 -4.34433 30.4103 -4.34433 13.033 13.033C-4.34433 30.4103 -4.34433 58.5897 13.033 75.967C30.4103 93.3443 58.5897 93.3443 75.967 75.967Z"
                    fill="#E7E8E9"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.4599 51.6147H59.0208L66 30.0367H32.4748L31.1039 25.0275C30.9792 24.3853 30.4807 24 29.8576 24H25.2463C24.4985 24 24 24.5138 24 25.2844C24 26.055 24.4985 26.5688 25.2463 26.5688H28.9852L35.092 50.4587L33.7211 52.5138C31.8516 54.9541 33.5964 58.422 36.5875 58.422H59.0208C59.7685 58.422 60.2671 57.7798 60.2671 57.1376C60.2671 56.367 59.7685 55.8532 59.0208 55.8532H36.5875C35.5905 55.8532 35.092 54.8257 35.5905 54.055L37.4599 51.6147ZM37.3353 59.578C39.0801 59.578 40.451 60.9908 40.451 62.789C40.451 64.5872 39.0801 66 37.3353 66C35.5905 66 34.2196 64.5872 34.2196 62.789C34.2196 60.9908 35.5905 59.578 37.3353 59.578ZM59.1454 62.789C59.1454 60.9908 57.7745 59.578 56.0297 59.578C54.2849 59.578 52.9139 60.9908 52.9139 62.789C52.9139 64.5872 54.2849 66 56.0297 66C57.7745 66 59.1454 64.5872 59.1454 62.789Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text">
                {this.props.translate("cart.summary_empty_text")}
              </p>
              <Link
                to={global.home.links[this.props.lang]}
                className="see-all-experiences-button"
              >
                {this.props.translate("global.go_back_home")}
              </Link>
            </div>
          </div>
        )}
        <CSSTransition
          in={this.props.user.settings.isAuthModalOn}
          timeout={300}
          classNames="bl-modal"
          unmountOnExit
        >
          <BLModal
            handleCloseModal={this.props.toggleAuthModal}
            headerFix={true}
          >
            <Auth
              redirectTo={auth.payment.links[this.props.lang]}
              lang={this.props.lang}
              cart={this.props.cart}
              user={this.props.user}
            />
          </BLModal>
        </CSSTransition>
      </div>
    );
  }
}
export default Cart;
