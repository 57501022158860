import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import PickADate from "./PackageOptions/PickADate";
import OpenEnded from "./PackageOptions/OpenEnded";
import Gift from "./PackageOptions/Gift";
import { CSSTransition } from "react-transition-group";
import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import packageTypes, { PACKAGE_TYPES } from "../../utils/db/packageTypes";
import {
  dataLayer__addToCart,
  dataLayer__removeFromCart,
} from "../../utils/DataLayerEvents";
import Pass from "./PackageOptions/Pass";
import PassGift from "./PackageOptions/PassGift";
import BOOKING_MODE from "../../utils/constants";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder/lib";
import "react-placeholder/lib/reactPlaceholder.css";

const PackageOptionPlaceholder = () => (
  <div className="package-placeholder">
    <div className="row">
      <div className="col-12">
        <TextBlock
          className="description-placeholder placeholder-item"
          rows={2}
          style={{
            marginBottom: 50,
            width: "85%",
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <RectShape
          className="selectbox-placeholder placeholder-item w-100"
          style={{ height: 60, marginBottom: 25, borderRadius: 4 }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <RectShape
          className="selectbox-placeholder placeholder-item w-100"
          style={{ height: 60, borderRadius: 4, marginBottom: 25 }}
        />
      </div>
      <div className="col-6">
        <RectShape
          className="selectbox-placeholder placeholder-item w-100"
          style={{ height: 60, borderRadius: 4, marginBottom: 25 }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <RectShape
          className="selectbox-placeholder placeholder-item w-100"
          style={{ height: 60, marginBottom: 25, borderRadius: 4 }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 d-flex justify-content-end">
        <RectShape
          className="amount-placeholder placeholder-item"
          style={{
            height: 40,
            width: 130,
            marginBottom: 25,
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <TextBlock
          className="description-placeholder placeholder-item"
          rows={2}
          style={{
            width: "85%",
          }}
        />
      </div>
      <div className="col-6 d-flex justify-content-end">
        <RectShape
          className="amount-placeholder placeholder-item"
          style={{
            height: 49,
            width: 210,
          }}
        />
      </div>
    </div>
  </div>
);

const placeholder = (
  <div className="package-placeholder-container">
    <PackageOptionPlaceholder />
  </div>
);

class PackageOptions extends Component {
  state = {
    selectedTab: this.props.selectedTab
      ? this.props.selectedTab
      : this.props.product?.booking_mode === BOOKING_MODE.TICKET
      ? PACKAGE_TYPES.PASS
      : PACKAGE_TYPES.PICKADATE,
    isLoading: true,
    product: this.props.product,
    isNotAvailable: false,
  };

  tabs = Object.keys(packageTypes)
    ?.filter(
      (key) =>
        packageTypes[key]?.bookingModes?.includes(
          this.props.product?.booking_mode
        ) && !packageTypes[key].disabled
    )
    .map((key, index) => {
      return {
        id: packageTypes[key].id,
        title: this.props.translate(
          `package_options.${packageTypes[key].name}`
        ),
        bookingModes: packageTypes[key].bookingModes,
        packageType: Number(key),
        index,
      };
    });

  componentDidMount = () => {
    if (this.props.isUpdate && !this.props.isVoucher)
      API.get(`products/${this.props.item.product.slug}`, {
        headers: { ...headers, "Accept-Language": this.props.lang },
      })
        .then((response) =>
          this.setState({ product: response.data.data, isLoading: false })
        )
        .catch((err) => handleErrors(err));
    else this.setState({ isLoading: false });

    if (this.props.product?.available_for) {
      this.checkIfAvailable();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      JSON?.stringify(prevProps?.product?.available_for) !==
      JSON?.stringify(this.props.product?.available_for)
    ) {
      this.checkIfAvailable();
    }
  };

  checkIfAvailable = () => {
    this.setState({
      isNotAvailable:
        !this.props.product?.available_for?.sale ||
        !this.props.product?.available_for?.booking
          ? this.props.product.available_for?.text
          : false,
    });
  };

  handleCartDataLayers = (unit, isAdding, packageType = 1, preferredDate) => {
    const { product } = this.state;

    const dataLayerProduct = {
      id: product.id,
      title: product.title,
      code: product.code,
      currency: product.currency,
      provider: product.provider,
      category: product.category,
      unit,
      package_type: this.props.translate(
        `package_options.${packageTypes[this.state.selectedTab].name}`
      ),
      stats: product.stats,
      preferred_date: preferredDate,
      video_url: product.video_url,
      location: product.location,
    };

    if (isAdding) dataLayer__addToCart(dataLayerProduct);
    else dataLayer__removeFromCart(dataLayerProduct);
  };

  render() {
    const mutualProps = {
      translate: this.props.translate,
      currency: this.props.currency,
      handleBuyNow: !this.props.isUpdate ? this.props.handleBuyNow : () => {},
      handleUpdatePackage: this.props.isUpdate
        ? this.props.handleUpdatePackage
        : () => {},
      isAddingToCart: this.props.isAddingToCart,
      isUpdatingPackage: this.props.isUpdatingPackage,
      isUpdate: this.props.isUpdate,
      product:
        this.props.isUpdate && !this.props.isVoucher
          ? this.state.product
          : this.props.product,
      lang: this.props.lang,
      isLoading: this.state.isLoading,
      units:
        this.props.isUpdate && !this.props.isVoucher
          ? this.props.item.units
          : this.props.units,
      itemId: this.props.isUpdate ? this.props.item.id : "",
      item: this.props.isUpdate ? this.props.item : {},
      handleCartDataLayers: this.handleCartDataLayers,
      selectedAvailability: this.props.selectedAvailability,
      handleGetAvailabilities: this.props.handleGetAvailabilities,
      handleSetSelectedAvailability: this.props.handleSetSelectedAvailability,
      productAvailabilities: this.props.productAvailabilities,
      productAvailabilitiesLoading: this.props.productAvailabilitiesLoading,
      selectedPackage: this.props.selectedPackage,
      handleSetSelectedPackage: this.props.handleSetSelectedPackage,
      isUpdating: this.props.isUpdating,
    };

    const giftProps = this.props.isVoucher
      ? this.props.isUpdate
        ? {
            isVoucher: true,
            amount: this.props.item.amount,
            quantity: this.props.quantity,
          }
        : {
            isVoucher: true,
            amount: this.props.amount,
            quantity: this.props.quantity,
          }
      : { isVoucher: false };

    return (
      <div className="package-options w-100">
        <h6 className="title m-0">
          {this.props.isUpdate
            ? !this.props.isVoucher
              ? this.props.item.product.title + " "
              : this.props.translate("voucher.voucher_gift_info")
            : this.props.isVoucher
            ? this.props.translate("voucher.voucher_gift_info")
            : this.props.translate("package_options.package_options")}
        </h6>
        <div className="package-options-tabs-area d-flex flex-column position-relative">
          {this.state.isNotAvailable ? (
            <div className="not-available-to-buy position-absolute d-flex justify-content-center align-items-center text-center">
              <h6>{this.state.isNotAvailable}</h6>
            </div>
          ) : (
            ``
          )}
          {!this.props.isVoucher && (
            <div
              className="package-options-tabs position-relative"
              data-active-tab={
                this.props.isUpdate || this.props.isVoucher
                  ? 0
                  : this.tabs?.find(
                      (tab) => tab.packageType === this.state.selectedTab
                    )?.index
              }
              data-is-not-available={!!this.state.isNotAvailable}
            >
              {this.tabs?.map((data) => {
                return ((this.props.isUpdate || this.props.isVoucher) &&
                  this.props.selectedTab.toString() ===
                    data.packageType.toString()) ||
                  (!this.props.isUpdate && !this.props.isVoucher) ? (
                  <button
                    key={data.id}
                    className={`package-options-tab ${
                      this.state.selectedTab === data.packageType
                        ? `active`
                        : ``
                    }`}
                    onClick={() =>
                      this.setState({
                        selectedTab: Number(data.packageType),
                      })
                    }
                    disabled={!!this.state.isNotAvailable}
                  >
                    {data.title}
                  </button>
                ) : (
                  ""
                );
              })}
            </div>
          )}
          <div
            className="package-options-tabs-contents-holder position-relative"
            data-is-not-available={!!this.state.isNotAvailable}
          >
            <ReactPlaceholder
              ready={!this.state.isLoading && !!!this.state.isNotAvailable}
              customPlaceholder={placeholder}
            >
              <div className="package-options-tab-contents-holder d-flex align-items-start">
                <div className="package-options-tab-content w-100">
                  <CSSTransition
                    in={this.state.selectedTab === PACKAGE_TYPES.PICKADATE}
                    timeout={0}
                    unmountOnExit
                  >
                    <PickADate {...mutualProps} />
                  </CSSTransition>
                  <CSSTransition
                    in={this.state.selectedTab === PACKAGE_TYPES.OPENENDED}
                    timeout={0}
                    unmountOnExit
                  >
                    <OpenEnded {...mutualProps} />
                  </CSSTransition>
                  <CSSTransition
                    in={this.state.selectedTab === PACKAGE_TYPES.GIFT}
                    timeout={0}
                    unmountOnExit
                  >
                    <Gift {...mutualProps} {...giftProps} />
                  </CSSTransition>
                  <CSSTransition
                    in={this.state.selectedTab === PACKAGE_TYPES.PASS}
                    timeout={0}
                    unmountOnExit
                  >
                    <Pass {...mutualProps} />
                  </CSSTransition>
                  <CSSTransition
                    in={this.state.selectedTab === PACKAGE_TYPES.PASSGIFT}
                    timeout={0}
                    unmountOnExit
                  >
                    <PassGift {...mutualProps} />
                  </CSSTransition>
                </div>
              </div>
            </ReactPlaceholder>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(PackageOptions);
