import React from "react";
import BLButton from "../../utils/components/BLButton";

import logo from "../../assets/images/Logo.png";
import logoWhite from "../../assets/images/Logo-White.png";
import logoIcon from "../../assets/images/Logo-Icon.png";
import { copyCode } from "../../utils/helper";

const LogoUsageContent = (props) => {
  const downloadSet = (type) => {
    const filename = `bucketlist_${type}.zip`;

    const a = document.createElement("a");
    a.href = `${process.env.REACT_APP_CDN_URL}/images/logo/${filename}`;
    a.download = filename;
    a.click();
    a.remove();
  };
  return (
    <div className="media-page-content logo-usage">
      <div className="row">
        <div className="col-12">
          <h3 className="content-title">
            {props.translate("media.logo_usage_page.title")}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <section>
            <div className="row">
              <div className="col-12 col-xl-4 d-flex justify-content-center align-items-center">
                <div className="logo-holder d-flex flex-column">
                  <div className="logo-area d-flex justify-content-center align-items-center">
                    <img
                      src={logo}
                      alt="Bucketlist Logo"
                      className="mw-100 mh-100"
                    />
                  </div>
                  <BLButton type="sec" clicked={() => downloadSet("orange")}>
                    {props.translate("global.download")}
                  </BLButton>
                </div>
              </div>
              <div className="col-12 col-xl-4 d-flex justify-content-center align-items-center">
                <div className="logo-holder d-flex flex-column">
                  <div className="logo-area opposite d-flex justify-content-center align-items-center">
                    <img
                      src={logoWhite}
                      alt="Bucketlist Logo in White"
                      className="mw-100 mh-100"
                    />
                  </div>
                  <BLButton type="sec" clicked={() => downloadSet("white")}>
                    {props.translate("global.download")}
                  </BLButton>
                </div>
              </div>
              <div className="col-12 col-xl-4 d-flex justify-content-center align-items-center">
                <div className="logo-holder d-flex flex-column">
                  <div className="logo-area d-flex justify-content-center align-items-center">
                    <img
                      src={logoIcon}
                      alt="Bucketlist B Icon"
                      className="mw-100 mh-100 h-100"
                    />
                  </div>
                  <BLButton type="sec" clicked={() => downloadSet("icon")}>
                    {props.translate("global.download")}
                  </BLButton>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <section>
            <div className="row">
              <div className="col-12">
                <h4 className="logo-sec-title">
                  {props.translate("media.logo_usage_page.sec_title_2")}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="color-holder w-100">
                  <div className="row">
                    <div className="col-12">
                      <div className="color-title">
                        {props.translate("global.bucketlist_orange")}
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-5 d-flex justify-content-start">
                      <div
                        className="color-info hex copy-on-click position-relative user-select-all"
                        onClick={copyCode}
                        data-copied-text={props.translate("global.copied")}
                      >
                        #E75B2B
                      </div>
                    </div>
                    <div className="col-7 col-sm-5 col-md-6 col-xl-5 offset-sm-2 offset-md-1 offset-xl-2">
                      <div className="row my-2">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="color-info-holder w-100 d-flex justify-content-between align-items-center">
                            <div className="color-info text-center">
                              C <span className="user-select-all">0</span>
                            </div>
                            <div className="color-info text-center">
                              M <span className="user-select-all">55</span>
                            </div>
                            <div className="color-info text-center">
                              Y <span className="user-select-all">74</span>
                            </div>
                            <div className="color-info text-center">
                              K <span className="user-select-all">9</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="color-info-holder w-100 d-flex justify-content-between align-items-center">
                            <div className="color-info text-center">
                              R <span className="user-select-all">230</span>
                            </div>
                            <div className="color-info text-center">
                              G <span className="user-select-all">91</span>
                            </div>
                            <div className="color-info text-center">
                              B <span className="user-select-all">43</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LogoUsageContent;
