import React, { useEffect, useState } from "react";

import BLInput from "../../../utils/components/BLInput";
import BLLoader from "../../../utils/components/BLLoader";
import BLDatePicker from "../../../utils/components/BLDatePicker";
import { currencyFormatter, validateInput } from "../../../utils/helper";
import BLDropdown from "../../../utils/components/BLDropdown";
import Amounts from "./components/Amounts";
import dayjs from "dayjs";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

const PassGift = ({
  currency,
  translate,
  lang,
  product,
  selectedPackage,
  handleSetSelectedPackage,
  units,
  isUpdate,
  isAddingToCart,
  handleCartDataLayers,
  handleUpdatePackage,
  itemId,
  isUpdating,
  handleBuyNow,
  item,
}) => {
  const [counts, setCounts] = useState({});
  const [info, setInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    areaCode: "90",
    countryCode: "TR",
    deliveryDate: "",
    message: "",
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      const {
        delivery_recipient_first_name: firstName,
        delivery_recipient_last_name: lastName,
        delivery_recipient_email: email,
        delivery_recipient_phone_w_out_code: phone,
        delivery_recipient_area_code: areaCode,
        delivery_recipient_phone_cc: countryCode,
        delivery_recipient_send_date: deliveryDate,
        gift_message: message,
      } = item;
      setInfo((prevState) => ({
        ...prevState,
        firstName,
        lastName,
        email,
        phone,
        areaCode,
        countryCode,
        deliveryDate,
        message,
      }));
    }
  }, [isUpdate, item]);

  useEffect(() => {
    const checkIfFilled = () => {
      const { firstName, lastName, email, phone, deliveryDate, message } = info;
      if (
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        !validateInput("email", email).success ||
        phone === "" ||
        !validateInput("phone", phone).success ||
        deliveryDate === "" ||
        deliveryDate === "Invalid date" ||
        message === ""
      )
        return false;

      const addedCount = Object.keys(counts).filter(
        (key) => counts[key].count > 0
      );

      if (!addedCount.length) return false;

      return true;
    };

    if (isFilled !== checkIfFilled()) {
      setIsFilled(checkIfFilled());
    }
  }, [counts, info, isFilled]);

  const handleSetCountryCode = (value) => {
    const valueArr = value.split("+");

    const countryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    setInfo((prevState) => ({ ...prevState, areaCode, countryCode }));
  };

  const handleChange = ({ target: { name, value } }) => {
    setInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="option-holder pick-a-date">
      <p className="desc">{translate("package_options.gift_tab.answer")}</p>
      <form
        action="#!"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-6">
            <BLInput
              label={translate("global.name")}
              type="text"
              name="firstName"
              value={info?.firstName}
              changed={handleChange}
              limitCharacters={["text"]}
            />
          </div>
          <div className="col-12 col-sm-6">
            <BLInput
              label={translate("global.last_name")}
              type="text"
              name="lastName"
              value={info?.lastName}
              changed={handleChange}
              limitCharacters={["text"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BLInput
              label={translate("global.email")}
              type="email"
              name="email"
              value={info?.email}
              changed={handleChange}
              validationType="email"
              inputProps={{ inputMode: "email" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <BLInput
              label={translate("global.phone")}
              type="tel"
              name="phone"
              value={info?.phone}
              changed={handleChange}
              validationType="phone"
              setCountryCode={(value) => handleSetCountryCode(value)}
              countryCode={info?.countryCode + " +" + info?.areaCode}
              inputProps={{ inputMode: "tel" }}
            />
          </div>
          <div className="col-12 col-sm-6">
            <BLDatePicker
              lang={lang}
              type="single"
              placeholder={translate("global.delivery_date")}
              setSelected={(value) => {
                setInfo((prevState) => ({
                  ...prevState,
                  deliveryDate: dayjs(value).format("DD/MM/YYYY"),
                }));
              }}
              selected={
                info?.deliveryDate
                  ? dayjs(info?.deliveryDate, "DD/MM/YYYY")
                  : null
              }
              minDate={new Date()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BLInput
              label={translate("package_options.gift_tab.your_message")}
              type="textarea"
              name="message"
              value={info?.message}
              changed={handleChange}
              charLimit={
                process.env.REACT_APP_MAX_GIFT_MESSAGE_CHAR
                  ? process.env.REACT_APP_MAX_GIFT_MESSAGE_CHAR
                  : 150
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <BLDropdown
              name="package"
              options={product?.packages?.map((item) => ({
                name: item.title,
                value: item.id,
              }))}
              placeholder={translate("experience.select_package")}
              default={selectedPackage?.id}
              setValue={(value) => {
                handleSetSelectedPackage(
                  product.packages.find(
                    (item) => String(item.id) === String(value)
                  )
                );
              }}
            />
          </div>
        </div>

        <Amounts
          isLoading={isLoading}
          units={units}
          isUpdate={isUpdate}
          isVoucher={false}
          currency={currency}
          selectedPackage={selectedPackage}
          handleUpdateCounts={(counts) => {
            setCounts(counts);
            setIsLoading(false);
          }}
          handleCartDataLayers={handleCartDataLayers}
          handleUpdateTotalPrice={(totalPrice) => setTotalPrice(totalPrice)}
          packageType={PACKAGE_TYPES.PASSGIFT}
        />

        <div className="row">
          <div className="col d-flex justify-content-end align-items-end">
            <div className="total selectable">
              {translate("global.total")}{" "}
              <span>{currencyFormatter(currency, totalPrice, lang)}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="disclaimer">
              {translate("package_options.fill_all_fields_to_continue")}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 offset-sm-6 offset-md-2 d-flex justify-content-end align-items-center">
            {isAddingToCart ? (
              <BLLoader />
            ) : isUpdate ? (
              <button
                className="add-to-cart-button w-100"
                onClick={() =>
                  handleUpdatePackage(itemId, {
                    priceOptions: { ...counts },
                    packageType: PACKAGE_TYPES.PASSGIFT,
                    ...info,
                    totalPrice: totalPrice,
                    currency: currency,
                  })
                }
                disabled={!isFilled || isUpdating}
              >
                {isUpdating ? (
                  <div className="loader w-100 h-100 d-flex justify-content-center align-items-center">
                    <BLLoader loaderType="dots" />
                  </div>
                ) : (
                  translate("global.update")
                )}
              </button>
            ) : (
              <button
                className="add-to-cart-button w-100"
                onClick={() =>
                  handleBuyNow({
                    priceOptions: { ...counts },
                    packageType: PACKAGE_TYPES.PASSGIFT,
                    ...info,
                    totalPrice: totalPrice,
                    currency: currency,
                  })
                }
                disabled={!isFilled}
              >
                {translate("global.buy_it_now")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PassGift;
