import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function CatThumb(props) {
  return (
    <div
      className={`cat-thumb position-relative d-flex justify-content-center align-items-center overflow-hidden ${props.className}`}
      onClick={
        props.handlePromotionClick ? props.handlePromotionClick : () => {}
      }
    >
      <div
        className="image position-absolute w-100 h-100 overflow-hidden d-flex justify-content-center align-items-center"
        style={{
          background: `url("${props.image}") no-repeat 100% 100%`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img
          src={props.image}
          alt={props.name}
          className="thumb-image"
          style={{ opacity: "0" }}
        />
      </div>
      <Link
        to={props.onClick ? "#!" : props.link}
        className="name stretched-link text-center"
        onClick={
          props.onClick
            ? (e) => {
                e.preventDefault();
                props.onClick();
              }
            : () => {}
        }
      >
        {props.name}
      </Link>
    </div>
  );
}

CatThumb.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CatThumb;
