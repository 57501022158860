import React from "react";

export default function Blog(props) {
  return (
    <div className="single-blog w-100 d-flex align-items-start flex-column position-relative">
      <div
        className="image d-flex justify-content-center align-items-center overflow-hidden"
        style={{
          backgroundImage: `url("${props.blog.image_url}")`,
        }}
      >
        <img src={props.blog.image_url} alt="Blog Cover" />
      </div>
      <h5 className="cat text-uppercase">{props.blog.category}</h5>
      <h2 className="title">
        <a
          href={props.blog.url}
          target="_blank"
          rel="noopener noreferrer"
          className="stretched-link"
        >
          {props.blog.title}
        </a>
      </h2>
      <div className="info d-flex justify-content-start align-items-center">
        <div className="date text-uppercase">{props.blog.created_date}</div>
        <div className="read-time text-uppercase">
          {props.blog.reading_time}
        </div>
      </div>
    </div>
  );
}
