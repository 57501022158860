const Photo = ({ item }) => {
  return (
    <a
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      className="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <img
        src={item.image}
        alt={item.title}
        className="w-100 h-100 photo-feed-item"
      />
    </a>
  );
};
export default Photo;
