import React from "react";
import PropTypes from "prop-types";

import BLRating from "../../utils/components/BLRating";

const SingleComment = props => {
  /**
   * {"avatar_url":"https://picsum.photos/340/340?v=424680162",
   * "full_name":"Burak Gürbüz",
   * "title":"Lorem ipsum dolor sit amet",
   * "comment":"Güzel bir deneyimdi",
   * "rate":4,
   * "time":"1 yıl önce",
   * "answer":[]}
   */
  return (
    <>
      <div className="single-comment d-flex justify-content-between align-items-start position-relative">
        <div className="avatar d-flex justify-content-center align-items-center">
          <img src={props.avatar_url} alt={`${props.full_name} Avatar`} />
        </div>
        <div className="rest d-flex flex-column">
          <div className="info d-flex justify-content-between align-items-start">
            <div className="user-info">
              <h3 className="user-name">{props.full_name}</h3>
              <span className="location d-flex align-items-center">
                {props.title}
              </span>
            </div>
            <BLRating rating={props.rate} />
          </div>
          <div className="content">
            <p>{props.comment}</p>
          </div>

          <div className="footer d-flex justify-content-start align-items-center">
            <div className="date">{props.time}</div>
          </div>
        </div>
      </div>
      {Object.keys(props.answer).length &&
      props.answer.constructor === Object ? (
        <div className="single-comment d-flex justify-content-between align-items-start position-relative reply">
          <div className="avatar d-flex justify-content-center align-items-center">
            <img
              src={props.answer.avatar_url}
              alt={`${props.answer.full_name} Avatar`}
            />
          </div>
          <div className="rest d-flex flex-column">
            <div className="info d-flex justify-content-between align-items-start">
              <div className="user-info">
                <h3 className="user-name">{props.answer.full_name}</h3>
                <span className="location d-flex align-items-center">
                  {props.answer.title}
                </span>
              </div>
            </div>
            <div className="content">
              <p>{props.answer.comment}</p>
            </div>

            <div className="footer d-flex justify-content-between align-items-center">
              <div className="date">{props.answer.time}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

SingleComment.propTypes = {
  translate: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default SingleComment;
