const initState = {
  cart: {
    items: []
  }
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET": {
      const cart = JSON.parse(localStorage.getItem("bl-local-user-cart"));

      if (!cart) {
        localStorage.setItem("bl-local-user-cart", JSON.stringify(state.cart));
      }

      return cart ? { cart } : { cart: state.cart };
    }
    case "CART_UPDATE": {
      const cart = { ...state.cart };

      setTimeout(() => {
        cart.items = action.items;

        localStorage.setItem("bl-local-user-cart", JSON.stringify(cart));
      }, 10);

      return { cart };
    }
    case "RESET": {
      const cart = initState.cart;

      localStorage.setItem("bl-local-user-cart", JSON.stringify(cart));

      return { cart };
    }
    default:
      return state;
  }
};

export default cartReducer;
