import React from "react";

const Steps = (props) => {
  const steps = [
    props.translate("cart.summary"),
    props.translate("cart.payment"),
    props.translate("cart.thanks"),
  ];

  for (let i = 1; i <= 3; i++) {
    steps.push();
  }
  return (
    <div className="steps d-flex justify-content-center align-items-center">
      {steps.map((data, index) => (
        <div
          className={`step-holder d-flex justify-content-center align-items-center flex-column position-relative ${
            props.step >= index + 1 ? `active` : ``
          }`}
          data-step={index + 1}
          key={index + 1}
        >
          <div className="icon d-flex justify-content-center align-items-center position-relative rounded-circle">
            <svg
              width="23"
              height="17"
              viewBox="0 0 23 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.7988 0.385736L7.66585 13.8312L2.20102 8.29798C1.69806 7.78235 0.88113 7.78235 0.378173 8.29798C-0.126058 8.81361 -0.126058 9.64833 0.378173 10.1626L6.75947 16.6229C7.2828 17.099 8.06535 17.1517 8.58232 16.6229L22.6228 2.2504C23.1257 1.73609 23.1257 0.90005 22.6228 0.385736C22.1187 -0.128579 21.303 -0.128579 20.7988 0.385736Z"
                fill="white"
              />
            </svg>
          </div>
          <span className="name text-uppercase position-absolute">{data}</span>
        </div>
      ))}
    </div>
  );
};

export default Steps;
