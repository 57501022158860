import React from "react";
import { SidebarMenu } from "./SidebarMenu";
import { MobileMenu } from "./MobileMenu";

export const Sidebar = (props) => {
  return (
    <div className="sidebar">
      <SidebarMenu
        translate={props.translate}
        lang={props.lang}
        selectedSection={props.selectedSection}
        menuItems={props.menuItems}
      />
      <MobileMenu
        translate={props.translate}
        lang={props.lang}
        menuItems={props.menuItems}
      />
    </div>
  );
};
