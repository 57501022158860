import React from "react";

const SingleSection = props => {
  return (
    <section className="info-sec">
      <h4 className="sec-title">{props.title}</h4>
      <div className="content-area">{props.children}</div>
    </section>
  );
};

export default SingleSection;
