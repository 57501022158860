import React from "react";
import BLInput from "../../../../utils/components/BLInput";

export const Corporate = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <BLInput
            label={props.translate("payment.billing_info.corporate_name")}
            type="text"
            name="corporateName"
            value={props.invoiceInfo.corporateName}
            changed={(e) =>
              props.handleUpdateInvoiceInfo(e.target.name, e.target.value)
            }
            minChar={2}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <BLInput
            label={props.translate("payment.billing_info.tax_number")}
            type="text"
            name="taxNumber"
            value={props.invoiceInfo.taxNumber}
            changed={(e) =>
              props.handleUpdateInvoiceInfo(e.target.name, e.target.value)
            }
            inputProps={{ inputMode: "numeric" }}
            limitCharacters={["number"]}
          />
        </div>
        <div className="col">
          <BLInput
            label={props.translate("payment.billing_info.tax_location")}
            type="text"
            name="taxLocation"
            value={props.invoiceInfo.taxLocation}
            changed={(e) =>
              props.handleUpdateInvoiceInfo(e.target.name, e.target.value)
            }
            minChar={2}
          />
        </div>
      </div>
    </>
  );
};
