import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import GiftCard from "../GiftCard";

import bl_coupon from "../../assets/images/bl-coupon.png";
import BLButton from "../../utils/components/BLButton";
import BLLoader from "../../utils/components/BLLoader";
import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import { auth } from "../../App/routes";
import Axios from "axios";

class Use extends Component {
  state = {
    code: "",
    isSending: false,
  };

  cancelSource = Axios.CancelToken.source();

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.code) {
      if (!this.props.user.isLoggedIn) {
        this.props.toggleAuthModal();
        return false;
      }

      this.setState({ isSending: true });

      const configs = {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": `${this.props.lang}`,
          cancelToken: this.cancelSource.token,
        },
      };

      API.get(`redeem/${this.state.code}`, configs)
        .then((response) => {
          const { data } = response.data;

          sessionStorage.setItem("reservationTemp", JSON.stringify(data));

          this.props.history.push(
            auth.redeemedReservation.links[this.props.lang].replace(
              ":id",
              this.state.code
            )
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isSending: false });
        });
    }
  };

  render() {
    return (
      <GiftCard>
        <section className="page-sec use">
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column align-items-center">
                <h4 className="page-title text-center">
                  {this.props.translate("gift_card.use_page.page_title")}
                </h4>
                <div className="page-holder d-flex flex-column flex-lg-row justify-content-center align-items-center">
                  <img
                    src={bl_coupon}
                    alt={this.props.translate("gift_card.bucketlist_coupon")}
                    className="coupon"
                  />
                  <form className="info-area" onSubmit={this.handleSubmit}>
                    <h6 className="input-title">
                      {this.props.translate(
                        "gift_card.use_page.enter_your_experience_coupon_code"
                      )}
                    </h6>
                    <input
                      type="text"
                      className="gift-card-input"
                      value={this.state.code}
                      onChange={(e) => this.setState({ code: e.target.value })}
                    />
                    <div className="info-about-input d-flex justify-content-start align-items-start">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 10C0 4.48585 4.48615 0 10 0C15.5138 0 20 4.48585 20 10C20 15.5142 15.5138 20 10 20C4.48615 20 0 15.5142 0 10ZM1.23077 10C1.23077 14.8354 5.16462 18.7692 10 18.7692C14.8354 18.7692 18.7692 14.8354 18.7692 10C18.7692 5.16462 14.8354 1.23077 10 1.23077C5.16462 1.23077 1.23077 5.16462 1.23077 10ZM10.1594 7.04185C10.7278 7.04185 11.1886 6.58104 11.1886 6.01262C11.1886 5.44419 10.7278 4.98338 10.1594 4.98338C9.59096 4.98338 9.13015 5.44419 9.13015 6.01262C9.13015 6.58104 9.59096 7.04185 10.1594 7.04185ZM9.27292 9.336C9.27292 8.99631 9.54862 8.72062 9.88831 8.72062C10.228 8.72062 10.5037 8.996 10.5037 9.336V14.5668C10.5037 14.9065 10.228 15.1822 9.88831 15.1822C9.54862 15.1822 9.27292 14.9065 9.27292 14.5668V9.336Z"
                          fill="black"
                        />
                      </svg>
                      <p>
                        {this.props.translate(
                          "gift_card.use_page.coupon_code_info"
                        )}
                      </p>
                    </div>
                    <div className="buttons d-flex flex-column flex-md-row justify-content-between align-items-center">
                      {this.state.isSending ? (
                        <span className="w-50 text-center">
                          <BLLoader />
                        </span>
                      ) : (
                        <BLButton
                          type="pri"
                          action="submit"
                          disabled={this.state.isSending}
                        >
                          {this.props.translate("gift_card.use_code")}
                        </BLButton>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </GiftCard>
    );
  }
}

export default withLocalize(Use);
