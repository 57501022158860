import Cookies from "universal-cookie";
import { isCrawler } from "../utils/helper";
import dayjs from "dayjs";
import { default as dayjsTR } from "dayjs/locale/tr";

let langPref = isCrawler()
  ? "tr"
  : navigator.language.split("-")[0] === "tr"
  ? `tr`
  : `en`;

const language = navigator.language.toLowerCase();

const defCurrency =
  language === "en-us"
    ? { code: "USD", symbol: "$" }
    : language === "en-gb"
    ? { code: "GBP", symbol: "£" }
    : language === "tr-tr" || language === "tr"
    ? { code: "TRY", symbol: "₺" }
    : { code: "EUR", symbol: "€" };

const initState = {
  user: {
    isLoggedIn: false,
    currencyPref: defCurrency,
    langPref,
    settings: {
      isAuthModalOn: false,
    },
  },
};

const authReducer = (state = initState, action) => {
  const cookies = new Cookies();
  switch (action.type) {
    case "LOGIN": {
      const user = { ...state.user, ...action.user, isLoggedIn: true };

      if (!cookies.get("user")) {
        const options = action.user.rememberMe
          ? {
              expires: new Date(dayjs().add(1, "year")),
              sameSite: "none",
              secure: true,
              path: "/",
            }
          : { sameSite: "none", secure: true, path: "/" };

        cookies.set("user", action.user, options);
      }

      let preferences = JSON.parse(localStorage.getItem("preferences"));

      if (!preferences) preferences = {};

      preferences.currencyPref = user.currencyPref;
      preferences.langPref = user.langPref;

      localStorage.setItem("preferences", JSON.stringify(preferences));

      return { user };
    }
    case "REFRESH_TOKEN": {
      const user = { ...state.user, ...action.user };

      const userCookie = cookies.get("user");
      userCookie._expires = action.user._expires;

      let options = { sameSite: "none", secure: true, path: "/" };

      options = user.rememberMe
        ? { expires: new Date(dayjs().add(1, "year")), ...options }
        : options;

      cookies.set("user", userCookie, options);

      return { user };
    }
    case "AUTH_UPDATE": {
      const user = { ...state.user, ...action.user };
      let cookieUser = cookies.get("user");
      cookieUser = { ...cookieUser, ...action.user };

      let options = { sameSite: "none", secure: true, path: "/" };

      options = user.rememberMe
        ? { expires: new Date(dayjs().add(1, "year")), ...options }
        : options;

      cookies.set("user", cookieUser, options);

      return { user };
    }
    case "LOGOUT": {
      const { langPref, currencyPref } = JSON.parse(
        localStorage.getItem("preferences")
      );

      const defState = {
        ...initState.user,
        langPref: langPref,
        currencyPref: currencyPref,
      };

      cookies.remove("user", { sameSite: "none", secure: true, path: "/" });

      return { user: defState };
    }
    case "UPDATE_LANG": {
      const user = { ...state.user, langPref: action.lang };

      let preferences = JSON.parse(localStorage.getItem("preferences"));

      if (!preferences) preferences = {};

      preferences.langPref = action.lang;

      localStorage.setItem("preferences", JSON.stringify(preferences));

      dayjs.locale(action.lang === "tr" ? dayjsTR : "en");

      return { user };
    }
    case "UPDATE_CURRENCY":
      const user = { ...state.user, currencyPref: action.currency };

      let preferences = JSON.parse(localStorage.getItem("preferences"));

      if (!preferences) preferences = {};

      preferences.currencyPref = action.currency;

      localStorage.setItem("preferences", JSON.stringify(preferences));

      return { user };
    case "TOGGLE_AUTH_MODAL": {
      const user = { ...state.user };

      if (!user["settings"]["isAuthModalOn"] && !user.isLoggedIn)
        user["settings"]["isAuthModalOn"] = true;
      else user["settings"]["isAuthModalOn"] = false;

      return { user };
    }
    default:
      return state;
  }
};

export default authReducer;
