import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { global } from "../../App/routes";
import API, { headers } from "../../utils/API";

import BLLoader from "../../utils/components/BLLoader";
import { handleErrors } from "../../utils/helper";

const AutoSuggest = ({ placeholder, translate, lang }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState("");

  useEffect(() => {
    clearTimeout(searchTimeout);

    if (query.length) {
      const searchTimeout = setTimeout(
        () =>
          API.get(`content/search?q=${query}`, {
            headers: { ...headers, "Accept-Language": lang },
          })
            .then(({ data: r }) => {
              const { data: topics } = r;

              if (topics.length) {
                let i = 0;
                const suggestions = topics.filter((topic) => {
                  const name = topic.title.toLowerCase().trim();
                  const matchValue = query.toLowerCase().trim();

                  const check = name.indexOf(matchValue) > -1 && i < 5;
                  check && i++;
                  return check;
                });

                const getSuggestions = suggestions.map((suggestion) => {
                  const name = suggestion.title.toLowerCase().trim();
                  const matchValue = query.toLowerCase().trim();

                  const index = name.indexOf(matchValue);

                  const endStart = index + matchValue.length;
                  const endLength = name.length - index - matchValue.length;

                  return {
                    id: suggestion.slug,
                    name: suggestion.title,
                    cat: {
                      title: suggestion.categories[0].title,
                      slug: suggestion.categories[0].slug,
                    },
                    slug: suggestion.slug,
                    start: suggestion.title.substr(0, index),
                    highlighted: suggestion.title.substr(
                      index,
                      matchValue.length
                    ),
                    end: suggestion.title.substr(endStart, endLength),
                  };
                });

                setSuggestions(getSuggestions || []);
              } else setSuggestions([]);

              setTimeout(() => setIsSearching(false), 20);
            })
            .catch((err) => handleErrors(err)),
        1200
      );

      setSearchTimeout(searchTimeout);
    } else {
      setSuggestions([]);
      setIsSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, query]);

  return (
    <div className="AutoSuggest form-group position-relative">
      <form
        action="#"
        onSubmit={(e) => e.preventDefault()}
        className="position-relative"
      >
        <input
          type="text"
          name="query"
          id="query"
          value={query}
          className="form-control position-relative w-100 input"
          onChange={({ target }) => {
            setIsSearching(true);
            setQuery(target.value);
          }}
          placeholder={placeholder}
        />
        <button
          className={`remove-btn ${query !== "" && `active`}`}
          onClick={(e) => {
            e.preventDefault();
            setQuery("");
          }}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.2182 3.21815C7.50934 -1.07293 14.4912 -1.07251 18.782 3.21815C23.0727 7.50881 23.0727 14.4906 18.782 18.7817C14.4908 23.0728 7.50892 23.0728 3.2182 18.7817C-1.07252 14.4906 -1.07295 7.50924 3.2182 3.21815ZM14.1429 15.3397C14.4735 15.6703 15.0093 15.6703 15.3399 15.3397C15.6705 15.0091 15.6705 14.4733 15.3399 14.1427L12.1971 10.9999L15.1901 8.007C15.5206 7.67643 15.5206 7.14057 15.1901 6.81C14.8595 6.47943 14.3236 6.47943 13.993 6.81L11.0001 9.80292L7.85728 6.66016C7.5267 6.32959 6.99083 6.32959 6.66026 6.66016C6.32968 6.99031 6.32968 7.52659 6.66026 7.85716L9.80306 10.9999L6.51084 14.2921C6.18027 14.6227 6.18027 15.1585 6.51084 15.4891C6.84142 15.8197 7.37728 15.8197 7.70786 15.4891L11.0001 12.1969L14.1429 15.3397Z"
              fill="#979797"
            />
          </svg>
        </button>
        <div
          className={`position-absolute d-flex justify-content-center align-items-center p-md-2 icon ${
            isSearching ? `searching` : ``
          }`}
        >
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.2036 19.0616L26.7665 25.6231C27.0778 25.9344 27.0778 26.4492 26.7665 26.7605C26.6108 26.9222 26.4012 27 26.1976 27C25.994 27 25.7844 26.9162 25.6287 26.7605L19.0659 20.1991C17.0419 21.9592 14.4012 23.0248 11.515 23.0248C5.16766 23.0248 0 17.8583 0 11.5124C0 5.16652 5.16168 0 11.515 0C17.8623 0 23.0299 5.16053 23.0299 11.5124C23.0299 14.398 21.9641 17.0381 20.2036 19.0616ZM11.509 1.61641C6.05389 1.61641 1.61078 6.05255 1.61078 11.5124C1.61078 16.9723 6.05389 21.4144 11.509 21.4144C16.9701 21.4144 21.4072 16.9663 21.4072 11.5124C21.4072 6.05854 16.9701 1.61641 11.509 1.61641Z"
              fill="black"
            />
          </svg>
          <BLLoader loaderType="dots" />
        </div>
        <CSSTransition
          in={Boolean(suggestions.length) && !isSearching}
          timeout={300}
          classNames="suggestions-holder"
          unmountOnExit
        >
          <div className="position-absolute list-group w-100 suggestions-holder">
            {suggestions.map((suggestion) => (
              <Link
                to={global.helpDetail.links[lang]
                  .replace(":cat", suggestion.cat.slug)
                  .replace(":name", suggestion.slug)}
                key={suggestion.id}
                className={`w-100 suggestion`}
              >
                {suggestion.start}
                <span className="highlighted">{suggestion.highlighted}</span>
                {suggestion.end}
              </Link>
            ))}
          </div>
        </CSSTransition>
        <CSSTransition
          in={!Boolean(suggestions.length) && Boolean(query) && !isSearching}
          timeout={300}
          classNames="suggestions-holder"
          unmountOnExit
        >
          <div className="position-absolute list-group w-100 suggestions-holder">
            <h3 className="no-result-found">
              {translate("global.no_result_found_w_query")}
            </h3>
          </div>
        </CSSTransition>
      </form>
    </div>
  );
};

export default AutoSuggest;
