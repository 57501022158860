import React, { Component } from "react";
import Banner from "./components/Banner/Banner";
import MostLikedCats from "./components/MostLikedCats";
import PopularActivities from "./components/PopularActivities/PopularActivities";
import Recommends from "./components/Recommends";
import TrendLocations from "./components/TrendLocations";
import ExpInfo from "./components/ExpInfo";
import FollowUs from "./components/FollowUs/FollowUs";
import API, { headers } from "../utils/API.js";
import Axios from "axios";
import GoToTop from "./components/GoToTop.js";
import BLPageLoader from "../utils/components/BLPageLoader.js";
import { CSSTransition } from "react-transition-group";
import { checkIfOnScreen, handleErrors } from "../utils/helper";
import { global } from "../App/routes";
import {
  dataLayer__promotionClick,
  dataLayer__promotionView,
} from "../utils/DataLayerEvents";
import SEO from "../utils/components/SEO";
import FeedSlider from "./components/FeedSlider";
import NewestActivities from "./components/NewestActivities/NewestActivities";

class Home extends Component {
  state = {
    mostLikedCats: [],
    trendLocations: [],
    blogs: [],
    youtubeFeed: [],
    instagramFeed: [],
    isPageLoading: false,
    sliderIndex: 0,
    sliderCount: 4,
    isMostLikedCatsReady: false,
    isTrendLocationsReady: false,
    isBlogsReady: false,
    isCatsOnScreen: false,
    isLocationsOnScreen: false,
  };
  cancelSource = Axios.CancelToken.source();

  catsRef = React.createRef(null);
  locationsRef = React.createRef(null);

  getMostPopularCategories = () =>
    API.get("most_popular_categories", {
      headers: {
        ...headers,
        "Accept-Language": this.props.user.langPref,
        cancelToken: this.cancelSource.token,
      },
    });

  getTrendLocations = () =>
    API.get(`trend_locations`, {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        cancelToken: this.cancelSource.token,
      },
    });

  getBlogs = () =>
    API.get(`top_blog_list`, {
      headers: {
        ...headers,
        "Accept-Language": this.props.user.langPref,
        cancelToken: this.cancelSource.token,
      },
    });

  getYoutubeFeed = () =>
    API.get("feed/youtube", {
      headers: {
        ...headers,
        "Accept-Language": this.props.user.langPref,
        cancelToken: this.cancelSource.token,
      },
    });

  getInstagramFeed = () =>
    API.get("feed/instagram", {
      headers: {
        ...headers,
        "Accept-Language": this.props.user.langPref,
        cancelToken: this.cancelSource.token,
      },
    });

  componentDidMount = () => {
    this.getContent();

    document.addEventListener("scroll", this.checkIfPromotionVisible, false);
  };

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");

    document.removeEventListener("scroll", this.checkIfPromotionVisible, false);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.langPref !== this.props.user.langPref)
      this.setState(
        {
          isMostLikedCatsReady: false,
          isTrendLocationsReady: false,
          isBlogsReady: false,
        },
        this.getContent
      );
  };

  getContent = () => {
    Axios.all([
      this.getMostPopularCategories(),
      this.getTrendLocations(),
      this.getBlogs(),
      this.getYoutubeFeed(),
      this.getInstagramFeed(),
    ])
      .then(
        Axios.spread((mpc, l, b, youtube, instagram) => {
          setTimeout(() => {
            this.setState(
              {
                mostLikedCats: mpc.data.data || [],
                trendLocations: l.data.data || [],
                blogs: b.data.data || [],
                isPageLoading: false,
                youtubeFeed: youtube?.data?.data || [],
                instagramFeed: instagram?.data?.data?.length
                  ? instagram?.data?.data[0]
                  : [],
              },
              () =>
                this.setState({
                  isMostLikedCatsReady: true,
                  isTrendLocationsReady: true,
                  isBlogsReady: true,
                })
            );
          }, 1200);
        })
      )
      .catch((err) => {
        handleErrors(err);
      });
  };

  checkIfPromotionVisible = () => {
    const isCatsOnScreen = checkIfOnScreen(this.catsRef.current);
    if (isCatsOnScreen && !this.state.isCatsOnScreen)
      this.setState({ isCatsOnScreen: true }, this.handleCatsPromotionVisible);

    const isLocationsOnScreen = checkIfOnScreen(this.locationsRef.current);
    if (isLocationsOnScreen && !this.state.isLocationsOnScreen)
      this.setState(
        { isLocationsOnScreen: true },
        this.handleLocationsPromotionVisible
      );
  };

  handleCatsPromotionVisible = () => {
    if (this.state.isMostLikedCatsReady) {
      const items = this.state.mostLikedCats.map((cat, index) => ({
        title: cat.title,
        index: index + 1,
      }));

      const promotion = {
        id: "En Beğenilen Kategoriler",
        name: "Ana Sayfa - En Beğenilen Kategoriler",
      };

      dataLayer__promotionView(items, promotion);
    }
  };

  handleLocationsPromotionVisible = () => {
    if (this.state.isTrendLocationsReady) {
      const items = this.state.trendLocations.map((location, index) => ({
        title: location.name,
        index: index + 1,
      }));

      const promotion = {
        id: "En Favori Lokasyonlar",
        name: "Ana Sayfa - En Favori Lokasyonlar",
      };

      dataLayer__promotionView(items, promotion);
    }
  };

  handlePromotionClick = (item, id) => {
    const promotion = {
      id,
      name: `Ana Sayfa - ${id}`,
    };

    dataLayer__promotionClick(item, promotion);
  };

  render() {
    return (
      <>
        <SEO
          title={this.props.translate("page_title.home")}
          description={this.props.translate("page_description.home")}
        />
        <CSSTransition
          in={this.state.isPageLoading}
          timeout={300}
          classNames="page-loader"
          unmountOnExit
        >
          <BLPageLoader />
        </CSSTransition>
        <div className="Home">
          <Banner
            translate={this.props.translate}
            currency={this.props.user.currencyPref}
            lang={this.props.lang}
          />

          <NewestActivities
            listInfo={{
              type: global.home.type,
              name: "En Yeni Deneyimler",
            }}
          />

          <MostLikedCats
            mostLikedCats={this.state.mostLikedCats}
            translate={this.props.translate}
            lang={this.props.lang}
            isReady={this.state.isMostLikedCatsReady}
            propRef={this.catsRef}
            handlePromotionClick={this.handlePromotionClick}
            user={this.props.user}
          />

          <PopularActivities
            listInfo={{ type: global.home.type, name: "En Sevilen Deneyimler" }}
          />

          <Recommends
            translate={this.props.translate}
            listInfo={{ type: global.home.type, name: "Bucketlist Öneriyor" }}
          />
          <TrendLocations
            trendLocations={this.state.trendLocations}
            translate={this.props.translate}
            lang={this.props.lang}
            isReady={this.state.isTrendLocationsReady}
            propRef={this.locationsRef}
            handlePromotionClick={this.handlePromotionClick}
            user={this.props.user}
          />

          <ExpInfo translate={this.props.translate} lang={this.props.lang} />

          {this.props.lang === "tr" && (
            <FollowUs
              blogs={this.state.blogs}
              translate={this.props.translate}
              isReady={this.state.isBlogsReady}
            />
          )}

          {this.state.youtubeFeed.length > 0 && (
            <section>
              <FeedSlider
                title={this.props.translate("home.youtube_feed_title")}
                description={this.props.translate("home.youtube_feed_desc")}
                feed={this.state.youtubeFeed.map((item) => ({
                  title: item.title,
                  image:
                    item.thumbnails?.maxres?.url ||
                    item.thumbnails?.high?.url ||
                    item.thumbnails?.medium?.url ||
                    item.thumbnails?.standard?.url ||
                    item.thumbnails?.default,
                  link: item.url,
                }))}
                video
                lang={this.props.lang}
              />
            </section>
          )}

          {this.state.instagramFeed.length > 0 && (
            <section>
              <FeedSlider
                title={this.props.translate("home.instagram_feed_title")}
                description={this.props.translate("home.instagram_feed_desc")}
                feed={this.state.instagramFeed.map((item) => ({
                  title: item.media_type,
                  image: item.media_url,
                  link: item.permalink,
                }))}
                lang={this.props.lang}
              />
            </section>
          )}
          <GoToTop translate={this.props.translate} />
        </div>
      </>
    );
  }
}

export default Home;
