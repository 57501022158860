import React from "react";
import BLCollapsible from "../../utils/components/BLCollapsible";
import GoogleMapReact from "google-map-react";
import map_marker from "../../assets/images/map-marker.svg";

const MapMarker = () => (
  <img src={map_marker} alt="Map Marker" className="map-marker" />
);

export const ProductDetails = (props) => {
  const center = {
    lat: props.product.map_coordinates.latitude,
    lng: props.product.map_coordinates.longitude,
  };
  return (
    <div className="important-info-holder">
      <BLCollapsible
        summaryContents={[
          props.translate("experience.experience_content", {
            title: props.product.title,
          }),
        ]}
        classes="detailed-info-collapsibles"
        defaultOn={true}
      >
        <div
          dangerouslySetInnerHTML={{ __html: props.product.description }}
        ></div>
      </BLCollapsible>
      <BLCollapsible
        summaryContents={[props.translate("experience.important_info")]}
        classes="detailed-info-collapsibles"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.product.important_information,
          }}
        ></div>
      </BLCollapsible>
      <BLCollapsible
        summaryContents={[props.translate("experience.location")]}
        classes="detailed-info-collapsibles"
      >
        <div className="map-holder">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBeA7f5Eea6FfDWvPQdNhd6a7uuccLodS4",
            }}
            defaultCenter={center}
            defaultZoom={8}
          >
            <MapMarker lat={center.lat} lng={center.lng} />
          </GoogleMapReact>
        </div>
      </BLCollapsible>
    </div>
  );
};
