import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import NotFound from "../../App/NotFound";
import { global } from "../../App/routes";
import API from "../../utils/API";
import { getCurrentRoute } from "../../utils/helper";
import StaticPages from "../StaticPages";

const NotListedLegals = ({ lang, location, translate, match, history }) => {
  const [legal, setLegal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const el = document.querySelector(".page-holder");

    if (el) el.scrollIntoView();
  }, [location.pathname]);

  useEffect(() => {
    const slug = match.params.policy;

    getCurrentRoute(window.location.pathname, lang).then(({ route }) => {
      if (route) {
        const exists = Object.values(route.params.policy).some(
          (value) => value.en === slug || value.tr === slug
        );

        if (exists) getLegal(slug);
        else setNotFound(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (legal) {
      const currentLegal = legal.translations[lang];

      if (currentLegal) {
        history.push(
          global.notListedPolicy.links[lang].replace(
            ":policy",
            currentLegal.slug
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (legal && legal?.slug === match.params.policy) return;

    getLegal(match.params.policy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getLegal = (slug) => {
    setIsLoading(true);

    if (!slug) {
      return;
    }

    API.get(`legals/${slug}`)
      .then(({ data: r }) => {
        const currentLegal = r.data;

        if (currentLegal) {
          setLegal(currentLegal);

          if (currentLegal?.translations[lang]?.slug !== match.params.policy) {
            history.push(
              global.notListedPolicy.links[lang].replace(
                ":policy",
                currentLegal?.translations[lang]?.slug
              )
            );
          }

          setNotFound(false);
        } else setNotFound(true);

        setTimeout(() => setIsLoading(false));
      })
      .catch(() => setNotFound(true));
  };

  return notFound ? (
    <NotFound lang={lang} translate={translate} />
  ) : (
    <StaticPages lang={lang} isLoading={isLoading} singlePage>
      <Helmet>
        <title>{legal?.title}</title>

        {legal &&
          legal.translations &&
          Object.keys(legal.translations).map((key) => (
            <link
              key={key}
              rel="alternate"
              hrefLang={key}
              href={`${process.env.REACT_APP_URL}${global.notListedPolicy.links[
                key
              ].replace(":policy", legal.translations[key].slug)}`}
            />
          ))}
      </Helmet>
      <div
        className="page-holder cookie-policy selectable"
        dangerouslySetInnerHTML={{ __html: legal?.content }}
      />
    </StaticPages>
  );
};

export default NotListedLegals;
