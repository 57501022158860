import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import BLTab from "./BLTab";
import { withRouter } from "react-router";
import { getCurrentRoute } from "../helper";

class BLTabs extends Component {
  state = {
    tab: null,
  };

  tabs = [];

  componentDidMount = () => {
    setTimeout(() => this.handleScrollToTab(), 20);
  };

  handleScrollToTab = async () => {
    const route = await getCurrentRoute(
      this.props.location.pathname,
      this.props.lang
    );

    if (route) {
      const selectedTab = document.querySelectorAll(".bl-tabs .tab")[
        route.route.order - 1
      ];

      if (selectedTab)
        if (typeof selectedTab.scrollIntoViewIfNeeded === "function")
          selectedTab.scrollIntoViewIfNeeded();
        else
          selectedTab.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
    }
  };

  render() {
    this.props.tabs.sort((a, b) => parseInt(a.order) - parseInt(b.order));

    this.tabs = this.props.tabs.map((data, index) => (
      <BLTab
        key={data.id}
        url={data.links[this.props.lang]}
        title={data.name}
      />
    ));
    return (
      <div className="bl-tabs-holder d-flex">
        <div className="bl-tabs d-flex justify-content-center align-items-center">
          {this.tabs}
        </div>
      </div>
    );
  }
}

export default withRouter(withLocalize(BLTabs));
