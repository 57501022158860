import React, { useState, useEffect } from "react";
import BLButton from "../../utils/components/BLButton";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";
import API, { headers } from "../../utils/API";
import ProductSlider from "../../Home/components/PopularActivities/ProductSlider";
import giftCardIcon from "../../assets/images/gift-card-cart-icon.svg";

const AddedToCartModal = (props) => {
  const [interestingProducts, setInterestingProducts] = useState([]);

  useEffect(() => {
    if (!interestingProducts.length) {
      API.get(`interested_products?currency=${props.currency.code}`, {
        headers: { ...headers, "Accept-Language": props.lang },
      }).then((response) => {
        const { data } = response.data;

        setInterestingProducts(data);
      });
    }
  }, [interestingProducts, props.currency, props.lang]);

  const products = interestingProducts.length ? (
    <ProductSlider
      currency={props.currency}
      products={interestingProducts}
      user={props.user}
      className="added-to-cart-fix"
    />
  ) : (
    ""
  );

  return (
    <div className="added-to-cart-modal voucher-fix">
      <div className="title-area d-flex align-items-center">
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
            fill="url(#green-linear-gradient)"
          />
          <path
            d="M32.7988 14.3857L19.6659 27.8312L14.201 22.298C13.6981 21.7824 12.8811 21.7824 12.3782 22.298C11.8739 22.8136 11.8739 23.6483 12.3782 24.1626L18.7595 30.6229C19.2828 31.099 20.0654 31.1517 20.5823 30.6229L34.6228 16.2504C35.1257 15.7361 35.1257 14.9001 34.6228 14.3857C34.1187 13.8714 33.303 13.8714 32.7988 14.3857Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="green-linear-gradient"
              x1="-13.4331"
              y1="38.1728"
              x2="35.6225"
              y2="61.7231"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#80D88C" />
              <stop offset="0.601105" stopColor="#6DD498" />
              <stop offset="1" stopColor="#38CABB" />
            </linearGradient>
          </defs>
        </svg>
        {props.translate("added_to_cart_modal.added_to_cart")}
      </div>

      <div className="added-product-holder d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div className="info-area d-flex align-items-start">
          <div
            className="cover d-flex justify-content-center align-items-center"
            style={{
              background: `url(${giftCardIcon}) no-repeat 100%`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={giftCardIcon}
              alt="Voucher Icon"
              style={{ opacity: "0" }}
            />
          </div>
          <div className="info">
            <h5 className="title selectable">
              {props.translate("voucher.voucher_gift")}
            </h5>
            <div className="price-info">
              <div className="piece">{`${props.addedItem.delivery_recipient_first_name} ${props.addedItem.delivery_recipient_last_name}`}</div>
            </div>
            <div className="price-info mt-2">
              <span className="piece">
                {props.quantity}{" "}
                {props.translate(
                  `global.${props.quantity > 1 ? `pieces` : `piece`}`
                )}
              </span>
              <span className="price ml-2">
                {props.currency.symbol + props.amount}
              </span>
            </div>
          </div>
        </div>

        <div className="control-area d-flex flex-column flex-lg-row justify-content-end align-items-end">
          <div className="buttons-holder">
            <BLButton type="pri" clicked={props.handleCloseAddedToCartModal}>
              {props.translate("added_to_cart_modal.continue_shopping")}
            </BLButton>
            <Link
              to={global.cart.links[props.lang]}
              className="go-to-cart-button d-block text-center"
            >
              {props.translate("added_to_cart_modal.pay_now")}
            </Link>
          </div>
        </div>
      </div>

      <div className="interesting-experiences-holder">
        <h6 className="title">
          {props.translate(
            "added_to_cart_modal.experiences_you_may_be_interested_in"
          )}
        </h6>
        <div className="products-holder d-flex">{products}</div>
      </div>
    </div>
  );
};

export default AddedToCartModal;
