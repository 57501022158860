import React, { Component } from "react";

import ccCom from "../../../assets/images/cc-com.svg";
import SinglePaymentMethod from "./SinglePaymentMethod";
import CreditCard from "./methods/CreditCard";
import { Link } from "react-router-dom";
import BLLoader from "../../../utils/components/BLLoader";
import BLInfoArea from "../../../utils/components/BLInfoArea";
import {
  currencyFormatter,
  handleErrors,
  getCurrentYear,
} from "../../../utils/helper";
import { CSSTransition } from "react-transition-group";
import MoneyTransfer from "./methods/MoneyTransfer";
import API, { headers } from "../../../utils/API";
import BLCheckbox from "../../../utils/components/BLCheckbox";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

export default class PaymentMethods extends Component {
  state = {
    cc: {
      name: "",
      creditcard: "",
      expiry: { month: "", year: "" },
      cvc: "",
      is3DSecure: false,
    },
    installmentChoice: "",
    totalInterest: 0,
    installmentOptions: [],
    isGettingCCInfo: false,
    cardError: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.cc.creditcard.replace(/ /g, "").substr(0, 6) !==
      this.state.cc.creditcard.replace(/ /g, "").substr(0, 6)
    )
      this.getInstallment();

    if (
      (this.state.cc.creditcard.replace(/ /g, "").substring(0, 6) &&
        (prevProps.cartTotal !== this.props.cartTotal ||
          prevState.cc.creditcard.replace(/ /g, "").substring(0, 6) !==
            this.state.cc.creditcard.replace(/ /g, "").substring(0, 6))) ||
      prevProps.discountPrice !== this.props.discountPrice
    )
      this.getInstallment();

    if (prevProps.selectedGiftCards !== this.props.selectedGiftCards)
      this.getInstallment();

    if (prevProps.promoCodeApplied?.code !== this.props.promoCodeApplied?.code)
      this.getInstallment();
  };

  handleEnterInfo = (e) => {
    const { name, value } = e.target;

    const cc = { ...this.state.cc };

    cc[name] = value;

    this.setState({ cc }, () => {
      if (name === "creditcard" && value.replaceAll(" ", "").length === 16) {
        document.querySelector("input[name=month]")?.focus();
      }
    });

    this.handleCheckInfo();
  };

  handleEnterExpiry = ({ target }) => {
    const { name, value } = target;

    const cc = { ...this.state.cc };

    cc["expiry"][name] = value;

    this.setState({ cc }, () => {
      if (name === "month" && (value > 12 || value <= 0)) {
        return false;
      }
      if (cc["expiry"][name].length === 2) {
        let el = null;
        if (name === "month") {
          if (value <= 12 && value > 0)
            el = document.querySelector("input[name=year]");
        } else {
          if (value >= getCurrentYear(2))
            el = document.querySelector("input[name=cvc]");
        }

        el?.focus();
        setTimeout(() => {
          el?.setSelectionRange(0, el.value.length);
        }, 10);
      }
    });

    this.handleCheckInfo();
  };

  handleChooseInstallment = (installmentChoice) => {
    let totalInterest;

    this.state.installmentOptions.some((option) => {
      if (parseInt(option.value) === parseInt(installmentChoice)) {
        totalInterest = option.totalInterest;
        return true;
      }
      return false;
    });

    this.setState({ installmentChoice, totalInterest });

    this.handleCheckInfo();
  };

  handleCheckIs3DSecure = (value) => {
    this.setState({ cc: { ...this.state.cc, is3DSecure: value } });

    this.handleCheckInfo();
  };

  handleCheckInfo = () => {
    setTimeout(() => {
      const infoObj = {
        name: this.state.cc.name,
        number: this.state.cc.creditcard,
        expMon: this.state.cc.expiry.month,
        expYear: this.state.cc.expiry.year,
        cvc: this.state.cc.cvc,
        is3DSecure: this.state.cc.is3DSecure,
        installment: this.state.installmentChoice,
        totalInterest: this.state.totalInterest,
      };

      this.props.handleUpdateCreditCardInfo(infoObj);
    }, 10);
  };

  getInstallmentTimeout = "";
  getInstallment = () => {
    if (this.props.cart.items[0].package_type === PACKAGE_TYPES.GIFTCARD) {
      const installmentOptions = [
        {
          name: this.props.translate(
            "payment.pick_a_payment_method.one_time_payment"
          ),
          value: 1,
          expiry: 1,
          totalInterest: 0,
        },
      ];

      this.setState({ installmentOptions, installmentChoice: "1" });

      return false;
    }

    clearTimeout(this.getInstallmentTimeout);

    const { creditcard } = this.state.cc;

    if (creditcard.length < 6)
      this.setState({
        installmentOptions: [],
        isGettingCCInfo: false,
        cardError: false,
        installmentChoice: "",
        totalInterest: "",
      });

    if (creditcard && creditcard.length >= 6) {
      this.setState(
        {
          isGettingCCInfo: true,
          installmentChoice: "",
          installmentOptions: [],
          totalInterest: "",
        },
        this.handleCheckInfo
      );
      this.getInstallmentTimeout = setTimeout(() => {
        const configs = {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        };

        let price = this.props.cartTotal - this.props.promoPrice;

        price -= this.props.discountPrice;

        const params = {
          ccy: this.props.user.currencyPref.code,
          bin_number: creditcard.replace(/ /g, "").substr(0, 6),
          price,
        };

        API.post("checkout/retrieve_installment", params, configs)
          .then((response) => {
            const { installmentDetails } = response.data;

            const { installmentPrices } = installmentDetails;

            const installmentOptions = installmentPrices.map((data) => {
              return {
                name:
                  data.installmentNumber !== 1
                    ? this.props.translate(
                        "payment.pick_a_payment_method.installment_template",
                        {
                          installmentNumber: data.installmentNumber,
                          installmentPrice: currencyFormatter(
                            this.props.currency,
                            parseFloat(data.installmentPrice).toFixed(2)
                          ),
                          totalPrice: currencyFormatter(
                            this.props.currency,
                            parseFloat(data.totalPrice).toFixed(2)
                          ),
                        }
                      )
                    : this.props.translate(
                        "payment.pick_a_payment_method.single_installment_option",
                        {
                          totalPrice: currencyFormatter(
                            this.props.currency,
                            parseFloat(data.totalPrice).toFixed(2)
                          ),
                        }
                      ),
                value: data.installmentNumber,
                expiry: data.installmentNumber,
                totalInterest: data.totalPrice,
              };
            });

            this.setState(
              {
                installmentOptions,
                isGettingCCInfo: false,
                cardError: false,
              },
              () => {
                if (installmentOptions.length === 1)
                  this.setState(
                    { installmentChoice: "1" },
                    this.handleCheckInfo
                  );
              }
            );
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({
              isGettingCCInfo: false,
              cardError: true,
            });
          });
      }, 750);
    }
  };

  render() {
    return (
      <div className="payment-methods">
        <CSSTransition
          in={
            Math.round(
              parseFloat(this.props.cartTotal - this.props.promoPrice) * 100
            ) /
              100 >
            Math.round(parseFloat(this.props.discountPrice) * 100) / 100
          }
          timeout={0}
          unmountOnExit
        >
          <>
            <SinglePaymentMethod
              title={this.props.translate(
                "payment.pick_a_payment_method.money_transfer"
              )}
              active={this.props.paymentMethod === 2}
              handleSetPaymentMethod={this.props.handleSetPaymentMethod}
              type={2}
              disabled={true}
            >
              <MoneyTransfer
                cc={this.state.cc}
                translate={this.props.translate}
              />
            </SinglePaymentMethod>
            <SinglePaymentMethod
              title={this.props.translate(
                "payment.pick_a_payment_method.credit_card_n_debit_card"
              )}
              icon={ccCom}
              active={this.props.paymentMethod === 1}
              handleSetPaymentMethod={this.props.handleSetPaymentMethod}
              type={1}
            >
              <CreditCard
                cc={this.state.cc}
                handleEnterExpiry={this.handleEnterExpiry}
                handleEnterInfo={this.handleEnterInfo}
                installmentOptions={this.state.installmentOptions}
                handleChooseInstallment={this.handleChooseInstallment}
                handleCheckIs3DSecure={this.handleCheckIs3DSecure}
                translate={this.props.translate}
                installmentChoice={this.state.installmentChoice}
                isGettingCCInfo={this.state.isGettingCCInfo}
                cardError={this.state.cardError}
              />
            </SinglePaymentMethod>
          </>
        </CSSTransition>

        <CSSTransition
          in={
            Math.round(
              parseFloat(this.props.cartTotal - this.props.promoPrice) * 100
            ) /
              100 <=
            Math.round(parseFloat(this.props.discountPrice) * 100) / 100
          }
          timeout={0}
          unmountOnExit
        >
          <div className="dont-need-payment">
            <BLInfoArea type="info">
              {this.props.translate(
                "payment.pick_a_payment_method.payment_not_needed"
              )}
            </BLInfoArea>
          </div>
        </CSSTransition>

        <div
          className={`end d-flex d-lg-none flex-column-reverse flex-md-row justify-content-between ${
            this.props.payingError ? `align-items-start` : `align-items-center`
          }`}
        >
          <div className="disclaimer">
            <BLCheckbox
              label={this.props.translate(
                "payment.pick_a_payment_method.dsc_and_pif",
                {
                  dscLink: (
                    <Link
                      to="#!"
                      onClick={this.props.handleShowSaleAgreementModal}
                    >
                      {this.props.translate("global.dsc")}
                    </Link>
                  ),
                  pifLink: (
                    <Link
                      to="#!"
                      onClick={this.props.handleShowPreInfoAgreementModal}
                    >
                      {this.props.translate("global.pif")}
                    </Link>
                  ),
                }
              )}
              name="agreement"
              changed={this.props.handleSetAgreements}
              checked={this.props.agreementsAgreed}
              id="agreementsEnd"
            />
          </div>
          {this.props.isPaying ? (
            <BLLoader />
          ) : (
            <div className="d-flex justify-content-end w-100">
              <button
                className="pay-now-button position-relative"
                onClick={this.props.handlePay}
                disabled={!this.props.isOkayToPay}
              >
                {Math.round(parseFloat(this.props.cartTotal) * 100) / 100 >
                Math.round(parseFloat(this.props.discountPrice) * 100) / 100
                  ? this.props.translate(
                      "payment.pick_a_payment_method.pay_now"
                    )
                  : this.props.translate(
                      "payment.pick_a_payment_method.complete_process"
                    )}
                {!this.props.isOkayToPay && (
                  <span className="disabled-tooltip position-absolute w-100">
                    {this.props.translate(
                      "payment.pick_a_payment_method.button_disabled_tooltip"
                    )}
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
