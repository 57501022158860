import React from "react";
import HorizontalProduct from "./HorizontalProduct";
import BLCollapsible from "../../utils/components/BLCollapsible";
import { currencyFormatter } from "../../utils/helper";

const RefundedSingleOrder = (props) => {
  const product = (
    <div className="product-holder d-flex flex-column flex-md-row justify-content-between align-items-end align-items-md-start">
      <HorizontalProduct
        translate={props.translate}
        product={props.product}
        lang={props.lang}
        currency={props.currency}
      />
    </div>
  );

  return (
    <BLCollapsible
      classes="single-order-holder"
      summaryContents={[
        props.id,
        props.refundDate,
        currencyFormatter(
          props.currency,
          parseFloat(
            props.discount ? props.discount_amount : props.totalPrice
          ).toFixed(2),
          props.lang
        ),
      ]}
    >
      <>
        <div className="row flex-lg-row-reverse">
          <div className="col-12 col-lg-6">
            <div className="info-area d-flex flex-column">
              <div className="info d-flex justify-content-between align-items-center">
                <span className="title">
                  {props.translate("profile.orders_page.order_date")}:
                </span>
                <span className="content selectable">{props.date}</span>
              </div>
              <div className="info d-flex justify-content-between align-items-center">
                <span className="title">
                  {props.translate("profile.orders_page.refund_reason")}:
                </span>
                <span className="content selectable">{props.refundCode}</span>
              </div>
              <div className="info d-flex justify-content-between align-items-center">
                <span className="title">
                  {props.translate("profile.orders_page.refund_desc")}:
                </span>
                <span className="content selectable">
                  {props.refundDescription}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="products-list d-flex flex-column">{product}</div>
          </div>
        </div>
      </>
    </BLCollapsible>
  );
};

export default RefundedSingleOrder;
