export const login = (user) => {
  return { type: "LOGIN", user };
};

export const logout = () => {
  return { type: "LOGOUT" };
};

export const updateLanguage = (lang) => {
  return {
    type: "UPDATE_LANG",
    lang,
  };
};

export const updateCurrency = (currency) => {
  return {
    type: "UPDATE_CURRENCY",
    currency,
  };
};

export const update = (user) => {
  return { type: "AUTH_UPDATE", user };
};

export const toggleAuthModal = () => {
  return { type: "TOGGLE_AUTH_MODAL" };
};

export const refreshToken = (user) => ({
  type: "REFRESH_TOKEN",
  user,
});
