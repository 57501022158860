import React, { Component } from "react";
import Swiper from "swiper";
import crypto from "crypto";

import "swiper/swiper.scss";

import Product from "../../../utils/components/Product";
import { dataLayer__itemListView } from "../../../utils/DataLayerEvents";
import { checkIfOnScreen } from "../../../utils/helper";

export default class ProductSlider extends Component {
  state = {
    slides: this.props.products,
    products: [],
    isSliderOnScreen: false,
  };

  id = crypto.randomBytes(6).toString("hex");

  compRef = React.createRef(null);

  swiper = "";

  componentDidMount = () => {
    const products = this.state.slides.length
      ? this.state.slides.map((data, index) => (
          <div
            className="swiper-slide"
            key={data.id}
            onClick={
              this.props.handlePromotionClick
                ? () =>
                    this.props.handlePromotionClick({
                      ...data,
                      index: index + 1,
                    })
                : () => {}
            }
          >
            <Product product={data} currency={this.props.user.currencyPref} />
          </div>
        ))
      : [];

    this.setState({ products }, () => {
      if (!this.state.products.length) return;

      const options = {
        slidesPerView: this.props.slidesPerView ? this.props.slidesPerView : 4,
        spaceBetween: 15,
        slidesPerGroup: this.props.slidesPerView ? this.props.slidesPerView : 4,
        loop: true,
        grabCursor: true,
        on: {
          init: (swiper) => {
            swiper.update();
          },
          realIndexChange: (swiper) => {
            this.handleViewDataLayer(swiper.realIndex);
          },
          slideChangeTransitionStart: (swiper) => {
            let $wrapperEl = swiper.$wrapperEl;
            let params = swiper.params;
            $wrapperEl
              .children(
                "." + params.slideClass + "." + params.slideDuplicateClass
              )
              .each(function () {
                let idx = this.getAttribute("data-swiper-slide-index");
                this.innerHTML = $wrapperEl
                  .children(
                    "." +
                      params.slideClass +
                      '[data-swiper-slide-index="' +
                      idx +
                      '"]:not(.' +
                      params.slideDuplicateClass +
                      ")"
                  )
                  .html();
              });
          },
          slideChangeTransitionEnd: (swiper) => {
            swiper.slideToLoop(swiper.realIndex, 0, false);
          },
        },
        breakpoints: {
          320: {
            slidesPerView: `auto`,
            slidesPerGroup: 1,
            centeredSlides: true,
          },
          576: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: `auto`,
            slidesPerGroup: 2,
          },
          992: {
            slidesPerView: this.props.slidesPerView
              ? this.props.slidesPerView
              : 4,
            slidesPerGroup: this.props.slidesPerView
              ? this.props.slidesPerView
              : 4,
          },
        },
      };

      this.swiper = new Swiper(`#swiper-container-${this.id}`, options);

      document.addEventListener("scroll", this.handleCheckIfVisible, false);
    });
  };

  componentWillUnmount = () => {
    if (this.swiper && typeof this.swiper.destroy === "function")
      this.swiper.destroy();

    document.removeEventListener("scroll", this.handleCheckIfVisible, false);
  };

  handleCheckIfVisible = () => {
    const isSliderOnScreen = checkIfOnScreen(this.compRef.current);
    if (isSliderOnScreen && !this.state.isSliderOnScreen)
      this.setState(
        { isSliderOnScreen: true },
        this.handleViewDataLayer(this.swiper.realIndex)
      );
  };

  handleViewDataLayer = (sliderIndex) => {
    if (!this.props.listInfo) return;

    if (this.swiper && this.swiper.params) {
      const length = this.swiper.params.slidesPerGroup;
      const { slides } = this.state;

      if (slides.length) {
        const dataLayerProducts = [];
        Array.from({ length: length || 4 }, (_, i) => {
          let index = i + sliderIndex;

          if (index > slides.length - 1) {
            index = index - (slides.length - 1) - 1;
          }

          dataLayerProducts.push({
            ...slides[index],
            index: i + sliderIndex + 1,
          });
          return true;
        });

        const listInfo = {
          pageType: this.props.listInfo.type,
          listType: "Widget",
          pageName: this.props.listInfo.name,
          sortType: "",
        };

        dataLayer__itemListView(
          dataLayerProducts,
          listInfo,
          this.props.user.currencyPref.code
        );
      }
    }
  };

  controlSlider = (e, dir) => {
    e.preventDefault();
    if (this.swiper) {
      if (dir === "prev" && this.swiper.slidePrev) {
        this.swiper.slidePrev();
      }
      if (dir === "next" && this.swiper.slideNext) {
        this.swiper.slideNext();
      }
    }
  };

  render() {
    return (
      <div
        className={`product-slider-holder position-relative w-100 ${
          this.props.className ? `${this.props.className}` : ``
        }`}
        ref={this.compRef}
      >
        <div
          className="swiper-container products-holder"
          id={`swiper-container-${this.id}`}
        >
          <div className="swiper-wrapper">{this.state.products}</div>
        </div>
        <div className="controls-holder">
          <a
            href="#!"
            className="control d-flex justify-content-center align-items-center position-absolute prev rounded-circle"
            onClick={(e) => this.controlSlider(e, "prev")}
          >
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0001 2L1.56738 11.7854L12.0001 21.5707"
                stroke="#454C5E"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
          <a
            href="#!"
            className="control d-flex justify-content-center align-items-center position-absolute next rounded-circle"
            onClick={(e) => this.controlSlider(e, "next")}
          >
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99991 2L12.4326 11.7854L1.99991 21.5707"
                stroke="#454C5E"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    );
  }
}
