import React, { Suspense } from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import store from "./store";
import TagManager from "react-gtm-module";

import ErrorBoundary from "./App/ErrorBoundary";
import { componentLoader } from "./utils/helper";
import CookiesError from "./App/CookiesError";
import API from "./utils/API";
import Maintenance from "./Maintenance";

if (!navigator.cookieEnabled) {
  ReactDOM.render(<CookiesError />, document.getElementById("root"));
} else {
  const App = React.lazy(() => componentLoader(() => import("./App"), 3));

  if (process.env.REACT_APP_MAINTENANCE_MODE !== "true") {
    API.get("settings").then(({ data: res }) => {
      const { data } = res;

      const gtm = data.find((item) => item.key === "GTM");

      let tagManagerArgs = {};

      if (gtm && gtm.value) {
        tagManagerArgs.gtmId = gtm.value;
      } else {
        if (process.env.REACT_APP_GTM_ID) {
          tagManagerArgs.gtmId = process.env.REACT_APP_GTM_ID;
        }
      }

      TagManager.initialize(tagManagerArgs);
    });
  }

  ReactDOM.render(
    <ErrorBoundary>
      <CookiesProvider>
        <Provider store={store}>
          <LocalizeProvider store={store}>
            <BrowserRouter>
              <Suspense fallback={``}>
                {process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
                  <Maintenance />
                ) : (
                  <App />
                )}
              </Suspense>
            </BrowserRouter>
          </LocalizeProvider>
        </Provider>
      </CookiesProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
}
