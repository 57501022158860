import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import Sections from "../components/Sections";
import Helmet from "react-helmet";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import BLLoader from "../../utils/components/BLLoader";
import { global } from "../../App/routes";
import { dataLayer__checkout } from "../../utils/DataLayerEvents";
import packageTypes, { PACKAGE_TYPES } from "../../utils/db/packageTypes";

export default class Payment extends Component {
  state = {
    cartExists: false,
  };

  componentDidMount = () => {
    if (!this.props.cart.items.length) {
      this.props.history.push(global.summary.links[this.props.lang]);
    } else {
      this.setState({ cartExists: true }, () => {
        this.props.handleGetContracts();

        const { product, units, package_type, preferred_date } =
          this.props.cart.items[0];

        const isGiftCard = package_type === PACKAGE_TYPES.GIFTCARD;

        const dataLayerProduct = {
          title: isGiftCard ? `Hediye Kartı` : product.title,
          code: isGiftCard ? `Hediye Kartı` : product.code,
          units,
          category: isGiftCard ? `` : product.category,
          package_type: isGiftCard
            ? `Hediye Kartı`
            : this.props.translate(
                `package_options.${packageTypes[package_type].name}`
              ),
          video_url: product?.video_url,
          location: product?.location,
          stats: product?.stats,
          provider: product?.provider,
          preferred_date,
        };

        dataLayer__checkout(dataLayerProduct);
      });
    }
  };
  render() {
    const mutualProps = {
      translate: this.props.translate,
      currency: this.props.currency,
      cart: this.props.cart,
      lang: this.props.lang,
    };
    return (
      this.state.cartExists && (
        <div className="page payment-page">
          <Helmet>
            <title>{this.props.translate("cart.payment")}</title>
          </Helmet>
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-12 col-lg-8">
              <Sections
                {...mutualProps}
                personalInfo={this.props.personalInfo}
                invoiceInfo={this.props.invoiceInfo}
                selectedGiftCards={this.props.selectedGiftCards}
                handleUpdatePersonalInfo={this.props.handleUpdatePersonalInfo}
                handleUpdateInvoiceInfo={this.props.handleUpdateInvoiceInfo}
                handleUpdateCreditCardInfo={
                  this.props.handleUpdateCreditCardInfo
                }
                handleApplyGiftCard={this.props.handleApplyGiftCard}
                handleRemoveGiftCard={this.props.handleRemoveGiftCard}
                handlePay={this.props.handlePay}
                isPaying={this.props.isPaying}
                payingError={this.props.payingError}
                user={this.props.user}
                handleShowSaleAgreementModal={
                  this.props.handleShowSaleAgreementModal
                }
                handleShowPreInfoAgreementModal={
                  this.props.handleShowPreInfoAgreementModal
                }
                isOkayToPay={this.props.isOkayToPay}
                handleSetCountryCode={this.props.handleSetCountryCode}
                cartTotal={this.props.cartTotal}
                discountPrice={this.props.discountPrice}
                handleSetPaymentMethod={this.props.handleSetPaymentMethod}
                paymentMethod={this.props.paymentMethod}
                agreementsAgreed={this.props.agreementsAgreed}
                handleSetAgreements={this.props.handleSetAgreements}
                handleUpdateLocationInfo={this.props.handleUpdateLocationInfo}
                promoCodeApplied={this.props.promoCodeApplied}
                promoPrice={this.props.promoPrice}
              />
            </div>
            <div className="col-12 col-lg-4">
              <Sidebar
                {...mutualProps}
                cartTotal={this.props.cartTotal}
                newTotal={this.props.newTotal}
                discountPrice={this.props.discountPrice}
                isOkayToPay={this.props.isOkayToPay}
                handlePay={this.props.handlePay}
                isPaying={this.props.isPaying}
                agreementsAgreed={this.props.agreementsAgreed}
                handleSetAgreements={this.props.handleSetAgreements}
                handleShowSaleAgreementModal={
                  this.props.handleShowSaleAgreementModal
                }
                handleShowPreInfoAgreementModal={
                  this.props.handleShowPreInfoAgreementModal
                }
                promoCodeApplied={this.props.promoCodeApplied}
                handleApplyPromoCode={this.props.handleApplyPromoCode}
                promoPrice={this.props.promoPrice}
              />
            </div>
          </div>
          <CSSTransition in={this.props.isPaying} timeout={300} unmountOnExit>
            <BLModal notClosable="true" headerFix={true}>
              <div className="confirm-holder d-flex flex-column align-items-center">
                <div className="success-icon d-flex justify-content-center">
                  <BLLoader />
                </div>
                <p className="content text-center mt-3">
                  {this.props.translate("payment.processing_transaction")}
                  <br />
                  {this.props.translate("global.please_wait")}...
                </p>
              </div>
            </BLModal>
          </CSSTransition>
        </div>
      )
    );
  }
}
