import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const SocialMediaShare = ({ title }) => {
  return (
    <div className="social-media-share d-flex flex-column align-items-start position-fixed">
      <FacebookShareButton url={window.location.href} quote={title}>
        <FacebookIcon size={38} borderRadius={8} />
      </FacebookShareButton>
      <WhatsappShareButton url={window.location.href} title={title}>
        <WhatsappIcon size={38} borderRadius={8} />
      </WhatsappShareButton>
      <TwitterShareButton url={window.location.href} title={title}>
        <TwitterIcon size={38} borderRadius={8} />
      </TwitterShareButton>
    </div>
  );
};

export default SocialMediaShare;
