import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactRotatingText from "react-rotating-text";

class BLAnimatedInput extends Component {
  render() {
    return (
      <div className="bl-animated-input position-relative w-100">
        <input
          type="text"
          className={`position-relative w-100 ${
            this.props.inputProps.value !== "" ? `filled` : ``
          }`}
          {...this.props.inputProps}
        />
        <span className="placeholder-holder position-absolute">
          <ReactRotatingText
            items={this.props.placeholders}
            pause={2000}
            emptyPause={500}
          />
        </span>
      </div>
    );
  }
}

BLAnimatedInput.propTypes = {
  placeholders: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputProps: PropTypes.object,
};

export default BLAnimatedInput;
