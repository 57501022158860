import React, { Component } from "react";
import icon from "../assets/images/error-part.svg";
import logo from "../assets/images/logo.svg";

import "../assets/ErrorBoundary.scss";
import BLButton from "../utils/components/BLButton";

import translation from "../utils/i18n/errorboundary.json";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    const preferences = navigator.cookieEnabled
      ? JSON.parse(localStorage.getItem("preferences"))
      : {};

    let lang =
      preferences?.langPref || navigator.language || navigator.userLanguage;

    if (lang.includes("-")) lang = lang.split("-")[0];

    if (lang !== "tr" && lang !== "en") lang = "en";

    this.state = { hasError: false, lang };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.location.hostname.startsWith("bucketlist.com.tr"))
      window.onerror(
        `Error: ${error}\nError Info: ${errorInfo.componentStack}`,
        window.location.href
      );

    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary d-flex justify-content-center align-items-center vw-100 vh-100">
          <div className="content d-flex flex-column align-items-center">
            <img src={icon} alt="Error icon" />
            <h4 className="text-center">
              {translation[this.state.lang].title}
            </h4>
            <p className="text-center">
              {translation[this.state.lang].content}
            </p>
            <BLButton
              type="sec"
              clicked={() => {
                window.location = "/";
              }}
            >
              {translation[this.state.lang].button}
            </BLButton>
          </div>

          <img src={logo} alt="Bucketlist" className="logo position-absolute" />
        </div>
      );
    }
    return this.props.children;
  }
}
