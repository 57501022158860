import React from "react";
import PropTypes from "prop-types";

import BLButton from "../../utils/components/BLButton";

import infoIcon from "../../assets/images/info-icon.svg";

const Sidebar = (props) => {
  return (
    <div className="sidebar d-flex flex-column position-relative overflow-hidden">
      <div className="info-about-buying">
        <div className="info d-flex align-items-center">
          <img src={infoIcon} alt="Info Icon" />
          <span className="text">
            {props.translate("experience.sidebar_info_1")}
          </span>
        </div>
        <div className="info d-flex align-items-center">
          <img src={infoIcon} alt="Info Icon" />
          <span className="text">
            {props.translate("experience.sidebar_info_2")}
          </span>
        </div>
        <div className="info d-flex align-items-center">
          <img src={infoIcon} alt="Info Icon" />
          <span className="text">
            {props.translate("experience.sidebar_info_3")}
          </span>
        </div>
      </div>
      <BLButton
        type="pri"
        clicked={props.handleScrollToPackageOptions}
        classes="package-options-button"
      >
        {props.translate("package_options.package_options")}
      </BLButton>
    </div>
  );
};

Sidebar.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Sidebar;
