import React from "react";
import PropTypes from "prop-types";

import BLButton from "../../utils/components/BLButton";
import BLInput from "../../utils/components/BLInput";
import BLDatePicker from "../../utils/components/BLDatePicker";
import BLDropdown from "../../utils/components/BLDropdown";
import dayjs from "dayjs";
import BLLoader from "../../utils/components/BLLoader";

const AccountInfo = (props) => {
  return (
    <div className="page-holder w-100">
      <div className="row">
        <div className="col">
          <h4 className="page-title">
            {props.translate("profile.account_info_page.title")}
          </h4>
        </div>
      </div>
      {props.isLoading ? (
        <BLLoader />
      ) : (
        <div className="row">
          <div className="col">
            <section className="page-sec">
              <form action="#!" onSubmit={props.handleUpdateAccountInfo}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <BLInput
                      type="text"
                      label={props.translate("global.name")}
                      name="first_name"
                      value={props.user.first_name}
                      changed={props.handleChangeInfo}
                      limitCharacters={["text"]}
                      minChar={2}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <BLInput
                      type="text"
                      label={props.translate("global.last_name")}
                      name="last_name"
                      value={props.user.last_name}
                      changed={props.handleChangeInfo}
                      limitCharacters={["text"]}
                      minChar={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <BLInput
                      type="email"
                      label={props.translate("global.email")}
                      name="email"
                      value={props.user.email}
                      changed={props.handleChangeInfo}
                      validationType="email"
                      inputProps={{ inputMode: "email" }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <BLInput
                      type="tel"
                      label={props.translate("global.phone")}
                      name="phone"
                      value={props.user.phone ? props.user.phone : ""}
                      changed={props.handleChangeInfo}
                      validationType="phone"
                      setCountryCode={props.handleUpdateCountryCode}
                      countryCode={
                        props.user.phoneCountryCode + " +" + props.user.areaCode
                      }
                      inputProps={{ inputMode: "tel" }}
                      limitCharacters={["number"]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <BLDatePicker
                      lang={props.lang}
                      type="single"
                      placeholder={props.translate("global.birthdate")}
                      selected={
                        props.user.date_of_birth
                          ? new Date(
                              dayjs(props.user.date_of_birth, "DD-MM-YYYY")
                            )
                          : ""
                      }
                      setSelected={(value) =>
                        props.handleSelectInfo(
                          "date_of_birth",
                          value,
                          "date_of_birth"
                        )
                      }
                      maxDate={new Date(dayjs().subtract(18, "years"))}
                      notClearable={true}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <BLDropdown
                      placeholder={props.translate(
                        "profile.account_info_page.gender"
                      )}
                      name="gender"
                      default={props.user.gender ? props.user.gender : ""}
                      setValue={(value) =>
                        props.handleSelectInfo("gender", value)
                      }
                      options={[
                        { name: props.translate("global.male"), value: "male" },
                        {
                          name: props.translate("global.female"),
                          value: "female",
                        },
                        {
                          name: props.translate("global.not_specified"),
                          value: "not_specified",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <BLButton
                      type="pri"
                      classes="update-button w-100"
                      disabled={props.isSending || !props.isAccountDetailsOk}
                      action="submit"
                    >
                      {props.translate(
                        "profile.account_info_page.update_account_info"
                      )}
                    </BLButton>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

AccountInfo.propTypes = {
  lang: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

export default AccountInfo;
