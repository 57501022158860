import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SEO from "../../utils/components/SEO";
import AboutUsTemplate from "../AboutUsTemplate";
import InPressContent from "../components/InPressContent";
import LogoUsageContent from "../components/LogoUsageContent";
import MediaSidebar from "../components/MediaSidebar";

const menuItems = [
  {
    id: 0,
    title: "media.nav.logo_usage",
    Content: LogoUsageContent,
  },
  {
    id: 1,
    title: "media.nav.in_press",
    Content: InPressContent,
    isHidden: true,
  },
];

const Media = (props) => {
  const [selectedPage, setSelectedPage] = useState(String(0));

  const SelectedItem = menuItems.filter(
    (data) => String(selectedPage) === String(data.id)
  )[0];

  return (
    <AboutUsTemplate>
      <Helmet>
        <title>{props.translate(menuItems[selectedPage].title)}</title>
      </Helmet>
      <SEO
        title={props.translate(menuItems[selectedPage].title)}
        description={props.translate("page_description.media")}
      />
      <div className="page media-page">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <div className="row">
              <div className="col-12 col-md-4 col-xl-3">
                <MediaSidebar
                  lang={props.lang}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  translate={props.translate}
                  menuItems={menuItems}
                />
              </div>
              <div className="col-12 col-md-8 col-xl-9">
                {<SelectedItem.Content translate={props.translate} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutUsTemplate>
  );
};

export default Media;
