import React, { useEffect } from "react";
import { useState } from "react";
import BLCheckbox from "../../utils/components/BLCheckbox";
import BLButton from "../../utils/components/BLButton";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

const MobileFilters = (props) => {
  const [activeFilter, setActiveFilter] = useState("cats");
  const [selectedLocations, setSelectedLocations] = useState(
    props.filters.locations
  );
  const [selectedCategories, setSelectedCategories] = useState(
    props.filters.categories
  );

  useEffect(() => {
    if (selectedLocations !== props.filters.locations)
      setSelectedLocations(props.filters.locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters.locations]);

  const handleSelectFilter = (e, filter, value) => {
    const { checked } = e.target;

    let selectedFilters = [];

    if (filter === "location") selectedFilters = [...selectedLocations];
    if (filter === "cat") selectedFilters = [...selectedCategories];

    if (checked) {
      selectedFilters = [...selectedFilters, value];
    } else {
      const index = selectedFilters.indexOf(value);

      selectedFilters.splice(index, 1);
    }

    if (filter === "location") setSelectedLocations(selectedFilters);
    if (filter === "cat") setSelectedCategories(selectedFilters);
  };

  const subCats =
    props.cat.type === "cat"
      ? props.cat.children
      : props.cat.type === "subCat"
      ? props.cat.siblings
      : [];

  return (
    <div className="mobile-filter-holder filters">
      <div className="filters-holder">
        <div className={`filter ${activeFilter === "cats" ? `active` : ``}`}>
          <div
            className="filter-title text-uppercase d-flex justify-content-between align-items-center"
            onClick={() => setActiveFilter("cats")}
          >
            {props.translate("category.categories")}
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 4.42857L9 1"
                stroke="#8E8E8E"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="filter-list">
            {props.cat.type === "cat" ? (
              <NavLink
                to={global.catPage.links[props.lang].replace(
                  ":main_cat",
                  props.cat.slug
                )}
                className="main-cat d-flex justify-content-between align-items-center"
              >
                {props.cat.name}
                <svg
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 4L9 1"
                    stroke="#8E8E8E"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </NavLink>
            ) : props.cat.type === "subCat" && props.cat.parent ? (
              <NavLink
                to={global.catPage.links[props.lang].replace(
                  ":main_cat",
                  props.cat.parent.slug
                )}
                className="main-cat d-flex justify-content-between align-items-center active"
              >
                {props.cat.parent.name}
                <svg
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 4L9 1"
                    stroke="#8E8E8E"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </NavLink>
            ) : (
              ""
            )}

            {subCats.length ? (
              <div className="sub-cats">
                {props.cat.type === "subCat" ? (
                  <NavLink
                    to={global.subCatPage.links[props.lang]
                      .replace(":main_cat", props.cat.parent.slug)
                      .replace(":sub_cat", props.cat.slug)}
                    className="sub-cat d-flex justify-content-between align-items-center"
                  >
                    {props.cat.name}
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 4L9 1"
                        stroke="#8E8E8E"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NavLink>
                ) : (
                  ""
                )}
                {subCats.map((data) => (
                  <NavLink
                    to={global.subCatPage.links[props.lang]
                      .replace(
                        ":main_cat",
                        props.cat.type === "cat"
                          ? props.cat.slug
                          : props.cat.type === "subCat"
                          ? props.cat.parent.slug
                          : ""
                      )
                      .replace(":sub_cat", data.slug)}
                    className="sub-cat d-flex justify-content-between align-items-center"
                    key={data.id}
                  >
                    {data.name}
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 4L9 1"
                        stroke="#8E8E8E"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NavLink>
                ))}
              </div>
            ) : (
              ""
            )}

            {props.categories && props.categories.length
              ? props.categories.map((data) => (
                  <NavLink
                    to={global.catPage.links[props.lang].replace(
                      ":main_cat",
                      data.slug
                    )}
                    className="main-cat d-flex justify-content-between align-items-center"
                    key={data.id}
                  >
                    {data.name}
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 4L9 1"
                        stroke="#8E8E8E"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NavLink>
                ))
              : ""}
          </div>
        </div>
        <div
          className={`filter ${activeFilter === "locations" ? `active` : ``}`}
        >
          <div
            className="filter-title text-uppercase d-flex justify-content-between align-items-center"
            onClick={() => setActiveFilter("locations")}
          >
            {props.translate("category.locations")}
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 4.42857L9 1"
                stroke="#8E8E8E"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="filter-list">
            {props.locations.map((data) => (
              <div key={data.id} className="filter-item">
                <BLCheckbox
                  label={data.name}
                  name={data.slug}
                  checked={
                    data.slug === props.slug ||
                    selectedLocations.includes(data.id)
                  }
                  changed={
                    props.cat.type === "location"
                      ? () => props.handleSelectCat(data.slug)
                      : (e) => {
                          handleSelectFilter(e, "location", data.id);
                          props.handleFilterDataLayers(
                            data.name,
                            !selectedLocations.includes(data.id)
                          );
                        }
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="filter-button d-flex flex-column">
        <BLButton
          type="sec"
          clicked={(e) =>
            props.handleApplyFilter(e, [
              {
                type: "locations",
                value: selectedLocations,
              },
              { type: "categories", value: selectedCategories },
            ])
          }
        >
          {props.translate("global.apply")}
        </BLButton>
        <BLButton
          type="empty"
          clicked={() => props.handleCloseMobileFilter("filters")}
        >
          {props.translate("global.cancel")}
        </BLButton>
      </div>
    </div>
  );
};

export default MobileFilters;
