import React, { Component } from "react";

import Banner from "./components/Banner";

import FAQs from "./components/FAQs";
import CorporateVoucherGift from "./components/CorporateVoucherGift";
import { CSSTransition } from "react-transition-group";
import BLModal from "../utils/components/BLModal";
import PackageOptions from "../Experience/components/PackageOptions";
import { update } from "../Cart/cartActions";
import { connect } from "react-redux";
import crypto from "crypto";
import AddedToCartModal from "./components/AddedToCartModal";
import { handleErrors } from "../utils/helper";
import API, { headers } from "../utils/API";
import { global } from "../App/routes";
import SEO from "../utils/components/SEO";
import { PACKAGE_TYPES } from "../utils/db/packageTypes";

class Voucher extends Component {
  state = {
    isPackageOptionsOn: false,
    amount: 100,
    quantity: 1,
    isAddingToCart: false,
    isAddedToCartModalOn: false,
  };

  handleShowPackageOptions = () => {
    this.setState({ isPackageOptionsOn: true });
  };

  /**
   * Remove comments to activate adding to cart function
   * 
  handleAddToCart = (info) => {
    this.setState({ isAddingToCart: true });

    const id = crypto.randomBytes(6).toString("hex");

    const cartItem = {
      id,
      temp_id: id,
      delivery_recipient_first_name: info.firstName,
      delivery_recipient_last_name: info.lastName,
      delivery_recipient_email: info.email,
      delivery_recipient_area_code: info.areaCode,
      delivery_recipient_phone_cc: info.countryCode,
      delivery_recipient_phone_w_out_code: info.phone,
      delivery_recipient_phone: "+" + info.areaCode + "-" + info.phone,
      delivery_recipient_send_date: info.deliveryDate,
      gift_message: info.message,
      package_type: 4,
      units: [
        {
          gift_amount: this.state.amount,
          id: 0,
          name: "",
          price: 0,
          quantity: this.state.quantity,
        },
      ],
      amount: parseFloat(this.state.amount) * parseInt(this.state.quantity),
    };

    if (this.props.user.isLoggedIn) {
      const params = {
        ccy: this.props.user.currencyPref.code,
        items: [{ ...cartItem }],
      };

      API.post("cart", params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            const { data } = response.data;

            const cart = data;

            cart.forEach((data) => {
              if (data.package_type === 3 || data.package_type === 4) {
                data.delivery_recipient_area_code =
                  data.delivery_recipient_phone.area_code;
                data.delivery_recipient_phone_cc =
                  data.delivery_recipient_phone.country_code;
                data.delivery_recipient_phone_w_out_code =
                  data.delivery_recipient_phone.number;
              }
            });

            this.props.updateCart(cart);

            setTimeout(
              () =>
                this.setState({
                  addedItem: cartItem,
                  isAddedToCartModalOn: true,
                }),
              10
            );
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isAddingToCart: false });
          return false;
        });
    } else {
      this.props.updateCart([...this.props.cart.items, cartItem]);
      setTimeout(
        () =>
          this.setState({
            isAddedToCartModalOn: true,
          }),
        750
      );
    }
  };
   */

  handleBuyNow = (info) => {
    this.setState({ isAddingToCart: true });

    const id = crypto.randomBytes(6).toString("hex");

    const cartItem = {
      id,
      temp_id: id,
      delivery_recipient_first_name: info.firstName,
      delivery_recipient_last_name: info.lastName,
      delivery_recipient_email: info.email,
      delivery_recipient_area_code: info.areaCode,
      delivery_recipient_phone_cc: info.countryCode,
      delivery_recipient_phone_w_out_code: info.phone.replace(/\s/g, ""),
      delivery_recipient_phone:
        "+" + info.areaCode + "-" + info.phone.replace(/\s/g, ""),
      delivery_recipient_send_date: info.deliveryDate,
      gift_message: info.message,
      package_type: PACKAGE_TYPES.GIFTCARD,
      units: [
        {
          gift_amount: this.state.amount,
          id: 0,
          name: "",
          price: 0,
          quantity: this.state.quantity,
        },
      ],
      amount: parseFloat(this.state.amount) * parseInt(this.state.quantity),
    };

    if (this.props.user.isLoggedIn) {
      const params = {
        ccy: this.props.user.currencyPref.code,
        items: [{ ...cartItem }],
      };

      API.post("cart", params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            const { data } = response.data;

            const cart = data;

            cart.forEach((data) => {
              if (
                data.package_type === PACKAGE_TYPES.GIFT ||
                data.package_type === PACKAGE_TYPES.GIFTCARD ||
                data.package_type === PACKAGE_TYPES.PASSGIFT
              ) {
                data.delivery_recipient_area_code =
                  data.delivery_recipient_phone.area_code;
                data.delivery_recipient_phone_cc =
                  data.delivery_recipient_phone.country_code;
                data.delivery_recipient_phone_w_out_code =
                  data.delivery_recipient_phone.number;
              }
            });

            this.props.updateCart(cart);

            setTimeout(
              () =>
                this.setState(
                  {
                    addedItem: cartItem,
                  },
                  () => {
                    this.props.history.push(
                      global.summary.links[this.props.lang]
                    );
                  }
                ),
              10
            );
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isAddingToCart: false });
          return false;
        });
    } else {
      this.props.updateCart([cartItem]);

      setTimeout(
        () =>
          this.setState({ addedItem: cartItem }, () => {
            this.props.history.push(global.summary.links[this.props.lang]);
          }),
        10
      );
    }
  };

  handleCloseAddedToCartModal = () => {
    this.setState({
      isAddedToCartModalOn: false,
      isPackageOptionsOn: false,
      isAddingToCart: false,
    });
  };

  render() {
    return (
      <div className="voucher">
        <SEO
          title={this.props.translate("page_title.voucher")}
          description={this.props.translate("page_description.voucher")}
        />
        <Banner
          translate={this.props.translate}
          lang={this.props.lang}
          handleShowPackageOptions={this.handleShowPackageOptions}
          handleSetAmount={(amount) => this.setState({ amount })}
          handleSetQuantity={(quantity) => this.setState({ quantity })}
          quantity={this.state.quantity}
          currency={this.props.user.currencyPref}
        />

        <FAQs translate={this.props.translate} />

        <CorporateVoucherGift
          translate={this.props.translate}
          lang={this.props.lang}
        />

        <CSSTransition
          in={this.state.isPackageOptionsOn}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() =>
              this.setState({ isPackageOptionsOn: false })
            }
            headerFix={true}
          >
            <div className="Experience modal-fix">
              <PackageOptions
                currency={this.props.user.currencyPref}
                lang={this.props.lang}
                isAddingToCart={this.state.isAddingToCart}
                handleBuyNow={this.handleBuyNow}
                isVoucher={true}
                amount={
                  parseFloat(this.state.amount) *
                  parseFloat(this.state.quantity)
                }
                quantity={this.state.quantity}
                selectedTab={PACKAGE_TYPES.GIFT}
              />
            </div>
          </BLModal>
        </CSSTransition>

        <CSSTransition
          in={this.state.isAddedToCartModalOn}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={this.handleCloseAddedToCartModal}
            headerFix={true}
          >
            <AddedToCartModal
              currency={this.props.user.currencyPref}
              handleCloseAddedToCartModal={this.handleCloseAddedToCartModal}
              lang={this.props.lang}
              amount={this.state.amount}
              quantity={this.state.quantity}
              translate={this.props.translate}
              user={this.props.user}
              addedItem={this.state.addedItem}
            />
          </BLModal>
        </CSSTransition>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (items) => dispatch(update(items)),
  };
};

export default connect(null, mapDispatchToProps)(Voucher);
