import React, { Component, createRef } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router";
import { global } from "../App/routes";
import Banner from "./components/Banner";

class AboutUsTemplate extends Component {
  bannerRef = createRef();

  componentDidMount = () => {
    window.scrollBy(0, 0);
  };

  componentDidUpdate = () => {
    if (
      this.props.location.pathname !==
      global.aboutUs.links[this.props.activeLanguage.code]
    )
      window.scrollTo({
        top: this.bannerRef.current.scrollHeight,
        behavior: "smooth",
      });
  };

  render() {
    return (
      <div className="AboutUs">
        <div className="container-fluid">
          <Banner lang={this.props.lang} bannerRef={this.bannerRef} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withLocalize(withRouter(AboutUsTemplate));
