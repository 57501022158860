import React from "react";
import PropTypes from "prop-types";

const BLButton = (props) => {
  return (
    <button
      className={`bl-button d-flex justify-content-center align-items-center ${
        props.type === "pri" ? `bl-pri-button` : ``
      }
      ${props.type === "sec" ? `bl-sec-button` : ``}
      ${props.type === "alt" ? `bl-alt-button` : ``}
      ${props.type === "empty" ? `bl-empty-button` : ``}
     ${props.opposite ? `opposite` : ``} ${
        props.additionalClasses ? props.additionalClasses : ``
      } ${props.classes ? props.classes : ``}`}
      type={props.action ? props.action : `button`}
      onClick={props.clicked ? props.clicked : () => {}}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

BLButton.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  clicked: PropTypes.func,
  opposite: PropTypes.bool,
  classes: PropTypes.string,
  action: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BLButton;
