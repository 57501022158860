import React from "react";
import PropTypes from "prop-types";
import HorizontalProduct from "./HorizontalProduct";
import BLCollapsible from "../../utils/components/BLCollapsible";
import { currencyFormatter } from "../../utils/helper";
import BLInfoArea from "../../utils/components/BLInfoArea";
import HorizontalGiftCard from "./HorizontalGiftCard";

const SingleOrder = (props) => {
  const [isGiftCard, setIsGiftCard] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [refunds, setRefunds] = React.useState();

  React.useEffect(() => {
    const productsArr = props.products.map((data, index) => {
      setIsGiftCard(data.type === "gift_card");

      const productRefunds = data.type !== "gift_card" &&
        Boolean(data.refunds.length) && (
          <BLInfoArea type="info" className="refunds">
            <h6>
              {props.translate("profile.orders_page.refunds_in_this_order")}:
            </h6>
            {data.refunds.map((refund, index) => (
              <div
                className="unit d-flex justify-content-between align-items-center mt-2"
                key={index}
              >
                <div className="name">{refund.unit_name}</div>
                <div className="count text-center">
                  x{refund.quantity}{" "}
                  {props.translate(
                    `global.piece${refund.quantity > 1 ? `s` : ``}`
                  )}
                </div>
                <div className="price text-right">
                  {currencyFormatter(
                    props.currency,
                    parseFloat(refund.amount).toFixed(2),
                    props.lang
                  )}
                </div>
              </div>
            ))}
          </BLInfoArea>
        );

      setRefunds(productRefunds);

      return (
        <div key={index}>
          <div
            key={index}
            className="product-holder d-flex flex-column flex-md-row justify-content-between align-items-end align-items-md-start"
          >
            {data.type === "gift_card" ? (
              <HorizontalGiftCard
                translate={props.translate}
                name={`${data.delivery.recipient_first_name} ${data.delivery.recipient_last_name}`}
                email={data.delivery.recipient_email}
                phone={data.delivery.recipient_phone}
                amount={currencyFormatter(
                  props.currency,
                  parseFloat(data.total),
                  props.lang
                )}
              />
            ) : (
              <HorizontalProduct
                translate={props.translate}
                product={data}
                lang={props.lang}
                currency={props.currency}
                data={data}
              />
            )}
          </div>
        </div>
      );
    });

    setProducts(productsArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BLCollapsible
      classes="single-order-holder"
      summaryContents={[
        props.id,
        props.date,
        currencyFormatter(
          props.currency,
          parseFloat(props.totalPrice).toFixed(2),
          props.lang
        ),
        props.orderStatus,
      ]}
    >
      <>
        <div className="row flex-lg-row-reverse">
          <div className="col-12 col-lg-6">
            <div className="info-area d-flex flex-column">
              <div className="info d-flex justify-content-between align-items-center">
                <span className="title bold">
                  {props.translate("profile.orders_page.payment_info")}
                </span>
                <span className="content regular selectable">
                  {props.paymentMethod}
                </span>
              </div>
              {!isGiftCard && (
                <div className="info d-flex justify-content-between align-items-center">
                  <span className="title">
                    {props.translate("profile.orders_page.subtotal")}:
                  </span>
                  <span className="content selectable">
                    {currencyFormatter(
                      props.currency,
                      parseFloat(props.subtotal).toFixed(2),
                      props.lang
                    )}
                  </span>
                </div>
              )}
              {!isGiftCard && (
                <div className="info d-flex justify-content-between align-items-center">
                  <span className="title">
                    {props.translate("profile.orders_page.discount_amount")}:
                  </span>
                  <span className="content selectable">
                    {props.promo_amount ? (
                      <span className="color-pri">
                        -{" "}
                        {currencyFormatter(
                          props.currency,
                          parseFloat(props.promo_amount).toFixed(2),
                          props.lang
                        )}
                      </span>
                    ) : (
                      props.translate("profile.orders_page.not_used")
                    )}
                  </span>
                </div>
              )}
              {!isGiftCard && (
                <div className="info d-flex justify-content-between align-items-center">
                  <span className="title">
                    {props.translate("profile.orders_page.gift_card")}:
                  </span>
                  <span className="content selectable">
                    {props.gift_amount ? (
                      <span className="color-pri">
                        -{" "}
                        {currencyFormatter(
                          props.currency,
                          parseFloat(props.gift_amount).toFixed(2),
                          props.lang
                        )}
                      </span>
                    ) : (
                      props.translate("profile.orders_page.not_used")
                    )}
                  </span>
                </div>
              )}
              {!isGiftCard && props.installment > 1 && (
                <div className="info d-flex justify-content-between align-items-center">
                  <span className="title">
                    {props.translate("profile.orders_page.interest_amount")}:
                  </span>
                  <span className="content selectable">
                    {props.interest_amount ? (
                      <span>
                        {currencyFormatter(
                          props.currency,
                          parseFloat(props.interest_amount).toFixed(2),
                          props.lang
                        )}
                      </span>
                    ) : (
                      props.translate("profile.orders_page.not_used")
                    )}
                  </span>
                </div>
              )}
              <div className="info d-flex justify-content-between align-items-center">
                <span className="title">
                  {props.translate("profile.orders_page.payment_amount")}:
                </span>
                <span className="content selectable">
                  {currencyFormatter(
                    props.currency,
                    parseFloat(props.totalPrice).toFixed(2),
                    props.lang
                  )}
                </span>
              </div>
            </div>
            {refunds}
          </div>
          <div className="col-12 col-lg-6">
            <div className="products-list d-flex flex-column">{products}</div>
          </div>
        </div>
      </>
    </BLCollapsible>
  );
};

SingleOrder.propTypes = {
  translate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  paymentStatus: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentMethod: PropTypes.string,
  discount: PropTypes.number,
};

export default SingleOrder;
