import React, { Component } from "react";
import BLBannerWTabs from "../../utils/components/BLBannerWTabs";
import { auth, global } from "../../App/routes";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";
import { getCurrentRoute, getBase64, handleErrors } from "../../utils/helper";
import BLButton from "../../utils/components/BLButton";
import BLLoader from "../../utils/components/BLLoader";
import API, { headers } from "../../utils/API";
import toastr from "toastr";
import { withRouter } from "react-router";

class Banner extends Component {
  state = {
    breadcrumbs: [],
    tabs: [],
    isAvatarLoaded: false,
    isSending: false,
  };

  componentDidMount = () => {
    this.handleCheckBreadcrumbs();
    this.setTabs();

    const avatarEl = document.getElementById("user-avatar__profile");
    if (avatarEl)
      avatarEl.onload = () => this.setState({ isAvatarLoaded: true });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.langPref !== this.props.user.langPref) this.setTabs();

    if (prevProps?.location?.pathname !== this.props.location.pathname)
      this.handleCheckBreadcrumbs();
  };

  setTabs = () => {
    const tabs = Object.values(auth)
      .filter((data) => data.parent === "profile")
      .map((data) => ({
        id: data.id,
        links: data.links,
        name: this.props.translate(`profile.${data.name}`),
        order: data.order,
      }));

    this.setState({ tabs });
  };

  handleCheckBreadcrumbs = () => {
    getCurrentRoute(window.location.pathname, this.props.user.langPref).then(
      ({ route }) =>
        this.getBreadcrumbs({
          id: 2,
          name: this.props.translate(`profile.${route.name}`),
        })
    );
  };

  getBreadcrumbs = (newItem) => {
    const breadcrumbs = [
      {
        id: 0,
        name: this.props.translate("global.homepage"),
        route: global.home.links[this.props.lang],
      },
      {
        id: 1,
        name: this.props.translate("global.my_account"),
      },
      newItem,
    ];

    this.setState({ breadcrumbs });
  };

  handleUpdateAvatar = async (e) => {
    const file = e.target.files[0];

    if (file.size <= process.env.REACT_APP_PROFILE_MAX_FILE_SIZE) {
      const avatar_url = await getBase64(file);

      if (avatar_url) {
        this.setState({ isSending: true, isAvatarLoaded: false }, () => {
          const params = { avatar_url };

          API.patch("account", params, {
            headers: {
              ...headers,
              Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
            },
          })
            .then((response) => {
              toastr.clear();
              toastr.success(response.data.info.message);

              const user = { avatar: response.data.data.avatar_url };

              this.props.update(user);

              const avatarEl = document.getElementById("user-avatar__profile");
              if (avatarEl)
                avatarEl.onload = () =>
                  this.setState({ isSending: false, isAvatarLoaded: true });
            })
            .catch((err) => {
              handleErrors(err);
              this.setState({ isSending: false, isAvatarLoaded: true });
            });
        });
      }
    } else {
      toastr.error(
        this.props.translate("profile.avatar_max_file_size", {
          size: process.env.REACT_APP_PROFILE_MAX_FILE_SIZE / 1024 / 1024,
        })
      );
      return false;
    }
  };

  render() {
    return (
      <BLBannerWTabs tabs={this.state.tabs}>
        <BLBreadcrumbs breadcrumbs={this.state.breadcrumbs} color="white" />
        <div className="user-info d-flex flex-column flex-md-row justify-content-start align-items-center">
          <div className="avatar-holder position-relative">
            <div className="avatar d-flex justify-content-center align-items-center rounded-circle overflow-hidden">
              <img
                src={this.props.user.avatar}
                alt={`${this.props.user.first_name} Avatar`}
                className={!this.state.isAvatarLoaded ? `d-none` : ``}
                id="user-avatar__profile"
              />
              {!this.state.isAvatarLoaded && <BLLoader />}
            </div>
            <BLButton
              type="pri"
              classes="add-button position-absolute"
              disabled={this.state.isSending}
            >
              <input
                type="file"
                name="avatar"
                id="avatar"
                className="d-none"
                onChange={this.handleUpdateAvatar}
                disabled={this.state.isSending}
              />
              <label htmlFor="avatar" className="d-flex align-items-center">
                <svg
                  className="m-0 mx-3"
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9866 3.0441L12.0947 2.56954L11.6618 0.816371C11.5865 0.560901 11.4485 0.5 11.1853 0.5H5.41332C5.15009 0.5 5.00776 0.567229 4.93675 0.816371L4.50386 2.56954L1.61201 3.0441C1.00167 3.15293 0.5 3.53985 0.5 4.1514V13.2997C0.5 13.9113 0.997702 14.407 1.61201 14.407H14.9864C15.6007 14.407 16.0984 13.9113 16.0984 13.2997V4.1514C16.0986 3.53985 15.5918 3.15783 14.9866 3.0441ZM8.2993 5.97717C6.99809 5.97717 5.9428 7.02768 5.9428 8.32354C5.9428 9.61939 6.99809 10.6699 8.2993 10.6699C9.6005 10.6699 10.6558 9.61939 10.6558 8.32354C10.6558 7.02768 9.6005 5.97717 8.2993 5.97717ZM4.16899 8.32354C4.16899 10.5949 6.01825 12.4364 8.2993 12.4364C10.5803 12.4364 12.4296 10.5949 12.4296 8.32354C12.4296 6.05215 10.5803 4.21072 8.2993 4.21072C6.01825 4.21072 4.16899 6.05215 4.16899 8.32354Z"
                    fill="white"
                  />
                </svg>
                {/* {this.props.user.avatar
                  ? this.props.translate("global.change")
                  : this.props.translate("global.add")} */}
              </label>
            </BLButton>
          </div>
          <div className="info-area d-flex flex-column justify-content-center align-items-start">
            <span className="welcome-text">
              {this.props.translate("profile.welcome")}
            </span>
            <h4 className="user-name">{this.props.user.fullname}</h4>
          </div>
        </div>
      </BLBannerWTabs>
    );
  }
}
export default withRouter(Banner);
