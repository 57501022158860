import React, { useEffect } from "react";
import AirBalloon from "../../assets/images/hot-air-balloon.svg";
import Cloud from "../../assets/images/cloud.svg";
import Cookies from "universal-cookie";
import { isElementVisible } from "../../utils/helper";

const GoToTop = ({ translate }) => {
  const [shouldBeIn, setShouldBeIn] = React.useState(false);
  const [forceShouldBeIn, setForceShouldBeIn] = React.useState(false);
  const [isCookieIn, setIsCookieIn] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookies = new Cookies();

  useEffect(() => {
    document.addEventListener("scroll", updateShouldBeIn, false);

    return () => {
      document.removeEventListener("scroll", updateShouldBeIn, false);
    };
  }, []);

  useEffect(() => {
    setIsCookieIn(!cookies.get("cookies-disclaimer"));
  }, [cookies]);

  useEffect(() => {
    const el = document.querySelector(".to-top");

    if (!el) return;

    if (shouldBeIn && !forceShouldBeIn) {
      el.classList.add("enter");
      setTimeout(() => {
        el.classList.add("enter-done");
      }, 400);
    } else {
      el.classList.remove("enter-done");
      setTimeout(() => {
        el.classList.remove("enter");
      }, 50);

      setTimeout(() => {
        setForceShouldBeIn(false);
      }, 400);
    }
  }, [shouldBeIn, forceShouldBeIn]);

  const updateShouldBeIn = () => {
    const isBannerIn = isElementVisible(".Home .banner");

    setShouldBeIn(!isBannerIn);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        setForceShouldBeIn(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      className="to-top d-flex flex-column align-items-center justify-content-center position-sticky"
      role="button"
      data-cookie-fix={isCookieIn}
    >
      <img
        src={Cloud}
        alt="Top Cloud"
        className="cloud--top position-absolute"
      />
      <img src={AirBalloon} alt="Hot Air Balloon" className="air-balloon" />
      <img
        src={Cloud}
        alt="Bottom Cloud"
        className="cloud--bottom position-absolute"
      />

      <span className="text-uppercase">{translate("global.go_to_top")}</span>
    </div>
  );
};

export default GoToTop;
