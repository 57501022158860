import React from "react";
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";

const BLLoader = (props) => {
  return (
    <div className="bl-loader">
      {props.loaderType === "dots" ? (
        <div className={`loading-dots d-flex`}>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      ) : (
        <div className="spinner-grow color-pri" role="status">
          <span className="sr-only">{props.translate("global.loading")}</span>
        </div>
      )}
    </div>
  );
};

BLLoader.propTypes = {
  loaderType: PropTypes.string,
};

export default withLocalize(BLLoader);
