import React from "react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

export const MobileMenu = (props) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <div className="mobile-menu position-relative d-flex d-lg-none justify-content-center">
      <button
        className="menu-button d-flex justify-content-between align-items-center"
        onClick={() => setIsMenuActive(!isMenuActive)}
        data-active={isMenuActive}
      >
        <span className="menu-name-holder">
          <span className="text-uppercase">
            {props.translate("static_pages.sidebar.menu")}:
          </span>{" "}
          {props.translate(`static_pages.sidebar.${global.privacyPolicy.name}`)}
        </span>
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="down-arrow"
        >
          <path
            d="M8.98828 1.44283L4.99968 5.98828L1.01107 1.44283"
            stroke="black"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <CSSTransition in={isMenuActive} timeout={300} unmountOnExit>
        <div className="mobile-menu-holder position-absolute">
          <ul>
            {props.menuItems.map((item) => (
              <li key={item.slug}>
                <NavLink
                  to={item.link}
                  className="sidebar-group-item w-100 d-flex justify-content-between align-items-center"
                >
                  <span>{item.title}</span>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.90624 1.6145L11.6875 8.07284L4.90624 14.5312"
                      stroke="#B6B6B6"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};
