import React from "react";
import GoogleMapReact from "google-map-react";

import map_marker from "../../assets/images/map-marker.svg";

const MapMarker = () => (
  <img src={map_marker} alt="Map Marker" className="map-marker" />
);

const Location = (props) => {
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${props.location?.address_line}+${props.location?.town_name}+${props.location?.city_name}`;

  return (
    <div className="location-page page-holder">
      <div className="location-info">
        <p className="mb-0">
          <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
            {props.location?.address_line}
          </a>
        </p>
        <p>
          <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
            {`${props.location?.town_name}${
              props.location?.town_name ? `, ` : ``
            }`}
            {props.location?.city_name}
          </a>
        </p>
      </div>
      <div className="map-holder">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBCVbAYJlbv4nSSwHxy1ZrFhdCmVR6SBSk" }}
          defaultCenter={props.center}
          defaultZoom={11}
        >
          <MapMarker lat={props.center.lat} lng={props.center.lng} />
        </GoogleMapReact>
      </div>
    </div>
  );
};
export default Location;
