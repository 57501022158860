import React, { Component } from "react";
import { Sidebar } from "./components/Sidebar";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router";
import { global } from "../App/routes";

class StaticPages extends Component {
  state = {
    selectedSection: "",
    locationPathname: "",
    isSinglePage: true,
  };

  componentDidMount = () => {
    this.setState({ isSinglePage: `singlePage` in this.props });

    if (!("singlePage" in this.props)) {
      setTimeout(this.showSection, 200);

      window.addEventListener("scroll", this.setSection, false);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.locationPathname !== this.props.location.pathname
    ) {
      setTimeout(this.showSection, 20);
    }

    if (prevProps.lang !== this.props.lang && !("singlePage" in this.props)) {
      setTimeout(
        () =>
          this.props.menuItems.some((item) => {
            if (
              item.translations[prevProps.lang].slug ===
              this.state.selectedSection
            ) {
              this.props.history.push(item.link);
              return true;
            }
            return false;
          }),
        20
      );
    }

    if (prevProps.isLoading !== this.props.isLoading && !this.props.isLoading) {
      this.showSection();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.setSection, false);
    clearTimeout(this.setSectionTimeout);
  };

  showSection = () => {
    if ("singlePage" in this.props) return false;

    const { section } = this.props.match.params;
    const { privacyPolicy } = global;

    const el = document.querySelector(`[data-section=${section}]`);

    if (!el) return false;

    if (section) {
      setTimeout(() => {
        el.scrollIntoView();

        this.setState({
          selectedSection: section,
          locationPathname: privacyPolicy.links[this.props.lang].replace(
            ":section?",
            section
          ),
        });
      }, 20);
    }
  };

  sectionOffset = 90;
  timoutDuration = 300;
  setSectionTimeout = "";
  setSection = () => {
    clearTimeout(this.setSectionTimeout);

    if ("singlePage" in this.props) return false;

    const { menuItems } = this.props;
    this.setSectionTimeout = setTimeout(() => {
      let selectedSection = this.state.selectedSection;

      const listedMenuItems = menuItems.filter((item) => !item.not_listed);

      listedMenuItems.some((item, index) => {
        let el = listedMenuItems[index + 1]?.slug
          ? document.querySelector(
              `[data-section=${listedMenuItems[index + 1].slug}]`
            )
          : null;

        const nextEl =
          index < listedMenuItems.length - 1 &&
          listedMenuItems[index + 1].slug &&
          el
            ? el.getBoundingClientRect().top - this.sectionOffset
            : null;

        if (
          index === 0 &&
          listedMenuItems.length > 1 &&
          el?.getBoundingClientRect().top - this.sectionOffset > 0
        ) {
          selectedSection = item;
          return true;
        }

        if (
          index > 0 &&
          document
            .querySelector(`[data-section=${item.slug}]`)
            ?.getBoundingClientRect().top -
            this.sectionOffset <=
            0 &&
          nextEl &&
          nextEl > 0
        ) {
          selectedSection = item;
          return true;
        }

        if (
          !nextEl &&
          document
            .querySelector(`[data-section=${item.slug}]`)
            ?.getBoundingClientRect().top -
            this.sectionOffset <
            0
        ) {
          selectedSection = item;
          return true;
        }

        return false;
      });

      const locationPathname = selectedSection?.link;

      this.setState({ selectedSection, locationPathname }, () => {
        this.props.history.push(locationPathname);
      });
    }, this.timoutDuration);
  };

  render() {
    return (
      <div className="static-page-holder">
        <div className="container-fluid">
          <div className="row">
            {!this.state.isSinglePage && (
              <div className="col-12 col-lg-4 offset-lg-1 col-xl-3">
                <Sidebar
                  lang={this.props.lang}
                  translate={this.props.translate}
                  section={this.props.match.params.section}
                  selectedSection={this.state.selectedSection}
                  menuItems={this.props.menuItems}
                />
              </div>
            )}
            <div
              className={`col-12 col-lg-7 ${
                this.state.isSinglePage ? `offset-lg-2` : ``
              }`}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withLocalize(StaticPages));
