import BLButton from "../../utils/components/BLButton";
import BLCheckbox from "../../utils/components/BLCheckbox";
import BLLoader from "../../utils/components/BLLoader";
import BLSwitch from "../../utils/components/BLSwitch";

const NotificationSettings = (props) => {
  return (
    <div className="page-holder w-100">
      <div className="row">
        <div className="col">
          <h4 className="page-title">
            {props.translate("profile.notification_settings_page.title")}
          </h4>
        </div>
      </div>
      {props.isLoading ? (
        <BLLoader />
      ) : (
        <div className="row">
          <div className="col">
            <section className="page-sec">
              <form
                action="#!"
                onSubmit={props.handleUpdateNotificationSettings}
              >
                <div className="row mb-4">
                  <div className="col-12">
                    <BLCheckbox
                      name="agreement"
                      checked={props.notificationSettings.agreement}
                      label={props.translate(
                        "profile.notification_settings_page.agreement_text",
                        {
                          agreement: (
                            <u
                              role="button"
                              onClick={props.showNotificationConsentAgreement}
                              className="text-decoration-underline"
                            >
                              {props.translate(
                                "profile.notification_settings_page.agreement_link"
                              )}
                            </u>
                          ),
                        }
                      )}
                      changed={props.handleChangeNotificationSettings}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <p>
                      {props.translate(
                        "profile.notification_settings_page.i_agree_to_receive"
                      )}
                    </p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <BLSwitch
                      name="sms"
                      checked={props.notificationSettings.sms}
                      label={props.translate("global.sms")}
                      onChange={props.handleChangeNotificationSettings}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <BLSwitch
                      name="email"
                      checked={props.notificationSettings.email}
                      label={props.translate("global.email")}
                      onChange={props.handleChangeNotificationSettings}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <BLSwitch
                      name="call"
                      checked={props.notificationSettings.call}
                      label={props.translate("global.phone")}
                      onChange={props.handleChangeNotificationSettings}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <BLButton
                      type="pri"
                      classes="update-button w-100"
                      disabled={
                        props.isSending || !props.isNotificationSettingsOk
                      }
                      action="submit"
                    >
                      {props.translate(
                        "profile.notification_settings_page.update_notification_settings"
                      )}
                    </BLButton>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationSettings;
