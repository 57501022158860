import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";

const GiftAnExperience = (props) => {
  return (
    <>
      <div className="gift-an-experience-holder d-flex justify-content-start align-items-center">
        <svg
          className="icon"
          width="82"
          height="77"
          viewBox="0 0 82 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.875 20.4608H60.6595C61.8229 19.6641 62.8223 18.8725 63.5192 18.1632C67.6551 13.9947 67.6551 7.21004 63.5192 3.04155C59.5012 -1.01899 52.5005 -1.00871 48.4876 3.04155C46.2634 5.27742 40.3696 14.3802 41.1845 20.4608H40.8155C41.6252 14.3802 35.7315 5.27742 33.5124 3.04155C29.4944 -1.00871 22.4936 -1.00871 18.4807 3.04155C14.35 7.21004 14.35 13.9947 18.4756 18.1632C19.1777 18.8725 20.1771 19.6641 21.3405 20.4608H5.125C2.30113 20.4608 0 22.7686 0 25.6007V33.3106C0 34.7292 1.148 35.8806 2.5625 35.8806H35.875V25.6007H46.125V35.8806H79.4375C80.852 35.8806 82 34.7292 82 33.3106V25.6007C82 22.7686 79.704 20.4608 76.875 20.4608ZM35.5726 20.2912C35.5726 20.2912 35.3574 20.4608 34.6245 20.4608C31.0831 20.4608 24.3232 16.7703 22.1144 14.5396C19.967 12.3705 19.967 8.83426 22.1144 6.66521C23.1547 5.61666 24.5334 5.04099 25.9991 5.04099C27.4597 5.04099 28.8384 5.61666 29.8787 6.66521C33.333 10.1501 36.7668 19.0524 35.5726 20.2912ZM46.4274 20.2912C46.4274 20.2963 46.6426 20.4608 47.3704 20.4608C50.9169 20.4608 57.6768 16.7703 59.8856 14.5396C62.0381 12.3705 62.0381 8.83426 59.8856 6.6652C57.7998 4.56297 54.1866 4.57325 52.1213 6.6652C48.667 10.1501 45.2332 19.0524 46.4274 20.2912ZM5.125 41.0205V71.8601C5.125 74.6973 7.42613 77 10.25 77H35.875V41.0205H5.125ZM46.125 77V41.0205H76.875V71.8601C76.875 74.6973 74.579 77 71.75 77H46.125Z"
            fill="white"
          />
        </svg>
        <p className="content text-left">
          {props.translate("experience.gift_an_experience_content")}
        </p>
      </div>
      <div className="gift-an-experience-holder buy-voucher-fix d-flex align-items-center position-relative">
        <svg
          className="icon"
          width="46"
          height="32"
          viewBox="0 0 46 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.04297 0C1.81359 0 0 1.79431 0 4V10.6667H5.63554C6.54709 7.99529 9.68264 7.00729 11.9696 8.51431L13.4766 9.50844V0H4.04297ZM17.2537 12L19.1738 10.7331C20.1921 10.0614 21.5625 10.7804 21.5625 12.0013C21.5625 13.2168 20.1961 13.9411 19.1738 13.2669L17.2537 12ZM20.0254 16.1901C21.7965 16.1901 23.4065 15.1105 24.0129 13.3333H46V28C46 30.2057 44.1864 32 41.957 32H16.1719V16.4036L19.0936 20.7396C19.5001 21.3438 20.3327 21.5223 20.9624 21.1094C21.5823 20.7005 21.7495 19.8737 21.3362 19.2604L19.1849 16.0677L19.2897 16.0893C19.5334 16.1397 19.7773 16.1901 20.0254 16.1901ZM28.3008 26.6667H39.2617C40.0066 26.6667 40.6094 26.0703 40.6094 25.3333C40.6094 24.5964 40.0066 24 39.2617 24H28.3008C27.5559 24 26.9531 24.5964 26.9531 25.3333C26.9531 26.0703 27.5559 26.6667 28.3008 26.6667ZM39.2617 21.3333H28.3008C27.5559 21.3333 26.9531 20.737 26.9531 20C26.9531 19.263 27.5559 18.6667 28.3008 18.6667H39.2617C40.0066 18.6667 40.6094 19.263 40.6094 20C40.6094 20.737 40.0066 21.3333 39.2617 21.3333ZM16.1719 0H41.957C44.1864 0 46 1.79431 46 4V10.6667H24.0134C23.0957 7.97556 19.9415 7.01867 17.6787 8.51431L16.1719 9.50844V0ZM10.4746 10.7331C9.45084 10.0578 8.08594 10.7865 8.08594 11.9987C8.08594 13.2145 9.44743 13.9433 10.4746 13.2669L12.3948 12L10.4746 10.7331ZM8.686 21.1093C9.30593 21.513 10.1416 21.3502 10.5548 20.7396L13.4766 16.4036V32H4.04297C1.81359 32 0 30.2057 0 28V13.3332H5.63491C6.23947 15.106 7.84767 16.19 9.62307 16.19C9.87113 16.19 10.115 16.1396 10.3587 16.0892C10.3936 16.082 10.4286 16.0747 10.4636 16.0676L8.31225 19.2604C7.89897 19.8737 8.06617 20.7004 8.686 21.1093Z"
            fill="#EF7D4B"
          />
        </svg>

        <p className="content text-left">
          <Link
            to={global.voucher.links[props.lang]}
            className="stretched-link"
          >
            {props.translate("experience.buy_voucher_content")}
          </Link>
        </p>
        <Link
          to={global.voucher.links[props.lang]}
          className="link-button position-absolute stretched-link"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.90624 1.61426L11.6875 8.0726L4.90624 14.5309"
              stroke="#B6B6B6"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
    </>
  );
};

GiftAnExperience.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default GiftAnExperience;
