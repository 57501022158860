import React, { Component } from "react";
import { Link } from "react-router-dom";
import PopularActivities from "../../Home/components/PopularActivities/PopularActivities";
import { global } from "../../App/routes";
import { currencyFormatter } from "../../utils/helper";
import dayjs from "dayjs";

class Changed extends Component {
  state = {
    giftCard: { currency: { code: "", symbol: "" } },
    isLoading: true,
  };
  componentDidMount = () => {
    const giftCardSession = sessionStorage.getItem("giftCardTemp");

    if (giftCardSession) {
      const giftCard = JSON.parse(giftCardSession);

      this.setState({ giftCard, isLoading: false });
      setTimeout(() => sessionStorage.removeItem("giftCardTemp"));
    } else {
      // this.props.history.push("/");
    }
  };

  render() {
    return (
      <div className="changed-page">
        <section className="bl-progress-bar d-flex justify-content-center align-items-start">
          <div className="page-progress done d-flex flex-column align-items-center">
            <div className="icon position-relative">
              <svg
                width="47"
                height="48"
                viewBox="0 0 47 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.5 47C36.2025 47 46.5 36.7025 46.5 24C46.5 11.2975 36.2025 1 23.5 1C10.7975 1 0.5 11.2975 0.5 24C0.5 36.7025 10.7975 47 23.5 47Z"
                  fill="#4BCA92"
                  stroke="#4BCA92"
                />
                <path
                  d="M33.2988 15.3857L20.1659 28.8312L14.701 23.298C14.1981 22.7824 13.3811 22.7824 12.8782 23.298C12.3739 23.8136 12.3739 24.6483 12.8782 25.1626L19.2595 31.6229C19.7828 32.099 20.5654 32.1517 21.0823 31.6229L35.1228 17.2504C35.6257 16.7361 35.6257 15.9001 35.1228 15.3857C34.6187 14.8714 33.803 14.8714 33.2988 15.3857Z"
                  fill="white"
                />
              </svg>
            </div>
            <h6 className="name text-center">
              {this.props.translate("gift_card.changed_page.login_step")}
            </h6>
          </div>
          <div className="page-progress d-flex flex-column align-items-center">
            <div className="icon position-relative">
              <svg
                width="47"
                height="48"
                viewBox="0 0 47 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.5 47C36.2025 47 46.5 36.7025 46.5 24C46.5 11.2975 36.2025 1 23.5 1C10.7975 1 0.5 11.2975 0.5 24C0.5 36.7025 10.7975 47 23.5 47Z"
                  stroke="#E75B2B"
                />
              </svg>
            </div>
            <h6 className="name text-center">
              {this.props.translate("gift_card.changed_page.change_done_step")}
            </h6>
          </div>
        </section>
        <section className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="page-holder d-flex flex-column flex-lg-row justify-content-center align-items-start">
                  <div className="info">
                    <h5 className="title">
                      {this.props.translate(
                        "gift_card.changed_page.start_exploring_experiences"
                      )}
                    </h5>
                    <p className="result">
                      {this.props.translate(
                        "gift_card.changed_page.amount_of_gift_card_added_to_your_account",
                        {
                          amount: (
                            <span className="amount">
                              {currencyFormatter(
                                this.state.giftCard.currency,
                                this.state.giftCard.credit,
                                this.props.lang
                              )}
                            </span>
                          ),
                        }
                      )}
                    </p>
                    <p className="text">
                      {this.props.translate(
                        "gift_card.changed_page.description_below_amount"
                      )}
                    </p>
                    <Link
                      to={global.experiences.links[this.props.lang]}
                      className="see-all-experiences-button"
                    >
                      {this.props.translate(
                        "gift_card.changed_page.start_exploring"
                      )}
                    </Link>
                  </div>
                  <div className="details d-flex flex-column">
                    <h5 className="title">
                      {this.props.translate(
                        "gift_card.changed_page.exchange_details"
                      )}
                    </h5>
                    <p className="content">
                      {this.props.translate(
                        "gift_card.changed_page.sidebar_text"
                      )}
                    </p>
                    <div className="infos">
                      <span className="detail-type text-left">
                        {this.props.translate(
                          "gift_card.changed_page.credit_expiry_date"
                        )}
                      </span>
                      <span className="detail-content text-right">
                        {dayjs(this.state.giftCard.expiry_date).format(
                          "DD MMMM YYYY HH:mm"
                        )}
                      </span>
                      <span className="detail-type text-left">
                        {this.props.translate(
                          "gift_card.changed_page.credit_amount"
                        )}
                      </span>
                      <span className="detail-content text-right">
                        {currencyFormatter(
                          this.state.giftCard.currency,
                          this.state.giftCard.credit,
                          this.props.lang
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PopularActivities
          translate={this.props.translate}
          currency={this.props.user.currencyPref.symbol}
        />
      </div>
    );
  }
}

export default Changed;
