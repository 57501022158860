import React from "react";
import BLInput from "../../../utils/components/BLInput";

const PersonalInfo = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <BLInput
            label={props.translate("global.name")}
            type="text"
            name="firstName"
            value={props.personalInfo.firstName}
            changed={props.handleUpdatePersonalInfo}
          />
        </div>
        <div className="col-12 col-md-6">
          <BLInput
            label={props.translate("global.last_name")}
            type="text"
            name="lastName"
            value={props.personalInfo.lastName}
            changed={props.handleUpdatePersonalInfo}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <BLInput
            label={props.translate("global.phone")}
            type="tel"
            name="phone"
            value={props.personalInfo.phone}
            changed={props.handleUpdatePersonalInfo}
            validationType="phone"
            setCountryCode={(value) => props.handleSetCountryCode(value)}
            countryCode={
              props.personalInfo.countryCode +
              " +" +
              props.personalInfo.areaCode
            }
          />
        </div>
        <div className="col-12 col-md-6">
          <BLInput
            label={props.translate("global.email")}
            type="text"
            name="email"
            value={props.personalInfo.email}
            changed={props.handleUpdatePersonalInfo}
            validationType="email"
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
