import Home from "../Home/Home";
import Auth from "../Auth/Auth";
import Category from "../Category/Category";
import AccountDetails from "../Profile/AccountDetails";
import WishList from "../Profile/WishList";
import ChangePassword from "../Profile/ChangePassword";
import Orders from "../Profile/Orders";
import Reservations from "../Profile/Reservations";
import GiftCards from "../Profile/GiftCards";
import Use from "../GiftCard/components/Use";
import Change from "../GiftCard/components/Change";
import HowTo from "../GiftCard/components/HowTo";
import Voucher from "../Voucher/Voucher";
import CorporateVoucher from "../CorporateVoucher/CorporateVoucher";
import Changed from "../GiftCard/components/Changed";
import Experience from "../Experience/Experience";
import ResetPassword from "../Auth/ResetPassword";
import { default as ChangePasswordFromReset } from "../Auth/ChangePassword";
import Cart from "../Cart/Cart";
import HelpCenter from "../HelpCenter/HelpCenter";
import HelpDetail from "../HelpCenter/HelpDetail";
import { Contact } from "../StaticPages/pages/Contact";
import Reservation from "../Reservation/Reservation";
import Locations from "../Category/Locations";
import SocialMediaCallback from "../Auth/components/SocialMediaCallback";
import AboutUs from "../AboutUs/pages/AboutUs";
import { Career } from "../AboutUs/pages/Career";
import Media from "../AboutUs/pages/Media";
import NotFound from "./NotFound";
import PrivacyPolicy from "../StaticPages/pages/PrivacyPolicy";
import CookiePolicy from "../StaticPages/pages/CookiePolicy";
import Supplier from "../Supplier/Supplier";
import ClarificationText from "../StaticPages/pages/ClarificationText";
import ConsentText from "../StaticPages/pages/ConsentText";
import Location from "../Category/Location";
import Search from "../Category/Search";
import Experiences from "../Category/Experiences";
import NotListedLegals from "../StaticPages/pages/NotListedLegals";

export const global = {
  home: {
    id: "global-1",
    property: Home,
    links: { en: "/en", tr: "/" },
    name: "home",
    exact: true,
    onNav: false,
    type: "Ana Sayfa",
  },
  notFound: {
    id: "global-not-found",
    property: NotFound,
    links: { en: "/en/404", tr: "/404" },
    name: "not_found",
    exact: true,
    onNav: false,
    type: "404",
  },

  howToGiftCard: {
    id: "global-2",
    property: HowTo,
    links: {
      en: "/en/voucher/how-to-use",
      tr: "/kupon-islemleri/hediye-karti",
    },
    name: "gift_card",
    exact: true,
    onNav: false,
    parent: "gift_card",
    order: 2,
    type: "Kupon İşlemleri",
  },
  useGiftCard: {
    id: "global-3",
    property: Use,
    links: {
      en: "/en/voucher/use",
      tr: "/kupon-islemleri/rezervasyon-yap",
    },
    name: "use_gift_card",
    exact: true,
    onNav: false,
    parent: "gift_card",
    order: 1,
    type: "Kupon İşlemleri",
  },
  changeGiftCard: {
    id: "global-6",
    property: Change,
    links: {
      en: "/en/voucher/exchange",
      tr: "/kupon-islemleri/donustur",
    },
    name: "change_gift_card",
    exact: true,
    onNav: false,
    parent: "gift_card",
    order: 3,
    type: "Kupon İşlemleri",
  },
  giftCardTransactions: {
    id: "global-7",
    property: Use,
    links: {
      en: "/en/voucher/use",
      tr: "/kupon-islemleri/rezervasyon-yap",
    },
    name: "card_transactions",
    exact: true,
    onNav: true,
    type: "Kupon İşlemleri",
    order: 3,
  },
  experiences: {
    id: "global-20",
    property: Experiences,
    links: {
      en: "/en/experiences",
      tr: "/deneyimler",
    },
    name: "experiences_page",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  voucher: {
    id: "global-9",
    property: Voucher,
    links: { en: "/en/voucher", tr: "/hediye-karti" },
    name: "voucher",
    exact: true,
    onNav: true,
    type: "Hediye Kartı",
    order: 4,
  },
  corporateVoucher: {
    id: "global-10",
    property: CorporateVoucher,
    links: { en: "/en/for-businesses", tr: "/isletmeler-icin" },
    name: "corporate_voucher",
    exact: true,
    onNav: true,
    type: "İçerik",
    extras: {
      className: "corporate-link",
    },
    order: 5,
  },
  experience: {
    id: "global-12",
    property: Experience,
    links: { en: "/en/experience/:name", tr: "/deneyim/:name" },
    name: "experience",
    exact: true,
    onNav: false,
    type: "Deneyim Detay",
  },
  cart: {
    id: "global-13",
    property: Cart,
    links: { en: "/en/my-cart", tr: "/sepetim" },
    name: "cart",
    exact: true,
    onNav: false,
    type: "Sepet",
  },
  location: {
    id: "global-14",
    property: Location,
    links: { en: "/en/locations/:location", tr: "/lokasyonlar/:location" },
    name: "location",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  search: {
    id: "global-15",
    property: Search,
    links: { en: "/en/search", tr: "/arama" },
    name: "search",
    exact: true,
    onNav: false,
    type: "Arama Sonuç",
  },
  helpCenter: {
    id: "global-16",
    property: HelpCenter,
    links: { en: "/en/help-center", tr: "/yardim-merkezi" },
    name: "help_center",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  helpDetail: {
    id: "global-17",
    property: HelpDetail,
    links: {
      en: "/en/help-center/:cat/:name",
      tr: "/yardim-merkezi/:cat/:name",
    },
    name: "help_detail",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  aboutUs: {
    id: "global-18",
    property: AboutUs,
    links: { en: "/en/about-us", tr: "/hakkimizda" },
    name: "about_us",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  contact: {
    id: "global-19",
    property: Contact,
    links: { en: "/en/contact", tr: "/bize-ulasin" },
    name: "contact",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  locations: {
    id: "global-20",
    property: Locations,
    links: { en: "/en/locations", tr: "/lokasyonlar" },
    name: "locations",
    exact: true,
    onNav: false,
    onMobileNav: true,
    type: "Listeleme",
    order: 2,
  },
  activities: {
    id: "global-activities",
    property: Locations,
    links: { en: "/en/activities", tr: "/aktiviteler" },
    name: "activities",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  activity: {
    id: "global-activity",
    property: Location,
    links: { en: "/en/activities/:location", tr: "/aktiviteler/:location" },
    name: "activity",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  summary: {
    id: "global-21",
    property: Cart,
    links: { en: "/en/summary", tr: "/ozet" },
    name: "summary",
    exact: true,
    onNav: false,
    isHeaderHidden: true,
    type: "Sepet",
  },
  career: {
    id: "global-22",
    property: Career,
    links: { en: "/en/career", tr: "/kariyer" },
    name: "career",
    exact: true,
    onNav: false,
  },
  media: {
    id: "global-23",
    property: Media,
    links: { en: "/en/media", tr: "/basinda-biz/basin-materyalleri" },
    name: "media",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  catPage: {
    id: "global-4",
    property: Category,
    links: {
      en: "/en/categories/:main_cat",
      tr: "/kategoriler/:main_cat",
    },
    name: "cat_page",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  subCatPage: {
    id: "global-5",
    property: Category,
    links: {
      en: "/en/categories/:main_cat/:sub_cat",
      tr: "/kategoriler/:main_cat/:sub_cat",
    },
    name: "sub_cat_page",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  privacyPolicy: {
    id: "global-24",
    property: PrivacyPolicy,
    links: {
      en: "/en/privacy-policy/:section?",
      tr: "/gizlilik-politikasi/:section?",
    },
    name: "privacy_policy",
    exact: true,
    onNav: false,
    params: {
      section: {
        enlightmentText: {
          en: "protection-of-personal-data",
          tr: "kisisel-verilerin-korunmasi",
        },
        cookiePolicy: { en: "cookies", tr: "cerezler" },
        consentText: { en: "consent-text", tr: "kvk-acik-riza-metni" },
        applicationForm: { en: "application-form", tr: "kvk-basvuru-formu" },
        userPrivacyNotice: {
          en: "user-privacy-notice",
          tr: "kullanici-aydinlatma-metni",
        },
      },
    },
    type: "İçerik",
  },
  notListedPolicy: {
    id: "global-24",
    property: NotListedLegals,
    links: {
      en: "/en/:policy",
      tr: "/:policy",
    },
    name: "privacy_policy",
    exact: true,
    onNav: false,
    params: {
      policy: {
        termsOfUse: {
          en: "user-agreement",
          tr: "kullanici-sozlesmesi",
        },
        shTermsOfUse: {
          en: "sh-terms-of-usage",
          tr: "im-kullanim-sartlari",
        },
      },
    },
    type: "İçerik",
  },
  cookiePolicy: {
    id: "global-25",
    property: CookiePolicy,
    links: {
      en: "/en/cookie-policy",
      tr: "/kvkk-gizlilik-politikasi/cerezler",
    },
    name: "cookie_policy",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  supplier: {
    id: "global-26",
    property: Supplier,
    links: { en: "/en/supplier/:slug", tr: "/saglayici/:slug" },
    name: "supplier",
    exact: true,
    onNav: false,
    type: "Listeleme",
  },
  clarificationText: {
    id: "global-27",
    property: ClarificationText,
    links: {
      en: "/en/clarification-text",
      tr: "/kvkk-gizlilik-politikasi/aydinlatma-metni",
    },
    name: "clarification_text",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
  consentText: {
    id: "global-28",
    property: ConsentText,
    links: {
      en: "/en/en/consent-text",
      tr: "/kvkk-gizlilik-politikasi/riza-metni",
    },
    name: "consent_text",
    exact: true,
    onNav: false,
    type: "İçerik",
  },
};

export const guest = {
  login: {
    id: "guest-1",
    property: Auth,
    links: { en: "/en/login", tr: "/giris" },
    name: "login",
    exact: true,
    onNav: false,
    type: "Kimlik Doğrulama",
  },
  register: {
    id: "guest-2",
    property: Auth,
    links: { en: "/en/register", tr: "/kaydol" },
    name: "register",
    exact: true,
    onNav: false,
    type: "Kimlik Doğrulama",
  },
  resetPassword: {
    id: "guest-3",
    property: ResetPassword,
    links: { en: "/en/reset-password", tr: "/sifre-sifirla" },
    name: "reset-password",
    exact: true,
    onNav: false,
    type: "Kimlik Doğrulama",
  },
  changePassword: {
    id: "guest-4",
    property: ChangePasswordFromReset,
    links: {
      en: "/en/password/reset/:email/:token",
      tr: "/sifre/sifirla/:email/:token",
    },
    name: "change-password",
    exact: true,
    onNav: false,
    type: "Kimlik Doğrulama",
  },
};

export const auth = {
  profile: {
    id: "auth-1",
    property: AccountDetails,
    links: { en: "/en/account-details", tr: "/hesap-detaylari" },
    name: "account_details",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 1,
    type: "Profil",
  },
  wishList: {
    id: "auth-2",
    property: WishList,
    links: { en: "/en/wish-list", tr: "/istek-listem" },
    name: "wish_list",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 2,
    type: "Profil",
  },
  orders: {
    id: "auth-3",
    property: Orders,
    links: { en: "/en/orders", tr: "/siparislerim" },
    name: "orders",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 3,
    type: "Profil",
  },
  reservations: {
    id: "auth-4",
    property: Reservations,
    links: { en: "/en/coupons", tr: "/kuponlarim" },
    name: "reservations",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 4,
    type: "Profil",
  },
  giftCards: {
    id: "auth-5",
    property: GiftCards,
    links: { en: "/en/gift-cards", tr: "/hediye-kartlarim" },
    name: "gift_cards",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 5,
    type: "Profil",
  },
  changePassword: {
    id: "auth-6",
    property: ChangePassword,
    links: { en: "/en/change-password", tr: "/sifre-degistir" },
    name: "change_password",
    exact: true,
    onNav: false,
    parent: "profile",
    order: 6,
    type: "Profil",
  },
  payment: {
    id: "auth-7",
    property: Cart,
    links: { en: "/en/payment", tr: "/odeme" },
    name: "payment",
    exact: true,
    onNav: false,
    isHeaderHidden: true,
    type: "Ödeme",
  },
  paymentThanks: {
    id: "auth-8",
    property: Cart,
    links: { en: "/en/payment/thanks", tr: "/odeme/tesekkurler" },
    name: "payment_thanks",
    exact: true,
    onNav: false,
    isHeaderHidden: true,
    type: "Sepet",
  },
  redeemedReservation: {
    id: "auth-9",
    property: Reservation,
    links: { en: "/en/reservation/:id", tr: "/rezervasyon/:id" },
    name: "redeemed_reservation",
    exact: true,
    onNav: false,
    type: "Kupon İşlemleri",
  },
  changedGiftCard: {
    id: "auth-10",
    property: Changed,
    links: {
      en: "/en/voucher/exchange/completed",
      tr: "/kupon-islemleri/donustur/tamamlandi",
    },
    name: "changed_gift_card",
    exact: true,
    onNav: false,
    type: "Kupon İşlemleri",
  },
};

export const callback = {
  socialLogin: {
    id: "callback-1",
    property: SocialMediaCallback,
    links: {
      en: "/en/login/:sm",
      tr: "/giris/:sm",
    },
    name: "social_login",
    exact: true,
    onNav: false,
    isHeaderHidden: true,
    type: "Kimlik Doğrulama",
  },
};

export const redirected = {
  locations: {
    id: "redirected-1",
    oldRoute: "global.locations",
    newRoute: "global.activities",
  },
  location: {
    id: "redirected-2",
    oldRoute: "global.location",
    newRoute: "global.activity",
  },
};
