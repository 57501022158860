import React, { Component } from "react";
import BLDropdown from "../../../utils/components/BLDropdown";
import BLDatePicker from "../../../utils/components/BLDatePicker";

import BLLoader from "../../../utils/components/BLLoader";
import dayjs from "dayjs";
import { currencyFormatter } from "../../../utils/helper";
import Amounts from "./components/Amounts";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";
import BOOKING_MODE from "../../../utils/constants";

export default class PickADate extends Component {
  state = {
    counts: {},
    preferredDate: this.props.isUpdate ? this.props.item.preferred_date : "",
    preferredHour: this.props.isUpdate ? this.props.item.preferred_time : "",
    totalPrice: parseFloat(0.0).toFixed(2),
    isLoading: true,
    isFilled: false,
  };

  hourOptions = [];

  minDate =
    this.props.product && this.props.product?.schedule?.date_range_type === 2
      ? new Date(
          dayjs(this.props.product?.schedule?.booking_start_date, "DD-MM-YYYY")
        )
      : new Date();

  maxDate =
    this.props.product && this.props.product?.schedule?.date_range_type === 2
      ? new Date(
          dayjs(this.props.product?.schedule?.booking_expiry_date, "DD-MM-YYYY")
        )
      : "";

  componentDidUpdate = (prevProps) => {
    if (this.state.isFilled !== this.checkIfFilled()) {
      this.setState({ isFilled: this.checkIfFilled() });
    }

    if (this.props.selectedPackage?.id !== prevProps.selectedPackage?.id) {
      this.setState({ isLoading: true, preferredDate: "", preferredHour: "" });
    }
  };

  checkIfFilled = () => {
    if (
      !this.props.selectedPackage?.id ||
      (this.props.product?.booking_mode === BOOKING_MODE.DATEANDTIME &&
        (!this.state.preferredDate || !this.state.preferredHour)) ||
      (this.props.product?.booking_mode === BOOKING_MODE.DATEONLY &&
        !this.state.preferredDate)
    )
      return false;

    const addedCount = Object.keys(this.state.counts).filter(
      (key) => this.state.counts[key].count > 0
    );

    if (!addedCount.length) return false;

    return true;
  };

  handlePickADate = (name, value, type) => {
    const dateOrTime =
      type === "date" ? dayjs(value).format("DD/MM/YYYY") : value;

    this.setState({ [name]: dateOrTime });
  };

  render() {
    return (
      <div className="option-holder pick-a-date">
        <p className="desc">
          {this.props.translate("package_options.pick_a_date_tab.answer")}
        </p>
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row">
            <div
              className={`col-12 ${
                this.props.product?.booking_mode !== BOOKING_MODE.DATEANDTIME
                  ? `col-md-6`
                  : ``
              }`}
            >
              <BLDropdown
                name="package"
                options={this.props.product?.packages?.map((item) => ({
                  name: item.title,
                  value: item.id,
                }))}
                placeholder={this.props.translate("experience.select_package")}
                default={this.props.selectedPackage?.id}
                setValue={(value) => {
                  this.props.handleSetSelectedPackage(
                    this.props.product.packages.find(
                      (item) => String(item.id) === String(value)
                    )
                  );
                }}
              />
            </div>
            {this.props.product?.booking_mode !== BOOKING_MODE.TICKET &&
              this.props.selectedPackage && (
                <>
                  <div className="col-12 col-md-6">
                    <BLDatePicker
                      lang={this.props.lang}
                      activeLanguage={{ code: this.props.lang }}
                      type="single"
                      placeholder={
                        this.props.productAvailabilitiesLoading
                          ? `Loading...`
                          : this.props.translate("product.preferred_date")
                      }
                      setSelected={(value) => {
                        this.handlePickADate("preferredDate", value, "date");

                        const eventStart = dayjs(value).format("YYYY-MM-DD");

                        if (!eventStart) return;

                        const selectedAvailability =
                          this.props.productAvailabilities.find((item) =>
                            item.date.formatted.startsWith(eventStart)
                          );

                        if (selectedAvailability)
                          this.props.handleSetSelectedAvailability(
                            selectedAvailability
                          );
                      }}
                      selected={
                        this.state.preferredDate
                          ? dayjs(this.state.preferredDate, "DD/MM/YYYY")
                          : null
                      }
                      minDate={this.minDate}
                      maxDate={this.maxDate}
                      includeDates={
                        this.props.productAvailabilities
                          ?.filter((availability) => availability?.available)
                          ?.map((pA) => new Date(pA.date.formatted)) || []
                      }
                      dateFormat="dd MMMM yyyy"
                      onMonthChange={(date) => {
                        const startDate = dayjs(date)
                          .startOf("month")
                          .isSame(dayjs().startOf("month"))
                          ? dayjs().format("YYYY-MM-DD")
                          : dayjs(date).startOf("month").format("YYYY-MM-DD");
                        const endDate = dayjs(date)
                          .endOf("month")
                          .format("YYYY-MM-DD");

                        if (!startDate || !endDate) return;

                        this.props.handleGetAvailabilities &&
                          this.props.handleGetAvailabilities({
                            startDate,
                            endDate,
                          });
                      }}
                      isLoading={this.props.productAvailabilitiesLoading}
                    />
                  </div>
                  {this.props.product?.booking_mode ===
                    BOOKING_MODE.DATEANDTIME && (
                    <div className="col-12 col-md-6">
                      <BLDropdown
                        options={this.props.selectedAvailability?.availabilities
                          ?.filter((a) => !a.soldOut && !a.closed)
                          ?.filter((a) => {
                            if (
                              dayjs(this.state.preferredDate).isSame(
                                dayjs(),
                                "day"
                              )
                            ) {
                              return dayjs(a.time, "HH:mm").isAfter(dayjs());
                            }
                            return true;
                          })
                          ?.map((a) => ({
                            name: a?.time,
                            value: a?.time,
                          }))}
                        name="preferredHour"
                        setValue={(value) => {
                          this.handlePickADate("preferredHour", value, "time");
                        }}
                        placeholder={this.props.translate(
                          this.props.productAvailabilitiesLoading
                            ? `global.loading`
                            : `product.preferred_time`
                        )}
                        disabled={this.state.preferredDate ? false : true}
                        default={this.state.preferredHour}
                      />
                    </div>
                  )}
                </>
              )}
          </div>

          <Amounts
            isLoading={this.state.isLoading}
            units={this.props.units}
            isUpdate={this.props.isUpdate}
            isVoucher={this.props.isVoucher}
            currency={this.props.currency}
            selectedPackage={this.props.selectedPackage}
            handleUpdateCounts={(counts) =>
              this.setState({ counts, isLoading: false })
            }
            handleCartDataLayers={this.props.handleCartDataLayers}
            preferredDate={this.state.preferredDate}
            handleUpdateTotalPrice={(totalPrice) =>
              this.setState({ totalPrice })
            }
            packageType={PACKAGE_TYPES.PICKADATE}
          />

          <div className="row">
            <div className="col d-flex justify-content-end align-items-end">
              <div className="total selectable">
                {this.props.translate("global.total")}{" "}
                <span>
                  {currencyFormatter(
                    this.props.currency,
                    this.state.totalPrice,
                    this.props.lang
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="disclaimer">
                {this.props.translate(
                  "package_options.fill_all_fields_to_continue"
                )}
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 offset-sm-6 offset-md-2 d-flex justify-content-end align-items-center">
              {this.props.isAddingToCart ? (
                <BLLoader />
              ) : this.props.isUpdate ? (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleUpdatePackage(this.props.itemId, {
                      priceOptions: { ...this.state.counts },
                      packageType: PACKAGE_TYPES.PICKADATE,
                      preferredDate: this.state.preferredDate,
                      preferredHour: this.state.preferredHour,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled || this.props.isUpdating}
                >
                  {this.props.isUpdating ? (
                    <div className="loader w-100 h-100 d-flex justify-content-center align-items-center">
                      <BLLoader loaderType="dots" />
                    </div>
                  ) : (
                    this.props.translate("global.update")
                  )}
                </button>
              ) : (
                <button
                  className="add-to-cart-button w-100"
                  onClick={() =>
                    this.props.handleBuyNow({
                      priceOptions: { ...this.state.counts },
                      packageType: PACKAGE_TYPES.PICKADATE,
                      preferredDate: this.state.preferredDate,
                      preferredHour: this.state.preferredHour,
                      alternativeDate: this.state.alternativeDate,
                      alternativeHour: this.state.alternativeHour,
                      totalPrice: this.state.totalPrice,
                      currency: this.props.currency,
                    })
                  }
                  disabled={!this.state.isFilled}
                >
                  {this.props.translate("global.buy_it_now")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
