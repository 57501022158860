import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import BLButton from "../utils/components/BLButton";
import BLLoader from "../utils/components/BLLoader";
import { BLBreadcrumbs } from "../utils/components/BLBreadcrumbs";
import { global } from "../App/routes";
import LocationsSidebar from "./components/LocationsSiderbar";
import { getCitiesWithProduct } from "../utils/helper";
import CatThumb from "../utils/components/CatThumb";
import SEO from "../utils/components/SEO";

class Locations extends Component {
  state = {
    locations: [],
    locationsToShow: [],
    filteredLocations: [],
    keyword: "",
    breadcrumbs: [],
    isLoading: true,
    page: 1,
    isLoadMoreActive: false,
    isSearching: false,
  };

  pageLimit = 24;
  pageCount = 1;

  componentDidMount = async () => {
    const breadcrumbs = [
      {
        id: 0,
        name: this.props.translate("global.homepage"),
        route: global.home.links[this.props.lang],
      },
      {
        id: 1,
        name: this.props.translate("category.locations"),
      },
    ];

    this.setState({ breadcrumbs });

    await getCitiesWithProduct(this.props.lang, 212).then((response) => {
      const { data } = response.data;

      const locationsToShow = [];

      const pageLimit =
        data.length >= this.pageLimit ? this.pageLimit : data.length;

      for (let i = 0; i < pageLimit; i++) locationsToShow.push(data[i]);

      const isLoadMoreActive = data.length >= this.pageLimit ? true : false;

      this.pageCount = Math.ceil(data.length / this.pageLimit);

      this.setState({
        locations: data,
        locationsToShow,
        isLoading: false,
        isLoadMoreActive,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.page !== this.state.page) {
      this.setState({ isLoadMoreActive: this.checkLoadMoreStatus() });
    }

    if (prevState.keyword !== this.state.keyword) {
      this.handleFilter();
    }
  };

  checkLoadMoreStatus = () => {
    return !(this.state.page >= this.pageCount);
  };

  handleLoadMore = () => {
    if (this.state.page < this.pageCount) {
      const locationsToShow = this.state.locationsToShow;

      const start = locationsToShow.length;
      const newPageCount = (this.state.page + 1) * this.pageLimit;
      const end =
        newPageCount > this.state.locations.length
          ? this.state.locations.length
          : newPageCount;

      for (let i = start; i < end; i++)
        locationsToShow.push(this.state.locations[i]);

      this.setState((prevState) => {
        return { page: prevState.page + 1, locationsToShow };
      });
    }
  };

  filterTimeout = "";

  handleFilter = () => {
    if (this.state.keyword) {
      const keyword = this.state.keyword;
      this.setState({ isSearching: true, isLoadMoreActive: false });

      clearTimeout(this.filterTimeout);

      this.filterTimeout = setTimeout(() => {
        const filteredLocations = this.state.locations.filter((data) =>
          data.name
            .toLocaleLowerCase(this.props.lang)
            .includes(keyword.toLocaleLowerCase(this.props.lang))
        );

        this.setState({ filteredLocations, isSearching: false });
      }, 750);
    } else {
      this.setState({
        filteredLocations: [],
        isLoadMoreActive: this.checkLoadMoreStatus(),
      });
    }
  };

  render() {
    const locationsToShow =
      this.state.filteredLocations.length || this.state.keyword
        ? this.state.filteredLocations
        : this.state.locationsToShow;

    const locations =
      this.state.isLoading || this.state.isSearching ? (
        <BLLoader />
      ) : locationsToShow.length ? (
        locationsToShow.map((data) => {
          const url = global.activity.links[this.props.lang].replace(
            ":location",
            data.slug
          );
          return (
            <CatThumb
              key={data.id}
              link={url}
              name={data.name}
              image={data.thumb_image}
            />
          );
        })
      ) : (
        <span className="mx-auto">
          {this.props.translate("category.no_result_found")}
        </span>
      );

    return (
      <div className="cat-page locations-fix">
        <SEO
          title={this.props.translate("page_title.activities")}
          description={this.props.translate("page_description.activities")}
        />
        <section className="banner position-relative">
          <div className="cat-breadcrumbs-holder position-absolute w-100">
            <div className="container">
              <div className="row">
                <div className="col">
                  <BLBreadcrumbs
                    breadcrumbs={this.state.breadcrumbs}
                    color="white"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overlay position-absolute d-flex justify-content-center align-items-center overflow-hidden"></div>
          <div className="container">
            <div className="row">
              <div className="col d-flex align-items-center justify-content-center">
                <h1 className="cat-title selectable text-uppercase">
                  {this.props.translate("category.all_locations")}
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col">
              <section className="cat-content d-flex flex-column flex-lg-row justify-content-between align-items-start">
                <div className="d-none d-lg-flex">
                  <LocationsSidebar
                    translate={this.props.translate}
                    lang={this.props.lang}
                    locations={this.state.locations}
                  />
                </div>
                <section className="list-side">
                  <div className="row">
                    <div className="col-12 col-md-6 offset-md-6 col-xl-4 offset-xl-8">
                      <div className="search-area d-flex flex-column flex-md-row align-items-start align-items-md-center">
                        <div className="form-area w-100 d-flex justify-content-start align-items-center position-relative">
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.7208 12.0018L16.853 16.133C17.049 16.329 17.049 16.6532 16.853 16.8492C16.7549 16.951 16.623 17 16.4948 17C16.3666 17 16.2346 16.9472 16.1366 16.8492L12.0044 12.718C10.7301 13.8262 9.06742 14.4971 7.25017 14.4971C3.25371 14.4971 0 11.2441 0 7.24856C0 3.25299 3.24994 0 7.25017 0C11.2466 0 14.5003 3.24922 14.5003 7.24856C14.5003 9.06541 13.8292 10.7277 12.7208 12.0018ZM7.2464 1.01774C3.81171 1.01774 1.01419 3.81086 1.01419 7.24856C1.01419 10.6863 3.81171 13.4831 7.2464 13.4831C10.6849 13.4831 13.4786 10.6825 13.4786 7.24856C13.4786 3.81463 10.6849 1.01774 7.2464 1.01774Z"
                              fill="#8E8E8E"
                            />
                          </svg>
                          <input
                            type="text"
                            name="keyword"
                            value={this.state.keyword}
                            onChange={(e) =>
                              this.setState({ keyword: e.target.value })
                            }
                            placeholder={this.props.translate(
                              "category.search_locations"
                            )}
                          />
                          {this.state.keyword ? (
                            <button
                              className="remove-keyword-button position-absolute"
                              onClick={() => this.setState({ keyword: "" })}
                              type="button"
                            >
                              <svg
                                width="27"
                                height="26"
                                viewBox="0 0 27 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.2"
                                  d="M13.1605 0C5.91368 0 0 5.84156 0 13C0 20.1584 5.91368 26 13.1605 26C20.4073 26 26.321 20.1584 26.321 13C26.321 5.84156 20.4073 0 13.1605 0Z"
                                  fill="black"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.8015 8.44802C17.538 8.18445 17.1106 8.18445 16.8471 8.44802L13.1993 12.0958L9.49481 8.39136C9.23446 8.13101 8.81235 8.13101 8.552 8.39136C8.29165 8.65171 8.29165 9.07382 8.552 9.33417L12.2565 13.0386L8.60914 16.686C8.34558 16.9495 8.34558 17.3768 8.60914 17.6404C8.87271 17.904 9.30003 17.904 9.56359 17.6404L13.2109 13.9931L16.9151 17.6972C17.1754 17.9576 17.5975 17.9576 17.8579 17.6972C18.1182 17.4369 18.1182 17.0148 17.8579 16.7544L14.1537 13.0503L17.8015 9.40247C18.0651 9.1389 18.0651 8.71158 17.8015 8.44802Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="results d-flex justify-content-start align-items-start flex-wrap">
                    {locations}
                  </div>
                  <CSSTransition
                    in={
                      !this.state.isLoading &&
                      !this.state.isSearching &&
                      this.state.isLoadMoreActive
                    }
                    timeout={0}
                    unmountOnExit
                  >
                    <div className="load-more-button-holder d-flex justify-content-center mt-4">
                      {this.state.isLoadingMore ? (
                        <BLLoader />
                      ) : (
                        <BLButton
                          type="pri"
                          classes="px-5 mx-auto"
                          clicked={this.handleLoadMore}
                        >
                          {this.props.translate("global.load_more")}
                        </BLButton>
                      )}
                    </div>
                  </CSSTransition>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Locations;
