import React from "react";
import { global } from "./routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import image from "../assets/images/404.png";
import Helmet from "react-helmet";
import { useEffect } from "react";
import API from "../utils/API";
import PrerenderRedirect from "../utils/components/PrerenderRedirect";
import { isCrawler } from "../utils/helper";

const NotFound = (props) => {
  const [isFetchComplete, setIsFetchComplete] = React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      global.notFound.links[props.lang] !== location.pathname &&
      location.pathname !== redirect?.url
    ) {
      API.get(`redirect-check?path=${location.pathname + location.search}`)
        .catch(({ response: res }) => {
          const { redirect_url: url, code } = res.data;

          setRedirect({ url, code });
          setIsFetchComplete(true);
        })
        .catch(() => {
          setIsFetchComplete(true);
        });
    } else {
      setIsFetchComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, props.lang]);

  useEffect(() => {
    if (!isCrawler() && redirect?.url) {
      history.push(redirect.url);
    }
  }, [history, redirect]);

  return (
    isFetchComplete &&
    (redirect && isCrawler() ? (
      <PrerenderRedirect to={redirect.url} code={redirect.code} />
    ) : (
      <div
        className="not-found-page d-flex flex-column align-items-center position-relative"
        style={{ zIndex: 6 }}
      >
        <Helmet>
          <title>404</title>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <h1 className="title">404</h1>
        <p className="text text-center">
          {props.translate("global.404_page_content")}
        </p>
        <Link
          to={global.home.links[props.lang]}
          className="go-back-button d-flex justify-content-center align-items-center"
        >
          {props.translate("global.go_back_home")}
        </Link>

        <img src={image} alt="404" className="image" />
      </div>
    ))
  );
};

export default NotFound;
