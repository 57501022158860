import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { global } from "../App/routes";
import API, { headers } from "../utils/API";
import { BLBreadcrumbs } from "../utils/components/BLBreadcrumbs";
import Product from "../utils/components/Product";
import { handleErrors } from "../utils/helper";
import lazyLoadSpinner from "../assets/images/lazy-load.png";
import ReactPlaceholder from "react-placeholder/lib";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";
import {
  dataLayer__itemListClick,
  dataLayer__itemListView,
} from "../utils/DataLayerEvents";
import { Helmet } from "react-helmet";

const ProductPlaceholder = () => (
  <div className="product-placeholder bl-single-product">
    <RectShape
      className="cover-placeholder placeholder-item"
      style={{ height: "auto", margin: 0, marginBottom: 10 }}
    />
    <TextBlock
      className="cat-placeholder placeholder-item"
      style={{ width: "20%", marginBottom: 8 }}
    />
    <TextBlock
      className="name-placeholder placeholder-item"
      rows={2}
      style={{ width: "75%", marginBottom: 5 }}
    />
    <div className="stats-placeholder d-flex justify-content-start align-items-center">
      <RectShape
        className="stat-placeholder placeholder-item"
        style={{
          width: 49,
          height: 22,
          borderRadius: 2,
          marginRight: 12,
          marginTop: 10,
          marginBottom: 15,
        }}
      />
      <TextBlock
        className="price-placeholder placeholder-item"
        row={1}
        style={{ width: 50 }}
      />
    </div>
    <TextBlock
      className="price-placeholder placeholder-item"
      row={1}
      style={{ width: 50, marginBottom: 15 }}
    />
    <TextBlock
      className="availability-placeholder placeholder-item"
      row={1}
      style={{ width: 75, marginBottom: 15 }}
    />
  </div>
);

const placeholder = (
  <div className="product-slider-placeholder w-100 d-flex justify-content-center align-items-center overflow-hidden">
    <div className="products-holder d-flex d-lg-none justify-content-center align-items-center flex-wrap">
      {Array.from(Array(2).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
    <div className="products-holder d-none d-lg-flex d-xl-none justify-content-start align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
    <div className="products-holder d-none d-xl-flex justify-content-start align-items-center">
      {Array.from(Array(4).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
  </div>
);

export default class Supplier extends Component {
  state = {
    breadcrumbs: [],
    products: [],
    page: 0,
    hasMore: false,
    isLoading: true,
    isLoadingMore: false,
    providerName: "",
    isReady: false,
  };

  slug = this.props.match.params.slug;
  limit = 12;

  componentDidMount = () => {
    const breadcrumbs = [
      {
        id: 0,
        name: this.props.translate("global.homepage"),
        route: global.home.links[this.props.lang],
      },
      {
        id: 1,
        name: this.props.translate("global.supplier"),
      },
    ];

    this.setState({ breadcrumbs });

    this.getProducts();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.currencyPref.code !== this.props.user.currencyPref.code)
      this.setState({ isReady: false }, this.getProducts);
  };

  getProducts = (nextPage = false) => {
    let endpoint = `provider/${this.slug}?limit=${this.limit}&currency=${this.props.user.currencyPref.code}`;

    endpoint += nextPage ? `&page=${parseInt(this.state.page) + 1}` : ``;

    if (nextPage) this.setState({ isLoadingMore: true });

    API.get(endpoint, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        "Accept-Language": this.props.activeLanguage.code,
      },
    })
      .then((response) => {
        const { data, meta, provider } = response.data;

        const products = nextPage ? [...this.state.products, ...data] : data;

        this.setState(
          {
            products,
            page: meta.current_page,
            hasMore: meta.current_page < meta.last_page,
            isReady: true,
          },
          () => {
            if (nextPage) this.setState({ isLoadingMore: false });
            else this.setState({ isLoading: false });

            if (!this.state.providerName)
              this.setState({ providerName: provider.name }, () => {
                this.getBreadcrumbs({
                  id: 2,
                  name: provider.name,
                });
              });

            const dataLayerProducts = products.map((product, index) => ({
              ...product,
              index: index + 1,
            }));

            const listInfo = {
              pageType: global.supplier.type,
              listType: "Genel",
              pageName: document.title,
              sortType: "Çok sevilenler",
            };

            dataLayer__itemListView(
              dataLayerProducts,
              listInfo,
              this.props.user.currencyPref.code
            );
          }
        );
      })
      .catch((err) => handleErrors(err));
  };

  loadMore = () => this.getProducts(true);

  getBreadcrumbs = (newItem) => {
    const breadcrumbs = [...this.state.breadcrumbs, newItem];

    this.setState({ breadcrumbs });
  };

  handleItemClick = (product) => {
    const listInfo = {
      pageType: global.supplier.type,
      listType: `Genel`,
      pageName: document.title,
      sortType: "Çok sevilenler",
    };

    dataLayer__itemListClick(product, listInfo);
  };

  render() {
    return (
      <div className="Supplier">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="breadcrumbs-holder d-none d-md-flex">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <BLBreadcrumbs
                  breadcrumbs={this.state.breadcrumbs}
                  color={"white"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="supplier-name d-flex justify-content-start align-items-center">
            <span className="label">
              {this.props.translate("global.supplier")}
            </span>
            <span className="sign">&gt;</span>
            <span className="name">{this.state.providerName}</span>
          </div>
        </div>
        <div className="products-holder">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <ReactPlaceholder
                  ready={this.state.isReady}
                  customPlaceholder={placeholder}
                  showLoadingAnimation
                >
                  <InfiniteScroll
                    dataLength={this.state.products.length}
                    next={this.loadMore}
                    hasMore={this.state.hasMore}
                    loader={
                      <div className="w-100 d-flex justify-content-center mt-4 lazy-loader mx-auto">
                        <img src={lazyLoadSpinner} alt="Lazy load spinner" />
                      </div>
                    }
                    style={{ width: "calc(100% + 60px)" }}
                    className="d-flex justify-content-center justify-content-sm-start align-items-start flex-wrap"
                  >
                    {this.state.products.map((data) => (
                      <Product
                        key={data.id}
                        product={data}
                        currency={this.props.user.currencyPref}
                        handleRemoveFromBucketlist={
                          this.handleRemoveFromBucketlist
                        }
                      />
                    ))}
                  </InfiniteScroll>
                </ReactPlaceholder>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
