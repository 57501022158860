import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { setViewportHeight } from "../helper";

const BLDrawer = (props) => {
  const [isModalOn, setIsModalOn] = useState(false);

  setTimeout(() => {
    setIsModalOn(true);
  }, 0);

  useEffect(() => {
    setViewportHeight();

    window.addEventListener("resize", setViewportHeight);

    const bodyOldStyles = document.querySelector("body").style;

    document.querySelector("body").style.overflow = "hidden";
    document.querySelector("body").style.maxHeight = "100vh";
    document.querySelector("body").setAttribute("data-is-modal-on", "true");
    return () => {
      document.querySelector("body").style = bodyOldStyles;
      window.removeEventListener("resize", setViewportHeight);
      document.querySelector("body").setAttribute("data-is-modal-on", "false");
    };
  }, [isModalOn, props.headerFix]);

  return (
    <div className="bl-drawer position-fixed d-flex flex-column justify-content-center">
      <div
        className="bl-drawer-overlay position-fixed"
        onClick={props.handleCloseDrawer}
      ></div>
      <CSSTransition
        in={isModalOn}
        timeout={300}
        unmountOnExit
        classNames="bl-drawer-content"
      >
        <div className="bl-drawer-holder position-fixed">
          <h4 className="drawer-title text-center">{props.title}</h4>
          <div className="content">{props.children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};

BLDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default BLDrawer;
