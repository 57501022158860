import React, { Component } from "react";
import { connect } from "react-redux";
import toastr from "toastr";

import Profile from "./Profile";
import AccountInfo from "./components/AccountInfo";
import BillingInfo from "./components/BillingInfo";
import API, { headers } from "../utils/API";
import Axios from "axios";
import dayjs from "dayjs";
import { update } from "../Auth/authActions";
import { handleErrors, validateInput } from "../utils/helper";
import BLLoader from "../utils/components/BLLoader";
import Helmet from "react-helmet";
import PhoneConfirmation from "../Cart/components/PhoneConfirmation";
import NotificationSettings from "./components/NotificationSettings";
import { CSSTransition } from "react-transition-group";
import BLModal from "../utils/components/BLModal";

class AccountDetails extends Component {
  state = {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      areaCode: "90",
      phoneCountryCode: "TR",
      date_of_birth: "",
      gender: "",
      invoice: {
        invoiceType: 1,
        isNotCitizen: false,
        identityNo: "",
        corporateName: "",
        taxNumber: "",
        taxLocation: "",
        address_line: "",
        country_id: "",
        city_id: "",
        state_id: "",
      },
    },
    notificationSettings: {
      agreement: false,
      sms: false,
      email: false,
      call: false,
    },
    errMessages: {},
    isLoading: true,
    isSending: false,
    isAccountDetailsOk: false,
    isBillingInfoOk: false,
    isNotificationSettingsOk: false,
    isConfirmPhoneOn: false,
    isConfirmingPhone: false,
    confirmationCode: "",
    confirmResponse: { status: false, success: false, message: "" },
    showNotificationConsentAgreement: false,
    notificationConsentAgreement: null,
  };

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    API.get("account", {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        "Accept-Language": this.props.lang,
        cancelToken: this.cancelSource.token,
      },
    })
      .then((response) => {
        const { data } = response.data;
        const user = {
          ...data,
          phone: data.phone.number || "",
          areaCode: data.phone.area_code || "90",
          phoneCountryCode: data.phone.country_code || "TR",
          date_of_birth: data.date_of_birth || "",
          invoice: {
            invoiceType: data.billing_type ? data.billing_type.toString() : "1",
            isNotCitizen: !data.citizen_of_turkey,
            identityNo: data.idn || "",
            corporateName: data.company_name || "",
            taxNumber: data.tax_number || "",
            taxLocation: data.tax_office || "",
            address_line: data.contact.address_line || "",
            country_id: data.contact.country.id,
            city_id: data.contact.city.id,
            state_id: data.contact.state.id,
            zip: data.contact.zip,
          },
        };
        delete user.contact;
        delete user.notify_with_email;
        delete user.notify_with_sms;
        delete user.notify_with_call;
        delete user.contact_consent;

        const agreement =
          data.notify_with_sms ||
          data.notify_with_email ||
          data.notify_with_call ||
          data.contact_consent ||
          false;

        const notificationSettings = {
          agreement,
          sms: data.notify_with_sms,
          email: data.notify_with_email,
          call: data.notify_with_call,
        };

        this.setState({
          user,
          defaultUser: user,
          notificationSettings,
          defaultNotificationSettings: notificationSettings,
        });

        setTimeout(() => {
          this.setState({ isLoading: false });

          this.handleCheckInfo("accountDetails");
          this.handleCheckInfo("billingInfo");
          this.handleCheckNotificationInfo();
        }, 20);
      })
      .catch((err) => handleErrors(err));

    this.handleGetConsentAgreement();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.isConfirmPhoneOn &&
      !this.state.isConfirmPhoneOn &&
      this.state.user.is_phone_verified
    )
      this.updateAccountInfo();
    else if (
      prevState.isConfirmPhoneOn &&
      !this.state.isConfirmPhoneOn &&
      !this.state.user.is_phone_verified
    )
      this.setState({ isSending: false });

    if (prevProps.lang !== this.props.lang) {
      this.handleGetConsentAgreement();
    }
  };

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");
  };

  handleGetConsentAgreement = () => {
    const agreementLink =
      this.props.lang === "tr"
        ? "ticari-elektronik-iletiler"
        : "commercial-electronic-messages";

    API.get(`legals/${agreementLink}`, {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        cancelToken: this.cancelSource.token,
      },
    })
      .then(({ data }) => {
        this.setState({ notificationConsentAgreement: data.data.content });
      })
      .catch((err) => handleErrors(err));
  };

  handleChangeInfo = (e) => {
    const { name, value } = e.target;

    const user = { ...this.state.user };

    user[name] = value;

    this.setState({ user }, () => this.handleCheckInfo("accountDetails"));
  };

  handleUpdateCountryCode = (value) => {
    const valueArr = value.split("+");

    const phoneCountryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    const user = { ...this.state.user, phoneCountryCode, areaCode };
    this.setState({ user }, () => this.handleCheckInfo("accountDetails"));
  };

  handleChangeBillingInfo = (name, value) => {
    const user = { ...this.state.user };

    const invoice = { ...user.invoice };

    invoice[name] = value;

    if (name === "country_id") {
      invoice["city_id"] = "";
      invoice["state_id"] = "";
    } else if (name === "city_id") invoice["state_id"] = "";

    user["invoice"] = invoice;

    this.setState({ user }, () => this.handleCheckInfo("billingInfo"));
  };

  handleChangeNotificationSettings = (e) => {
    const { name, checked } = e.target;

    const notificationSettings = { ...this.state.notificationSettings };

    notificationSettings[name] = checked;

    this.setState({ notificationSettings }, this.handleCheckNotificationInfo);
  };

  handleSelectInfo = (name, value, type = null) => {
    const user = { ...this.state.user };

    if (type === "invoice") user.invoice[name] = value;
    if (type === "date_of_birth")
      user[name] = value ? dayjs(value).format("DD/MM/YYYY") : "";
    else user[name] = value;

    this.setState({ user });

    setTimeout(() => this.handleCheckInfo("accountDetails"), 20);
    setTimeout(() => this.handleCheckInfo("billingInfo"), 20);
  };

  handleCheckInfo = (type) => {
    let buttonName, fieldsToCheck;
    if (type === "accountDetails") {
      buttonName = "isAccountDetailsOk";

      fieldsToCheck = [
        "first_name",
        "last_name",
        "email",
        "phone",
        "areaCode",
        "date_of_birth",
        "gender",
      ];
    } else if (type === "billingInfo") {
      buttonName = "isBillingInfoOk";

      fieldsToCheck = [
        "address_line",
        "country_id",
        "city_id",
        "state_id",
        "zip",
      ];

      if (parseInt(this.state.user.invoice.invoiceType) === 1) {
        if (!this.state.user.invoice.isNotCitizen) {
          fieldsToCheck.push("identityNo");
        }
      } else {
        fieldsToCheck.push("corporateName");
        fieldsToCheck.push("taxNumber");
        fieldsToCheck.push("taxLocation");
      }
    }

    this.setState({ [buttonName]: false });

    let buttonStatus = true;
    const state =
      type === "accountDetails" ? this.state.user : this.state.user.invoice;
    const defaultState =
      type === "accountDetails"
        ? this.state.defaultUser
        : this.state.defaultUser.invoice;

    fieldsToCheck = fieldsToCheck.filter(
      (field) =>
        String(state[field]) !== String(defaultState[field]) ||
        (String(state[field]) === "" && String(defaultState[field]) !== "")
    );

    if (fieldsToCheck.length) {
      fieldsToCheck.some((data) => {
        if (!state[data] || state[data] === "") {
          buttonStatus = false;
          return true;
        }

        if (data === "email" || data === "phone") {
          if (!validateInput(data, state[data]).success) {
            buttonStatus = false;
            return true;
          }
        }

        if (data === "identityNo") {
          if (!validateInput("idNo", state[data]).success) {
            buttonStatus = false;
            return true;
          }
        }

        if (data === "zip") {
          if (!validateInput("number", state[data]).success) {
            buttonStatus = false;
            return true;
          }
        }

        return false;
      });
    } else {
      buttonStatus = false;
    }

    this.setState({ [buttonName]: buttonStatus });
  };

  handleCheckNotificationInfo = () => {
    const { agreement, sms, email, call } = this.state.notificationSettings;

    const fields = [sms, email, call];

    if (fields.includes(true) && agreement) {
      this.setState({ isNotificationSettingsOk: true });
    } else if (fields.includes(true) && !agreement) {
      this.setState({ isNotificationSettingsOk: false });
    } else {
      this.setState({ isNotificationSettingsOk: true });
    }
  };

  sendConfirmationCode = () => {
    let phone = parseInt(
      this.state.user.phone.toString().replace(/[^0-9]+/gi, "")
    );

    if (!phone) {
      return false;
    }
    while (phone.toString().charAt(0) === "0") {
      phone = parseInt(phone.substring(1));
    }

    if (!phone) {
      return false;
    }

    this.setState({ isSending: true });

    const params = {
      phone:
        this.state.user.areaCode +
        "-" +
        this.state.user.phone.toString().replace(/\s/g, ""),
      phone_cc: this.state.user.phoneCountryCode,
    };

    API.post("account/phone-verification/start", params, {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        if (!response.data.info.is_phone_verified) {
          const confirmResponse = {
            status: false,
            success: false,
            message: "",
          };
          const user = { ...this.state.user };

          user["is_phone_verified"] = response.data.info.is_phone_verified;

          this.setState(
            { confirmResponse, isConfirmingPhone: false, user },
            () => this.setState({ isConfirmPhoneOn: true })
          );
        } else {
          this.updateAccountInfo();
        }
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isSending: false });
      });
  };

  handleClosePhoneConfirmModal = (e) => {
    if (e) e.preventDefault();

    this.setState({ isConfirmPhoneOn: false, confirmationCode: "" });
  };

  handleConfirmPhone = (e) => {
    if (e) e.preventDefault();

    if (this.state.confirmationCode !== "") {
      this.setState({ isConfirmingPhone: true });

      const params = { code: this.state.confirmationCode };

      API.post("account/phone-verification/verify", params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          const confirmResponse = {
            status: true,
            success: response.data.status,
            message: response.data.info.message,
          };

          const user = { ...this.state.user };
          user.is_phone_verified = response.data.status;

          setTimeout(() => this.setState({ confirmResponse, user }), 1200);
          if (response.data.status) {
            setTimeout(() => {
              if (this.state.isConfirmPhoneOn)
                this.setState({ isConfirmPhoneOn: false });
            }, 4200);
          } else {
            setTimeout(
              () =>
                this.setState({ confirmResponse, isConfirmingPhone: false }),
              1200
            );
          }
        })
        .catch((err) => {
          handleErrors(err);
          setTimeout(() => this.setState({ isConfirmingPhone: false }), 20);
        });
    }
  };

  handleUpdateAccountInfo = (e) => {
    if (e) e.preventDefault();

    if (
      this.state.defaultUser.phone !== this.state.user.phone &&
      this.state.user.phone
    )
      this.sendConfirmationCode();
    else this.updateAccountInfo();
  };

  updateAccountInfo = () => {
    const defaultUser = { ...this.state.defaultUser };
    delete defaultUser.invoice;

    const user = { ...this.state.user };
    delete user.invoice;

    const compare = Object.keys(user).filter(
      (key) => user[key] !== defaultUser[key]
    );

    if (compare.length) {
      this.setState({ isSending: true });

      const { user } = this.state;

      const params = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone
          ? "+" + user.areaCode + "-" + user.phone.toString().replace(/\s/g, "")
          : "",
        phone_cc: user.phoneCountryCode,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
      };

      API.patch("account", params, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data } = response.data;
          this.props.update({
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            hashed_email: data.hashed_email,
            gender: data.gender,
          });

          const defaultData = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone.number
              ? data.phone.number.toString().replace(/\s/g, "")
              : "",
            areaCode: data.phone.area_code || "90",
            phoneCountryCode: data.phone.country_code || "TR",
            date_of_birth: data.date_of_birth || "",
            gender: data.gender,
          };

          const defaultUser = { ...this.state.defaultUser, ...defaultData };

          toastr.clear();
          toastr.success(response.data.info.message);
          this.setState(
            {
              isSending: false,
              defaultUser,
            },
            () => this.handleCheckInfo("accountDetails")
          );
        })
        .catch((err) => {
          handleErrors(err);

          this.setState({ isSending: false });
        });
    }
  };

  handleUpdateBillingInfo = (e) => {
    if (e) e.preventDefault();

    const compare = Object.keys(this.state.user.invoice).filter(
      (key) =>
        this.state.user.invoice[key] &&
        this.state.user.invoice[key] !== this.state.defaultUser.invoice[key]
    );

    if (compare.length) {
      this.setState({ isSending: true });

      const { invoice } = this.state.user;

      const idn =
        invoice.invoiceType.toString() === "1" && !invoice.isNotCitizen
          ? invoice.identityNo
          : "";
      const citizen_of_turkey =
        invoice.invoiceType.toString() === "1" ? !invoice.isNotCitizen : null;
      const company_name =
        invoice.invoiceType.toString() === "2" ? invoice.corporateName : "";
      const tax_number =
        invoice.invoiceType.toString() === "2" ? invoice.taxNumber : "";
      const tax_office =
        invoice.invoiceType.toString() === "2" ? invoice.taxLocation : "";

      const params = {
        billing_type: invoice.invoiceType,
        citizen_of_turkey,
        idn,
        company_name,
        tax_number,
        tax_office,
        address_line: invoice.address_line,
        country_id: invoice.country_id,
        city_id: invoice.city_id,
        state_id: invoice.state_id,
        zip: invoice.zip,
      };

      API.patch("account", params, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data } = response.data;

          const defaultData = {
            invoice: {
              invoiceType: data.billing_type
                ? data.billing_type.toString()
                : "1",
              isNotCitizen: !data.citizen_of_turkey,
              identityNo: data.idn || "",
              corporateName: data.company_name || "",
              taxNumber: data.tax_number || "",
              taxLocation: data.tax_office || "",
              address_line: data.contact.address_line || "",
              country_id: data.contact.country.id,
              city_id: data.contact.city.id,
              state_id: data.contact.state.id,
              zip: data.contact.zip,
            },
          };

          const user = { ...this.state.user, ...defaultData };
          const defaultUser = { ...this.state.defaultUser, ...defaultData };

          toastr.clear();
          toastr.success(response.data.info.message);

          this.setState(
            {
              isSending: false,
              user,
              defaultUser,
            },
            () => this.handleCheckInfo("billingInfo")
          );
        })
        .catch((err) => {
          handleErrors(err);

          this.setState({ isSending: false });
        });
    }
  };

  handleUpdateNotificationSettings = (e) => {
    if (e) e.preventDefault();

    this.setState({ isSending: true });

    const { notificationSettings } = this.state;

    const params = {
      contact_consent: notificationSettings.agreement,
      notify_with_email: notificationSettings.email,
      notify_with_sms: notificationSettings.sms,
      notify_with_call: notificationSettings.call,
    };

    API.patch("account", params, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        "Accept-Language": this.props.lang,
      },
    }).then((response) => {
      const { data } = response.data;

      const defaultData = {
        agreement: data.contact_consent,
        email: data.notify_with_email,
        sms: data.notify_with_sms,
        call: data.notify_with_call,
      };

      const defaultNotificationSettings = {
        ...this.state.defaultNotificationSettings,
        ...defaultData,
      };

      toastr.clear();
      toastr.success(response.data.info.message);

      this.setState({
        isSending: false,
        defaultNotificationSettings,
      });
    });
  };

  render() {
    const lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : this.props.defaultLanguage;

    const mutualProps = {
      lang: lang,
      translate: this.props.translate,
      user: this.state.user,
      isSending: this.state.isSending,
      isLoading: this.state.isLoading,
      handleChangeInfo: this.handleChangeInfo,
      handleSelectInfo: this.handleSelectInfo,
    };

    return (
      <Profile>
        <Helmet>
          <title>{this.props.translate("profile.account_details")}</title>
        </Helmet>
        <div className="page-content account-details">
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                {this.state.isLoading ? (
                  <BLLoader />
                ) : (
                  <AccountInfo
                    {...mutualProps}
                    handleUpdateAccountInfo={this.handleUpdateAccountInfo}
                    handleUpdateCountryCode={this.handleUpdateCountryCode}
                    isAccountDetailsOk={this.state.isAccountDetailsOk}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-center">
                {this.state.isLoading ? (
                  <BLLoader />
                ) : (
                  <BillingInfo
                    {...mutualProps}
                    handleChangeBillingInfo={this.handleChangeBillingInfo}
                    handleUpdateBillingInfo={this.handleUpdateBillingInfo}
                    isBillingInfoOk={this.state.isBillingInfoOk}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-center">
                {this.state.isLoading ? (
                  <BLLoader />
                ) : (
                  <NotificationSettings
                    {...mutualProps}
                    notificationSettings={this.state.notificationSettings}
                    handleChangeNotificationSettings={
                      this.handleChangeNotificationSettings
                    }
                    handleUpdateNotificationSettings={
                      this.handleUpdateNotificationSettings
                    }
                    isNotificationSettingsOk={
                      this.state.isNotificationSettingsOk
                    }
                    showNotificationConsentAgreement={() =>
                      this.setState({ showNotificationConsentAgreement: true })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <PhoneConfirmation
          handleClosePhoneConfirmModal={this.handleClosePhoneConfirmModal}
          confirmationCode={this.state.confirmationCode}
          handleUpdate={(value) => this.setState({ confirmationCode: value })}
          isConfirmPhoneOn={this.state.isConfirmPhoneOn}
          phone={this.state.user.phone}
          handleConfirmPhone={this.handleConfirmPhone}
          confirmResponse={this.state.confirmResponse}
          isConfirmingPhone={this.state.isConfirmingPhone}
          translate={this.props.translate}
          sendConfirmationCode={this.sendConfirmationCode}
          removeHeaderFix={true}
        />

        <CSSTransition
          in={this.state.showNotificationConsentAgreement}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() =>
              this.setState({ showNotificationConsentAgreement: false })
            }
            headerFix={true}
          >
            <div className="modal-content-holder overflow-auto">
              <div
                className="contract-content"
                dangerouslySetInnerHTML={{
                  __html: this.state.notificationConsentAgreement,
                }}
              />
            </div>
          </BLModal>
        </CSSTransition>
      </Profile>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(null, mapDispatchToProps)(AccountDetails);
