import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import BLInput from "../../utils/components/BLInput";
import BLButton from "../../utils/components/BLButton";
import BLLoader from "../../utils/components/BLLoader";
import BLInfoArea from "../../utils/components/BLInfoArea";

class PhoneConfirmation extends Component {
  state = {
    countdown: 0,
  };

  countdownLimit = 120;
  countdownTimeout = "";
  countdownInterval = "";

  componentDidMount = () => {
    this.startCountdown();
  };

  startCountdown = () => {
    this.setState({ countdown: this.countdownLimit });

    this.countdownTimeout = setTimeout(() => {
      this.countdownInterval = setInterval(() => {
        if (this.state.countdown > 0)
          this.setState((prevState) => {
            return { countdown: prevState.countdown - 1 };
          });
        else clearInterval(this.countdownInterval);
      }, 1000);
    }, 1000);
  };

  stopCountdown = () => {
    clearInterval(this.countdownInterval);
    clearTimeout(this.countdownTimeout);
  };

  componentWillUnmount = () => {
    this.stopCountdown();
  };

  handleSendCodeAgain = () => {
    this.props.sendConfirmationCode();
    this.startCountdown();
  };

  render() {
    return (
      <CSSTransition
        in={this.props.isConfirmPhoneOn}
        timeout={300}
        classNames="bl-modal"
        unmountOnExit={true}
      >
        <BLModal
          handleCloseModal={this.props.handleClosePhoneConfirmModal}
          headerFix={true}
          keepFix={this.props.removeHeaderFix ? false : true}
        >
          <div className="confirm-holder d-flex flex-column align-items-center">
            {this.props.confirmResponse.success ? (
              <>
                <div className="success-icon">
                  {" "}
                  <svg
                    width="193"
                    height="193"
                    viewBox="0 0 193 193"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.5 193C149.795 193 193 149.795 193 96.5C193 43.2045 149.795 0 96.5 0C43.2045 0 0 43.2045 0 96.5C0 149.795 43.2045 193 96.5 193Z"
                      fill="url(#paint0_linear)"
                    />
                    <path
                      d="M136.717 60.611L81.3299 116.766L58.2826 93.6563C56.1614 91.5028 52.7161 91.5028 50.5949 93.6563C48.4684 95.8098 48.4684 99.296 50.5949 101.444L77.5073 128.425C79.7144 130.413 83.0148 130.634 85.195 128.425L144.409 68.3987C146.53 66.2507 146.53 62.759 144.409 60.611C142.283 58.463 138.843 58.463 136.717 60.611Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="-56.3607"
                        y1="160.16"
                        x2="149.46"
                        y2="258.969"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#80D88C" />
                        <stop offset="0.601105" stopColor="#6DD498" />
                        <stop offset="1" stopColor="#38CABB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <p className="content text-center">
                  {this.props.confirmResponse.message}
                </p>
              </>
            ) : (
              <>
                <h3 className="title text-center">
                  {this.props.translate("payment.phone_confirmation.title")}
                </h3>
                <p className="content text-center selectable">
                  {this.props.translate("payment.phone_confirmation.content", {
                    phone_number: this.props.phone,
                  })}
                </p>
                <BLInput
                  name="confirmationCode"
                  value={this.props.confirmationCode}
                  label={this.props.translate(
                    "payment.phone_confirmation.confirmation_code"
                  )}
                  type="text"
                  changed={(e) => this.props.handleUpdate(e.target.value)}
                />
                {this.props.isConfirmingPhone ? (
                  <BLLoader />
                ) : (
                  <>
                    <span className="send-again-button d-flex mb-3">
                      {this.state.countdown ? (
                        this.props.translate(
                          "payment.phone_confirmation.wait_s_before_sending_again",
                          { seconds: this.state.countdown }
                        )
                      ) : (
                        <button
                          className="btn btn-empty"
                          onClick={this.handleSendCodeAgain}
                        >
                          {this.props.translate(
                            "payment.phone_confirmation.send_again"
                          )}
                        </button>
                      )}
                    </span>
                    <BLButton
                      type="sec"
                      classes="w-100"
                      clicked={this.props.handleConfirmPhone}
                    >
                      {this.props.translate(
                        "payment.phone_confirmation.confirm"
                      )}
                    </BLButton>
                    {this.props.confirmResponse.status &&
                      !this.props.confirmResponse.success && (
                        <BLInfoArea type="error">
                          {this.props.confirmResponse.message}
                        </BLInfoArea>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </BLModal>
      </CSSTransition>
    );
  }
}

export default PhoneConfirmation;
