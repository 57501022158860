import React from "react";
import PropTypes from "prop-types";

const BLRating = (props) => {
  return (
    <div className="BLRating d-flex justify-content-center align-items-center position-relative">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0963 0.734303L15.8485 6.7225C15.9681 7.0818 16.3271 7.32132 16.6861 7.44109L22.9085 8.3992C23.9855 8.3992 24.3444 9.59684 23.6265 10.3154L19.0793 14.9862C18.84 15.2257 18.7204 15.7048 18.7204 16.0641L19.7973 22.5314C19.917 23.4895 18.9597 24.3278 18.122 23.8488L12.498 20.7349C12.139 20.4954 11.78 20.4954 11.421 20.7349L5.79693 23.8488C4.9593 24.3278 3.88235 23.6092 4.12167 22.5314L5.19862 16.0641C5.31828 15.7048 5.07896 15.2257 4.83964 14.9862L0.292506 10.3154C-0.305801 9.59684 0.0531827 8.3992 1.01047 8.27943L7.23286 7.32132C7.59185 7.32132 7.95083 6.96203 8.07049 6.60274L10.8227 0.61454C11.421 -0.223808 12.6176 -0.223809 13.0963 0.734303Z"
          fill="white"
        />
      </svg>
      {props.tooltip ? (
        <span className="disabled-tooltip position-absolute bottom">
          {props.translate("product.rating")}
        </span>
      ) : (
        ""
      )}
      <span>{props.rating}</span>
    </div>
  );
};

BLRating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BLRating;
