import React from "react";
import SingleExperience from "./SingleExperience";
import SingleGiftCard from "./SingleGiftCard";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";

const Coupons = (props) => {
  const mutualProps = { currency: props.currency, translate: props.translate };
  return (
    <div className="coupons-area">
      <div className="row">
        <div className="col">
          {props.cart.items && props.cart.items.length
            ? props.cart.items.map((data) =>
                data.package_type !== PACKAGE_TYPES.GIFTCARD ? (
                  <SingleExperience
                    key={data.id}
                    {...mutualProps}
                    item={data}
                  />
                ) : (
                  <SingleGiftCard key={data.id} {...mutualProps} item={data} />
                )
              )
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Coupons;
