import React from "react";

import giftCard from "../../assets/images/gift-card-cart-icon.svg";
import { formatPhoneNumber } from "../../utils/helper";

const HorizontalGiftCard = (props) => {
  return (
    <div className="product horizontal d-flex justify-content-start align-items-start">
      <div className="image d-flex justify-content-center align-items-center overflow-hidden">
        <img src={giftCard} alt="Gift Cover" />
      </div>
      <div className="info-area-holder">
        <h4 className="title m-0 mb-2">
          {props.translate("global.gift_card")}
        </h4>
        <div className="single-info">
          <div className="info-title">{props.translate("global.to")}</div>
          <div className="info-content">{props.name}</div>
        </div>
        <div className="single-info">
          <div className="info-title">{props.translate("global.email")}</div>
          <div className="info-content">{props.email}</div>
        </div>
        <div className="single-info">
          <div className="info-title">{props.translate("global.phone")}</div>
          <div className="info-content">
            +{props.phone.area_code} {formatPhoneNumber(props.phone.number)}
          </div>
        </div>
        <div className="single-info">
          <div className="info-title">{props.translate("global.amount")}</div>
          <div className="info-content">{props.amount}</div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalGiftCard;
