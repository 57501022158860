import React, { Component } from "react";
import PropTypes from "prop-types";
import crypto from "crypto";
import { validateInput } from "../helper";
import countries from "../db/countries.json";
import BLLoader from "./BLLoader";
import is from "is_js";
import InputMask from "react-input-mask";
import BLInfoArea from "./BLInfoArea";
import { CSSTransition } from "react-transition-group";
import { Translate } from "react-localize-redux";
import BLPasswordRequirements from "./BLPasswordRequirements";

class BLInput extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changed: PropTypes.func,
    focused: PropTypes.func,
    onBlur: PropTypes.func,
    classes: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    maxLength: PropTypes.number,
    validationType: PropTypes.string,
    processing: PropTypes.bool,
  };

  state = {
    showPass: false,
    isFilled: this.props.value ? true : false,
    value: this.props.value,
    countryCode: this.props.countryCode ? this.props.countryCode : "TR +90",
    error: "",
    isTooltipOn: false,
    isPasswordInfoOn: false,
  };

  id = crypto.randomBytes(6).toString("hex");

  componentDidMount = () => {
    if (this.props.type === "tel") this.setState({ countries });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.value !== this.props.value)
      if (this.props.value) this.setState({ isFilled: true });
      else this.setState({ isFilled: false });

    if (this.props.type === "tel")
      if (prevProps.countryCode !== this.props.countryCode)
        this.setState({ countryCode: this.props.countryCode });

    if (!prevProps.error && this.props.error && this.state.error)
      this.setState({ error: "" });
  };

  handleValueCheck = (e, type) => {
    const value =
      type === "creditCard" ? e.target.value.replace(/ /g, "") : e.target.value;

    if (type) {
      const validation = validateInput(type, value);
      if (!validation.success)
        this.setState({
          error: this.props.customError
            ? this.props.customError
            : validation.error,
        });
      else this.setState({ error: "" });
    }

    this.handleCheckIfFilled(e);
  };

  handleCheckIfFilled = (e) => {
    if (e.target !== document.querySelector("input:focus"))
      if (e.target.value) this.setState({ isFilled: true });
      else this.setState({ isFilled: false });
  };

  handleChange = (e) => {
    if (`limitCharacters` in this.props && !this.checkCharacters(e))
      return false;

    this.setState({ value: e.target.value, error: "" });

    this.handleCheckIfFilled(e);

    if (this.props.changed) this.props.changed(e);
  };

  checkCharacters = ({ target }) => {
    const { limitCharacters } = this.props;

    let val = target.value;

    if (limitCharacters.includes("text"))
      val = val.replace(/[A-Za-zğüşöçıİ]/gi, ``).trim();

    if (limitCharacters.includes("number"))
      val = val.replace(/[0-9]/g, ``).trim();

    if (val.length) return false;
    return true;
  };

  handleFocus = (e) => {
    if (!this.state.isFilled) this.setState({ isFilled: true });

    if (`tooltip` in this.props) {
      this.setState({ isTooltipOn: true });
    }

    if (`showPassInfo` in this.props) this.setState({ isPasswordInfoOn: true });

    if (this.props.focused) this.props.focused(e);
  };

  handleBlur = (e) => {
    this.handleValueCheck(e, this.props.validationType);

    this.handleCheckForError(e);

    if (`tooltip` in this.props) {
      this.setState({ isTooltipOn: false });
    }

    if (!this.props.value.length) this.setState({ isPasswordInfoOn: false });

    if (this.props.onBlur) this.props.onBlur(e);
  };

  handleCheckForError = ({ target }) => {
    if (`minChar` in this.props) {
      let error = "";
      if (target.value.length < this.props.minChar)
        error = (
          <Translate
            id="global.input_length_error"
            data={{ key: this.props.label }}
          />
        );
      else {
        const parts = target.value.split(" ");

        if (parts.length > 1) {
          const partsCheck = parts.filter(
            (part) => part && part.length < this.props.minChar
          );

          if (partsCheck.length)
            error = (
              <Translate
                id="global.input_length_error"
                data={{ key: this.props.label }}
              />
            );
        }
      }

      this.setState({ error });
    }
  };

  render() {
    const countryCodes =
      this.state.countries && this.state.countries.length
        ? this.state.countries.map((data) =>
            data.callingCodes[0] ? (
              <option
                value={data.alpha2Code + " +" + data.callingCodes[0]}
                key={data.alpha2Code}
              >
                {data.alpha2Code + " +" + data.callingCodes[0]}
              </option>
            ) : (
              ""
            )
          )
        : "";
    return (
      <div
        className={`bl-input position-relative ${
          this.props.error ? `error` : ``
        } ${this.props.classes ? this.props.classes : ""} 
        ${this.props.type === "tel" ? `country-selector-fix` : ""}`}
        data-placeholder={
          this.props.dataPlaceholder ? this.props.dataPlaceholder : ""
        }
      >
        <label
          htmlFor={`bl-input-${this.id}`}
          className={`position-absolute ${
            this.state.isFilled === true ? `active` : ``
          }`}
        >
          {this.props.label}
        </label>
        {this.props.type === "tel" ? (
          <span className="country-code-selector position-absolute">
            <select
              name="countryCode"
              id="country-code"
              value={this.state.countryCode}
              onChange={(e) => {
                this.setState({ countryCode: e.target.value });
                this.props.setCountryCode(e.target.value);
              }}
              {...this.props.inputProps}
            >
              {countryCodes}
            </select>
          </span>
        ) : (
          ""
        )}
        {this.props.type === "textarea" ? (
          <textarea
            name={this.props.name}
            id={`bl-input-${this.id}`}
            className="selectable"
            onFocus={() => {
              !this.state.isFilled && this.setState({ isFilled: true });
            }}
            value={this.props.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            maxLength={`charLimit` in this.props ? this.props.charLimit : -1}
            {...this.props.inputProps}
          />
        ) : "isMasked" in this.props ? (
          <InputMask
            type={
              this.props.type === "password"
                ? this.state.showPass
                  ? "text"
                  : "password"
                : this.props.type
            }
            className={`selectable ${is.safari() ? `safari-fix` : ``}`}
            name={this.props.name}
            id={`bl-input-${this.id}`}
            onFocus={this.handleFocus}
            value={this.props.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            {...this.props.inputProps}
            mask={this.props.mask}
          />
        ) : (
          <input
            type={
              this.props.type === "password"
                ? this.state.showPass
                  ? "text"
                  : "password"
                : this.props.type
            }
            className={`selectable ${is.safari() ? `safari-fix` : ``}`}
            maxLength={this.props.maxLength ? this.props.maxLength : -1}
            name={this.props.name}
            id={`bl-input-${this.id}`}
            onFocus={this.handleFocus}
            value={this.props.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            {...this.props.inputProps}
          />
        )}
        {`charLimit` in this.props && (
          <span className="position-absolute limit-info">
            <Translate
              id="global.char_left"
              data={{ char: this.props.charLimit - this.props.value.length }}
            />
          </span>
        )}
        {this.props.type === "password" && (
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              this.setState((prevState) => {
                return { showPass: !prevState.showPass };
              });
            }}
            className={`show-pass-btn position-absolute ${
              this.state.showPass ? `showing` : ``
            }`}
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.60482 21.2031H8.30794C8.80271 21.2031 9.20378 21.6054 9.20378 22.1016C9.20378 22.5978 8.80271 23 8.30794 23H3.60482C1.62897 23 0.0214844 21.3878 0.0214844 19.4062V12.0391C0.0214844 10.0575 1.62897 8.44531 3.60482 8.44531H4.67816V5.27679C4.67816 2.36716 7.08939 0 10.0532 0C13.0169 0 15.4282 2.36716 15.4282 5.27679V8.44531H16.5048C18.4807 8.44531 20.0882 10.0575 20.0882 12.0391V12.9824C20.0882 13.4786 19.6871 13.8809 19.1923 13.8809C18.6975 13.8809 18.2965 13.4786 18.2965 12.9824V12.0391C18.2965 11.0483 17.4927 10.2422 16.5048 10.2422H3.60482C2.61689 10.2422 1.81315 11.0483 1.81315 12.0391V19.4062C1.81315 20.397 2.61689 21.2031 3.60482 21.2031ZM10.0532 1.79688C8.07731 1.79688 6.46983 3.35795 6.46983 5.27679V8.44531H13.6365V5.27679C13.6365 3.35795 12.029 1.79688 10.0532 1.79688Z"
                fill="black"
              />
              <path
                d="M8.39779 16.4414C8.89254 16.4414 9.29362 16.0392 9.29362 15.543C9.29362 15.0468 8.89254 14.6445 8.39779 14.6445C7.90303 14.6445 7.50195 15.0468 7.50195 15.543C7.50195 16.0392 7.90303 16.4414 8.39779 16.4414Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1082 17.8431C21.1753 17.9278 21.2834 18.0811 21.3149 18.1261C21.532 18.436 21.532 18.8492 21.3149 19.1591C21.2834 19.2041 21.1754 19.3573 21.1083 19.442C20.8083 19.8205 20.1059 20.7065 19.1287 21.4887C17.8758 22.4915 16.5864 23 15.2965 23C14.0065 23 12.7172 22.4915 11.4642 21.4886C10.4869 20.7064 9.78464 19.8204 9.4848 19.4421C9.41771 19.3576 9.30958 19.204 9.278 19.159C9.06094 18.8491 9.06099 18.4359 9.27809 18.126C9.30962 18.081 9.41775 17.9276 9.48507 17.8427C9.78464 17.4647 10.4869 16.5787 11.4643 15.7965C12.7172 14.7936 14.0065 14.2852 15.2965 14.2852C16.5864 14.2852 17.8758 14.7936 19.1287 15.7966C20.1057 16.5786 20.808 17.4644 21.108 17.8429L21.1082 17.8431ZM11.1469 18.6426C12.5713 20.3422 13.9659 21.2031 15.2965 21.2031C16.6271 21.2031 18.0217 20.3422 19.4461 18.6426C18.0217 16.9431 16.6269 16.082 15.2965 16.082C13.9659 16.082 12.5712 16.943 11.1469 18.6426Z"
                fill="black"
              />
              <path
                d="M5.03841 16.4414C5.53317 16.4414 5.93424 16.0392 5.93424 15.543C5.93424 15.0468 5.53317 14.6445 5.03841 14.6445C4.54366 14.6445 4.14258 15.0468 4.14258 15.543C4.14258 16.0392 4.54366 16.4414 5.03841 16.4414Z"
                fill="black"
              />
              <path
                d="M15.3408 20.3496C16.2808 20.3496 17.0428 19.5853 17.0428 18.6426C17.0428 17.6998 16.2808 16.9355 15.3408 16.9355C14.4007 16.9355 13.6387 17.6998 13.6387 18.6426C13.6387 19.5853 14.4007 20.3496 15.3408 20.3496Z"
                fill="black"
              />
            </svg>
          </a>
        )}
        {this.props.error && (
          <span className="error-message">{this.props.error}</span>
        )}
        {this.state.error && (
          <span className="error-message">{this.state.error}</span>
        )}
        {this.props.warning && (
          <span className="warning-message position-absolute text-center">
            {this.props.warning}
          </span>
        )}
        {this.props.processing && <BLLoader />}
        {`tooltip` in this.props && (
          <CSSTransition
            in={this.state.isTooltipOn}
            timeout={300}
            unmountOnExit
            classNames="input-tooltip"
          >
            <BLInfoArea
              type="info"
              className={`input-tooltip ${this.props.tooltip.position}`}
            >
              {this.props.tooltip.content}
            </BLInfoArea>
          </CSSTransition>
        )}
        {`showPassInfo` in this.props && (
          <BLPasswordRequirements
            isPasswordInfoOn={this.state.isPasswordInfoOn}
            value={this.props.value}
          />
        )}
      </div>
    );
  }
}

export default BLInput;
