import React, { Component, createRef } from "react";

import Search from "./Search";
import BannerFeatures from "./BannerFeatures";

export default class Banner extends Component {
  sliderVideoRef = createRef();

  componentDidMount = () => {
    if (this.sliderVideoRef.current) {
      this.sliderVideoRef.current.play();
    }
  };

  render() {
    return (
      <section className="banner position-relative">
        <div className="banner-slider position-absolute w-100 h-100">
          <div className="slider-item w-100 h-100 d-flex justify-content-center align-items-center">
            <video
              playsInline
              muted
              loop
              autoPlay={false}
              ref={this.sliderVideoRef}
              className="w-100 h-100"
            >
              <source
                src="https://cdn.bucketlist.com.tr/files/bucketlist_homepage.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content d-flex justify-content-start align-items-center flex-column">
                <h1 className="motto" data-lang={this.props.lang}>
                  {this.props.translate("home.banner.motto")}
                </h1>
                <h6 className="sub-title">
                  {this.props.translate("home.banner.sub_title")}
                </h6>
                <Search
                  translate={this.props.translate}
                  currency={this.props.currency}
                  lang={this.props.lang}
                />

                <BannerFeatures translate={this.props.translate} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
