import React from "react";
import PropTypes from "prop-types";
import BLBannerWTabs from "../../utils/components/BLBannerWTabs";
import { global } from "../../App/routes";

import logo from "../../assets/images/logo-white.svg";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";

const Banner = (props) => {
  const tabsKeys = Object.keys(global).filter(
    (key) => global[key].parent === "gift_card"
  );

  const tabs = tabsKeys.map((key) => {
    const name =
      key === "changeGiftCard" ? (
        <>
          <svg
            className="bi bi-arrow-repeat mr-2"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M2.854 7.146a.5.5 0 00-.708 0l-2 2a.5.5 0 10.708.708L2.5 8.207l1.646 1.647a.5.5 0 00.708-.708l-2-2zm13-1a.5.5 0 00-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 00-.708.708l2 2a.5.5 0 00.708 0l2-2a.5.5 0 000-.708z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M8 3a4.995 4.995 0 00-4.192 2.273.5.5 0 01-.837-.546A6 6 0 0114 8a.5.5 0 01-1.001 0 5 5 0 00-5-5zM2.5 7.5A.5.5 0 013 8a5 5 0 009.192 2.727.5.5 0 11.837.546A6 6 0 012 8a.5.5 0 01.501-.5z"
              clipRule="evenodd"
            />
          </svg>
          {props.translate(`gift_card.${global[key].name}`)}
        </>
      ) : (
        props.translate(`gift_card.${global[key].name}`)
      );
    return {
      id: global[key].id,
      links: global[key].links,
      name: name,
      order: global[key].order,
    };
  });
  return (
    <BLBannerWTabs tabs={tabs}>
      <BLBreadcrumbs
        breadcrumbs={[
          {
            id: 0,
            name: props.translate("global.homepage"),
            route: global.home.links[props.lang],
          },
          {
            id: 1,
            name: props.translate("gift_card.page_title"),
          },
        ]}
        color="white"
      />
      <div className="row">
        <div className="col d-flex justify-content-center">
          <div className="text-w-logo w-100 text-center">
            {props.translate("gift_card.banner_text", {
              logo: <img src={logo} alt="Bucketlist Logo" />,
            })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="sub-text w-100 text-center">
            {props.translate("gift_card.banner_subtext")}
          </div>
        </div>
      </div>
    </BLBannerWTabs>
  );
};

Banner.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Banner;
