import dayjs from "dayjs";
import { PACKAGE_TYPES } from "./db/packageTypes";

export const dataLayer__pageView = (page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > page_view.html
  window.dataLayer.push({
    event: "page_view", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: user.isLoggedIn ? `Evet` : `Hayır`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email || "", // Email adresi hashli olarak yazılmalıdır.
      id: user.id || "", // Kullanıcı ID'si yazılmalıdır.
      type: user.isLoggedIn ? "Üye" : `Ziyaretçi`, // Üye ya da Ziyaretçi olarak bilgi dönmelidir.
    },
  });
};

export const dataLayer__addToCart = (product) => {
  if (!(`dataLayer` in window)) return;
  // UA > ua-add-to-cart.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "experience_added", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      currencyCode: product.currency?.code,
      add: {
        products: {
          name: product.title, // Deneyimin adı yazılmalıdır.
          id: product.code, // Deneyimin ID'si yazılmalıdır.
          sku: product.code, // Deneyimin ID'si yazılmalıdır.
          price: product.unit?.discounted_price || product.unit?.price, // Kişi başı tutar yazılmalıdır.
          brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
          category:
            product.category && product.category.length
              ? `${product.category[0]?.name}/${product.category[1]?.name}`
              : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
          variant: product.unit?.name, // Deneyimdeki kişi sayısı yazılmalıdır.
          dimension7: product.package_type, // Satın alma seçenekleri yazılmalıdır.
          dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
          dimension9: product.preferred_date
            ? dayjs(product.preferred_date, "DD/MM/YYYY").format("YYYYMMDD")
            : "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
          dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
          dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
          dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
          dimension15: product.unit?.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          quantity: product.unit?.count, // Kaç kişinin alacağı bilgisi yazılmalıdır. Grup 3 ya da Grup 5 olsa dahi adet bilgisi yazılmalıdır.
        },
      },
    },
  });

  // GA4 > ga4-add_to_cart.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: {
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: product.unit?.discounted_price || product.unit?.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: product.unit?.name, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: `Deneyim Detay - . - ${product.title.replace(
          "| Bucketlist",
          ".trim()"
        )} - .`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: product.unit?.index, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: product.unit?.count,
      },
    },
  });
};

export const dataLayer__removeFromCart = (product) => {
  if (!(`dataLayer` in window)) return;
  // UA > ua-remove-from-cart.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "experience_removed", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      currencyCode: product.currency?.code,
      remove: {
        products: {
          name: product.title, // Deneyimin adı yazılmalıdır.
          id: product.code, // Deneyimin ID'si yazılmalıdır.
          sku: product.code, // Deneyimin ID'si yazılmalıdır.
          price: product.unit?.discounted_price || product.unit?.price, // Kişi başı tutar yazılmalıdır.
          brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
          category:
            product.category && product.category.length
              ? `${product.category[0].name}/${product.category[1].name}`
              : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
          variant: product.unit?.name, // Deneyimdeki kişi sayısı yazılmalıdır.
          dimension7: product.package_type, // Satın alma seçenekleri yazılmalıdır.
          dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
          dimension9: product.preferred_date
            ? dayjs(product.preferred_date, "DD/MM/YYYY").format("YYYYMMDD")
            : "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
          dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
          dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
          dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
          dimension15: product.unit?.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          quantity: product.unit?.count, // Kaç kişinin alacağı bilgisi yazılmalıdır. Grup 3 ya da Grup 5 olsa dahi adet bilgisi yazılmalıdır.
        },
      },
    },
  });

  // GA4 > ga4-remove_from_cart.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      items: {
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: product.unit?.discounted_price || product.unit?.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: product.unit?.name, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: `Deneyim Detay - . - ${product.title.replace(
          "| Bucketlist",
          ".trim()"
        )} - .`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: product.unit?.index, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: product.unit?.count,
      },
    },
  });
};

export const dataLayer__itemView = (product, page, user) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-view_item.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      items: [
        {
          item_name: product.title, // Deneyimin adı yazılmalıdır.
          item_id: product.code, // Deneyimin ID"si yazılmalıdır.
          price:
            product.price_options && product.price_options.length
              ? product.price_options[0].discounted_price ||
                product.price_options[0].price
              : ``, // Kişi başı tutar yazılmalıdır.
          item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
          item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
          item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
          item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          item_variant:
            product.price_options && product.price_options.length
              ? product.price_options[0].label
              : ``, // Deneyimdeki kişi sayısı yazılmalıdır.
          item_list_name: `Deneyim Detay - . - ${product.title?.replace(
            "| Bucketlist",
            ".trim()"
          )} - .`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
          index: 1, // Listelemedeki pozisyonu yazılmalıdır.
          quantity: 1,
        },
      ],
    },
  });

  // UA > ua-experience-detail.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "experience_detail", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      detail: {
        products: [
          {
            name: product.title, // Deneyimin adı yazılmalıdır.
            id: product.code, // Deneyimin ID'si yazılmalıdır.
            sku: product.code, // Deneyimin ID'si yazılmalıdır.
            price:
              product.price_options && product.price_options.length
                ? product.price_options[0].discounted_price ||
                  product.price_options[0].price
                : ``, // Kişi başı tutar yazılmalıdır.
            brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
            category:
              product.category && product.category.length
                ? `${product.category[0].name}/${product.category[1].name}`
                : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
            variant:
              product.price_options && product.price_options.length
                ? product.price_options[0].label
                : ``, // Deneyimdeki kişi sayısı yazılmalıdır.
            dimension7: "", // Satın alma seçenekleri yazılmalıdır.
            dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
            dimension9: "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
            dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
            dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
            dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
            dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
            dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
            dimension15: product.price_options?.length
              ? product.price_options[0].price
              : 0, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          },
        ],
      },
    },
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: user.isLoggedIn ? `Evet` : `Hayır`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email || "", // Email adresi hashli olarak yazılmalıdır.
      id: user.id || "", // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Üye ya da Ziyaretçi olarak bilgi dönmelidir.
      buyer: user.has_bought_before ? `Evet` : `Hayır`,
    },
  });
};

export const dataLayer__cartView = (product) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-view_cart.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      items: product.units?.map((unit) => ({
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: "Sepet - . - Alışveriş Özetim - .", // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: 1, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: unit.quantity,
      })),
    },
  });

  // UA > ua-checkout-ozet.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "checkout", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
        products: product.units?.map((unit) => ({
          name: product.title, // Deneyimin adı yazılmalıdır.
          id: product.code, // Deneyimin ID'si yazılmalıdır.
          sku: product.code, // Deneyimin ID'si yazılmalıdır.
          price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
          brand: product.provider?.name, // Hizmet sağlayıcının ID'si yazılmalıdır.
          category:
            product.category && product.category.length
              ? `${product.category[0].name}/${product.category[1].name}`
              : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
          variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
          dimension7: product.package_type, // Satın alma seçenekleri yazılmalıdır.
          dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
          dimension9:
            product.package_type === 1
              ? dayjs(product.preferred_date, "DD/MM/YYYY").format("YYYYMMDD")
              : "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
          dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
          dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
          dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
          dimension15: unit.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          quantity: unit.quantity, // Kaç kişinin alacağı bilgisi yazılmalıdır. Grup 3 ya da Grup 5 olsa dahi adet bilgisi yazılmalıdır.
        })),
      },
    },
  });
};

export const dataLayer__checkout = (product) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-begin_checkout.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      items: product.units?.map((unit) => ({
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: "Sepet - . - Ödeme - .", // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: 1, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: unit.quantity,
      })),
    },
  });

  // UA > ua-checkout-odeme.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "checkout", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      checkout: {
        actionField: {
          step: 2,
        },
        products: product.units?.map((unit) => ({
          name: product.title, // Deneyimin adı yazılmalıdır.
          id: product.code, // Deneyimin ID'si yazılmalıdır.
          sku: product.code, // Deneyimin ID'si yazılmalıdır.
          price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
          brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
          category:
            product.category && product.category.length
              ? `${product.category[0].name}/${product.category[1].name}`
              : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
          variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
          dimension7: product.package_type, // Satın alma seçenekleri yazılmalıdır.
          dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
          dimension9:
            product.package_type === PACKAGE_TYPES.PICKADATE
              ? dayjs(product.preferred_date, "DD/MM/YYYY").format("YYYYMMDD")
              : "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
          dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
          dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
          dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
          dimension15: unit.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          quantity: unit.quantity, // Kaç kişinin alacağı bilgisi yazılmalıdır. Grup 3 ya da Grup 5 olsa dahi adet bilgisi yazılmalıdır.
        })),
      },
    },
  });
};

export const dataLayer__purchaseCompleted = (product, checkout, page, user) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-purchase.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: checkout.id,
      affiliation: "Bucketlist",
      value: checkout.amount,
      tax: checkout.tax, // Vergi tutarı yazılabiliyorsa yazılsın
      coupon: checkout.gift_card, // Kupon kodu kullanılmışsa yazılmalı
      shipping: 0,
      currency: checkout.currency,
      items: product.units?.map((unit) => ({
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: "Sepet - . - Teşekkürler - .", // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: 1, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: unit.quantity,
      })),
    },
  });

  // UA > ua-transaction-completed.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "transaction_completed", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      purchase: {
        actionField: {
          id: checkout.id, // Backoffice'e giden transaction ID değeri yazılmalı
          affiliation: "Bucketlist", // Sabit değer yazılmalı
          revenue: checkout.amount, // Total transaction value (incl. tax and shipping)
          tax: checkout.tax, // Vergi tutarı yazılabiliyorsa yazılsın
          coupon: checkout.gift_card, // Kupon kodu kullanılmışsa yazılmalı
        },
        products: product.units?.map((unit) => ({
          name: product.title, // Deneyimin adı yazılmalıdır.
          id: product.code, // Deneyimin ID'si yazılmalıdır.
          sku: product.code, // Deneyimin ID'si yazılmalıdır.
          price: unit.discounted_price || unit.price, // Kişi başı tutar yazılmalıdır.
          brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
          category:
            product.category && product.category.length
              ? `${product.category[0].name}/${product.category[1].name}`
              : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
          variant: unit.name, // Deneyimdeki kişi sayısı yazılmalıdır.
          dimension7: product.package_type, // Satın alma seçenekleri yazılmalıdır.
          dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
          dimension9:
            product.package_type === PACKAGE_TYPES.PICKADATE
              ? dayjs(product.preferred_date, "DD/MM/YYYY").format("YYYYMMDD")
              : "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
          dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
          dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
          dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
          dimension15: unit.name, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
          quantity: 1, // Kaç kişinin alacağı bilgisi yazılmalıdır. Grup 3 ya da Grup 5 olsa dahi adet bilgisi yazılmalıdır.
        })),
      },
    },
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: user.isLoggedIn ? `Evet` : `Hayır`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email || "", // Email adresi hashli olarak yazılmalıdır.
      id: user.id || "", // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Üye ya da Ziyaretçi olarak bilgi dönmelidir.
      buyer: user.has_bought_before ? `Evet` : `Hayır`,
    },
  });
};

export const dataLayer__itemListView = (products, listInfo, currencyCode) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-view_item_list.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      items: products.map((product) => ({
        item_name: product.title, // Deneyimin adı yazılmalıdır.
        item_id: product.code, // Deneyimin ID"si yazılmalıdır.
        price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
        item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
        item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
        item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
        item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        item_variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
        item_list_name: `${listInfo.pageType} - ${
          listInfo.listType
        } - ${listInfo.pageName.replace("| Bucketlist", "").trim()} - ${
          listInfo.sortType
        }`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        index: product.index, // Listelemedeki pozisyonu yazılmalıdır.
        quantity: 1,
      })),
    },
  });

  // UA > ua-experience-impression.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "experience_impression", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      currencyCode,
      impressions: products.map((product) => ({
        name: product.title, // Deneyimin adı yazılmalıdır.
        id: product.code, // Deneyimin ID'si yazılmalıdır.
        sku: product.code, // Deneyimin ID'si yazılmalıdır.
        price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
        brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
        category:
          product.category && product.category.length
            ? `${product.category[0].name}/${product.category[1].name}`
            : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
        variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
        dimension7: "", // Satın alma seçenekleri yazılmalıdır.
        dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
        dimension9: "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
        dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
        dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
        dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
        dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
        dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
        dimension15: product.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
        list: `${listInfo.pageType} - ${listInfo.listType} - ${listInfo.pageName
          .replace("| Bucketlist", "")
          .trim()} - ${listInfo.sortType}`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        position: product.index,
      })),
    },
  });
};

export const dataLayer__itemListClick = (product, listInfo) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-select_item.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_name: product.title, // Deneyimin adı yazılmalıdır.
          item_id: product.code, // Deneyimin ID"si yazılmalıdır.
          price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
          item_brand: product.provider?.id, // Hizmet sağlayıcının ID"si yazılmalıdır.
          item_category: product.category ? product.category[0].name : ``, // Deneyimin ana kategorisi yazılmalıdır.
          item_category2: product.category ? product.category[1].name : ``, // Deneyimin alt kategorisi yazılmalıdır
          item_category3: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
          item_category4: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
          item_variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
          item_list_name: `${listInfo.pageType} - ${
            listInfo.listType
          } - ${listInfo.pageName.replace("| Bucketlist", "").trim()} - ${
            listInfo.sortType
          }`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
          index: product.index, // Listelemedeki pozisyonu yazılmalıdır.
          quantity: 1,
        },
      ],
    },
  });

  // UA > ua-experience-click.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "experience_click", // Sabit olarak bu değer yazılmalıdır.
    ecommerce: {
      click: {
        actionField: {
          list: `${listInfo.pageType} - ${
            listInfo.listType
          } - ${listInfo.pageName.replace("| Bucketlist", "").trim()} - ${
            listInfo.sortType
          }`, // {{Page Type}} - {{Listeleme Tipi}} - {{Page Name}} - {{Sıralama Tipi}}
        },
        products: [
          {
            name: product.title, // Deneyimin adı yazılmalıdır.
            id: product.code, // Deneyimin ID'si yazılmalıdır.
            sku: product.code, // Deneyimin ID'si yazılmalıdır.
            price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
            brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
            category:
              product.category && product.category.length
                ? `${product.category[0].name}/${product.category[1].name}`
                : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
            variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
            dimension7: "", // Satın alma seçenekleri yazılmalıdır.
            dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
            dimension9: "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
            dimension10: product.video_url ? `Evet` : `Hayır`, // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
            dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
            dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
            dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
            dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
            dimension15: product.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
            position: product.index,
          },
        ],
      },
    },
  });
};

export const dataLayer__promotionView = (items, promotion) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-view_promotion.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "view_promotion",
    ecommerce: {
      items: items.map((item) => ({
        promotion_id: promotion.id,
        promotion_name: promotion.name,
        creative_name: item.title,
        creative_slot: item.index,
        location_id: promotion.id,
      })),
    },
  });

  // UA > ua-promotion-view.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "promo_view",
    ecommerce: {
      promoView: {
        promotions: items.map((item) => ({
          id: promotion.id,
          name: promotion.name,
          creative: item.title,
          position: item.index,
        })),
      },
    },
  });
};

export const dataLayer__promotionClick = (item, promotion) => {
  if (!(`dataLayer` in window)) return;
  // GA4 > ga4-select_promotion.html
  window.dataLayer.push({
    ecommerce: null,
  });
  window.dataLayer.push({
    event: "select_promotion",
    ecommerce: {
      items: [
        {
          promotion_id: promotion.id,
          promotion_name: promotion.name,
          creative_name: item.title,
          creative_slot: item.index,
          location_id: promotion.id,
        },
      ],
    },
  });

  // UA > ua-promotion-click.html
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "promo_click",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: promotion.id,
            name: promotion.name,
            creative: item.title,
            position: item.index,
          },
        ],
      },
    },
  });
};

export const dataLayer__addToBucketlist = (product, page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > add_to_wishlist.html
  window.dataLayer.push({
    event: "add_to_wishlist", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sayta tipi ne ise o değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    experience: {
      name: product.title, // Deneyimin adı yazılmalıdır.
      id: product.code, // Deneyimin ID'si yazılmalıdır.
      sku: product.code, // Deneyimin ID'si yazılmalıdır.
      price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
      brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
      category:
        product.category && product.category.length
          ? `${product.category[0].name}/${product.category[1].name}`
          : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
      variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
      dimension7: "", // Satın alma seçenekleri yazılmalıdır.
      dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
      dimension9: "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
      dimension10: product.video_url ? "Evet" : "Hayır", // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
      dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
      dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
      dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
      dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
      dimension15: product.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
    },
    user: {
      login: `Evet`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email, // Email adresi hashli olarak yazılmalıdır.
      id: user.id, // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Kullanıcının cinsiyeti yazılmalıdır
      buyer: user.has_bought_before ? `Evet` : `Hayır`, // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__removeFromBucketlist = (product, page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > remove_from_wishlist.html
  window.dataLayer.push({
    event: "remove_from_wishlist", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sayta tipi ne ise o değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    experience: {
      name: product.title, // Deneyimin adı yazılmalıdır.
      id: product.code, // Deneyimin ID'si yazılmalıdır.
      sku: product.code, // Deneyimin ID'si yazılmalıdır.
      price: product.discounted_price || product.price, // Kişi başı tutar yazılmalıdır.
      brand: product.provider?.id, // Hizmet sağlayıcının ID'si yazılmalıdır.
      category:
        product.category && product.category.length
          ? `${product.category[0].name}/${product.category[1].name}`
          : ``, // Deneyimin kategori hiyerarşisi yazılmalıdır.
      variant: product.participant_count, // Deneyimdeki kişi sayısı yazılmalıdır.
      dimension7: "", // Satın alma seçenekleri yazılmalıdır.
      dimension8: product.stats?.review, // Deneyime yapılan yorum sayısı yazılmalıdır.
      dimension9: "", // Deneyimin seçili tarihi yazılmalıdır. Bu adım deneyim sepete eklendiğinde ve satın alma anında çalışabilir.
      dimension10: product.video_url ? "Evet" : "Hayır", // Deneyim açıklamalarında video olup olmadığı yazılmalıdır.
      dimension11: product.location?.city_name, // Deneyimin olduğu il yazılmalıdır.
      dimension12: product.location?.town_name, // Deneyimin olduğu ilçe yazılmalıdır.
      dimension13: product.provider?.name, // Deneyimin hizmet sağlayıcısı yazılmalıdır.
      dimension14: product.stats?.rate, // Deneyimin ratingi yazılmalıdır.
      dimension15: product.price, // Deneyimin indirimsiz, ilk fiyatı yazılmalıdır.
    },
    user: {
      login: `Evet`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email, // Email adresi hashli olarak yazılmalıdır.
      id: user.id, // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Kullanıcının cinsiyeti yazılmalıdır
      buyer: user.has_bought_before ? `Evet` : `Hayır`, // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__login = (page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > login.html
  window.dataLayer.push({
    event: "login", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: "Evet", // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email, // Email adresi hashli olarak yazılmalıdır.
      id: user.id, // Kullanıcı ID'si yazılmalıdır.
      type: "Üye", // Üye ya da Ziyaretçi olarak bilgi dönmelidir.
    },
  });
};

export const dataLayer__signUp = (page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > sign_up.html
  window.dataLayer.push({
    event: "sign_up", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: "Evet", // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email, // Email adresi hashli olarak yazılmalıdır.
      id: user.id, // Kullanıcı ID'si yazılmalıdır.
      type: "Üye", // Üye ya da Ziyaretçi olarak bilgi dönmelidir.
    },
  });
};

export const dataLayer__filtered = (filter, page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > filtered.html
  window.dataLayer.push({
    event: "filtered", // Sabit olarak bu değer yazılmalıdır.
    filter: {
      type: filter.type,
      value: filter.value,
    },
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: user.isLoggedIn ? `Evet` : `Hayır`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email || "", // Email adresi hashli olarak yazılmalıdır.
      id: user.id || "", // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Kullanıcının cinsiyeti yazılmalıdır
      buyer: user.has_bought_before ? "Evet" : "Hayır", // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__unfiltered = (filter, page, user) => {
  if (!(`dataLayer` in window)) return;
  // Genel > unfiltered.html
  window.dataLayer.push({
    event: "unfiltered", // Sabit olarak bu değer yazılmalıdır.
    filter: {
      type: filter.type,
      value: filter.value,
    },
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sabit olarak bu değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: user.isLoggedIn ? `Evet` : `Hayır`, // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: user.hashed_email || "", // Email adresi hashli olarak yazılmalıdır.
      id: user.id || "", // Kullanıcı ID'si yazılmalıdır.
      gender: user.gender, // Kullanıcının cinsiyeti yazılmalıdır
      buyer: user.has_bought_before ? "Evet" : "Hayır", // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__forgotPassword = (page) => {
  if (!(`dataLayer` in window)) return;
  // Genel > forgot_password.html
  window.dataLayer.push({
    event: "forgot_password", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sayfa tipine göre değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: "Hayır", // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: "", // Email adresi hashli olarak yazılmalıdır.
      id: "", // Kullanıcı ID'si yazılmalıdır.
      gender: "", // Kullanıcının cinsiyeti yazılmalıdır
      buyer: "Hayır", // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__passwordSent = (page) => {
  if (!(`dataLayer` in window)) return;
  // Genel > password_sent.html
  window.dataLayer.push({
    event: "password_sent", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sayfa tipine göre değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: "Hayır", // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: "", // Email adresi hashli olarak yazılmalıdır.
      id: "", // Kullanıcı ID'si yazılmalıdır.
      gender: "", // Kullanıcının cinsiyeti yazılmalıdır
      buyer: "Hayır", // Daha önce satın alma yapmış mı?
    },
  });
};

export const dataLayer__passwordUpdated = (page) => {
  if (!(`dataLayer` in window)) return;
  // Genel > password_updated.html
  window.dataLayer.push({
    event: "password_updated", // Sabit olarak bu değer yazılmalıdır.
    page: {
      name: page.title.replace("| Bucketlist", "").trim(), // Sayfa başlığı yazılmalıdır. H1 tercih edilmelidir.
      type: page.type, // Sayfa tipine göre değer yazılmalıdır.
      language: page.lang, // Seçili dil yazılmalıdır.
      currency: page.currency, // Seçili para birimi yazılmalıdır.
    },
    user: {
      login: "Hayır", // Giriş yapıp yapmadığı bilgisi Evet/Hayır olarak gelmelidir.
      email: "", // Email adresi hashli olarak yazılmalıdır.
      id: "", // Kullanıcı ID'si yazılmalıdır.
      gender: "", // Kullanıcının cinsiyeti yazılmalıdır
      buyer: "Hayır", // Daha önce satın alma yapmış mı?
    },
  });
};
