import React from "react";
import { BLRadio } from "../../utils/components/BLRadio";
import { useState } from "react";
import BLButton from "../../utils/components/BLButton";

const MobileSort = props => {
  const [sort, setSort] = useState(props.selectedSort);
  return (
    <div className="mobile-filter-holder sort">
      <div className="filters-holder d-flex flex-column">
        <div className="filter d-flex flex-column active">
          <div className="filter-list d-flex flex-column">
            <div className="filter-item">
              <BLRadio
                label={props.translate("category.top_selling")}
                name="sort"
                value="hot"
                checked={sort === "hot"}
                changed={() => setSort("hot")}
              />
            </div>
            <div className="filter-item">
              <BLRadio
                label={props.translate("category.recently_added")}
                name="sort"
                value="newest"
                checked={sort === "newest"}
                changed={() => setSort("newest")}
              />
            </div>
            <div className="filter-item">
              <BLRadio
                label={props.translate("category.lowest_price")}
                name="sort"
                value="lowest"
                checked={sort === "lowest"}
                changed={() => setSort("lowest")}
              />
            </div>
            <div className="filter-item">
              <BLRadio
                label={props.translate("category.highest_price")}
                name="sort"
                value="highest"
                checked={sort === "highest"}
                changed={() => setSort("highest")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="filter-button d-flex flex-column">
        <BLButton
          type="sec"
          clicked={e =>
            props.handleApplyFilter(e, [{ type: "sort", value: sort }])
          }
        >
          {props.translate("global.apply")}
        </BLButton>
        <BLButton
          type="empty"
          clicked={() => props.handleCloseMobileFilter("sort")}
        >
          {props.translate("global.cancel")}
        </BLButton>
      </div>
    </div>
  );
};

export default MobileSort;
