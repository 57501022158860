import React, { Component } from "react";

import Profile from "./Profile";
import SingleOrder from "./components/SingleOrder";
import ContentTabs from "./components/ContentTabs";
import API, { headers } from "../utils/API";
import { handleErrors } from "../utils/helper";
import BLInfoArea from "../utils/components/BLInfoArea";
import Helmet from "react-helmet";
import BLLoader from "../utils/components/BLLoader";
import RefundedSingleOrder from "./components/RefundedSingleOrder";
import { CSSTransition } from "react-transition-group";
import BLButton from "../utils/components/BLButton";

export default class Orders extends Component {
  state = {
    selectedTab: 0,
    selectedOrders: [],
    orders: [],
    refundedOrders: [],
    isLoading: true,
    ordersFetched: false,
    refundedOrdersFetched: false,
    ordersPage: 1,
    refundedOrdersPage: 1,
    isLoadingMore: false,
    hasMoreOrders: false,
    hasMoreRefundedOrders: false,
  };

  limit = 10;

  componentDidMount = () => {
    setTimeout(() => this.handleGetOrders(), 10);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.langPref !== this.props.user.langPref) {
      this.setState(
        {
          orders: [],
          refundedOrders: [],
          ordersFetched: false,
          refundedOrdersFetched: false,
          ordersPage: 1,
          refundedOrdersPage: 1,
          isLoading: true,
        },
        this.handleGetOrders
      );
    }
  };

  handleSelectTab = (selectedTab) =>
    this.setState({ isLoading: true, selectedTab }, () =>
      this.handleGetOrders()
    );

  filterOrders = () => {
    const selectedOrders =
      this.state.selectedTab === 0
        ? this.state.orders
        : this.state.selectedTab === 1
        ? this.state.refundedOrders
        : [];

    this.setState({ selectedOrders }, () =>
      this.setState({ isLoading: false })
    );
  };

  handleGetOrders = () => {
    if (this.state.selectedTab === 0 && !this.state.ordersFetched) {
      API.get("account/orders?limit=" + this.limit, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data, meta } = response.data;
          const { current_page, last_page } = meta;
          const hasMoreOrders = current_page < last_page;

          this.setState({ orders: data, hasMoreOrders }, () => {
            this.setState({ ordersFetched: true });
            this.filterOrders();
          });
        })
        .catch((err) => handleErrors(err));
    } else {
      this.filterOrders();
    }

    if (this.state.selectedTab === 1 && !this.state.refundedOrdersFetched)
      API.get("account/refunds?limit=" + this.limit, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data, meta } = response.data;
          const { current_page, last_page } = meta;
          const hasMoreRefundedOrders = current_page < last_page;

          this.setState({ refundedOrders: data, hasMoreRefundedOrders }, () => {
            this.setState({ refundedOrdersFetched: true });
            this.filterOrders();
          });
        })
        .catch((err) => handleErrors(err));
    else this.filterOrders();
  };

  handleLoadMore = () => {
    this.setState({ isLoadingMore: true }, () => {
      if (this.state.selectedTab === 0) {
        API.get(
          `account/orders?limit=${this.limit}&page=${
            this.state.ordersPage + 1
          }`,
          {
            headers: {
              ...headers,
              Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
              "Accept-Language": this.props.lang,
            },
          }
        )
          .then(({ data: res }) => {
            const { data, meta } = res;
            const { current_page: ordersPage, last_page } = meta;
            const hasMoreOrders = ordersPage < last_page;

            const orders = [...this.state.orders, ...data];

            this.setState(
              {
                orders,
                hasMoreOrders,
                ordersPage,
                isLoadingMore: false,
              },
              this.filterOrders
            );
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({ isLoadingMore: false });
          });
      } else if (this.state.selectedTab === 1) {
        API.get(
          `account/orders?limit=${this.limit}&page=${
            this.state.refundedOrdersPage + 1
          }`,
          {
            headers: {
              ...headers,
              Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
              "Accept-Language": this.props.lang,
            },
          }
        )
          .then(({ data: res }) => {
            const { data, meta } = res;
            const { current_page: refundedOrdersPage, last_page } = meta;
            const hasMoreRefundedOrders = refundedOrdersPage < last_page;

            const refundedOrders = [...this.state.refundedOrders, ...data];

            this.setState(
              {
                refundedOrders,
                hasMoreRefundedOrders,
                refundedOrdersPage,
                isLoadingMore: false,
              },
              this.filterOrders
            );
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({ isLoadingMore: false });
          });
      }
    });
  };

  render() {
    return (
      <Profile>
        <Helmet>
          <title>{this.props.translate("profile.orders")}</title>
        </Helmet>
        <div className="page-content orders">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="page-holder w-100">
                  <div className="row">
                    <div className="col">
                      <h4 className="page-title">
                        {this.props.translate("profile.orders_page.title")}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <section className="page-sec">
                        {this.state.isLoading ? (
                          <div className="mt-3">
                            <BLLoader />
                          </div>
                        ) : !this.state.selectedOrders.length &&
                          this.state.selectedTab === 0 &&
                          this.state.ordersFetched ? (
                          <BLInfoArea type="info">
                            {this.props.translate(
                              "profile.orders_page.empty_text"
                            )}
                          </BLInfoArea>
                        ) : (
                          <div className="products-holder">
                            <ContentTabs
                              setSelectedTab={this.handleSelectTab}
                              selectedTab={this.state.selectedTab}
                              translate={this.props.translate}
                              tabs={[
                                this.props.translate("global.all"),

                                this.props.translate(
                                  "profile.orders_page.refunds"
                                ),
                              ]}
                            />

                            <div className="orders-holder w-100">
                              <div className="header w-100">
                                <div className="title">
                                  {this.props.translate(
                                    "profile.orders_page.order_num"
                                  )}
                                </div>
                                <div className="title">
                                  {this.props.translate(
                                    this.state.selectedTab === 1
                                      ? "profile.orders_page.refund_date"
                                      : "profile.orders_page.order_date"
                                  )}
                                </div>
                                <div className="title">
                                  {this.props.translate(
                                    this.state.selectedTab === 1
                                      ? "profile.orders_page.refund_amount"
                                      : "profile.orders_page.order_amount"
                                  )}
                                </div>
                                <div className="title">
                                  {this.props.translate(
                                    "profile.orders_page.payment_status"
                                  )}
                                </div>
                              </div>
                              <div className="orders w-100 d-flex flex-column">
                                <CSSTransition
                                  in={
                                    this.state.isLoading &&
                                    ((this.state.selectedTab === 0 &&
                                      !this.state.ordersFetched) ||
                                      (this.state.selectedTab === 1 &&
                                        !this.state.refundedOrdersFetched))
                                  }
                                  timeout={0}
                                  unmountOnExit
                                >
                                  <div className="mt-3">
                                    <BLLoader />
                                  </div>
                                </CSSTransition>

                                <CSSTransition
                                  in={
                                    this.state.selectedTab === 0 &&
                                    this.state.ordersFetched
                                  }
                                  timeout={0}
                                  unmountOnExit
                                >
                                  <>
                                    {this.state.selectedOrders.map((data) => (
                                      <SingleOrder
                                        key={data.order_no}
                                        translate={this.props.translate}
                                        id={data.order_no}
                                        totalPrice={data.total}
                                        date={data.created_at}
                                        subtotal={data.amount}
                                        paymentStatus={data.payment_status}
                                        discount={data.is_discounted}
                                        discount_amount={data.discounted_amount}
                                        promo_amount={data.promotion_discount}
                                        gift_amount={data.gift_card_discount}
                                        paymentMethod={data.payment_type}
                                        orderStatus={data.status}
                                        products={data.products}
                                        currency={data.currency}
                                        installment={data.installment}
                                        interest_amount={data.late_interest}
                                        lang={this.props.lang}
                                      />
                                    ))}
                                    {this.state.hasMoreOrders && (
                                      <BLButton
                                        type="pri"
                                        classes="px-4 mt-5 mx-auto"
                                        clicked={this.handleLoadMore}
                                        disabled={this.state.isLoadingMore}
                                      >
                                        {this.props.translate(
                                          "global.load_more"
                                        )}
                                      </BLButton>
                                    )}
                                  </>
                                </CSSTransition>

                                <CSSTransition
                                  in={
                                    this.state.selectedTab === 1 &&
                                    this.state.refundedOrdersFetched
                                  }
                                  timeout={0}
                                  unmountOnExit
                                >
                                  <>
                                    {this.state.selectedOrders.length ? (
                                      this.state.selectedOrders.map((data) => (
                                        <RefundedSingleOrder
                                          key={data.order_no}
                                          translate={this.props.translate}
                                          id={data.order_no}
                                          totalPrice={data.total}
                                          date={data.created_at}
                                          paymentStatus={data.payment_status}
                                          discount={data.is_discounted}
                                          discount_amount={
                                            data.discounted_amount
                                          }
                                          product={{
                                            title: data.title,
                                            slug: data.slug,
                                            code: data.code,
                                            category: data.category,
                                            cover_image_url:
                                              data.cover_image_url,
                                            stats: data.stats,
                                            type: "product",
                                          }}
                                          paymentMethod={data.payment_type}
                                          currency={data.currency}
                                          lang={this.props.lang}
                                          status={data.status}
                                          refundDate={data.refunded_at}
                                          refundCode={data.refund_code}
                                          refundDescription={
                                            data.refund_description
                                          }
                                        />
                                      ))
                                    ) : (
                                      <BLInfoArea type="info">
                                        {this.props.translate(
                                          "profile.orders_page.empty_refund_text"
                                        )}
                                      </BLInfoArea>
                                    )}
                                    {this.state.hasMoreRefundedOrders && (
                                      <BLButton
                                        type="pri"
                                        classes="px-4 mt-5 mx-auto"
                                        clicked={this.handleLoadMore}
                                        disabled={this.state.isLoadingMore}
                                      >
                                        {this.props.translate(
                                          "global.load_more"
                                        )}
                                      </BLButton>
                                    )}
                                  </>
                                </CSSTransition>
                              </div>
                            </div>
                          </div>
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Profile>
    );
  }
}
