import React from "react";
import AboutUsTemplate from "../AboutUsTemplate";

export const Career = () => {
  return (
    <AboutUsTemplate>
      <div>
        <h6>Career</h6>
      </div>
    </AboutUsTemplate>
  );
};
