import React from "react";

const InPressContent = (props) => {
  return (
    <div className="media-page-content in-press">
      <div className="row">
        <div className="col-12">
          <h3 className="content-title">
            {props.translate("media.in_press_page.title")}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-12 offset-sm-2 offset-md-0">
          <div className="posts-holder">
            {Array.from({ length: 5 }, (_, i) => (
              <div key={i} className="post-holder d-flex">
                <div className="row align-items-start">
                  <div className="col-12 col-xl-5 d-flex justify-content-center">
                    <img
                      src="//via.placeholder.com/720x480?text=360x230"
                      alt="Press Cover"
                      className="mw-100"
                    />
                  </div>
                  <div className="col-12 col-xl-7 d-flex flex-column justify-content-between content-col">
                    <div className="content-holder w-100">
                      <h3 className="title">Lorem ipsum dolor sit amet</h3>
                      <p className="summary">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Voluptates beatae quidem earum.
                      </p>
                    </div>
                    <div className="info-holder w-100 d-flex justify-content-between align-items-center">
                      <div className="date">10 Ocak 2021</div>
                      <a href="#!" className="read-more-link">
                        {props.translate("global.read_more")} {">"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InPressContent;
