import { useEffect, useRef, useState } from "react";
import duvarTirmanisi from "../assets/images/4-duvar-tirmanisi.jpg";
import tupluDalis from "../assets/images/7-tuplu-dalis.jpg";
import rafting from "../assets/images/ikizdere-nehri-nafting-arkadaslar.jpg";
import kamp from "../assets/images/kamp.jpg";
import kapadokyaTurkiye from "../assets/images/kapadokya-turkiye.jpg";
import yamacParasutu from "../assets/images/yamac-parasutu-paragliding.jpg";

export const slidesArr = [
  { id: 0, url: duvarTirmanisi },
  { id: 1, url: tupluDalis },
  { id: 2, url: rafting },
  { id: 3, url: kamp },
  { id: 4, url: kapadokyaTurkiye },
  { id: 5, url: yamacParasutu },
];

const Slider = () => {
  const [slides] = useState(slidesArr);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderTimeout = useRef(0);

  useEffect(() => {
    if (slides?.length) {
      stopSlider();
      sliderTimeout.current = setTimeout(() => {
        setActiveIndex((prevState) =>
          prevState === slides.length - 1 ? 0 : prevState + 1
        );
      }, 6000);
    }

    return () => stopSlider();
  }, [activeIndex, slides.length]);

  const stopSlider = () => {
    if (sliderTimeout?.current) clearInterval(sliderTimeout.current);
  };

  const controlSlider = (e, index) => {
    if (e) e.preventDefault();

    if (parseInt(index) !== activeIndex) {
      setActiveIndex(parseInt(index));
    }
  };

  return (
    <>
      <div className="slider-holder position-absolute w-100 h-100">
        {slides &&
          slides.length &&
          slides.map((data, index) => (
            <div
              className={`slide w-100 h-100 d-flex justify-content-start align-items-end overflow-hidden position-absolute ${
                index === activeIndex ? `active` : ``
              }`}
              key={data.id}
              data-index={index}
              style={{
                background: `url("${data.url}") no-repeat center center`,
                backgroundSize: "cover",
              }}
            >
              <img
                src={data.url}
                alt={`Slide ${index}`}
                className="w-100 h-100 position-relative"
              />
              <div className="overlay position-absolute w-100 h-100"></div>
            </div>
          ))}
      </div>
      <div className="slider-controls position-absolute d-flex align-items-center">
        {slides &&
          slides.length &&
          slides.map((data, index) => (
            <a
              key={data.id}
              href="#!"
              onClick={(e) => controlSlider(e, index)}
              className={`control position-relative mx-2 py-2 ${
                activeIndex === index ? `active` : ``
              } ${activeIndex > index ? `filled` : ``}`}
            >
              &nbsp;
            </a>
          ))}
      </div>
    </>
  );
};

export default Slider;
