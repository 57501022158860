import React from "react";
import PropTypes from "prop-types";

import img1 from "../../assets/images/corporate-voucher/oduller.jpg";
import img2 from "../../assets/images/corporate-voucher/ekip-etkinlikleri.jpg";
import img3 from "../../assets/images/corporate-voucher/kutlamalar.jpg";

const Bridge = (props) => {
  const bridgeContent = [
    {
      id: 0,
      cover: img1,
      title: props.translate("corporate_voucher.bridge.sec_1_title"),
      content: props.translate("corporate_voucher.bridge.sec_1_content"),
    },
    {
      id: 1,
      cover: img2,
      title: props.translate("corporate_voucher.bridge.sec_2_title"),
      content: props.translate("corporate_voucher.bridge.sec_2_content"),
    },
    {
      id: 2,
      cover: img3,
      title: props.translate("corporate_voucher.bridge.sec_3_title"),
      content: props.translate("corporate_voucher.bridge.sec_3_content"),
    },
  ];
  return (
    <section className="bridge">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-start">
          {bridgeContent.map((data) => (
            <div key={data.id} className="col-12 col-md-4 mb-5 mb-md-0">
              <div className="cover d-flex justify-content-center align-items-center overflow-hidden">
                <img
                  src={data.cover}
                  alt={props.translate("global.content_cover")}
                />
              </div>
              <h4 className="title text-uppercase">{data.title}</h4>
              <p className="content">{data.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

Bridge.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Bridge;
