import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";
import PopularActivities from "../../Home/components/PopularActivities/PopularActivities";
import TrendLocations from "../../Home/components/TrendLocations";
import API, { headers } from "../../utils/API";
import PrerenderRedirect from "../../utils/components/PrerenderRedirect";
import {
  dataLayer__promotionClick,
  dataLayer__promotionView,
} from "../../utils/DataLayerEvents";
import { checkIfOnScreen, handleErrors } from "../../utils/helper";

const NotLive = ({ translate, lang }) => {
  const [trendLocations, setTrendLocations] = useState({
    isOnScreen: false,
    isReady: false,
    items: [],
  });

  const locationsRef = useRef(null);

  useEffect(() => {
    const cancelSource = Axios.CancelToken.source();

    API.get(`trend_locations`, {
      headers: {
        ...headers,
        "Accept-Language": lang,
        cancelToken: cancelSource.token,
      },
    })
      .then(({ data: r }) => {
        setTrendLocations({ isReady: true, items: r.data });
      })
      .catch((err) => handleErrors(err));
  }, [lang]);

  const handlePromotionClick = (item, id) => {
    const promotion = {
      id,
      name: `Arama - ${id}`,
    };

    dataLayer__promotionClick(item, promotion);
  };

  const checkIfPromotionVisible = () => {
    const isOnScreen = checkIfOnScreen(locationsRef.current);

    if (isOnScreen && !trendLocations.isOnScreen && trendLocations.isReady) {
      setTrendLocations({ ...trendLocations, isOnScreen: true });
      handleLocationsPromotionVisible();
    }
  };

  const handleLocationsPromotionVisible = () => {
    if (trendLocations.isReady) {
      const items = trendLocations.items.map((location, index) => ({
        title: location.name,
        index: index + 1,
      }));

      const promotion = {
        id: "En Favori Lokasyonlar",
        name: "Arama - En Favori Lokasyonlar",
      };

      dataLayer__promotionView(items, promotion);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", checkIfPromotionVisible, false);
    return () => {
      document.removeEventListener("scroll", checkIfPromotionVisible, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendLocations]);

  return (
    <div className="NotLive">
      <PrerenderRedirect code={302} />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="icon d-flex justify-content-center align-items-center rounded-circle">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M54 6H10C8.89551 6 8 5.10547 8 4C8 2.89453 8.89551 2 10 2H54C55.1045 2 56 2.89453 56 4C56 5.10547 55.1045 6 54 6Z"
                      fill="black"
                    />
                    <path
                      d="M54 62H10C8.89551 62 8 61.1055 8 60C8 58.8945 8.89551 58 10 58H54C55.1045 58 56 58.8945 56 60C56 61.1055 55.1045 62 54 62Z"
                      fill="black"
                    />
                    <path
                      d="M48.7998 62H15.2002C14.0987 62 13.2041 61.1094 13.2002 60.0059C13.1992 59.625 13.21 50.5508 16.7656 42.8027C19.1699 37.5625 21.9014 33.8711 23.4463 32C21.9014 30.1289 19.1699 26.4375 16.7656 21.1973C13.21 13.4492 13.1992 4.375 13.2002 3.99414C13.2041 2.89062 14.0987 2 15.2002 2H48.7998C49.9014 2 50.7959 2.89062 50.7998 3.99414C50.8008 4.375 50.7901 13.4492 47.2344 21.1973C44.8301 26.4375 42.0987 30.1289 40.5537 32C42.0987 33.8711 44.8301 37.5625 47.2344 42.8027C50.7901 50.5508 50.8008 59.625 50.7998 60.0059C50.7959 61.1094 49.9014 62 48.7998 62ZM17.2842 58H46.7158C46.5166 55.0566 45.8467 49.3691 43.5996 44.4707C40.3906 37.4785 36.499 33.4375 36.461 33.3965C35.7051 32.6211 35.7051 31.3789 36.461 30.6035C36.499 30.5625 40.3907 26.5215 43.5996 19.5293C45.8467 14.6309 46.5166 8.94336 46.7158 6H17.2842C17.4834 8.94336 18.1533 14.6309 20.4004 19.5293C23.6094 26.5215 27.501 30.5625 27.5391 30.6035C28.2949 31.3789 28.2949 32.6211 27.5391 33.3965C27.501 33.4375 23.6094 37.4785 20.4004 44.4707C18.1533 49.3691 17.4834 55.0566 17.2842 58Z"
                      fill="black"
                    />
                    <path
                      d="M22.448 53.9602C22.448 53.9602 22.4314 48.8856 24.3717 44.6568C26.3119 40.4281 29.4755 38.04 32.0001 38.04C34.5246 38.04 37.6882 40.4281 39.6285 44.6568C41.5687 48.8856 41.5522 53.9602 41.5522 53.9602H22.448V53.9602Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <p className="no-result-text text-center">
                  {translate("experience.not_live")}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 d-flex justify-content-center">
                <Link
                  to={global.home.links[lang]}
                  className="go-back-home d-flex justify-content-center align-items-center"
                >
                  {translate("global.go_back_home")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <PopularActivities
              lang={lang}
              translate={translate}
              listInfo={{
                type: global.search.type,
                name: "En Sevilen Deneyimler",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TrendLocations
              trendLocations={trendLocations.items}
              isReady={trendLocations.isReady}
              lang={lang}
              translate={translate}
              handlePromotionClick={handlePromotionClick}
              propRef={locationsRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotLive;
