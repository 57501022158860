import React, { Component } from "react";
import giftCard from "../../../assets/images/gift-card-cart-icon.svg";
import { Gift } from "./couponDetailTypes/Gift";
import { currencyFormatter } from "../../../utils/helper";

import crypto from "crypto";

export default class SingleGiftCard extends Component {
  state = {
    isDetailOn: false,
  };

  id = crypto.randomBytes(6).toString("hex");

  componentDidMount = () => {
    document
      .querySelector(`#cig-${this.id} .cover img`)
      .addEventListener("load", this.scrollEvent, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.calculateHeight, false);
  };

  calculateHeight = () => {
    const expHolder = document.querySelector(`#cig-${this.id}`);

    if (expHolder) {
      const coverHeight = document.querySelector(`#cig-${this.id} .cover`)
          .clientHeight,
        summaryHeight = document.querySelector(`#cig-${this.id} .info-area`)
          .clientHeight,
        mobileAmountsHeight = document.querySelector(
          `#cig-${this.id} .mobile-amounts`
        ).clientHeight;

      let height = coverHeight > summaryHeight ? coverHeight : summaryHeight;

      height += mobileAmountsHeight;

      height += mobileAmountsHeight
        ? 30
        : coverHeight > summaryHeight
        ? 26
        : 14;

      expHolder.setAttribute("style", `max-height: ${height}px`);
    }
  };

  scrollEvent = () => {
    this.calculateHeight();
    window.addEventListener("resize", this.calculateHeight, false);
  };

  render() {
    return (
      <div
        className="exp-holder gift-card d-flex align-items-start overflow-hidden"
        data-details={this.state.isDetailOn ? `on` : `off`}
        id={`cig-${this.id}`}
      >
        <div className="cover h-100 d-flex justify-content-center align-items-center overflow-hidden">
          <img src={giftCard} alt="Gift Card" />
        </div>
        <div className="info-area">
          <div className="summary-area d-flex flex-column justify-content-between position-relative">
            <div className="exp-info">
              <h3 className="exp-title m-0 selectable">
                {this.props.translate("global.gift_card")}
              </h3>
              <p className="exp-code">
                <span className="selectable text-uppercase">
                  {this.props.item.delivery_recipient_first_name}{" "}
                  {this.props.item.delivery_recipient_last_name}
                </span>
              </p>
            </div>
            <div className="amounts d-none d-md-flex flex-column justify-content-between align-items-end">
              <div className="total-price text-right">
                {this.props.translate("payment.my_coupons.gift_card_amount")}:{" "}
                <span className="selectable">
                  {currencyFormatter(
                    this.props.currency,
                    this.props.item.amount,
                    this.props.lang
                  )}
                </span>
              </div>
            </div>
            <div className="down-arrow position-absolute">
              <button
                onClick={() =>
                  this.setState((prevState) => {
                    return { isDetailOn: !prevState.isDetailOn };
                  })
                }
              >
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1836 1.62074L6.59955 7.98438L1.0155 1.62074"
                    stroke="black"
                    strokeWidth="1.89"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="mobile-amounts d-flex d-md-none flex-column justify-content-between align-items-start">
          <div className="total-price text-right">
            {this.props.translate("payment.my_coupons.gift_card_amount")}:{" "}
            <span className="selectable">
              {currencyFormatter(
                this.props.currency,
                this.props.item.amount,
                this.props.lang
              )}
            </span>
          </div>
        </div>
        <Gift
          translate={this.props.translate}
          email={this.props.item.delivery_recipient_email}
          firstName={this.props.item.delivery_recipient_first_name}
          lastName={this.props.item.delivery_recipient_last_name}
          phone={"+" + this.props.item.delivery_recipient_phone.formatted}
          date={this.props.item.delivery_recipient_send_date}
          message={this.props.item.gift_message}
        />
      </div>
    );
  }
}
