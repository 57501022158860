import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";

import Banner from "./components/Banner";
import { update } from "../Auth/authActions";

class Profile extends Component {
  render() {
    const lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : this.props.defaultLanguage;

    return (
      <div className="Profile">
        <Banner
          user={{
            ...this.props.user,
            fullname: `${this.props.user.firstName} ${this.props.user.lastName}`,
          }}
          translate={this.props.translate}
          lang={lang}
          update={this.props.update}
        />

        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Profile));
