import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { localizeReducer } from "react-localize-redux";
import authReducer from "./Auth/authReducer";
import cartReducer from "./Cart/cartReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  localize: localizeReducer,
  cart: cartReducer,
  routing: routerReducer
});

export default rootReducer;
