import React from "react";
import BLButton from "../../utils/components/BLButton";
import SingleGiftCard from "./SingleGiftCard";

const GiftCardsList = (props) => {
  return (
    <div className="gift-cards-list-holder">
      <div className="header">
        <div className="title d-none d-lg-block">
          {props.translate("profile.gift_cards_page.gift_card_num")}
        </div>
        <div className="title">
          {props.translate("profile.gift_cards_page.card_status")}
        </div>
        <div className="title">
          {props.translate("profile.gift_cards_page.card_amount")}
        </div>
        <div className="title d-none d-lg-block">
          {props.translate("profile.gift_cards_page.card_date")}
        </div>
      </div>
      <div className="gift-cards">
        {props.giftCards.length
          ? props.giftCards.map((data) => (
              <SingleGiftCard
                key={data.card_number}
                id={data.card_number}
                date={data.expiry_date}
                amount={parseInt(data.credit)}
                currency={data.currency}
                status={data.status}
                status_id={data.status_id}
                translate={props.translate}
              />
            ))
          : ""}

        {props.hasMore && (
          <BLButton
            type="pri"
            classes="px-4 mt-5 mx-auto"
            clicked={props.handleLoadMore}
            disabled={props.isLoadingMore}
          >
            {props.translate("global.load_more")}
          </BLButton>
        )}
      </div>
    </div>
  );
};

export default GiftCardsList;
