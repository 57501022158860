import React, { useState, useEffect } from "react";
import toastr from "toastr";
import Profile from "./Profile";
import BLInput from "../utils/components/BLInput";
import BLButton from "../utils/components/BLButton";
import API, { headers } from "../utils/API";
import { handleErrors, validatePassword } from "../utils/helper";
import Helmet from "react-helmet";
import { update } from "../Auth/authActions";
import { connect } from "react-redux";

const ChangePassword = (props) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const checkIfFilled = () => {
      setIsButtonActive(false);

      if (!validatePassword(newPass)) return false;
      if (props.user.isPasswordCreated) {
        if (newPass !== "" && oldPass !== "") setIsButtonActive(true);
      } else {
        if (newPass !== "") setIsButtonActive(true);
      }
    };
    checkIfFilled();
  }, [oldPass, newPass, props.user.isPasswordCreated]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSending(true);
    toastr.clear();

    const params = props.user.isPasswordCreated
      ? {
          old_password: oldPass,
          new_password: newPass,
        }
      : { new_password: newPass };

    API.post("password/change", params, {
      headers: {
        ...headers,
        Authorization: `${props.user._tokenType} ${props.user._token}`,
        "Accept-Language": props.lang,
      },
    })
      .then((response) => {
        toastr.success(response.data.info.message);

        if (!props.user.isPasswordCreated)
          props.update({ isPasswordCreated: true });

        setOldPass("");
        setNewPass("");

        setIsSending(false);
      })
      .catch((err) => {
        handleErrors(err);
        setIsSending(false);
      });
  };

  return (
    <Profile>
      <Helmet>
        <title>{props.translate("profile.change_password")}</title>
      </Helmet>
      <div className="page-content change-password">
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="page-holder w-100">
                <div className="row">
                  <div className="col">
                    <h4 className="page-title">
                      {props.translate("profile.change_password_page.title")}
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <section className="page-sec">
                      <form action="#!" onSubmit={handleSubmit}>
                        {props.user.isPasswordCreated && (
                          <div className="row">
                            <div className="col">
                              <BLInput
                                type="password"
                                label={props.translate(
                                  "profile.change_password_page.old_password"
                                )}
                                name="old_password"
                                value={oldPass}
                                changed={(e) => setOldPass(e.target.value)}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col">
                            <BLInput
                              type="password"
                              label={props.translate(
                                "profile.change_password_page.new_password"
                              )}
                              name="new_password"
                              value={newPass}
                              changed={(e) => setNewPass(e.target.value)}
                              showPassInfo
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <BLButton
                              type="pri"
                              classes="update-button w-100"
                              action="submit"
                              disabled={!isButtonActive || isSending}
                            >
                              {props.translate(
                                "profile.change_password_page.update_password"
                              )}
                            </BLButton>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Profile>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
