import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import BLButton from "../../utils/components/BLButton";
import BLInput from "../../utils/components/BLInput";

import logo from "../../assets/images/logo-white.svg";
import { global } from "../../App/routes";
import {
  MAX_GIFT_AMOUNT,
  MIN_GIFT_AMOUNT,
  currencyFormatter,
} from "../../utils/helper";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";

const Banner = (props) => {
  const [amount, setAmount] = useState(250);
  const [otherAmount, setOtherAmount] = useState("");
  const [otherAmountWarning, setOtherAmountWarning] = useState("");

  const amounts = [
    { id: 1, amount: 250 },
    { id: 2, amount: 500 },
    { id: 3, amount: 1000 },
    { id: 4, amount: 1500 },
    { id: 5, amount: 2000 },
  ];

  useEffect(() => {
    props.handleSetAmount(typeof amount === "number" ? amount : otherAmount);

    if (typeof amount !== "number") {
      if (otherAmount > 0) {
        if (parseInt(otherAmount) < MIN_GIFT_AMOUNT)
          setOtherAmountWarning(
            props.translate("voucher.min_amount_warning", {
              amount: currencyFormatter(
                props.currency,
                MIN_GIFT_AMOUNT,
                props.lang
              ),
            })
          );
        else if (parseInt(otherAmount) > MAX_GIFT_AMOUNT)
          setOtherAmountWarning(
            props.translate("voucher.max_amount_warning", {
              amount: currencyFormatter(
                props.currency,
                MAX_GIFT_AMOUNT,
                props.lang
              ),
            })
          );
        else setOtherAmountWarning("");
      }
    } else setOtherAmountWarning("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, otherAmount]);

  return (
    <section className="banner">
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="breadcrumbs-holder">
              <BLBreadcrumbs
                breadcrumbs={[
                  {
                    id: 0,
                    name: props.translate("global.homepage"),
                    route: global.home.links[props.lang],
                  },
                  {
                    id: 1,
                    name: props.translate("voucher.page_title"),
                  },
                ]}
                color="white"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center">
            <div className="banner-holder d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <div className="voucher-card position-relative d-flex flex-column justify-content-center align-items-center">
                <img src={logo} className="bl-logo" alt="Bucketlist Logo" />
                <div className="amount selectable">
                  {currencyFormatter(
                    props.currency,
                    amount === "other"
                      ? parseInt(otherAmount)
                      : parseInt(amount),
                    props.lang
                  )}
                </div>
                <div className="card-no d-flex flex-column align-items-center not-selectable">
                  <p className="text-uppercase">
                    {props.translate("voucher.card_number")}
                  </p>
                  <div className="card-no-placeholder d-flex justify-content-center aling-items-center">
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                  </div>
                </div>
                <div className="white-stripe">&nbsp;</div>
              </div>
              <div className="create-voucher-area d-flex flex-column justify-content-center align-items-center">
                <h4 className="title text-center">
                  {props.translate("voucher.create_a_voucher")}
                </h4>
                <p className="content text-center">
                  {props.translate("voucher.create_a_voucher_content")}
                </p>
                <div className="amount-choices d-flex justify-content-center align-items-center flex-wrap">
                  {amounts.map((data) => (
                    <BLButton
                      key={data.id}
                      type="pri"
                      opposite={amount !== data.amount}
                      classes="amount-choice"
                      clicked={() => {
                        setAmount(data.amount);
                      }}
                    >
                      {currencyFormatter(
                        props.currency,
                        data.amount,
                        props.lang
                      )}
                    </BLButton>
                  ))}
                  <BLInput
                    type="number"
                    name="other"
                    label={props.translate("voucher.other_currency", {
                      currency: props.currency.symbol,
                    })}
                    classes={`amount-choice ${
                      amount === "other" && otherAmount !== "" ? `selected` : ``
                    }`}
                    value={otherAmount}
                    changed={(e) => {
                      setOtherAmount(e.target.value);
                      e.target.value !== ""
                        ? setAmount("other")
                        : setAmount(amounts[0].amount);
                    }}
                    focused={(e) => {
                      e.target.value !== "" && setAmount("other");
                    }}
                    inputProps={{
                      max: MAX_GIFT_AMOUNT,
                      min: MIN_GIFT_AMOUNT,
                      inputMode: "numeric",
                    }}
                    warning={otherAmountWarning}
                  />
                </div>
                <div className="finalize-area w-100 d-flex justify-content-center align-items-center">
                  <BLButton
                    type="pri"
                    clicked={(amount) => props.handleShowPackageOptions(amount)}
                    disabled={!!otherAmountWarning}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 0V2.8H2.8L7.84 13.44L5.88 16.8C5.74 17.22 5.6 17.78 5.6 18.2C5.6 19.74 6.86 21 8.4 21H25.2V18.2H8.96C8.82 18.2 8.68 18.06 8.68 17.92V17.7799L9.94 15.3999H20.3C21.42 15.3999 22.26 14.8399 22.68 13.9999L27.72 4.9C28 4.62 28 4.48 28 4.2C28 3.36 27.44 2.8 26.6 2.8H5.88L4.62 0H0ZM8.4 22.4C6.86 22.4 5.6 23.66 5.6 25.2C5.6 26.74 6.86 28 8.4 28C9.94 28 11.2 26.74 11.2 25.2C11.2 23.66 9.94 22.4 8.4 22.4ZM19.6 25.2C19.6 23.66 20.86 22.4 22.4 22.4C23.94 22.4 25.2 23.66 25.2 25.2C25.2 26.74 23.94 28 22.4 28C20.86 28 19.6 26.74 19.6 25.2Z"
                        fill="white"
                      />
                    </svg>
                    {props.translate("global.add_to_cart")}
                  </BLButton>
                </div>
                <span className="have-a-voucher">
                  <Link
                    to={global.helpDetail.links[props.lang]
                      .replace(":cat", "hediye-karti")
                      .replace(
                        ":name",
                        "bucketlist-hediye-kartini-nasil-kullanabilirim"
                      )}
                  >
                    {props.translate("voucher.how_to_use_voucher")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default Banner;
