import React from "react";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

const SidebarItem = ({ cat, slug, title, lang }) => {
  return (
    <div className="content">
      <NavLink
        to={global.helpDetail.links[lang]
          .replace(":cat", cat)
          .replace(":name", slug)}
      >
        {title}
      </NavLink>
    </div>
  );
};

export default SidebarItem;
