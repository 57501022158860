import React, { Component } from "react";
import BLCheckbox from "../../utils/components/BLCheckbox";
import BLDropdown from "../../utils/components/BLDropdown";
import BLInput from "../../utils/components/BLInput";
import BLButton from "../../utils/components/BLButton";
import BLDatePicker from "../../utils/components/BLDatePicker";
import dayjs from "dayjs";
import BLLoader from "../../utils/components/BLLoader";
import { validateInput } from "../../utils/helper";
import BOOKING_MODE from "../../utils/constants";

class Reserve extends Component {
  state = {
    isUsingOwnInfo: false,
    isUsageAgreementChecked: true,
    preferred_date: "",
    preferred_time: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    areaCode: "90",
    countryCode: "TR",
    isOkayToReserve: false,
  };

  minDate =
    this.props.reservation.product &&
    this.props.reservation.product.schedule.date_range_type === 2
      ? new Date(
          dayjs(
            this.props.reservation.product.schedule.booking_start_date,
            "DD-MM-YYYY"
          )
        )
      : new Date();

  maxDate =
    this.props.reservation.product &&
    this.props.reservation.product.schedule.date_range_type === 2
      ? new Date(
          dayjs(
            this.props.reservation.product.schedule.booking_expiry_date,
            "DD-MM-YYYY"
          )
        )
      : "";

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.isUsageAgreementChecked !==
        this.state.isUsageAgreementChecked ||
      prevState.preferred_date !== this.state.preferred_date ||
      prevState.preferred_time !== this.state.preferred_time ||
      prevState.first_name !== this.state.first_name ||
      prevState.last_name !== this.state.last_name ||
      prevState.email !== this.state.email ||
      prevState.phone !== this.state.phone
    ) {
      this.handleCheck();
    }
  };

  handleCheck = () => {
    if (
      this.state.isUsageAgreementChecked &&
      ((this.props.reservation?.booking_mode === BOOKING_MODE.DATEANDTIME &&
        this.state.preferred_date &&
        this.state.preferred_time) ||
        (this.props.reservation?.booking_mode === BOOKING_MODE.DATEONLY &&
          this.state.preferred_date)) &&
      this.state.first_name !== "" &&
      this.state.last_name !== "" &&
      this.state.email !== "" &&
      validateInput("email", this.state.email).success &&
      this.state.phone !== "" &&
      validateInput("phone", this.state.phone).success
    )
      this.setState({ isOkayToReserve: true });
    else this.setState({ isOkayToReserve: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleUpdateCountryCode = (value) => {
    const valueArr = value.split("+");

    const countryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    this.setState({ countryCode, areaCode });
  };

  handleToggleMyInfo = (e) => {
    this.setState({ isUsingOwnInfo: e.target.checked });
    let info = {};
    if (e.target.checked) {
      const { first_name, last_name, email, phone, areaCode, countryCode } =
        this.props.user;
      info = {
        first_name,
        last_name,
        email,
        phone: phone ? phone : "",
        areaCode: areaCode ? areaCode : "90",
        countryCode: countryCode ? countryCode : "TR",
      };
    } else {
      info = { first_name: "", last_name: "", email: "", phone: "" };
    }

    setTimeout(() => this.setState({ ...info }), 20);
  };

  handlePickADate = (name, value, type) => {
    const dateOrTime =
      type === "date" ? dayjs(value).format("DD/MM/YYYY") : value;

    this.setState({ [name]: dateOrTime });
  };

  render() {
    return (
      <div className="reserve-page">
        <div className="info-area">
          <h6 className="title">
            {this.props.translate("reservation.reserve_page.booking_details")}
          </h6>
          <p className="content">
            {this.props.translate("reservation.reserve_page.desc")}
          </p>
        </div>
        <form
          action="#!"
          className="redeem-form mt-4"
          onSubmit={(e) => this.props.handleSubmit(e, this.state)}
        >
          <div className="row mb-2">
            <div className="col-12 col-sm-6">
              <BLDatePicker
                lang={this.props.lang}
                activeLanguage={{ code: this.props.lang }}
                type="single"
                placeholder={
                  this.props.productAvailabilitiesLoading
                    ? `Loading...`
                    : this.props.translate("product.preferred_date")
                }
                setSelected={(value) => {
                  this.handlePickADate("preferred_date", value, "date");

                  const eventStart = dayjs(value).format("YYYY-MM-DD");

                  if (!eventStart) return;

                  const selectedAvailability =
                    this.props.productAvailabilities.find((item) =>
                      item.date.formatted.startsWith(eventStart)
                    );

                  if (selectedAvailability)
                    this.props.handleSetSelectedAvailability(
                      selectedAvailability
                    );
                }}
                selected={
                  this.state.preferred_date
                    ? dayjs(this.state.preferred_date, "DD/MM/YYYY")
                    : null
                }
                minDate={this.minDate}
                maxDate={this.maxDate}
                includeDates={
                  this.props.productAvailabilities
                    ?.filter((availability) => availability?.available)
                    ?.map((pA) => new Date(pA.date.formatted)) || []
                }
                dateFormat="dd MMMM yyyy"
                onMonthChange={(date) => {
                  const startDate = dayjs(date)
                    .startOf("month")
                    .isSame(dayjs().startOf("month"))
                    ? dayjs().format("YYYY-MM-DD")
                    : dayjs(date).startOf("month").format("YYYY-MM-DD");
                  const endDate = dayjs(date)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                  if (!startDate || !endDate) return;

                  this.props.handleGetAvailabilities &&
                    this.props.handleGetAvailabilities({
                      startDate,
                      endDate,
                    });
                }}
                isLoading={this.props.productAvailabilitiesLoading}
              />
            </div>

            {this.props.reservation?.booking_mode ===
              BOOKING_MODE.DATEANDTIME && (
              <div className="col-12 col-md-6">
                <BLDropdown
                  options={this.props.selectedAvailability?.availabilities
                    ?.filter((a) => !a.soldOut && !a.closed)
                    ?.filter((a) => {
                      if (
                        dayjs(this.state.preferred_date).isSame(dayjs(), "day")
                      ) {
                        return dayjs(a.time, "HH:mm").isAfter(dayjs());
                      }
                      return true;
                    })
                    ?.map((a) => ({
                      name: a?.time,
                      value: a?.time,
                    }))}
                  name="preferred_time"
                  setValue={(value) => {
                    this.handlePickADate("preferred_time", value, "time");
                  }}
                  placeholder={this.props.translate(
                    this.props.productAvailabilitiesLoading
                      ? `global.loading`
                      : `product.preferred_time`
                  )}
                  disabled={this.state.preferred_date ? false : true}
                  default={this.state.preferred_time}
                />
              </div>
            )}
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <h6 className="sec-title m-0">
                {this.props.translate(
                  "reservation.reserve_page.participant_details"
                )}
              </h6>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-12">
              <BLCheckbox
                label={this.props.translate(
                  "reservation.reserve_page.use_my_info"
                )}
                name="use_my_info"
                checked={this.state.isUsingOwnInfo}
                changed={(e) => this.handleToggleMyInfo(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <BLInput
                type="text"
                name="first_name"
                label={this.props.translate("global.first_name")}
                changed={(e) => this.handleChange(e)}
                value={this.state.first_name}
                inputProps={{ disabled: this.state.isUsingOwnInfo }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <BLInput
                type="text"
                name="last_name"
                label={this.props.translate("global.last_name")}
                changed={(e) => this.handleChange(e)}
                value={this.state.last_name}
                inputProps={{ disabled: this.state.isUsingOwnInfo }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <BLInput
                type="email"
                name="email"
                label={this.props.translate("global.email")}
                validationType="email"
                changed={(e) => this.handleChange(e)}
                value={this.state.email}
                inputProps={{
                  disabled: this.state.isUsingOwnInfo,
                  inputMode: "email",
                }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <BLInput
                type="tel"
                name="phone"
                label={this.props.translate("global.phone_number")}
                validationType="phone"
                changed={(e) => this.handleChange(e)}
                value={this.state.phone}
                setCountryCode={this.handleUpdateCountryCode}
                countryCode={
                  this.state.countryCode + " +" + this.state.areaCode
                }
                inputProps={{
                  disabled: this.state.isUsingOwnInfo && this.props.user.phone,
                  inputMode: "tel",
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-7">
              {this.props.translate(
                "reservation.reserve_page.you_need_to_fill_info"
              )}
            </div>
            <div className="col-12 col-sm-5">
              {this.props.isSending ? (
                <BLLoader />
              ) : (
                <BLButton
                  type="alt"
                  classes="w-100 mt-4 mt-sm-0"
                  action="submit"
                  disabled={!this.state.isOkayToReserve}
                >
                  {this.props.translate(
                    "reservation.reserve_page.check_reservation"
                  )}
                </BLButton>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Reserve;
