import React, { Component } from "react";
import BLInput from "../../utils/components/BLInput";
import { ratingStars } from "../../utils/helper";
import BLButton from "../../utils/components/BLButton";
import { CSSTransition } from "react-transition-group";
import BLCheckbox from "../../utils/components/BLCheckbox";

export default class Review extends Component {
  state = {
    topic: "",
    review: "",
    rating: 0,
    requestsContact: 0,
    isNameHidden: false,
  };

  handleSubmitForm = (e) => {
    e.preventDefault();

    const params = {
      topic: this.state.topic,
      review: this.state.review,
      rating: this.state.rating,
      requestsContact: this.state.requestsContact,
      isNameHidden: this.state.isNameHidden,
    };

    this.props.handleMakeAReview(params);
  };

  render() {
    return (
      <div className="make-review-holder overflow-auto">
        <h6 className="title text-center">
          {this.props.translate("profile.reservations_page.review_title")}
        </h6>
        <form action="#!" onSubmit={this.handleSubmitForm}>
          <BLInput
            type="text"
            name="topic"
            label={this.props.translate("profile.reservations_page.topic")}
            value={this.state.topic}
            changed={(e) => this.setState({ topic: e.target.value })}
          />
          <BLInput
            label={this.props.translate(
              "profile.reservations_page.review_comment"
            )}
            type="textarea"
            name="review"
            value={this.state.review}
            changed={(e) => this.setState({ review: e.target.value })}
            charLimit={
              process.env.REACT_APP_MAX_REVIEW_MESSAGE_CHAR
                ? process.env.REACT_APP_MAX_REVIEW_MESSAGE_CHAR
                : 255
            }
          />
          <div className="rating-area d-flex flex-column align-items-start">
            <span className="label">
              {this.props.translate("profile.reservations_page.rating")}:
            </span>
            <div className="stars-holder">
              {ratingStars(this.state.rating).map((data, index) => (
                <button
                  type="button"
                  key={data.key}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ rating: index + 1 });
                  }}
                >
                  {data.star}
                </button>
              ))}
            </div>
          </div>
          <CSSTransition
            in={this.state.rating > 0 && this.state.rating <= 2}
            timeout={0}
            unmountOnExit
          >
            <div className="w-100">
              <BLCheckbox
                name="requestsContact"
                label={this.props.translate(
                  "profile.reservations_page.request_contact_checkbox"
                )}
                changed={(e) =>
                  this.setState({ requestsContact: e.target.checked })
                }
              />
            </div>
          </CSSTransition>
          <div className="w-100">
            <BLCheckbox
              name="isNameHidden"
              label={this.props.translate(
                "profile.reservations_page.hide_my_name"
              )}
              changed={(e) => this.setState({ isNameHidden: e.target.checked })}
            />
          </div>
          <BLButton action="submit" type="sec">
            {this.props.translate("profile.reservations_page.publish_review")}
          </BLButton>
        </form>
      </div>
    );
  }
}
