import React from "react";
import { NavLink } from "react-router-dom";

const BLTab = props => {
  return (
    <NavLink
      to={props.url}
      className="tab d-flex justify-content-center align-items-center"
    >
      {props.title}
    </NavLink>
  );
};

export default BLTab;
