import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ContentTabs = props => {
  const tabs = props.tabs.map((data, index) => (
    <div
      key={index}
      className={`tab position-relative ${
        props.selectedTab === index ? `active` : ``
      }`}
      id={`tab-${index}`}
    >
      <Link
        to="#!"
        className={`stretched-link d-flex justify-content-center align-items-center`}
        onClick={e => {
          e.preventDefault();
          props.setSelectedTab(index);
        }}
      >
        {data}
      </Link>
    </div>
  ));
  return (
    <div className="tabs-holder d-flex justify-content-start align-items-center">
      {tabs}
    </div>
  );
};

ContentTabs.propTypes = {
  translate: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};

export default ContentTabs;
