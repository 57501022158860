import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import is from "is_js";

import Tabs from "./components/Tabs";
import Login from "./components/Login";
import Register from "./components/Register";

import { global, guest } from "../App/routes";
import { getCurrentRoute } from "../utils/helper";
import API from "../utils/API";
import Axios from "axios";
import { CSSTransition } from "react-transition-group";
import BLModal from "../utils/components/BLModal";

class Auth extends Component {
  state = {
    page: "",
    showLegalModal: null,
    agreements: [],
  };

  componentDidMount = () => {
    this.handleGetLegals();

    if (is.mobile()) {
      setTimeout(async () => {
        if (
          this.props.prevLocation &&
          this.props.prevLocation.pathname !==
            guest.login.links[this.props.lang] &&
          this.props.prevLocation.pathname !==
            guest.register.links[this.props.lang]
        ) {
          const currRoute = await getCurrentRoute(
            this.props.prevLocation.pathname,
            this.props.lang
          );

          const redirectRoute = {
            params: currRoute.match.params,
            links: currRoute.route.links,
          };

          localStorage.setItem(
            "login-redirects",
            JSON.stringify(redirectRoute)
          );
        }

        if (
          this.props.match.path !== guest.login.links[this.props.lang] &&
          this.props.match.path !== guest.register.links[this.props.lang]
        ) {
          this.props.history.push(guest.login.links[this.props.lang]);
          return false;
        }
      }, 200);
    }

    if (
      this.props.match.path === guest.login.links[this.props.lang] ||
      this.props.match.path === guest.register.links[this.props.lang]
    )
      window.scrollTo({ top: 0, behavior: "smooth" });

    this.setState(() => {
      const { path } = this.props.match;
      const { code } = this.props.activeLanguage;
      const { links } = guest.register;
      const page = path === links[code] ? "register" : "login";
      return { page };
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.lang !== this.props.lang) {
      this.handleGetLegals();
    }

    if (prevState.showLegalModal !== this.state.showLegalModal) {
      setTimeout(
        () => {
          this.setState({
            agreements: this.state.agreements.map((agreement) => ({
              ...agreement,
              on: agreement.name === this.state.showLegalModal,
            })),
          });
        },
        this.state.showLegalModal ? 0 : 300
      );
    }
  };

  handleGetLegals = () => {
    const legals = [
      API.get(
        `legals/${
          global.notListedPolicy.params.policy.termsOfUse[this.props.lang]
        }`
      ),
      API.get(
        `legals/${
          global.privacyPolicy.params.section.userPrivacyNotice[this.props.lang]
        }`
      ),
    ];

    Axios.all(legals).then(
      Axios.spread(({ data: tou }, { data: upn }) => {
        this.setState({
          agreements: [
            { name: "tou", content: tou.data.content, on: false },
            { name: "upn", content: upn.data.content, on: false },
          ],
        });
      })
    );
  };

  handlePageChange = (e, page) => {
    e.preventDefault();

    if (
      this.props.match.path === guest.login.links[this.props.lang] ||
      this.props.match.path === guest.register.links[this.props.lang]
    ) {
      const url = guest[page].links[this.props.lang];

      if (this.props.location.pathname !== url) {
        this.props.history.push(url);
      }
    } else {
      this.setState((prevState) => {
        if (prevState.page !== page) return { page };
      });
    }
  };

  render() {
    const mutualProps = {
      lang: this.props.lang,
      translate: this.props.translate,
      handlePageChange: this.handlePageChange,
      redirect:
        this.props.match.path === guest.login.links[this.props.lang] ||
        this.props.redirectTo,
      redirectTo: this.props.redirectTo
        ? this.props.redirectTo
        : this.props.prevLocation
        ? this.props.prevLocation.pathname
        : false,
      cart: this.props.cart,
      user: this.props.user,
    };

    return (
      <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              {process.env.REACT_APP_DEMO_MODE === "true" ? (
                <div className="demo-mode d-flex flex-column justify-content-center align-items-center">
                  <h6 className="desc">
                    {this.props.translate("demo_mode.auth")}
                  </h6>

                  {(this.props.location.pathname ===
                    guest.login.links[this.props.lang] ||
                    this.props.location.pathname ===
                      guest.register.links[this.props.lang]) && (
                    <Link to="/" className="go-back-link">
                      {this.props.translate("global.go_back_home")}
                    </Link>
                  )}
                </div>
              ) : (
                <div className="auth-holder d-flex justify-content-center flex-column">
                  <Tabs
                    lang={this.props.lang}
                    guest={guest}
                    translate={this.props.translate}
                    handlePageChange={this.handlePageChange}
                    page={this.state.page}
                  />
                  {(this.state.page === "login" ||
                    this.props.match.path ===
                      guest.login.links[this.props.lang]) && (
                    <Login {...mutualProps} />
                  )}
                  {(this.state.page === "register" ||
                    this.props.match.path ===
                      guest.register.links[this.props.lang]) && (
                    <Register
                      {...mutualProps}
                      handleShowLegal={(legal) =>
                        this.setState({ showLegalModal: legal })
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <CSSTransition
          in={this.state.showLegalModal !== null}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() => this.setState({ showLegalModal: null })}
            headerFix={
              this.props.location.pathname ===
              guest.register.links[this.props.lang]
            }
          >
            <div className="modal-content-holder overflow-auto">
              <div
                className="contract-content"
                dangerouslySetInnerHTML={{
                  __html: this.state.agreements.find(
                    (agreement) => agreement.on
                  )?.content,
                }}
              />
            </div>
          </BLModal>
        </CSSTransition>
      </div>
    );
  }
}

export default withLocalize(withRouter(Auth));
