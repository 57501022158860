import React from "react";

export const Gift = props => {
  return (
    <div className="details-area">
      <h6 className="title">{props.translate("payment.my_coupons.gift_it")}</h6>
      <div className="info-holder d-flex flex-column">
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("global.full_name")}:
          </h6>
          <p className="info-content text-right">{`${props.firstName} ${props.lastName}`}</p>
        </div>
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("global.email")}:
          </h6>
          <p className="info-content text-right">{props.email}</p>
        </div>
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("global.phone_number")}:
          </h6>
          <p className="info-content text-right">{props.phone}</p>
        </div>
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("payment.my_coupons.delivery_date")}:
          </h6>
          <p className="info-content text-right">{props.date}</p>
        </div>
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("payment.my_coupons.your_gift_message")}:
          </h6>
          <p className="info-content text-right">{props.message}</p>
        </div>
      </div>
    </div>
  );
};
