import React, { Component } from "react";

import { auth, global, guest } from "../App/routes";
import Steps from "./components/Steps";
import BLLoader from "../utils/components/BLLoader";
import { default as CartPage } from "./pages/Cart";
import Payment from "./pages/Payment";
import PaymentThanks from "./pages/PaymentThanks";
import PhoneConfirmation from "./components/PhoneConfirmation";
import { update, reset } from "./cartActions";
import { connect } from "react-redux";
import API, { headers } from "../utils/API";
import {
  handleErrors,
  getAccountDetails,
  validateInput,
  reformatText,
  currencyFormatter,
} from "../utils/helper";
import toastr from "toastr";
import BLModal from "../utils/components/BLModal";
import { CSSTransition } from "react-transition-group";
import { Prompt } from "react-router";
import PopularActivities from "../Home/components/PopularActivities/PopularActivities";
import dayjs from "dayjs";
import {
  dataLayer__addToCart,
  dataLayer__removeFromCart,
} from "../utils/DataLayerEvents";
import { PACKAGE_TYPES } from "../utils/db/packageTypes";
import BOOKING_MODE from "../utils/constants";

export class Cart extends Component {
  state = {
    step: 0,
    personalInfo: {
      identityNo: "",
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      areaCode: "90",
      countryCode: "TR",
      phone: "",
      email: this.props.user.email,
      isPhoneConfirmed: false,
    },
    invoiceInfo: {
      type: 1,
      isNotCitizen: false,
      identityNo: "",
      corporateName: "",
      taxNumber: "",
      taxLocation: "",
      address: "",
      country: "",
      countryName: "",
      city: "",
      cityName: "",
      district: "",
      districtName: "",
      postalCode: "",
    },
    cardInfo: {
      name: "",
      number: "",
      expMon: "",
      expYear: "",
      cvc: "",
      installment: "",
      totalInterest: null,
      is3DSecure: false,
    },
    selectedGiftCards: [],
    confirmationCode: "",
    confirmResponse: { status: false, success: false, message: "" },
    isLoading: true,
    isTotalCalculating: true,
    isUpdatingPackage: false,
    isUpdatingSuccessful: false,
    isApplyingGiftCard: false,
    isConfirmPhoneOn: false,
    isConfirmingPhone: false,
    isPaying: false,
    isOkayToPay: false,
    payingError: false,
    cartTotal: 0.0,
    newTotal: 0.0,
    discountPrice: 0.0,
    promoPrice: 0.0,
    isSaleAgreementModalOn: false,
    isPreInformationModalOn: false,
    agreementsAgreed: false,
    isUserUpdated: false,
    paymentMethod: 1,
    is3DSecureActive: false,
    pageContentFor3DS: "",
    saleAgreement: "",
    preInfoAgreement: "",
    promoCodeApplied: null,
    isUpdating: false,
  };

  calcTotalTimeout = "";

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.handleUpdateUser();

    const package_type = this.props.cart?.items[0]?.package_type;
    if (package_type !== 4) this.getFeaturedPromoCode();

    let step = 0;

    step =
      this.props.location.pathname ===
      auth.payment.links[this.props.activeLanguage.code]
        ? 2
        : this.props.location.pathname ===
          auth.paymentThanks.links[this.props.activeLanguage.code]
        ? 3
        : 1;

    this.calculateTotal();

    setTimeout(() => {
      this.setState({
        step,
        isLoading: false,
        isTotalCalculating: false,
      });
      this.checkPaymentInfo();
    }, 20);

    if (this.props.location.search) {
      const { search } = this.props.location;

      if (search.includes("err")) {
        const errCode = search.split("=")[1];

        toastr.error(
          this.props.translate(`payment_error.title`) +
            "<br/>" +
            this.props.translate(`payment_error.err_${errCode}`)
        );
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevState.isConfirmPhoneOn &&
      !this.state.isConfirmPhoneOn &&
      this.state.personalInfo.isPhoneConfirmed
    )
      this.paying();

    if (
      prevProps.location.pathname !==
        auth.paymentThanks.links[this.props.activeLanguage.code] &&
      this.props.location.pathname ===
        auth.paymentThanks.links[this.props.activeLanguage.code]
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (this.props.user.isLoggedIn && !prevProps.user.isLoggedIn) {
      this.setState({ isTotalCalculating: true });

      this.handleUpdateUser();

      this.getFeaturedPromoCode();

      clearTimeout(this.calcTotalTimeout);
      this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);
    }

    if (
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code
    ) {
      this.setState({ isTotalCalculating: true });

      const package_type = this.props.cart?.items[0]?.package_type;
      if (package_type !== 4) this.getFeaturedPromoCode();

      clearTimeout(this.calcTotalTimeout);
      this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);
    }
  };

  getFeaturedPromoCode = () => {
    API.get(`promotion?ccy=${this.props.user.currencyPref.code}`, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then(({ data: r }) => {
        const { data: promoCodeApplied } = r;

        if (promoCodeApplied) {
          this.setState({ promoCodeApplied }, () => {
            clearTimeout(this.calcTotalTimeout);
            this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);
          });
        }
      })
      .catch((err) => handleErrors(err));
  };

  handleApplyPromoCode = (code) => {
    if (code === null) {
      this.setState({ promoCodeApplied: null }, () => {
        clearTimeout(this.calcTotalTimeout);
        this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);
        this.checkPaymentInfo();
      });
    } else if (code) {
      API.post(
        "promotion",
        { code, ccy: this.props.user.currencyPref.code },
        {
          headers: {
            ...headers,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        }
      )
        .then(({ data: r }) => {
          const { data: promoCodeApplied } = r;
          this.setState({ promoCodeApplied }, () => {
            clearTimeout(this.calcTotalTimeout);
            this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);

            this.checkPaymentInfo();
          });
        })
        .catch((err) => handleErrors(err));
    }
  };

  handleUpdateUser = async () => {
    if (this.props.user.isLoggedIn && !this.state.isUserUpdated)
      await getAccountDetails(
        this.props.user._tokenType,
        this.props.user._token
      ).then((response) => {
        const { data } = response.data;

        const personalInfo = { ...this.state.personalInfo };
        const invoiceInfo = { ...this.state.invoiceInfo };

        personalInfo.name = data.first_name;
        personalInfo.surname = data.last_name;
        personalInfo.email = data.email;
        personalInfo.phone = data.phone.number ? data.phone.number : "";
        personalInfo.areaCode = data.phone.area_code
          ? data.phone.area_code
          : "90";
        personalInfo.countryCode = data.phone.country_code
          ? data.phone.country_code
          : "TR";

        invoiceInfo.type = data.billing_type ? data.billing_type : 1;
        invoiceInfo.isNotCitizen = !data.citizen_of_turkey;
        invoiceInfo.identityNo = data.idn ? data.idn : "";
        invoiceInfo.corporateName = data.company_name ? data.company_name : "";
        invoiceInfo.taxNumber = data.tax_number ? data.tax_number : "";
        invoiceInfo.taxLocation = data.tax_office ? data.tax_office : "";
        invoiceInfo.address = data.contact.address_line
          ? data.contact.address_line
          : "";
        invoiceInfo.country = data.contact.country.id
          ? data.contact.country.id
          : "";
        invoiceInfo.countryName = data.contact.country.name
          ? data.contact.country.name
          : "";
        invoiceInfo.city = data.contact.city.id ? data.contact.city.id : "";
        invoiceInfo.cityName = data.contact.city.name
          ? data.contact.city.name
          : "";
        invoiceInfo.district = data.contact.state.id
          ? data.contact.state.id
          : "";
        invoiceInfo.districtName = data.contact.state.name
          ? data.contact.state.name
          : "";
        invoiceInfo.postalCode = data.contact.zip ? data.contact.zip : "";

        this.setState({ personalInfo, invoiceInfo, isUserUpdated: true });
      });
  };

  infoCheckTimeout = "";
  checkPaymentInfo = () => {
    clearTimeout(this.infoCheckTimeout);

    this.setState({ isOkayToPay: false });

    this.infoCheckTimeout = setTimeout(() => {
      if (
        this.state.personalInfo.firstName !== "" &&
        this.state.personalInfo.lastName !== "" &&
        this.state.personalInfo.phone !== "" &&
        validateInput("phone", this.state.personalInfo.phone).success &&
        this.state.personalInfo.email !== "" &&
        validateInput("email", this.state.personalInfo.email).success &&
        ((parseInt(this.state.invoiceInfo.type) === 1 &&
          (this.state.invoiceInfo.identityNo !== "" ||
            this.state.invoiceInfo.isNotCitizen)) ||
          (parseInt(this.state.invoiceInfo.type) === 2 &&
            this.state.invoiceInfo.corporateName !== "" &&
            this.state.invoiceInfo.taxNumber !== "" &&
            this.state.invoiceInfo.taxLocation !== "")) &&
        this.state.invoiceInfo.address !== "" &&
        this.state.invoiceInfo.country !== "" &&
        this.state.invoiceInfo.city !== "" &&
        this.state.invoiceInfo.district !== "" &&
        this.state.invoiceInfo.postalCode !== "" &&
        (Math.round(parseFloat(this.state.discountPrice) * 100) / 100 +
          parseFloat(this.state.promoPrice) >=
          Math.round(parseFloat(this.state.cartTotal) * 100) / 100 ||
          (this.state.paymentMethod === 1 &&
            this.state.cardInfo.name !== "" &&
            this.state.cardInfo.number.replace(/ /g, "") !== "" &&
            validateInput("creditCard", this.state.cardInfo.number) &&
            this.state.cardInfo.number.replace(/ /g, "").length >= 15 &&
            this.state.cardInfo.expMon !== "" &&
            this.state.cardInfo.expYear !== "" &&
            this.state.cardInfo.cvc !== "" &&
            this.state.cardInfo.installment !== "") ||
          this.state.paymentMethod === 2) &&
        this.state.agreementsAgreed
      ) {
        this.setState({ isOkayToPay: true });
      }
    }, 400);
  };

  updateAmountTimeout = "";
  handleUpdateAmount = (e, experience, priceId, newCount) => {
    if (e) e.preventDefault();

    const cart = this.props.cart;

    const item = cart.items.find((data) => data.id === experience);
    let isAdding = null;

    if (item) {
      item.units.some((data) => {
        if (data.price_id === priceId) {
          isAdding = parseInt(newCount) > data.quantity;
          data.quantity = parseInt(newCount);
          return true;
        }
        return false;
      });

      const {
        product,
        units,
        package_type,
        preferred_date,
        preferred_time,
        amount,
      } = item;

      const dataLayerProduct = {
        id: product.id,
        title: product.title,
        code: product.code,
        currency: this.props.user.currencyPref,
        provider: product.provider,
        category: product.category,
        units,
        package_type: package_type,
        stats: product.stats,
        preferred_date: preferred_date,
        video_url: product.video_url,
        location: product.location,
      };

      if (isAdding) dataLayer__addToCart(dataLayerProduct);
      else dataLayer__removeFromCart(dataLayerProduct);

      this.setState({ isTotalCalculating: true });

      const giftOptions =
        package_type === 3
          ? {
              message: item.gift_message,
              firstName: item.delivery_recipient_first_name,
              lastName: item.delivery_recipient_last_name,
              email: item.delivery_recipient_email,
              countryCode: item.delivery_recipient_phone_cc,
              phone:
                typeof item.delivery_recipient_phone === "object"
                  ? item.delivery_recipient_phone?.number
                  : item.delivery_recipient_phone?.split("-")?.[1],
              deliveryDate: item.delivery_recipient_send_date,
              areaCode: item.delivery_recipient_area_code,
            }
          : {};

      const callback = () => {
        this.handleUpdateCart(
          item?.id,
          {
            priceOptions: units,
            packageType: package_type,
            preferredDate: preferred_date,
            preferredHour: preferred_time,
            totalPrice: amount,
            currency: this.props.user?.currencyPref,
            ...giftOptions,
          },
          true
        );
      };

      clearTimeout(this.updateAmountTimeout);
      this.updateAmountTimeout = setTimeout(
        callback,
        this.props.user?.isLoggedIn ? 1250 : 650
      );
    }
  };

  /**
   * Remove comments while activating cart system 
   * 
 
  handleRemoveFromCart = (e, id) => {
    if (e) e.preventDefault();

    this.setState({ isTotalCalculating: true });

    const cart = this.props.cart;

    const item = cart.items.filter((data) => data.id === id);

    if (item.length) {
      const index = cart.items.indexOf(item[0]);

      if (index > -1) {
        if (this.props.user.isLoggedIn) {
          API.delete(`cart/${id}`, {
            headers: {
              ...headers,
              "Accept-Language": this.props.lang,
              Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
            },
          }).catch((err) => handleErrors(err));
        }
        cart.items.splice(index, 1);

        this.props.updateCart(cart.items);

        clearTimeout(this.calcTotalTimeout);
        this.calcTotalTimeout = setTimeout(this.calculateTotal, 2000);
      }
    }
  };*/

  handleRemoveFromCart = (e, goBack = false) => {
    if (e) e.preventDefault();

    if (this.handleRemoveItem())
      this.setState({ leavingPageConfirmed: true }, () =>
        goBack
          ? this.props.history.goBack()
          : this.props.history.push(global.home.links[this.props.lang])
      );
  };

  handleUpdateCart = (id, options, quickUpdate = false) => {
    if (!id) return;

    const { priceOptions, packageType, totalPrice, currency } = options;
    const [item] = this.props?.cart?.items;

    const counts = quickUpdate
      ? priceOptions
          ?.filter((po) => po.quantity > 0)
          .map((po, index) => ({ ...po, index: index + 1 }))
      : Object.values(priceOptions)
          ?.filter((po) => po.count > 0)
          .map((po, index) => ({
            ...po,
            index: index + 1,
          }));

    if (packageType === PACKAGE_TYPES.PICKADATE) {
      if (
        item.booking_mode !== BOOKING_MODE.TICKET &&
        options.preferredDate === ""
      )
        return false;
    }

    if (
      packageType === PACKAGE_TYPES.GIFT ||
      packageType === PACKAGE_TYPES.PASSGIFT
    ) {
      if (
        options.firstName === "" ||
        options.lastName === "" ||
        options.email === "" ||
        options.phone === "" ||
        options.deliveryDate === ""
      )
        return false;
    }

    if (counts.length) {
      this.setState({
        isUpdatingPackage: true,
        isUpdatingSuccessful: false,
        isUpdating: true,
      });

      const units = counts?.map((po) => {
        const isPerPerson = item.selectedPackage?.pricing_is_per_person
          ? true
          : false;
        const unit = {
          price_id: po.price_id,
          gift_amount: null,
          quantity: isPerPerson ? (quickUpdate ? po.quantity : po.count) : 1,
          name: quickUpdate ? po.name : po.label,
          price: po.price,
          amount:
            parseFloat(po.price).toFixed(2) * parseFloat(po.count).toFixed(2),
          id: po.id,
          participant_count: po.participant_count,
          index: po.index,
        };

        if (!isPerPerson)
          unit.participant = [{ id: po.id, quantity: po.count }];

        return {
          ...unit,
        };
      });

      const { product, selectedPackage } = item;

      let cartItem = {
        product_id: product.id,
        id,
        temp_id: item.temp_id,
        package_type: packageType,
        units,
        itemType: 1,
        selectedPackage,
        productPackages: item.productPackages,
        package_id: selectedPackage.id,
        booking_mode: item.booking_mode,
        pricing_is_per_person: selectedPackage.pricing_is_per_person,
        product: {
          id: product.id,
          title: product.title,
          code: product.code,
          slug: product.slug,
          cover_image_url: product.cover_image_url,
          provider: product.provider,
          category: product.category,
          location: product.location,
          stats: product.stats,
          video_url: product.video_url,
        },
        amount: totalPrice,
      };

      if (packageType === PACKAGE_TYPES.PICKADATE) {
        cartItem = {
          ...cartItem,
          preferred_date: options.preferredDate,
          preferred_time: options.preferredHour,
          alternative_date: options.alternativeDate,
          alternative_time: options.alternativeHour,
          is_product_time:
            item.booking_mode === BOOKING_MODE.DATEANDTIME ? true : false,
        };
      } else if (packageType === PACKAGE_TYPES.OPENENDED) {
      } else if (
        packageType === PACKAGE_TYPES.GIFT ||
        packageType === PACKAGE_TYPES.PASSGIFT
      ) {
        cartItem = {
          ...cartItem,
          gift_message: options.message,
          delivery_recipient_first_name: options.firstName,
          delivery_recipient_last_name: options.lastName,
          delivery_recipient_email: options.email,
          delivery_recipient_phone_cc: options.countryCode,
          delivery_recipient_area_code: options.areaCode,
          delivery_recipient_phone_w_out_code: String(options.phone)?.replace(
            /\s/g,
            ""
          ),
          delivery_recipient_phone:
            "+" +
            String(options.areaCode) +
            "-" +
            String(options.phone)?.replace(/\s/g, ""),
          delivery_recipient_send_date: options.deliveryDate,
        };
      } else if (packageType === PACKAGE_TYPES.PASS) {
      } else {
        return false;
      }

      const cart = this.props.cart;

      const updatedCart = cart.items.map((data) => {
        if (data.id === id) {
          return { ...data, ...cartItem };
        }

        return data;
      });

      if (this.props.user.isLoggedIn) {
        const params = {
          ccy: currency.code,
          ...cartItem,
          id,
          units: units.filter((po) => po.quantity > 0),
          selectedPackage: undefined,
          productPackages: undefined,
          product: undefined,
          temp_id: undefined,
        };

        API.patch("cart", params, {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        })
          .then((response) => {
            if (response.data.status) {
              const { data: cart } = response.data;

              cart.forEach((data) => {
                if (data.package_type === 3 || data.package_type === 4) {
                  data.delivery_recipient_area_code =
                    data.delivery_recipient_phone.area_code;
                  data.delivery_recipient_phone_cc =
                    data.delivery_recipient_phone.country_code;
                  data.delivery_recipient_phone_w_out_code =
                    data.delivery_recipient_phone.number;
                }

                data.product.provider = product.provider;
                data.product.category = product.category;
              });

              this.props.updateCart([{ ...cartItem, ...cart[0] }]);

              setTimeout(() => {
                this.setState(
                  {
                    isUpdatingPackage: false,
                    isUpdatingSuccessful: true,
                  },
                  () =>
                    setTimeout(() => this.setState({ isUpdating: false }), 500)
                );
                this.calculateTotal();
              }, 10);
            }
          })
          .catch((err) => {
            handleErrors(err);
            this.setState(
              {
                isUpdatingPackage: false,
                isUpdatingSuccessful: false,
              },
              () => setTimeout(() => this.setState({ isUpdating: false }), 500)
            );
            return false;
          });
      } else {
        toastr.clear();
        toastr.success(this.props.translate("global.updated") + "!");
        this.props.updateCart(updatedCart);

        setTimeout(() => {
          this.setState(
            {
              isUpdatingPackage: false,
              isUpdatingSuccessful: true,
            },
            () => setTimeout(() => this.setState({ isUpdating: false }), 500)
          );
          this.calculateTotal();
        }, 10);
      }
    }
  };

  handleUpdateGiftPackage = (id, units) => {
    const { priceOptions, totalPrice, currency } = units;

    if (
      units.firstName === "" ||
      units.lastName === "" ||
      units.email === "" ||
      units.phone === "" ||
      units.deliveryDate === ""
    )
      return false;

    this.setState({ isUpdatingPackage: true, isUpdatingSuccessful: false });

    const allUnits = [
      {
        price_id: priceOptions[0].id,
        id: priceOptions[0].id,
        gift_amount: parseInt(priceOptions[0].gift_amount),
        amount: parseInt(priceOptions[0].gift_amount),
        quantity: priceOptions[0].count,
      },
    ];

    let cartItem = {
      units: allUnits,
      amount: totalPrice,
      preferred_date: "",
      preferred_time: "",
      alternative_date: "",
      alternative_time: "",
      is_product_time: "",
      gift_message: units.message,
      delivery_recipient_first_name: units.firstName,
      delivery_recipient_last_name: units.lastName,
      delivery_recipient_email: units.email,
      delivery_recipient_phone_cc: units.countryCode,
      delivery_recipient_phone_area_code: units.areaCode,
      delivery_recipient_phone: "+" + units.areaCode + "-" + units.phone,
      delivery_recipient_phone_w_out_code: units.phone,
      delivery_recipient_send_date: units.deliveryDate,
    };

    const cart = this.props.cart;

    const updatedCart = cart.items.map((data) => {
      if (data.id === id) {
        return { ...data, ...cartItem };
      }

      return data;
    });

    if (this.props.user.isLoggedIn) {
      const params = {
        ccy: currency.code,
        ...cartItem,
        id: id,
        package_type: 4,
      };

      API.patch("cart", params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            toastr.clear();
            toastr.success(this.props.translate("global.updated") + "!");

            const { data } = response.data;

            const cart = data;

            cart.forEach((data) => {
              if (data.package_type === 3 || data.package_type === 4) {
                data.delivery_recipient_area_code =
                  data.delivery_recipient_phone.area_code;
                data.delivery_recipient_phone_cc =
                  data.delivery_recipient_phone.country_code;
                data.delivery_recipient_phone_w_out_code =
                  data.delivery_recipient_phone.number;
              }
            });

            this.props.updateCart(cart);

            setTimeout(() => {
              this.setState({
                isUpdatingPackage: false,
                isUpdatingSuccessful: true,
              });
              this.calculateTotal();
            }, 10);
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isUpdatingPackage: false });
          return false;
        });
    } else {
      toastr.clear();
      toastr.success(this.props.translate("global.updated") + "!");
      this.props.updateCart(updatedCart);

      setTimeout(() => {
        this.setState({
          isUpdatingPackage: false,
          isUpdatingSuccessful: true,
        });
        this.calculateTotal();
      }, 10);
    }
  };

  calculateTotal = () => {
    let total = 0;
    const cart = this.props.cart;
    if (cart && cart.items && cart.items.length) {
      cart.items.forEach((data) => {
        if (data.package_type === 4) {
          const unit = data.units[0];
          total += parseFloat(
            parseFloat(unit.gift_amount).toFixed(2) * unit.quantity.toFixed(2)
          );
        } else {
          let expTotal = 0;
          data.units.map((data) => {
            expTotal += parseFloat(
              data.quantity.toFixed(2) * parseFloat(data.price).toFixed(2)
            );
            data.amount = parseFloat(
              data.quantity.toFixed(2) * parseFloat(data.price).toFixed(2)
            );

            return true;
          });
          data.amount = parseFloat(expTotal);
          total += parseFloat(expTotal);

          return true;
        }
      });
    }

    let cartTotal = parseFloat(total).toFixed(2);
    let discountPrice = 0.0;
    let promoPrice = 0.0;

    if (this.state.selectedGiftCards.length) {
      discountPrice = parseFloat(
        this.state.selectedGiftCards[0].amount
      ).toFixed(2);
    }

    if (this.state.promoCodeApplied) {
      let { is_percentage, amount } = this.state.promoCodeApplied;
      amount = parseFloat(amount);

      promoPrice = parseFloat(
        is_percentage ? cartTotal * amount : amount
      ).toFixed(2);
    }

    this.props.updateCart([...cart.items]);

    this.setState({
      cartTotal,
      discountPrice,
      promoPrice,
      isTotalCalculating: false,
    });
  };

  handleGetContracts = () => {
    API.get("checkout/contracts", {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((res) => {
        const { data } = res.data;

        const saleAgreement =
          data[0].type === 1 ? data[0].content : data[1].content;
        const preInfoAgreement =
          data[1].type === 2 ? data[1].content : data[0].content;

        this.setState({ saleAgreement, preInfoAgreement });
      })
      .catch((err) => handleErrors(err));
  };

  handleRedirectToPayment = (e) => {
    if (e) e.preventDefault();

    if (this.props.user.isLoggedIn)
      this.props.history.push(
        auth.payment.links[this.props.activeLanguage.code]
      );
    else this.props.toggleAuthModal();
  };

  handleShowAuthModal = (e) => {
    if (e) e.preventDefault();

    this.props.toggleAuthModal();
  };

  handleUpdatePersonalInfo = (e) => {
    const { name, value } = e.target;

    const personalInfo = { ...this.state.personalInfo };

    personalInfo[name] = value;

    this.setState({ personalInfo });

    this.checkPaymentInfo();
  };

  handleSetCountryCode = (value) => {
    const valueArr = value.split("+");

    const countryCode = valueArr[0].trim();
    const areaCode = valueArr[1];

    const personalInfo = { ...this.state.personalInfo, countryCode, areaCode };

    this.setState({ personalInfo });
  };

  handleUpdateInvoiceInfo = (name, value) => {
    const invoiceInfo = { ...this.state.invoiceInfo };

    invoiceInfo[name] = value;

    this.setState({ invoiceInfo });

    this.checkPaymentInfo();
  };

  handleUpdateLocationInfo = (location = { name: "", id: "" }) => {
    const invoiceInfo = { ...this.state.invoiceInfo, ...location };
    this.setState({ invoiceInfo }, this.checkPaymentInfo);
  };

  handleUpdateCreditCardInfo = (infoObj) => {
    const cardInfo = { ...infoObj };

    this.setState({ cardInfo }, () => {
      if (
        infoObj.totalInterest &&
        this.state.cardInfo.totalInterest.toString() !==
          this.state.cartTotal.toString()
      ) {
        this.setState({ newTotal: this.state.cardInfo.totalInterest });
      } else {
        this.setState({ newTotal: 0.0 });
      }

      setTimeout(this.checkPaymentInfo, 20);
    });
  };

  handleSetPaymentMethod = (type) => {
    this.setState({ paymentMethod: type });
    this.checkPaymentInfo();
  };

  handleApplyGiftCard = (giftCard) => {
    const selectedGiftCards = [...this.state.selectedGiftCards, giftCard];
    this.setState({ selectedGiftCards, isTotalCalculating: true });

    clearTimeout(this.calcTotalTimeout);
    this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);

    this.checkPaymentInfo();
  };

  handleRemoveGiftCard = (giftCardId) => {
    const selectedGiftCards = this.state.selectedGiftCards.filter(
      (data) => data.id !== giftCardId
    );
    this.setState({ selectedGiftCards, isTotalCalculating: true });

    clearTimeout(this.calcTotalTimeout);
    this.calcTotalTimeout = setTimeout(this.calculateTotal, 300);

    this.checkPaymentInfo();
  };

  handleSetAgreements = (e) =>
    this.setState(
      { agreementsAgreed: e.target.checked },
      this.checkPaymentInfo
    );

  handlePay = (e) => {
    if (e) e.preventDefault();
    if (!this.state.isPhoneConfirmed) this.sendConfirmationCode();
    else this.paying();
  };

  sendConfirmationCode = () => {
    let phone = parseInt(
      this.state.personalInfo.phone.toString().replace(/[^0-9]+/gi, "")
    );

    if (!phone) {
      return false;
    }
    while (phone.toString().charAt(0) === "0") {
      phone = parseInt(phone.substring(1));
    }

    if (!phone) {
      return false;
    }

    const params = {
      phone:
        this.state.personalInfo.areaCode +
        "-" +
        this.state.personalInfo.phone.toString().replace(/\s/g, ""),
      phone_cc: this.state.personalInfo.countryCode,
    };

    API.post("account/phone-verification/start", params, {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        if (!response.data.info.is_phone_verified) {
          this.setState({ isConfirmPhoneOn: true });
        } else {
          this.paying();
        }
      })
      .catch((err) => handleErrors(err));
  };

  handleClosePhoneConfirmModal = (e) => {
    if (e) e.preventDefault();

    this.setState({ isConfirmPhoneOn: false, confirmationCode: "" });
  };

  handleConfirmPhone = (e) => {
    if (e) e.preventDefault();

    if (this.state.confirmationCode !== "") {
      this.setState({ isConfirmingPhone: true });

      const params = { code: this.state.confirmationCode };

      API.post("account/phone-verification/verify", params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          const confirmResponse = {
            status: true,
            success: response.data.status,
            message: response.data.info.message,
          };

          const personalInfo = { ...this.state.personalInfo };
          personalInfo.isPhoneConfirmed = response.data.status;

          setTimeout(
            () => this.setState({ confirmResponse, personalInfo }),
            1200
          );
          if (response.data.status) {
            setTimeout(() => {
              if (this.state.isConfirmPhoneOn)
                this.setState({ isConfirmPhoneOn: false });
            }, 4200);
          } else {
            setTimeout(
              () =>
                this.setState({ confirmResponse, isConfirmingPhone: false }),
              1200
            );
          }
        })
        .catch((err) => {
          handleErrors(err);
          setTimeout(() => this.setState({ isConfirmingPhone: false }), 20);
        });
    }
  };

  paying = () => {
    this.setState({ isPaying: true });

    const personalInfo = {
      first_name: this.state.personalInfo.firstName,
      last_name: this.state.personalInfo.lastName,
      phone:
        "+" +
        this.state.personalInfo.areaCode +
        "-" +
        this.state.personalInfo.phone,
      email: this.state.personalInfo.email,
    };

    const invoiceType = {
      billing_type: this.state.invoiceInfo.type,
      idn:
        this.state.invoiceInfo.type.toString() === "1" &&
        !this.state.invoiceInfo.isNotCitizen
          ? this.state.invoiceInfo.identityNo
          : "",
      citizen_of_turkey:
        this.state.invoiceInfo.type.toString() === "1"
          ? !this.state.invoiceInfo.isNotCitizen
          : "",
      company_name:
        this.state.invoiceInfo.type.toString() === "2"
          ? this.state.invoiceInfo.corporateName
          : "",
      tax_office:
        this.state.invoiceInfo.type.toString() === "2"
          ? this.state.invoiceInfo.taxLocation
          : "",
      tax_number:
        this.state.invoiceInfo.type.toString() === "2"
          ? this.state.invoiceInfo.taxNumber
          : "",
      address_line: this.state.invoiceInfo.address,
      country_id: this.state.invoiceInfo.country,
      city_id: this.state.invoiceInfo.city,
      state_id: this.state.invoiceInfo.district,
      zip: this.state.invoiceInfo.postalCode,
    };

    const cardInfo = {
      card_holder_name: this.state.cardInfo.name,
      card_no: this.state.cardInfo.number.replace(/ /g, ""),
      card_exp_month: this.state.cardInfo.expMon,
      card_exp_year: this.state.cardInfo.expYear,
      card_cvc: this.state.cardInfo.cvc,
      card_installment: this.state.cardInfo.installment,
      card_3d_payment: this.state.cardInfo.is3DSecure ? 1 : 0,
    };

    const gift_card = this.state.selectedGiftCards.length
      ? this.state.selectedGiftCards[0].cardNo
      : "";

    const promotion_code = this.state.promoCodeApplied
      ? this.state.promoCodeApplied.code
      : ``;

    const data = {
      ccy: this.props.user.currencyPref.code,
      ...personalInfo,
      ...invoiceType,
      ...cardInfo,
      gift_card,
      payment_type: this.state.paymentMethod,
      promotion_code,
    };

    const configs = {
      headers: {
        ...headers,
        "Accept-Language": this.props.user.langPref,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    };

    API.post("checkout", data, configs)
      .then((response) => {
        if (response.data.status) {
          if (response.data.results.threeds)
            this.setState(
              {
                pageContentFor3DS: response.data.results.htmlContent,
              },
              () =>
                this.setState({ is3DSecureActive: true }, () => {
                  setTimeout(() => {
                    const forms = document.querySelectorAll(
                      ".threeds-form-holder form"
                    );

                    forms.forEach((form) => {
                      if (form.action !== "Default") form.submit();
                    });
                  }, 50);
                })
            );
          else
            setTimeout(() => {
              this.setState({ isPaying: false, payingError: false });
              this.props.history.push(
                auth.paymentThanks.links[this.props.activeLanguage.code] +
                  `?orderNo=${response.data.results.orderNo}`
              );
            }, 200);
        } else {
          toastr.clear();
          toastr.error(response.data.info.message);

          this.setState({ isPaying: false, payingError: true });
        }
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isPaying: false, payingError: true });
      });
  };

  handleShowSaleAgreementModal = (e) => {
    e.preventDefault();

    this.setState({ isSaleAgreementModalOn: true });
  };

  handleShowPreInfoAgreementModal = (e) => {
    e.preventDefault();

    this.setState({ isPreInformationModalOn: true });
  };

  handleRemoveItem = (location) => {
    const c = window.confirm(
      `Çıkmak istediğinize emin misiniz?\nBu ürünü satın almak için yeniden işlem yapmanız gerekir.`
    );
    if (c) {
      if (this.props.user.isLoggedIn) {
        API.delete(`cart/${this.props.cart.items[0].id}`, {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        }).catch((err) => handleErrors(err));
      }

      this.props.updateCart([]);
      return true;
    } else {
      return false;
    }
  };

  render() {
    const mutualProps = {
      cart: this.props.cart,
      currency: this.props.user.currencyPref,
      translate: this.props.translate,
      lang: this.props.activeLanguage.code,
      cartTotal: this.state.cartTotal,
      newTotal: this.state.newTotal,
      discountPrice: this.state.discountPrice,
      user: this.props.user,
      history: this.props.history,
    };

    const contractsAreas =
      this.props.cart && this.props.cart.items && this.props.cart.items.length
        ? {
            ":product-name":
              this.props.cart.items[0].package_type === 4
                ? this.props.translate(
                    "payment.pick_a_payment_method.gift_card_contract_product_name"
                  )
                : this.props.cart.items[0].product.title,
            ":expire-date":
              this.props.cart.items[0].package_type === 4
                ? dayjs(new Date()).add(1, "year").format("DD MMMM YYYY")
                : dayjs(new Date()).add(6, "months").format("DD MMMM YYYY"),
            ":quantity":
              this.props.cart.items[0].package_type === 4
                ? 1
                : this.props.cart.items[0].units.map(
                    (data) => `${data.name} x${data.quantity}<br/>`
                  ),
            ":purchase-date": dayjs(new Date()).format("DD MMMM YYYY"),
            ":amount-due": currencyFormatter(
              this.props.user.currencyPref,
              this.state.cartTotal,
              this.props.lang
            ),
          }
        : {};

    return (
      <div className="Cart" data-step={this.state.step}>
        {this.state.isLoading ? (
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <BLLoader />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col">
                <Steps
                  step={this.state.step}
                  translate={this.props.translate}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {this.state.step === 2 && this.state.isUserUpdated ? (
                  <Payment
                    {...mutualProps}
                    personalInfo={this.state.personalInfo}
                    handleUpdatePersonalInfo={this.handleUpdatePersonalInfo}
                    invoiceInfo={this.state.invoiceInfo}
                    handleUpdateInvoiceInfo={this.handleUpdateInvoiceInfo}
                    handleUpdateCreditCardInfo={this.handleUpdateCreditCardInfo}
                    selectedGiftCards={this.state.selectedGiftCards}
                    handleApplyGiftCard={this.handleApplyGiftCard}
                    handleRemoveGiftCard={this.handleRemoveGiftCard}
                    handlePay={this.handlePay}
                    isPaying={this.state.isPaying}
                    payingError={this.state.payingError}
                    handleShowSaleAgreementModal={
                      this.handleShowSaleAgreementModal
                    }
                    handleShowPreInfoAgreementModal={
                      this.handleShowPreInfoAgreementModal
                    }
                    isOkayToPay={this.state.isOkayToPay}
                    handleSetCountryCode={this.handleSetCountryCode}
                    handleSetPaymentMethod={this.handleSetPaymentMethod}
                    paymentMethod={this.state.paymentMethod}
                    agreementsAgreed={this.state.agreementsAgreed}
                    handleSetAgreements={this.handleSetAgreements}
                    handleUpdateLocationInfo={this.handleUpdateLocationInfo}
                    handleGetContracts={this.handleGetContracts}
                    promoCodeApplied={this.state.promoCodeApplied}
                    handleApplyPromoCode={this.handleApplyPromoCode}
                    promoPrice={this.state.promoPrice}
                  />
                ) : this.state.step === 3 ? (
                  <PaymentThanks
                    translate={this.props.translate}
                    lang={this.props.lang}
                    cart={this.props.cart}
                    resetCart={this.props.resetCart}
                    user={this.props.user}
                    selectedGiftCards={this.state.selectedGiftCards}
                  />
                ) : this.state.step === 1 ? (
                  <CartPage
                    {...mutualProps}
                    toggleAuthModal={this.props.toggleAuthModal}
                    handleUpdateAmount={this.handleUpdateAmount}
                    isTotalCalculating={this.state.isTotalCalculating}
                    isUpdatingPackage={this.state.isUpdatingPackage}
                    isUpdatingSuccessful={this.state.isUpdatingSuccessful}
                    handleUpdateCart={this.handleUpdateCart}
                    handleUpdateGiftPackage={this.handleUpdateGiftPackage}
                    handleRedirectToPayment={this.handleRedirectToPayment}
                    handleShowAuthModal={this.handleShowAuthModal}
                    handleRemoveFromCart={this.handleRemoveFromCart}
                    promoCodeApplied={this.state.promoCodeApplied}
                    isUpdating={this.state.isUpdating}
                  />
                ) : (
                  <span className="w-100 d-flex justify-content-center mt-5">
                    <BLLoader />
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <PopularActivities
            translate={this.props.translate}
            currency={this.props.user.currencyPref}
            listInfo={{
              type: auth.paymentThanks.type,
              name: `En Sevilen Deneyimler`,
            }}
          />
        )}

        <PhoneConfirmation
          handleClosePhoneConfirmModal={this.handleClosePhoneConfirmModal}
          confirmationCode={this.state.confirmationCode}
          handleUpdate={(value) => this.setState({ confirmationCode: value })}
          isConfirmPhoneOn={this.state.isConfirmPhoneOn}
          phone={this.state.personalInfo.phone}
          handleConfirmPhone={this.handleConfirmPhone}
          confirmResponse={this.state.confirmResponse}
          isConfirmingPhone={this.state.isConfirmingPhone}
          translate={this.props.translate}
          sendConfirmationCode={this.sendConfirmationCode}
          removeHeaderFix={true}
        />

        <CSSTransition
          in={this.state.isSaleAgreementModalOn}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() =>
              this.setState({ isSaleAgreementModalOn: false })
            }
            headerFix={true}
          >
            <div className="modal-content-holder overflow-auto">
              <div
                className="contract-content"
                dangerouslySetInnerHTML={{
                  __html: reformatText(this.state.saleAgreement, {
                    ...contractsAreas,
                    ":user-name": `${this.state.personalInfo.firstName} ${this.state.personalInfo.lastName}`,
                    ":user-address": `${this.state.invoiceInfo.address} ${this.state.invoiceInfo.districtName} ${this.state.invoiceInfo.cityName} ${this.state.invoiceInfo.countryName} ${this.state.invoiceInfo.postalCode}`,
                    ":user-phone": `+${this.state.personalInfo.areaCode}${this.state.personalInfo.phone}`,
                    ":user-email": this.state.personalInfo.email,
                  }),
                }}
              />
            </div>
          </BLModal>
        </CSSTransition>

        <CSSTransition
          in={this.state.isPreInformationModalOn}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={() =>
              this.setState({ isPreInformationModalOn: false })
            }
            headerFix={true}
          >
            <div className="modal-content-holder overflow-auto">
              <div
                className="contract-content"
                dangerouslySetInnerHTML={{
                  __html: reformatText(this.state.preInfoAgreement, {
                    ...contractsAreas,
                    ":user-name": `${this.state.personalInfo.firstName} ${this.state.personalInfo.lastName}`,
                    ":user-address": `${this.state.invoiceInfo.address} ${this.state.invoiceInfo.districtName} ${this.state.invoiceInfo.cityName} ${this.state.invoiceInfo.countryName} ${this.state.invoiceInfo.postalCode}`,
                    ":user-phone": `+${this.state.personalInfo.areaCode}${this.state.personalInfo.phone}`,
                    ":user-email": this.state.personalInfo.email,
                  }),
                }}
              />
            </div>
          </BLModal>
        </CSSTransition>
        <CSSTransition
          in={this.state.is3DSecureActive}
          timeout={300}
          unmountOnExit
        >
          <div
            className="threeds-form-holder d-none"
            dangerouslySetInnerHTML={{ __html: this.state.pageContentFor3DS }}
          ></div>
        </CSSTransition>
        <Prompt
          message={(location) => {
            if (!this.props.cart.items.length) return true;
            if (this.state.leavingPageConfirmed) return true;

            return location.pathname === auth.payment.links[this.props.lang] ||
              location.pathname === global.summary.links[this.props.lang] ||
              location.pathname === auth.paymentThanks.links[this.props.lang] ||
              location.pathname === guest.login.links[this.props.lang] ||
              this.props.location.pathname ===
                auth.paymentThanks.links[this.props.lang]
              ? true
              : this.handleRemoveItem();
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cartItem) => dispatch(update(cartItem)),
    resetCart: () => dispatch(reset()),
  };
};

export default connect(null, mapDispatchToProps)(Cart);
