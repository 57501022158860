import React from "react";
import BLInput from "../../../../utils/components/BLInput";
import BLCheckbox from "../../../../utils/components/BLCheckbox";

export const Personal = (props) => {
  return (
    <>
      <div className="row">
        <div className="col citizenship-check">
          <BLCheckbox
            name="isNotCitizen"
            label={props.translate("payment.billing_info.im_not_a_citizen")}
            checked={props.invoiceInfo.isNotCitizen}
            changed={(e) =>
              props.handleUpdateInvoiceInfo(e.target.name, e.target.checked)
            }
          />
        </div>
      </div>
      {!props.invoiceInfo.isNotCitizen ? (
        <div className="row">
          <div className="col">
            <BLInput
              label={props.translate("payment.my_personal_info.identity_no")}
              type="text"
              name="identityNo"
              maxLength={11}
              value={props.invoiceInfo.identityNo}
              validationType="idNo"
              changed={(e) =>
                props.handleUpdateInvoiceInfo(e.target.name, e.target.value)
              }
              inputProps={{ inputMode: "numeric" }}
              limitCharacters={["number"]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
