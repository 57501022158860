import React from "react";
import { withLocalize } from "react-localize-redux";
import Helmet from "react-helmet";

import bucket from "../../assets/images/bucket.svg";

const BLPageLoader = (props) => {
  return (
    <div className="bl-page-loader position-absolute d-flex justify-content-center align-items-center">
      <Helmet>
        <html className="page-loader-on" />
        <body className="page-loader-on" />
      </Helmet>
      <div className="loader d-flex flex-column position-relative">
        <span className="box position-absolute"></span>
        <span className="box position-absolute"></span>
        <div className="code">
          <img src={bucket} alt="Bucket" />
        </div>
        <span className="txt text-center">
          {props.activeLanguage && props.translate("global.loading")}
        </span>
      </div>
    </div>
  );
};

export default withLocalize(BLPageLoader);
