import React, { Component } from "react";

export default class CookiePolicy extends Component {
  render() {
    const { props } = this;
    return (
      <div
        className="page-holder cookie-policy selectable"
        data-section="cookiePolicy"
      >
        <h1 className="p-title">
          {props.translate("static_pages.cookie_policy.title")}
        </h1>
        <p>{props.translate("static_pages.cookie_policy.intro.line_1")}</p>
        <p>{props.translate("static_pages.cookie_policy.intro.line_2")}</p>
        <p>{props.translate("static_pages.cookie_policy.intro.line_3")}</p>
        <h4>{props.translate("static_pages.cookie_policy.content_2.title")}</h4>
        <p>{props.translate("static_pages.cookie_policy.content_2.line_1")}</p>
        <p>{props.translate("static_pages.cookie_policy.content_2.line_2")}</p>
        <h5>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_1.title"
          )}
        </h5>
        <p>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_1.line_1"
          )}
        </p>
        <p>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_1.line_2"
          )}
        </p>
        <p>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_1.line_3"
          )}
        </p>
        <h5>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_2.title"
          )}
        </h5>
        <p>
          {props.translate(
            "static_pages.cookie_policy.content_2.sub_content_2.line_1"
          )}
        </p>
        <h4>{props.translate("static_pages.cookie_policy.content_3.title")}</h4>
        <p>{props.translate("static_pages.cookie_policy.content_3.line_1")}</p>
        <ul>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_1"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_2"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_3"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_4"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_5"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_3.list.item_6"
            )}
          </li>
        </ul>
        <p>{props.translate("static_pages.cookie_policy.content_3.line_2")}</p>
        <h4>{props.translate("static_pages.cookie_policy.content_4.title")}</h4>
        <p>{props.translate("static_pages.cookie_policy.content_4.line_1")}</p>
        <ul>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_4.list.item_1"
            )}
            <ul>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_1.item_1"
                )}
              </li>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_1.item_2"
                )}
              </li>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_1.item_3"
                )}
              </li>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_1.item_4"
                )}
              </li>
            </ul>
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_4.list.item_2"
            )}
            <ul>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_2.item_1"
                )}
                <ul>
                  <li>
                    {props.translate(
                      "static_pages.cookie_policy.content_4.list.sub_list_2.sub_list.item_1"
                    )}
                  </li>
                  <li>
                    {props.translate(
                      "static_pages.cookie_policy.content_4.list.sub_list_2.sub_list.item_2"
                    )}
                  </li>
                  <li>
                    {props.translate(
                      "static_pages.cookie_policy.content_4.list.sub_list_2.sub_list.item_3"
                    )}
                  </li>
                  <li>
                    {props.translate(
                      "static_pages.cookie_policy.content_4.list.sub_list_2.sub_list.item_4"
                    )}
                  </li>
                  <li>
                    {props.translate(
                      "static_pages.cookie_policy.content_4.list.sub_list_2.sub_list.item_5"
                    )}
                  </li>
                </ul>
              </li>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_2.item_2"
                )}
              </li>
            </ul>
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_4.list.item_3"
            )}
            <ul>
              <li>
                {props.translate(
                  "static_pages.cookie_policy.content_4.list.sub_list_3.item_1"
                )}
              </li>
            </ul>
          </li>
        </ul>
        <h4>{props.translate("static_pages.cookie_policy.content_5.title")}</h4>
        <p>{props.translate("static_pages.cookie_policy.content_5.line_1")}</p>
        <p>{props.translate("static_pages.cookie_policy.content_5.line_2")}</p>
        <p>{props.translate("static_pages.cookie_policy.content_5.line_3")}</p>
        <h4>{props.translate("static_pages.cookie_policy.content_6.title")}</h4>
        <p>{props.translate("static_pages.cookie_policy.content_6.line_1")}</p>
        <p>{props.translate("static_pages.cookie_policy.content_6.line_2")}</p>
        <ul>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_6.list.item_1"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_6.list.item_2"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_6.list.item_3"
            )}
          </li>
          <li>
            {props.translate(
              "static_pages.cookie_policy.content_6.list.item_4"
            )}
          </li>
        </ul>
      </div>
    );
  }
}
