import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth, global } from "../../App/routes";
import Helmet from "react-helmet";
import { dataLayer__purchaseCompleted } from "../../utils/DataLayerEvents";
import packageTypes, { PACKAGE_TYPES } from "../../utils/db/packageTypes";

class PaymentThanks extends Component {
  steps = [
    { key: 1, step: 1, content: this.props.translate("payment_thanks.step1") },
    { key: 2, step: 2, content: this.props.translate("payment_thanks.step2") },
    { key: 3, step: 3, content: this.props.translate("payment_thanks.step3") },
  ];

  state = {
    orderNo: "",
    packageType: "",
  };

  componentDidMount = () => {
    if (this.props.cart.items.length)
      this.setState({ packageType: this.props.cart.items[0].package_type });

    const { search } = window.location;

    if (search) {
      const orderNo = search.substring(1, search.length).split("=")[1];
      this.setState({ orderNo }, () => {
        if (this.props.cart.items.length) {
          const { product, units, package_type, preferred_date, amount } =
            this.props.cart.items[0];
          const { isLoggedIn, hashed_email, id, gender, has_bought_before } =
            this.props.user;

          const checkout = {
            id: orderNo,
            amount,
            gift_card: this.props.selectedGiftCards.length
              ? this.props.selectedGiftCards[0]
              : "",
          };

          const isGiftCard = package_type === PACKAGE_TYPES.GIFTCARD;

          const dataLayerProduct = {
            title: isGiftCard ? `Hediye Kartı` : product.title,
            code: isGiftCard ? `Hediye Kartı` : product.code,
            units,
            category: isGiftCard ? `` : product.category,
            package_type: isGiftCard
              ? `Hediye Kartı`
              : this.props.translate(
                  `package_options.${packageTypes[package_type].name}`
                ),
            video_url: product?.video_url,
            location: product?.location,
            stats: product?.stats,
            provider: product?.provider,
            preferred_date,
          };

          const page = {
            title: document.title,
            type: auth.paymentThanks.type,
            lang: this.props.user.langPref,
            currency: this.props.user.currencyPref.code,
          };

          const user = {
            isLoggedIn,
            hashed_email,
            id,
            gender:
              gender === "male"
                ? `Erkek`
                : gender === "female"
                ? `Kadın`
                : "Diğer",
            has_bought_before,
          };

          dataLayer__purchaseCompleted(dataLayerProduct, checkout, page, user);

          setTimeout(() => this.props.resetCart, 20);
        }
      });
    }
  };

  render() {
    return (
      <div className="page thanks d-flex flex-column align-items-center">
        <Helmet>
          <title>{this.props.translate("cart.thanks")}</title>
        </Helmet>
        <div className="done-icon">
          <svg
            width="193"
            height="193"
            viewBox="0 0 193 193"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.5 193C149.795 193 193 149.795 193 96.5C193 43.2045 149.795 0 96.5 0C43.2045 0 0 43.2045 0 96.5C0 149.795 43.2045 193 96.5 193Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M136.717 60.611L81.3299 116.766L58.2826 93.6563C56.1614 91.5028 52.7161 91.5028 50.5949 93.6563C48.4684 95.8098 48.4684 99.296 50.5949 101.444L77.5073 128.425C79.7144 130.413 83.0148 130.634 85.195 128.425L144.409 68.3987C146.53 66.2507 146.53 62.759 144.409 60.611C142.283 58.463 138.843 58.463 136.717 60.611Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-56.3607"
                y1="160.16"
                x2="149.46"
                y2="258.969"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#80D88C" />
                <stop offset="0.601105" stopColor="#6DD498" />
                <stop offset="1" stopColor="#38CABB" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 className="congrats">
          {this.props.translate("payment_thanks.title")}
        </h3>
        <p className="payment-done">
          {this.props.translate("payment_thanks.content", {
            order_number: `#${this.state.orderNo}`,
          })}
        </p>
        {this.state.packageType === PACKAGE_TYPES.OPENENDED && (
          <div className="next-steps-holder d-flex flex-column flex-md-row justify-content-center align-items-center">
            {this.steps.map((data) => (
              <div
                key={data.key}
                className="next-step d-flex flex-column justify-content-center align-items-center"
              >
                <span className="step-number rounded-circle d-flex justify-content-center align-items-center">
                  {data.step}
                </span>
                <p className="content m-0 text-center">{data.content}</p>
              </div>
            ))}
          </div>
        )}
        <Link
          to={global.experiences.links[this.props.lang]}
          className="explore-link"
        >
          {this.props.translate("payment_thanks.explore_new_experiences")}
        </Link>
        <span className="or">
          {this.props.translate("global.or")}{" "}
          <Link to={auth.orders.links[this.props.lang]}>
            {this.props.translate("payment_thanks.track_my_orders")}
          </Link>
        </span>
      </div>
    );
  }
}

export default PaymentThanks;
