import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

const Filter = (props) => {
  const [isFilterOn, setIsFilterOn] = useState(true);
  return (
    <div
      className={`filter-holder ${props.disabled ? `d-none` : ``}`}
      id={props.name ? props.name : ""}
    >
      <div
        className={`filter-title d-flex justify-content-between align-items-center ${
          isFilterOn ? `active` : ``
        }`}
        onClick={(e) => {
          e.preventDefault();
          setIsFilterOn(!isFilterOn);
        }}
      >
        {props.title}
        <svg
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 4L9 1"
            stroke="#8E8E8E"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <CSSTransition
        in={isFilterOn}
        timeout={300}
        classNames="filters"
        unmountOnExit
      >
        <div className="filters">{props.children}</div>
      </CSSTransition>
    </div>
  );
};

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default Filter;
