import React, { Component } from "react";
import ApplyGiftCard from "./ApplyGiftCard";
import AppliedGiftCard from "./AppliedGiftCard";
import Axios from "axios";
import API, { headers } from "../../../utils/API";
import { handleErrors } from "../../../utils/helper";
import toastr from "toastr";
import { CSSTransition } from "react-transition-group";

export default class GiftCards extends Component {
  state = {
    giftCards: [],
    selectedGiftCards: this.props.selectedGiftCards,
    isApplyingGiftCard: false,
  };

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    API.get(
      `account/gift_cards?status=0&currency=${this.props.user.currencyPref.code}`,
      {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          cancelToken: this.cancelSource.token,
        },
      }
    )
      .then((response) => {
        const { data } = response.data;

        const giftCards = data.length
          ? data.map((data) => {
              return {
                id: `id-${data.card_number}`,
                cardNo: data.card_number,
                amount: data.credit,
                expiry: data.expiry_date,
                isUsed: false,
                currency: data.currency,
              };
            })
          : [];

        this.setState({ giftCards });
      })
      .catch((err) => handleErrors(err));
  };

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");
  };

  handleApplyGiftCard = (giftCardId, newGiftCard = null) => {
    if (this.props.selectedGiftCards.length < 1) {
      this.setState({ isApplyingGiftCard: true });

      let giftCard = "";

      if (giftCardId === "other" && newGiftCard) {
        this.handleAddGiftCard(newGiftCard);

        setTimeout(() => {
          if (!giftCard) {
            this.setState({ isApplyingGiftCard: false });
            return false;
          }

          setTimeout(() => this.setState({ isApplyingGiftCard: false }), 20);
        }, 100);
      } else if (giftCardId !== "other") {
        giftCard = this.state.giftCards.filter(
          (data) => data.id === giftCardId
        )[0];

        const giftCards = this.state.giftCards.map((data) => {
          const giftCard = { ...data };
          if (giftCardId === giftCard.id) giftCard.isUsed = true;

          return giftCard;
        });

        setTimeout(
          () => this.setState({ giftCards, isApplyingGiftCard: false }),
          20
        );

        this.props.handleApplyGiftCard(giftCard);
      } else {
        this.setState({ isApplyingGiftCard: false });
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  handleAddGiftCard = (newGiftCard) => {
    const card_number = newGiftCard;

    const params = { card_number };

    API.post("account/gift_cards/add", params, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        const { data } = response.data;

        if (!response.data.status) {
          toastr.clear();
          toastr.warning(response.data.info.message);
          return false;
        }

        const giftCards = this.state.giftCards;

        const giftCard = {
          id: `id-${data.card_number}`,
          cardNo: data.card_number,
          amount: data.credit,
          expiry: data.expiry_date,
          isUsed: true,
          currency: data.currency,
        };

        giftCards.push(giftCard);

        this.setState({ giftCards });

        toastr.clear();
        toastr.success(response.data.info.message);

        this.props.handleApplyGiftCard(giftCard);

        return giftCard;
      })
      .catch((err) => handleErrors(err));
  };

  handleDiscardGiftCard = (giftCardId) => {
    const giftCards = this.state.giftCards.map((data) => {
      const giftCard = { ...data };
      if (giftCardId === giftCard.id) giftCard.isUsed = false;

      return giftCard;
    });

    this.setState({ giftCards });

    this.props.handleRemoveGiftCard(giftCardId);
  };

  render() {
    return (
      <div className="gift-cards-area">
        {this.state.giftCards.map(
          (data) =>
            data.isUsed && (
              <AppliedGiftCard
                key={data.id}
                {...data}
                handleDiscardGiftCard={this.handleDiscardGiftCard}
                translate={this.props.translate}
              />
            )
        )}
        <CSSTransition
          in={this.props.selectedGiftCards.length < 1}
          timeout={0}
          unmountOnExit
        >
          <ApplyGiftCard
            key={this.state.giftCards.filter((data) => !data.isUsed).length}
            giftCards={this.state.giftCards}
            handleApplyGiftCard={this.handleApplyGiftCard}
            isApplyingGiftCard={this.state.isApplyingGiftCard}
            translate={this.props.translate}
          />
        </CSSTransition>
      </div>
    );
  }
}
