import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import API, { headers } from "../../utils/API";
import Axios from "axios";
import { handleErrors } from "../../utils/helper";
import ProductSlider from "./PopularActivities/ProductSlider";

import ReactPlaceholder from "react-placeholder/lib";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";

import "react-placeholder/lib/reactPlaceholder.css";
import { dataLayer__itemListClick } from "../../utils/DataLayerEvents";

const ProductPlaceholder = () => (
  <div className="product-placeholder">
    <RectShape
      className="cover-placeholder placeholder-item"
      style={{ height: "auto", margin: 0, marginBottom: 10 }}
    />
    <TextBlock
      className="cat-placeholder placeholder-item"
      style={{ width: "20%", marginBottom: 8 }}
    />
    <TextBlock
      className="name-placeholder placeholder-item"
      rows={2}
      style={{ width: "75%", marginBottom: 5 }}
    />
    <div className="stats-placeholder d-flex justify-content-start align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <RectShape
          key={data}
          className="stat-placeholder placeholder-item"
          style={{
            width: 49,
            height: 22,
            borderRadius: 2,
            marginRight: 12,
            marginTop: 10,
            marginBottom: 15,
          }}
        />
      ))}
    </div>
    <TextBlock
      className="price-placeholder placeholder-item"
      row={1}
      style={{ width: 50, marginBottom: 15 }}
    />
    <TextBlock
      className="availability-placeholder placeholder-item"
      row={1}
      style={{ width: 75, marginBottom: 15 }}
    />
  </div>
);

const placeholder = (
  <div className="product-slider-placeholder w-100 d-flex justify-content-center align-items-center overflow-hidden">
    <div className="products-holder d-flex d-md-none justify-content-center align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
    <div className="products-holder d-none d-md-flex justify-content-start align-items-center">
      {Array.from(Array(4).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
  </div>
);

class Recommends extends Component {
  state = { products: {}, isLoading: true };

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    this.handleGetProducts();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code ||
      prevProps.user.langPref !== this.props.user.langPref
    )
      this.setState({ isLoading: true }, this.handleGetProducts);
  };

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");
  };

  handleGetProducts = () => {
    API.get(
      `suggested_products?currency=${this.props.user.currencyPref.code}`,
      {
        headers: {
          ...headers,
          "Accept-Language": this.props.user.langPref,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          cancelToken: this.cancelSource.token,
        },
      }
    )
      .then((response) => {
        const { data } = response.data;

        // const isLoading = !Boolean(data.length);

        this.setState({ products: data }, () =>
          this.setState({ isLoading: false })
        );
      })
      .catch((err) => handleErrors(err));
  };

  handlePromotionClick = (product) => {
    const listInfo = {
      pageType: this.props.listInfo.type,
      listType: "Widget",
      pageName: this.props.listInfo.name,
      sortType: "",
    };

    dataLayer__itemListClick(product, listInfo);
  };

  render() {
    return this.state.isLoading ? (
      ""
    ) : (
      <section className="bl-recommends w-border">
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center flex-column">
              <h4 className="section-title text-center">
                {this.props.translate("home.recommends.title")}
              </h4>
              <p className="section-desc text-center swiper-fix">
                {this.props.translate("home.recommends.desc")}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ReactPlaceholder
                ready={!this.state.isLoading}
                customPlaceholder={placeholder}
                showLoadingAnimation
                firstLaunchOnly
              >
                <ProductSlider
                  currency={this.props.user.currencyPref}
                  products={this.state.products}
                  user={this.props.user}
                  slidesPerView={this.props.slidesPerView}
                  handlePromotionClick={this.handlePromotionClick}
                  listInfo={this.props.listInfo}
                />
              </ReactPlaceholder>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(Recommends));
