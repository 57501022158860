import React from "react";
import { NavLink } from "react-router-dom";

export const SidebarMenu = (props) => {
  return (
    <div className="sidebar-group d-none d-lg-flex flex-column position-sticky">
      <div className="sidebar-group-list d-flex flex-column align-items-start">
        {props.menuItems.map((item) => (
          <NavLink
            key={item.slug}
            to={item.link}
            className={`sidebar-group-item w-100 d-flex justify-content-between align-items-center ${
              props.selectedSection === item.slug ? `active` : ``
            }`}
          >
            <span>{item.title}</span>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.90624 1.6145L11.6875 8.07284L4.90624 14.5312"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
