import React from "react";
import PropTypes from "prop-types";

import voucherGift from "../../assets/images/corporate-voucher.svg";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";

const CorporateVoucherGift = (props) => {
  return (
    <section className="corporate-voucher">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="row d-flex flex-column-reverse flex-md-row align-items-center">
              <div className="col-12 col-sm-8 offset-sm-2 col-lg-5 offset-lg-0">
                <div className="content-area d-flex flex-column align-items-center align-items-lg-start">
                  <h4 className="title text-center text-lg-left">
                    {props.translate("voucher.corporate_voucher")}
                  </h4>
                  <h5 className="sub-title text-center text-lg-left">
                    {props.translate("voucher.corporate_voucher_sub")}
                  </h5>
                  <p className="text text-center text-lg-left">
                    {props.translate("voucher.corporate_voucher_desc")}
                  </p>
                  <Link
                    to={global.corporateVoucher.links[props.lang]}
                    className="contact-button text-center"
                  >
                    {props.translate("voucher.more_info")}
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-5 offset-lg-2 d-flex justify-content-center align-items-center">
                <img
                  src={voucherGift}
                  alt={props.translate("voucher.voucher_gift")}
                  className="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CorporateVoucherGift.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default CorporateVoucherGift;
