import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BLButton from "../../utils/components/BLButton";
import BLInput from "../../utils/components/BLInput";
import { global, guest } from "../../App/routes";
import API, { headers } from "../../utils/API";
import SocialMediaLogins from "./SocialMediaLogins";
import BLLoader from "../../utils/components/BLLoader";
import { login, toggleAuthModal, updateLanguage } from "../authActions";
import { update } from "../../Cart/cartActions";
import {
  getAccountDetails,
  getCurrentRoute,
  getUserData,
  handleErrors,
  loginRedirectUrl,
  validateInput,
  validatePassword,
} from "../../utils/helper";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import is from "is_js";
import { CSSTransition } from "react-transition-group";
import BLInfoArea from "../../utils/components/BLInfoArea";
import { dataLayer__signUp } from "../../utils/DataLayerEvents";
import BLCheckbox from "../../utils/components/BLCheckbox";
import SEO from "../../utils/components/SEO";

class Register extends Component {
  state = {
    name: "",
    surname: "",
    email: "",
    password: "",
    clarificationText: false,
    userAgreement: false,
    contactConsent: false,
    isSending: false,
    errMessages: {},
    errInputs: [],
    success: false,
    isOkayToRegister: false,
    showLegalModal: null,
    agreements: {
      tou: "",
      et: "",
    },
  };

  handleRegister = (e) => {
    if (e) e.preventDefault();

    this.setState({ isSending: true });

    const data = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      password: this.state.password,
      agreement: this.state.clarificationText && this.state.userAgreement,
      contact_consent: this.state.contactConsent,
    };

    API.post("user/register", data, {
      headers: { ...headers, "Accept-Language": this.props.user.langPref },
    })
      .then(({ data }) => {
        this.setState({ success: data.info.message }, this.handleLogin);
      })
      .catch((err) => {
        if (err.response) {
          const { errors } = err.response.data;

          this.setState({ errMessages: { ...errors } });
        } else {
          console.log(err);
        }

        this.setState({ isSending: false });
      });
  };

  handleLogin = () => {
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    API.post("user/login", data, {
      headers: { ...headers, "Accept-Language": this.props.user.langPref },
    })
      .then((response) => {
        const { token_type, access_token, refresh_token, expires_in } =
            response.data.data,
          login_updated_at = Date.now();

        getAccountDetails(token_type, access_token).then(async (r) => {
          const { data } = r.data;

          const user = getUserData(
            token_type,
            access_token,
            refresh_token,
            expires_in,
            true,
            data
          );

          user["login_updated_at"] = login_updated_at;

          await this.props.login(user);

          getCurrentRoute(
            window.location.pathname,
            this.props.user.langPref
          ).then(({ route }) => {
            const {
              langPref: lang,
              currencyPref,
              hashed_email,
              id,
            } = this.props.user;
            const page = {
              title: document.title,
              type: route.type,
              lang,
              currency: currencyPref.code,
            };

            const user = {
              hashed_email,
              id,
            };

            dataLayer__signUp(page, user);
          });

          if (user.langPref !== this.props.activeLanguage.code) {
            this.props.setActiveLanguage(data.account_language);
          }

          if (this.props.cart && this.props.cart.items.length) {
            const params = {
              ccy: this.props.user.currencyPref.code,
              new_basket: true,
              items: this.props.cart.items.map((item) => ({
                ...item,
                selectedPackage: undefined,
                productPackages: undefined,
                product: undefined,
                temp_id: undefined,
                id: undefined,
              })),
            };
            await API.post("cart", params, {
              headers: {
                ...headers,
                Authorization: `${user._tokenType} ${user._token}`,
              },
            })
              .then((response) => {
                const { data } = response.data;

                const cart = data;

                cart.forEach((data) => {
                  if (data.package_type === 3 || data.package_type === 4) {
                    data.delivery_recipient_area_code =
                      data.delivery_recipient_phone.area_code;
                    data.delivery_recipient_phone_cc =
                      data.delivery_recipient_phone.country_code;
                    data.delivery_recipient_phone_w_out_code =
                      data.delivery_recipient_phone.number;
                  }
                });

                this.props.updateCart(cart);
              })
              .catch((err) => handleErrors(err));
          } else {
            await API.get("cart?ccy=" + this.props.user.currencyPref.code, {
              headers: {
                ...headers,
                Authorization: `${user._tokenType} ${user._token}`,
              },
            })
              .then((response) => {
                const { data } = response.data;

                const cart = data;

                cart.forEach((data) => {
                  if (data.package_type === 3 || data.package_type === 4) {
                    data.delivery_recipient_area_code =
                      data.delivery_recipient_phone.area_code;
                    data.delivery_recipient_phone_cc =
                      data.delivery_recipient_phone.country_code;
                    data.delivery_recipient_phone_w_out_code =
                      data.delivery_recipient_phone.number;
                  }
                });

                this.props.updateCart(cart);
              })
              .catch((err) => handleErrors(err));
          }

          if (is.mobile()) {
            const url = loginRedirectUrl(this.props.user.langPref);

            if (url) this.props.history.push(url);
            else this.this.props.history.push("/");
          }

          setTimeout(() => this.props.toggleAuthModal(), 20);
        });
      })
      .catch((err) => {
        if (err.response) {
          const { info } = err.response.data;

          if (err.response.data.code)
            this.setState({ errMessages: info.message });
        }
      });
  };

  handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    this.setState({ [name]: type === "checkbox" ? checked : value }, () => {
      this.resetError(name);
      this.checkIfOkayToRegister();
    });
  };

  resetError = (name) => {
    if (name in this.state.errMessages) {
      const errMessages = { ...this.state.errMessages };
      delete errMessages[name];
      this.setState({ errMessages });
    }
  };

  checkIfOkayToRegister = () => {
    const inputs = [
      "name",
      "surname",
      "email",
      "password",
      "clarificationText",
      "userAgreement",
    ];

    const check = inputs.filter((key) => {
      let validate =
        key === "email"
          ? validateInput(key, this.state[key])
          : { success: true };

      if (key === "password") {
        validate = { success: validatePassword(this.state[key]) };
      }

      return (
        !this.state[key] || this.state[key].length < 2 || !validate.success
      );
    });

    let isOkayToRegister = true;
    if (check.length) {
      isOkayToRegister = false;
    }

    this.setState({ isOkayToRegister });
  };

  render() {
    return (
      <div className="tab-page register-page">
        <SEO
          title={this.props.translate("page_title.register")}
          description={this.props.translate("page_description.register")}
        />
        {this.state.success ? (
          <div className="d-flex flex-column align-items-center">
            <p className="desc text-center">{this.state.success}</p>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col">
                <BLInput
                  type="text"
                  name="name"
                  label={this.props.translate("global.name")}
                  value={this.state.name}
                  changed={this.handleChange}
                  minChar={2}
                  error={
                    "name" in this.state.errMessages &&
                    this.state.errMessages.name[0]
                  }
                  limitCharacters={["text"]}
                />
              </div>
              <div className="col">
                <BLInput
                  type="text"
                  name="surname"
                  label={this.props.translate("global.surname")}
                  value={this.state.surname}
                  changed={this.handleChange}
                  minChar={2}
                  error={
                    "surname" in this.state.errMessages &&
                    this.state.errMessages.surname[0]
                  }
                  limitCharacters={["text"]}
                />
              </div>
            </div>
            <BLInput
              type="email"
              name="email"
              label={this.props.translate("auth.email_address")}
              value={this.state.email}
              changed={this.handleChange}
              error={
                "email" in this.state.errMessages &&
                this.state.errMessages.email[0]
              }
              validationType="email"
              inputProps={{ inputMode: "email" }}
            />
            <BLInput
              type="password"
              name="password"
              label={this.props.translate("auth.password")}
              value={this.state.password}
              changed={this.handleChange}
              error={
                "password" in this.state.errMessages &&
                this.state.errMessages.password[0]
              }
              inputProps={{ inputMode: "password" }}
              showPassInfo
            />
            <div className="agreements">
              <BLCheckbox
                name="clarificationText"
                checked={this.state.clarificationText}
                changed={this.handleChange}
                label={this.props.translate(
                  "global.read_and_understood_agreement",
                  {
                    link: (
                      <Link
                        to={global.privacyPolicy.links[this.props.lang].replace(
                          ":section?",
                          global.privacyPolicy.params.section.userPrivacyNotice[
                            this.props.lang
                          ]
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleShowLegal("upn");
                        }}
                      >
                        {this.props.translate("global.enlightment_text")}
                      </Link>
                    ),
                  }
                )}
              />
            </div>
            <div className="agreements">
              <BLCheckbox
                name="userAgreement"
                checked={this.state.userAgreement}
                changed={this.handleChange}
                label={this.props.translate(
                  "global.read_and_understood_agreement",
                  {
                    link: (
                      <Link
                        to={global.notListedPolicy.links[
                          this.props.lang
                        ].replace(
                          ":policy",
                          global.notListedPolicy.params.policy.termsOfUse[
                            this.props.lang
                          ]
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleShowLegal("tou");
                        }}
                      >
                        {this.props.translate("global.user_agreement")}
                      </Link>
                    ),
                  }
                )}
              />
            </div>
            <div className="agreements mb-2">
              <BLCheckbox
                name="contactConsent"
                checked={this.state.contactConsent}
                changed={this.handleChange}
                label={this.props.translate("auth.contact_consent_label")}
              />
            </div>
            <CSSTransition
              in={"agreement" in this.state.errMessages}
              timeout={0}
              unmountOnExit
            >
              <BLInfoArea type="error">
                {this.state.errMessages.agreement
                  ? this.state.errMessages.agreement
                  : ""}
              </BLInfoArea>
            </CSSTransition>
            <BLButton
              type="pri"
              clicked={this.handleRegister}
              disabled={!this.state.isOkayToRegister}
              classes={`auth-button position-relative ${
                this.state.isSending ? `disabled` : ``
              }`}
            >
              {this.state.isSending ? (
                <BLLoader loaderType="dots" />
              ) : (
                <>
                  {this.props.translate("auth.register_button")}
                  {!this.state.isOkayToRegister && (
                    <span className="disabled-tooltip bottom position-absolute w-100">
                      {this.props.translate(
                        "auth.disabled_register_button_tooltip"
                      )}
                    </span>
                  )}
                </>
              )}
            </BLButton>
            <SocialMediaLogins
              lang={this.props.lang}
              redirectTo={this.props.redirectTo}
            />
            <div className="link-to-other text-center">
              {this.props.translate("auth.you_already_have_an_account")}
              {this.props.translate("auth.do_here", {
                link: (
                  <Link
                    to={guest.login.links[this.props.lang]}
                    onClick={(e) => {
                      this.props.handlePageChange(e, "login");
                    }}
                  >
                    {this.props.translate("auth.do_login")}
                  </Link>
                ),
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    updateLanguage: (lang) => dispatch(updateLanguage(lang)),
    updateCart: (cart) => dispatch(update(cart)),
    toggleAuthModal: () => dispatch(toggleAuthModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(Register)));
