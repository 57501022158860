import React from "react";
import { withLocalize } from "react-localize-redux";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

import externalLink from "../../assets/images/external-link-icon.svg";
import logo from "../../assets/images/logo-white.svg";

const Banner = (props) => {
  const lang = props.activeLanguage.code
    ? props.activeLanguage.code
    : props.defaultLanguage;

  return (
    <div className="Banner" ref={props.bannerRef}>
      <div className="row">
        <div className="col-12">
          <div className="video-area position-relative">
            <div className="row" style={{ margin: "0 15px" }}>
              <div className="col-12">
                <div className="content d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={logo}
                    alt="Bucketlist White Logo"
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tabs-area">
            <div className="row" style={{ margin: "0 15px" }}>
              <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                <div className="row">
                  <div className="col-4">
                    <NavLink
                      to={global.aboutUs.links[lang]}
                      className="tab-link d-flex justify-content-center align-items-center stretched-link"
                    >
                      {props.translate("banner.nav.about_us")}
                    </NavLink>
                  </div>
                  <div className="col-4">
                    <NavLink
                      to={global.media.links[lang]}
                      className="tab-link d-flex justify-content-center align-items-center stretched-link"
                    >
                      {props.translate("banner.nav.media")}
                    </NavLink>
                  </div>
                  <div className="col-4">
                    <a
                      href="https://linkedin.com/company/bucketlistcomtr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tab-link d-flex justify-content-center align-items-center stretched-link"
                    >
                      {props.translate("banner.nav.career")}{" "}
                      <img src={externalLink} alt="External link icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(Banner);
