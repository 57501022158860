import React from "react";
import PropTypes from "prop-types";
import crypto from "crypto";

const BLCheckbox = (props) => {
  const id = crypto.randomBytes(6).toString("hex");
  return (
    <div className="bl-checkbox d-inline-block">
      <input
        type="checkbox"
        name={props.name}
        id={`bl-checkbox-${id}`}
        className="d-none"
        onChange={props.changed ? props.changed : () => {}}
        checked={props.checked && props.checked}
      />
      <label
        htmlFor={`bl-checkbox-${id}`}
        className="d-flex justify-content-start align-items-center"
      >
        <span className="box d-flex justify-content-center align-items-center">
          <svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2323 4.59884L10.9672 13.8015L8.35976 10.162L3.25586 10.3455C4.80057 11.9862 5.84078 12.9559 7.41416 14.5702C8.59724 15.7853 9.53614 17.445 11.5089 16.8013C13.1008 16.2818 19.1461 8.92675 21.0686 6.78938L16.2323 4.59884Z"
              fill="transparent"
            />
          </svg>
        </span>
        <span className="text">{props.label}</span>
      </label>
    </div>
  );
};

BLCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  checked: PropTypes.bool,
  changed: PropTypes.func,
};

export default BLCheckbox;
