import React from "react";
import AboutUsTemplate from "../AboutUsTemplate";

import feature1Icon from "../../assets/images/about-us/feature-1.svg";
import feature2Icon from "../../assets/images/about-us/feature-2.svg";
import feature3Icon from "../../assets/images/about-us/feature-3.svg";
import SEO from "../../utils/components/SEO";

const features = [
  {
    id: 0,
    icon: feature1Icon,
    title: "about_us.features.title_1",
    content: "about_us.features.content_1",
  },
  {
    id: 1,
    icon: feature2Icon,
    title: "about_us.features.title_2",
    content: "about_us.features.content_2",
  },
  {
    id: 2,
    icon: feature3Icon,
    title: "about_us.features.title_3",
    content: "about_us.features.content_3",
  },
];

const investors = [
  {
    id: 0,
    title: "Investor 1",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
  {
    id: 1,
    title: "Investor 2",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
  {
    id: 2,
    title: "Investor 3",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
  {
    id: 3,
    title: "Investor 4",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
  {
    id: 4,
    title: "Investor 5",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
  {
    id: 5,
    title: "Investor 6",
    link: "https://google.com",
    logo: "//via.placeholder.com/200x66",
  },
];

const AboutUs = (props) => {
  return (
    <AboutUsTemplate>
      <SEO
        title={props.translate("about_us.title")}
        description={props.translate("page_description.aboutUs")}
      />
      <div className="page about-us pb-0">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <section className="mb-5">
              <div className="row">
                <div className="col-12">
                  <h6 className="section-title text-center text-uppercase">
                    {props.translate("about_us.title")}
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="section-text text-center">
                    {props.translate("about_us.desc")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1">
                  <p className="section-sub-text text-center">
                    {props.translate("about_us.sub_desc")}
                  </p>
                </div>
              </div>
              <div className="row d-none">
                <div className="col-12">
                  <div className="features-holder">
                    <div className="row">
                      {features.map((data) => (
                        <div key={data.id} className="col-12 col-lg-4">
                          <div className="feature d-flex flex-column align-items-center">
                            <img
                              src={data.icon}
                              alt={`${props.translate(data.title)} Icon`}
                            />
                            <h3 className="text-center">
                              {props.translate(data.title)}
                            </h3>
                            <p className="text-center">
                              {props.translate(data.content)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <section className="investors-section mt-5">
              <div className="row" style={{ margin: "0 15px" }}>
                <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                  <div className="features-holder m-0">
                    <div className="row">
                      {features.map((data) => (
                        <div
                          key={data.id}
                          className="col-12 col-lg-10 col-xl-4 offset-lg-1 offset-xl-0"
                        >
                          <div className="feature d-flex flex-column align-items-center">
                            <img
                              src={data.icon}
                              alt={`${props.translate(data.title)} Icon`}
                            />
                            <h3 className="text-center">
                              {props.translate(data.title)}
                            </h3>
                            <p className="text-center">
                              {props.translate(data.content)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-12">
            <section className="investors-section">
              <div className="row" style={{ margin: "0 15px" }}>
                <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="section-title text-center text-uppercase">
                        {props.translate("about_us.investors_title")}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="investors-holder">
                        <div className="row">
                          {investors.map((data) => (
                            <div
                              key={data.id}
                              className="col-12 col-md-4 col-lg-4 col-xl-2 d-flex align-items-center justify-content-center"
                            >
                              <a
                                href={data.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={data.logo}
                                  alt={`${data.title} Logo`}
                                  className="mw-100"
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </AboutUsTemplate>
  );
};

export default AboutUs;
