import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import BLCheckbox from "../../utils/components/BLCheckbox";
import BLTicker from "../../utils/components/BLTicker";

import yetiskin from "../../assets/images/filters/yetiskin-icon.svg";
import cocuk from "../../assets/images/filters/cocuk-icon.svg";
import bebek from "../../assets/images/filters/bebek-icon.svg";
import BLButton from "../../utils/components/BLButton";
import { NavLink } from "react-router-dom";
import { global } from "../../App/routes";

const Filters = (props) => {
  const [adultCount, setAdultCount] = useState(0);
  const [kidCount, setKidCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState(
    props.filters.locations
  );
  const [selectedCategories, setSelectedCategories] = useState(
    props.filters.categories
  );

  useEffect(() => {
    if (selectedLocations !== props.filters.locations)
      setSelectedLocations(props.filters.locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters.locations]);

  const handleSelectFilter = (e, filter, value) => {
    const { checked } = e.target;

    let selectedFilters = [];

    if (filter === "location") selectedFilters = [...selectedLocations];
    if (filter === "cat") selectedFilters = [...selectedCategories];

    if (checked) {
      selectedFilters = [...selectedFilters, value];
    } else {
      const index = selectedFilters.indexOf(value);

      selectedFilters.splice(index, 1);
    }

    if (filter === "location") setSelectedLocations(selectedFilters);
    if (filter === "cat") setSelectedCategories(selectedFilters);
  };

  const cats =
    (props.cat.type === "location" ||
      props.cat.type === "search" ||
      props.cat.type === "all") &&
    props.categories &&
    props.categories.length
      ? props.categories
      : props.subCats && props.subCats.length
      ? props.subCats
      : [];

  const subCats =
    props.cat.type === "cat"
      ? props.cat.children
      : props.cat.type === "subCat"
      ? props.cat.siblings
      : [];

  return (
    <div className="filters-holder flex-column">
      {cats && cats.length ? (
        <Filter title={props.translate("category.categories")} disabled={true}>
          {props.cat.type === "subCat" ? (
            <div className="option">
              <BLCheckbox
                label={props.cat.name}
                name={props.cat.slug}
                checked={true}
              />
            </div>
          ) : (
            ""
          )}
          {cats.map((data) => (
            <div key={data.id} className="option">
              <BLCheckbox
                label={data.name}
                name={data.slug}
                changed={
                  props.cat.type === "search"
                    ? (e) => handleSelectFilter(e, "cat", data.id)
                    : (e) =>
                        props.handleSelectCat(
                          e.target.name,
                          props.cat.type === "location" ||
                            props.cat.type === "all"
                            ? "cat"
                            : "subCat"
                        )
                }
              />
            </div>
          ))}
        </Filter>
      ) : null}

      <Filter title={props.translate("category.categories")} name="cat-filter">
        {props.cat.type === "cat" ? (
          <NavLink
            to={global.catPage.links[props.lang].replace(
              ":main_cat",
              props.cat.slug
            )}
            className="main-cat d-flex justify-content-between align-items-center"
          >
            {props.cat.name}
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 4L9 1"
                stroke="#8E8E8E"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        ) : props.cat.type === "subCat" && props.cat.parent ? (
          <NavLink
            to={global.catPage.links[props.lang].replace(
              ":main_cat",
              props.cat.parent.slug
            )}
            className="main-cat d-flex justify-content-between align-items-center active"
          >
            {props.cat.parent.name}
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 4L9 1"
                stroke="#8E8E8E"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        ) : (
          ""
        )}

        <div className="sub-cats">
          {props.cat.type === "subCat" ? (
            <NavLink
              to={global.subCatPage.links[props.lang]
                .replace(":main_cat", props.cat?.parent?.slug)
                .replace(":sub_cat", props.cat.slug)}
              className="sub-cat d-flex justify-content-between align-items-center"
            >
              {props.cat.name}
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 4L9 1"
                  stroke="#8E8E8E"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
          ) : (
            ""
          )}
          {subCats.map((data) => (
            <NavLink
              to={global.subCatPage.links[props.lang]
                .replace(
                  ":main_cat",
                  props.cat.type === "cat"
                    ? props.cat.slug
                    : props.cat.type === "subCat"
                    ? props.cat?.parent?.slug
                    : ""
                )
                .replace(":sub_cat", data.slug)}
              className="sub-cat d-flex justify-content-between align-items-center"
              key={data.id}
            >
              {data.name}
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 4L9 1"
                  stroke="#8E8E8E"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
          ))}
        </div>

        {props.categories && props.categories.length
          ? props.categories.map((data) => {
              const link = global.catPage.links[props.lang].replace(
                ":main_cat",
                data.slug
              );
              const url =
                props.cat.type === "location"
                  ? `${link}?filters:locations=${props.cat.id}`
                  : link;
              return (
                <NavLink
                  to={url}
                  className="main-cat d-flex justify-content-between align-items-center"
                  key={data.id}
                >
                  {data.name}
                  <svg
                    width="10"
                    height="5"
                    viewBox="0 0 10 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 4L9 1"
                      stroke="#8E8E8E"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavLink>
              );
            })
          : ""}
      </Filter>
      <Filter title="Özel Kategoriler" disabled={true}>
        <div className="option">
          <BLCheckbox label="Lorem" name="lorem1" />
        </div>
        <div className="option">
          <BLCheckbox label="Lorem 2" name="lorem2" />
        </div>
        <div className="option">
          <BLCheckbox label="Lorem 3" name="lorem3" />
        </div>
      </Filter>
      <Filter title="Bütçe Aralığı" disabled={true}>
        Range
      </Filter>
      <Filter title="Rezerve Kişi Sayısı" disabled={true}>
        <div className="option">
          <div className="sum">
            Toplam: <span>{adultCount + kidCount + infantCount} Kişi</span>
          </div>
          <BLTicker
            title="Yetişkin"
            count={adultCount}
            icon={yetiskin}
            set={setAdultCount}
            limits={{ start: 0, end: 10 }}
          />
          <BLTicker
            title="Çocuk (2-15 Yaş)"
            count={kidCount}
            icon={cocuk}
            set={setKidCount}
            limits={{ start: 0, end: 10 }}
          />
          <BLTicker
            title="Bebek (0-2 Yaş)"
            count={infantCount}
            icon={bebek}
            set={setInfantCount}
            limits={{ start: 0, end: 10 }}
          />
        </div>
      </Filter>
      <Filter title={props.translate("category.locations")}>
        {props.locations.map((data) => (
          <div key={data.id} className="option">
            <BLCheckbox
              label={data.name}
              name={data.slug}
              checked={
                data.slug === props.slug || selectedLocations.includes(data.id)
              }
              changed={
                props.cat.type === "location"
                  ? () => props.handleSelectCat(data.slug)
                  : (e) => {
                      handleSelectFilter(e, "location", data.id);
                      props.handleFilterDataLayers(
                        data.name,
                        !selectedLocations.includes(data.id)
                      );
                    }
              }
            />
          </div>
        ))}
      </Filter>
      <BLButton
        type="sec"
        classes="apply-button"
        clicked={(e) =>
          props.handleApplyFilter(e, [
            {
              type: "locations",
              value: selectedLocations,
            },
            { type: "categories", value: selectedCategories },
          ])
        }
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.38127 12.5506C2.96949 12.5506 0.211914 9.77393 0.211914 6.38127C0.211914 2.98861 2.98861 0.211914 6.38127 0.211914C9.77393 0.211914 12.5506 2.98861 12.5506 6.38127C12.5506 9.77393 9.79304 12.5506 6.38127 12.5506ZM6.38127 1.5907C3.75022 1.5907 1.60861 3.75022 1.60861 6.38127C1.60861 9.01232 3.75022 11.1539 6.38127 11.1539C9.01232 11.1539 11.1718 9.01232 11.1718 6.38127C11.1718 3.75022 9.01232 1.5907 6.38127 1.5907Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1286 14.8186C13.9472 14.8186 13.7657 14.7458 13.6392 14.6193L9.75589 10.736C9.48371 10.4638 9.48371 10.0281 9.75589 9.75589C10.0281 9.48371 10.4638 9.48371 10.736 9.75589L14.6014 13.6213C14.8736 13.8935 14.8736 14.3292 14.6014 14.6014C14.4915 14.7458 14.3101 14.8186 14.1286 14.8186Z"
            fill="white"
          />
        </svg>
        {props.translate("category.apply_filters")}
      </BLButton>
    </div>
  );
};

export default Filters;
