import React from "react";
import Filter from "./Filter";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";

const LocationsSidebar = (props) => (
  <section className="sidebar d-flex flex-column justify-content-center align-items-between">
    <div className="filters-holder flex-column">
      <Filter title={props.translate("category.locations")}>
        {props.locations.map((data) => (
          <Link
            to={global.activity.links[props.lang].replace(
              ":location",
              data.slug
            )}
            className="main-cat d-flex justify-content-between align-items-center"
            key={data.id}
          >
            {data.name}
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 4L9 1"
                stroke="#8E8E8E"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        ))}
      </Filter>
    </div>
  </section>
);

export default LocationsSidebar;
