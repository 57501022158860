import React from "react";
import SingleSection from "../components/SingleSection";
import PersonalInfo from "./paymentSections/PersonalInfo";
import Coupons from "./paymentSections/Coupons";
import DeliveryChoice from "./paymentSections/DeliveryChoice";
import InvoiceDetails from "./paymentSections/InvoiceDetails";
import GiftCards from "./paymentSections/GiftCards";
import PaymentMethods from "./paymentSections/PaymentMethods";
import { PACKAGE_TYPES } from "../../utils/db/packageTypes";

const Sections = (props) => {
  return (
    <div className="sections">
      <SingleSection title={props.translate("payment.my_personal_info.title")}>
        <PersonalInfo
          translate={props.translate}
          personalInfo={props.personalInfo}
          handleUpdatePersonalInfo={props.handleUpdatePersonalInfo}
          handleSetCountryCode={props.handleSetCountryCode}
        />
      </SingleSection>

      <SingleSection title={props.translate("payment.my_coupons.title")}>
        <Coupons
          translate={props.translate}
          currency={props.currency}
          cart={props.cart}
        />
      </SingleSection>

      <SingleSection title={props.translate("payment.delivery.title")}>
        <DeliveryChoice translate={props.translate} />
      </SingleSection>

      <SingleSection title={props.translate("payment.billing_info.title")}>
        <InvoiceDetails
          translate={props.translate}
          invoiceInfo={props.invoiceInfo}
          handleUpdateInvoiceInfo={props.handleUpdateInvoiceInfo}
          handleUpdateLocationInfo={props.handleUpdateLocationInfo}
        />
      </SingleSection>
      {props.cart.items[0].package_type !== PACKAGE_TYPES.GIFTCARD ? (
        <SingleSection title={props.translate("payment.use_a_gift_card.title")}>
          <GiftCards
            translate={props.translate}
            lang={props.lang}
            user={props.user}
            selectedGiftCards={props.selectedGiftCards}
            handleApplyGiftCard={props.handleApplyGiftCard}
            handleRemoveGiftCard={props.handleRemoveGiftCard}
            currency={props.currency}
          />
        </SingleSection>
      ) : (
        ""
      )}

      <SingleSection
        title={props.translate("payment.pick_a_payment_method.title")}
      >
        <PaymentMethods
          translate={props.translate}
          currency={props.currency}
          handleUpdateCreditCardInfo={props.handleUpdateCreditCardInfo}
          handlePay={props.handlePay}
          isPaying={props.isPaying}
          payingError={props.payingError}
          handleShowSaleAgreementModal={props.handleShowSaleAgreementModal}
          handleShowPreInfoAgreementModal={
            props.handleShowPreInfoAgreementModal
          }
          isOkayToPay={props.isOkayToPay}
          cartTotal={props.cartTotal}
          discountPrice={props.discountPrice}
          handleSetPaymentMethod={props.handleSetPaymentMethod}
          paymentMethod={props.paymentMethod}
          user={props.user}
          cart={props.cart}
          agreementsAgreed={props.agreementsAgreed}
          handleSetAgreements={props.handleSetAgreements}
          saleAgreement={props.saleAgreement}
          preInfoAgreement={props.preInfoAgreement}
          selectedGiftCards={props.selectedGiftCards}
          promoCodeApplied={props.promoCodeApplied}
          promoPrice={props.promoPrice}
        />
      </SingleSection>
    </div>
  );
};

export default Sections;
