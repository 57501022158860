import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import toastr from "toastr";

import DetailedInfo from "./components/DetailedInfo";
import Location from "./components/Location";
import Comments from "./components/Comments";
import PopularActivities from "../Home/components/PopularActivities/PopularActivities";
import Sidebar from "./components/Sidebar";

import GiftAnExperience from "./components/GiftAnExperience";
import Header from "./components/Header";
import Slider from "./components/Slider";

import BLModal from "../utils/components/BLModal.js";
import AddedToCartModal from "./components/AddedToCartModal.js";
import API, { headers } from "../utils/API";
import { getCurrentRoute, handleErrors, isCrawler } from "../utils/helper";
import BLLoader from "../utils/components/BLLoader";
import Helmet from "react-helmet";
import { update } from "../Cart/cartActions";
import BLButton from "../utils/components/BLButton";
import { global } from "../App/routes";
import NotFound from "../App/NotFound";
import {
  dataLayer__itemView,
  dataLayer__addToBucketlist,
  dataLayer__removeFromBucketlist,
} from "../utils/DataLayerEvents";
import PrerenderRedirect from "../utils/components/PrerenderRedirect";
import NotLive from "./components/NotLive";
import SEO from "../utils/components/SEO";
import SocialMediaShare from "./components/SocialMediaShare";

import { PACKAGE_TYPES } from "../utils/db/packageTypes";
import BOOKING_MODE from "../utils/constants";
import dayjs from "dayjs";

class Experience extends Component {
  DEF_TAB = 0;

  state = {
    inBucketlist: false,
    isAuthModalOn: false,
    activeTab: this.DEF_TAB,
    isDiscounted: true,
    isLoading: true,
    isAddingToBucketlist: false,
    isAddingToCart: false,
    isAddedToCartModalOn: false,
    product: {},
    reviews: [],
    reviewsPage: 1,
    reviewsHasNext: true,
    isLoadingMore: false,
    isNotFound: false,
    wrongSlugRedirectTo: false,
    isNotLiveAnymore: false,
    productAvailabilities: [],
    productPackages: [],
    productAvailabilitiesLoading: true,
    productPackagesLoading: true,
    selectedPackage: null,
    selectedAvailability: null,
    productUnits: [],
    productUnitsLoading: true,

    tabs: [
      {
        id: 0,
        title: "detailed_info",
      },
      {
        id: 1,
        title: "location",
      },
      {
        id: 2,
        title: "comments",
      },
    ],
    addedItem: {},
    otherProducts: [],
  };

  reviewsLimit = 5;

  componentDidMount = () => {
    this.handleGetProduct();

    this.handleTogglePackageOptionsButtonOnMobile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.isAddedToCartModalOn && !this.state.isAddedToCartModalOn)
      setTimeout(() => {
        this.setState({ addedItem: {} });
      }, 400);

    if (
      ((prevProps.match.params.name !== this.props.match.params.name &&
        this.props.match.params.name !== this.state.product.slug) ||
        (prevState.isNotFound === true && this.state.isNotFound === false)) &&
      !this.state.isLoading
    ) {
      this.handleGetProduct();
    }

    if (prevProps.user.currencyPref.code !== this.props.user.currencyPref.code)
      this.handleGetProduct(false, false, false);

    if (prevProps.lang !== this.props.lang && !this.state.isNotLiveAnymore) {
      if (
        this.state.product?.translations &&
        this.state.product.translations[this.props.lang]
      ) {
        this.setState({ isNotFound: false });
        const { translations } = this.state.product;
        const { lang } = this.props;
        const { slug } = translations[lang];

        getCurrentRoute(this.props.location.pathname, prevProps.lang).then(
          (curr) => {
            const url = curr.route.links[lang].replace(":name", slug);
            this.props.history.push(url);
          }
        );
      } else {
        this.setState({ isNotFound: true });
      }
    }

    if (
      prevState?.selectedPackage?.id !== this.state?.selectedPackage?.id ||
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code
    ) {
      this.handleGetUnits();
      this.handleGetAvailabilities();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener(
      "load",
      this.handleTogglePackageOptionsButtonOnMobile,
      false
    );

    toastr.clear();
  };

  handleScrollToPackageOptions = () => {
    return false;
    // const el = document.querySelector(".package-options");
    // if (!el) return;

    // if (this.state.activeTab !== 0)
    //   this.setState({ activeTab: 0 }, () => el.scrollIntoView(50));
    // else el.scrollIntoView(50);
  };

  handleTogglePackageOptionsButtonOnMobile = () => {
    return false;
    // window.addEventListener(
    //   "scroll",
    //   () => {
    //     const triggerEl = document.querySelector(
    //         ".experience-tabs-holder .tabs"
    //       ),
    //       buttonEl = document.querySelector(
    //         ".package-options-button.mobile-extra"
    //       ),
    //       packageOptionsEl = document.querySelector(".package-options");

    //     if (triggerEl && triggerEl.getBoundingClientRect()) {
    //       const triggerTop = triggerEl.getBoundingClientRect().top,
    //         packageOptionsTop = packageOptionsEl.getBoundingClientRect().top;

    //       if (
    //         triggerTop < 0 &&
    //         !buttonEl.classList.contains("showed") &&
    //         packageOptionsTop > 0
    //       )
    //         buttonEl.classList.add("showed");
    //       else if (
    //         (triggerTop > 0 && buttonEl.classList.contains("showed")) ||
    //         packageOptionsTop <= 0
    //       )
    //         buttonEl.classList.remove("showed");
    //     }
    //   },
    //   false
    // );
  };

  handleGetProduct = (
    getReviews = true,
    slideToTop = true,
    setLoading = true
  ) => {
    if (setLoading) {
      this.setState({
        isLoading: true,
        isNotFound: false,
        isNotLiveAnymore: false,
        activeTab: this.DEF_TAB,
      });
    }

    API.get(
      `products/${this.props.match.params.name}?currency=${this.props.user.currencyPref.code}`
    )
      .then((response) => {
        if (!response.data.data.translations[this.props.lang]) {
          this.setState({ isNotFound: true });
        }

        if (getReviews) {
          API.get(
            `products/${response.data.data.id}/reviews?limit=${this.reviewsLimit}&page=${this.state.reviewsPage}`
          )
            .then((response) => {
              const { data, meta } = response.data;
              const { current_page, last_page } = meta;

              const reviewsHasNext = current_page < last_page;

              const tabs = this.state.tabs;

              tabs[2].count = meta.total.toString();

              this.setState({ reviews: data, tabs, reviewsHasNext });
            })
            .catch((err) => handleErrors(err));
        }

        if (this.props.location.state?.type !== "book-now" && slideToTop)
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 10);

        const inBucketlist = response.data.data.bucket_list;
        let product = response.data.data;

        this.setState({ product, inBucketlist, isLoading: false }, () => {
          if (
            this.state.product.slug !== this.props.match.params.name &&
            this.state.product.translations[this.props.lang]
          ) {
            this.props.history.push(
              global.experience.links[this.props.lang].replace(
                ":name",
                this.state.product.slug
              )
            );
          }

          if (this.props.location?.state?.type === "book-now") {
            setTimeout(() => {
              this.handleScrollToPackageOptions();
            }, 200);
          }

          this.setState({ productPackages: product.packages });
          const selectedPackage =
            product.packages?.find((pck) => pck.is_default) || null;

          if (selectedPackage) this.setState({ selectedPackage });
          else
            this.setState({
              productAvailabilitiesLoading: false,
              productUnitsLoading: false,
            });
        });

        setTimeout(() => {
          toastr.clear();
          toastr.warning(
            this.props.translate("experience.cuncurrent_users", {
              count: response.data.data.stats.concurrent_users,
            }),
            null,
            {
              positionClass: "exp-fix toast-bottom-right",
            }
          );
        });

        setTimeout(() => {
          const { product } = this.state;

          const page = {
            title: document.title,
            type: global.experience.type,
            lang: this.props.user.langPref,
            currency: this.props.user.currencyPref.code,
          };

          const { isLoggedIn, hashed_email, id, gender, has_bought_before } =
            this.props.user;
          const user = {
            isLoggedIn,
            hashed_email,
            id,
            gender:
              gender === "male"
                ? `Erkek`
                : gender === "female"
                ? `Kadın`
                : `Diğer`,
            has_bought_before,
          };

          dataLayer__itemView(product, page, user);
        }, 90);
      })
      .then(() => {
        if (this.state?.product?.location?.city_id) {
          API.get(
            `interested_products?currency=${this.props.user.currencyPref.code}&location_ids[]=${this.state.product.location.city_id}`
          ).then(({ data: res }) => {
            this.setState({ otherProducts: res.data });
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) this.setState({ isNotFound: true });

        if (err?.response?.status === 301)
          if (isCrawler())
            this.setState({
              wrongSlugRedirectTo:
                window.location.pathname.replace(
                  err.response?.data?.header?.previous_slug,
                  err.response?.data?.header?.slug
                ) + window.location.search,
            });
          else if (
            err.response?.data?.header?.slug === this.props.match.params.name
          )
            this.handleGetProduct();
        if (err?.response?.data?.code === 404)
          this.setState({ isNotFound: true });
        else if (err?.response?.data?.code === 302)
          this.setState({ isNotLiveAnymore: true });
        else handleErrors(err);

        this.setState({ isLoading: false });
      });
  };

  handleGetAvailabilities = (dates = null) => {
    this.setState({ productAvailabilitiesLoading: true }, () => {
      let url = `products/${this.state.product.id}/availabilities?package_id=${this.state.selectedPackage.id}`;

      if (dates?.startDate && dates?.endDate) {
        const startDate = dayjs(dates.startDate).format("YYYY-MM-DD");
        const endDate = dayjs(dates.endDate).format("YYYY-MM-DD");
        url += `&from_date=${startDate}&to_date=${endDate}`;
      }

      API.get(url)
        .then(({ data: res }) => {
          const productAvailabilities = res.data
            .filter((item) => item)
            .map((availability) => {
              const availableSlots = availability
                ? availability?.availabilities.filter(
                    (a) => a && (!a.closed || !a.soldOut)
                  ) || []
                : [];

              return {
                ...availability,
                available: availableSlots.length > 0,
              };
            });

          const selectedAvailability = productAvailabilities.find(
            (availability) => availability.available
          );

          this.setState({
            productAvailabilities,
            productAvailabilitiesLoading: false,
            selectedAvailability,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ productAvailabilitiesLoading: false });
        });
    });
  };

  handleGetUnits = () => {
    this.setState({ productUnitsLoading: true }, () => {
      API.get(
        `products/${this.state.product.id}/units?package_id=${this.state.selectedPackage.id}&currency=${this.props.user.currencyPref.code}`
      )
        .then(({ data: res }) => {
          this.setState({
            productUnits: res.data,
            productUnitsLoading: false,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ productUnitsLoading: false });
        });
    });
  };

  handleToggleBucketlistStatus = async () => {
    if (!this.props.user.isLoggedIn) {
      this.props.toggleAuthModal();
      return false;
    }

    this.setState({ isAddingToBucketlist: true });

    const params = { pid: this.state.product.id };

    API.post("bucket_list", params, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          setTimeout(() => {
            this.setState(
              (prevState) => {
                return {
                  inBucketlist: !prevState.inBucketlist,
                  isAddingToBucketlist: false,
                };
              },
              () => {
                const {
                  title,
                  code,
                  price_options,
                  provider,
                  category,
                  stats,
                  video_url,
                  location,
                } = this.state.product;

                const { discounted_price, price, participant_count } =
                  price_options[0];

                const product = {
                  title,
                  code,
                  discounted_price,
                  price,
                  provider,
                  category,
                  participant_count,
                  stats,
                  video_url,
                  location,
                };

                const {
                  hashed_email,
                  id,
                  gender,
                  langPref,
                  currencyPref,
                  has_bought_before,
                } = this.props.user;

                const user = {
                  hashed_email,
                  id,
                  gender: this.props.translate(`global.${gender}`, null, {
                    defaultLanguage: "tr",
                  }),
                  has_bought_before,
                };

                const page = {
                  title: document.title,
                  type: global.experience.type,
                  lang: langPref,
                  currency: currencyPref.code,
                };

                if (this.state.inBucketlist)
                  dataLayer__addToBucketlist(product, page, user);
                else dataLayer__removeFromBucketlist(product, page, user);
              }
            );
          }, 300);
        }
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isAddingToBucketlist: false });
      });
  };

  handleUpdateCounts = (count, name) => {
    const basket = { ...this.state.basket };

    basket[name] = count;

    this.setState({ basket });
  };

  handleSelectOption = (value, name) => {
    const basket = { ...this.state.basket };

    basket[name] = value;

    this.setState({ basket });
  };

  /**
   * Remove comments to activate adding to cart function
   * 
  handleAddToCart = (options) => {
    const { priceOptions, packageType, totalPrice, currency } = options;

    const counts = Object.keys(priceOptions).filter(
      (key) => priceOptions[key].count > 0
    );

    if (packageType === 1) {
      if (
        (this.state.product.schedule.date_range_type !== 1 &&
          options.preferredDate === "") ||
        (this.state.product.schedule.time_range_type !== 1 &&
          options.preferredHour === "")
      )
        return false;
    }
    if (packageType === 3) {
      if (
        options.firstName === "" ||
        options.lastName === "" ||
        options.email === "" ||
        options.phone === "" ||
        options.deliveryDate === ""
      )
        return false;
    }

    if (counts.length) {
      this.setState({ isAddingToCart: true });

      const units = counts.map((key) => {
        const price_id = key.split("-")[1];
        return {
          price_id: parseInt(price_id),
          gift_amount: 0,
          quantity: priceOptions[key].count,
          name: priceOptions[key].label,
          price: priceOptions[key].price,
          id: priceOptions[key].id,
        };
      });

      const { product } = this.state;

      let cartItem = {
        product_id: product.id,
        id: `g-${new Date().getTime()}`,
        temp_id: `g-${new Date().getTime()}`,
        package_type: packageType,
        units,
        itemType: 1,
        product: {
          id: product.id,
          title: product.title,
          code: product.code,
          slug: product.slug,
          cover_image_url: product.cover_image_url,
        },
        amount: totalPrice,

        gift_message: "",
        delivery_recipient_first_name: "",
        delivery_recipient_last_name: "",
        delivery_recipient_email: "",
        delivery_recipient_phone_cc: "",
        delivery_recipient_phone: "",
        delivery_recipient_send_date: "",
        preferred_date: "",
        preferred_time: "",
        alternative_date: "",
        alternative_time: "",
        is_product_time: "",
      };

      if (packageType === 1) {
        cartItem = {
          ...cartItem,
          preferred_date: options.preferredDate,
          preferred_time: options.preferredHour,
          alternative_date: options.alternativeDate,
          alternative_time: options.alternativeHour,
          is_product_time:
            this.state.product.schedule.time_range_type === 1 ? false : true,
        };
      } else if (packageType === 2) {
      } else if (packageType === 3) {
        cartItem = {
          ...cartItem,
          gift_message: options.message,
          delivery_recipient_first_name: options.firstName,
          delivery_recipient_last_name: options.lastName,
          delivery_recipient_email: options.email,
          delivery_recipient_phone_cc: options.countryCode,
          delivery_recipient_area_code: options.areaCode,
          delivery_recipient_phone_w_out_code: options.phone,
          delivery_recipient_phone:
            "+" + options.areaCode + "-" + options.phone,
          delivery_recipient_send_date: options.deliveryDate,
        };
      } else {
        return false;
      }

      if (this.props.user.isLoggedIn) {
        const params = {
          ccy: currency.code,
          items: [{ ...cartItem }],
        };
        API.post("cart", params, {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        })
          .then((response) => {
            if (response.data.status) {
              const { data } = response.data;

              const cart = data;

              cart.forEach((data) => {
                if (data.package_type === 3 || data.package_type === 4) {
                  data.delivery_recipient_area_code =
                    data.delivery_recipient_phone.area_code;
                  data.delivery_recipient_phone_cc =
                    data.delivery_recipient_phone.country_code;
                  data.delivery_recipient_phone_w_out_code =
                    data.delivery_recipient_phone.number;
                }
              });

              this.props.updateCart(cart);

              setTimeout(
                () =>
                  this.setState({
                    addedItem: cartItem,
                    isAddedToCartModalOn: true,
                  }),
                10
              );
            }
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({ isAddingToCart: false });
            return false;
          });
      } else {
        this.props.updateCart([...this.props.cart.items, cartItem]);

        setTimeout(
          () =>
            this.setState({ addedItem: cartItem, isAddedToCartModalOn: true }),
          10
        );
      }
    }
  };
   */

  handleBuyNow = (options) => {
    const { priceOptions, packageType, totalPrice, currency } = options;

    const counts = Object.values(priceOptions)
      ?.filter((po) => po.count > 0)
      .map((po, index) => ({
        ...po,
        index: index + 1,
      }));

    if (packageType === PACKAGE_TYPES.PICKADATE) {
      if (
        (this.state.product.booking_mode !== BOOKING_MODE.TICKET &&
          options.preferredDate === "") ||
        (this.state.product.booking_mode === BOOKING_MODE.DATEANDTIME &&
          options.preferredHour === "")
      )
        return false;
    }

    if (
      packageType === PACKAGE_TYPES.GIFT ||
      packageType === PACKAGE_TYPES.PASSGIFT
    ) {
      if (
        options.firstName === "" ||
        options.lastName === "" ||
        options.email === "" ||
        options.phone === "" ||
        options.deliveryDate === ""
      )
        return false;
    }

    if (counts.length) {
      this.setState({ isAddingToCart: true });

      const units = counts.map((po) => {
        const isPerPerson = this.state.selectedPackage?.pricing_is_per_person
          ? true
          : false;
        const unit = {
          price_id: po.id,
          gift_amount: null,
          quantity: isPerPerson ? po.count : 1,
          name: po.label,
          price: po.price,
          amount:
            parseFloat(po.price).toFixed(2) * parseFloat(po.count).toFixed(2),
          id: po.id,
          participant_count: po.participant_count,
          index: po.index,
        };

        if (!isPerPerson)
          unit.participant = [{ id: po.id, quantity: po.count }];

        return {
          ...unit,
        };
      });

      const { product, selectedPackage } = this.state;

      let cartItem = {
        product_id: product.id,
        id: `g-${new Date().getTime()}`,
        temp_id: `g-${new Date().getTime()}`,
        package_type: packageType,
        units,
        itemType: 1,
        selectedPackage,
        productPackages: this.state.productPackages,
        package_id: selectedPackage.id,
        booking_mode: product.booking_mode,
        pricing_is_per_person: selectedPackage.pricing_is_per_person,
        product: {
          id: product.id,
          title: product.title,
          code: product.code,
          slug: product.slug,
          cover_image_url: product.cover_image_url,
          provider: product.provider,
          category: product.category,
          location: product.location,
          stats: product.stats,
          video_url: product.video_url,
        },
        amount: totalPrice,
      };

      if (packageType === PACKAGE_TYPES.PICKADATE) {
        cartItem = {
          ...cartItem,
          preferred_date: options.preferredDate,
          preferred_time: options.preferredHour,
          alternative_date: options.alternativeDate,
          alternative_time: options.alternativeHour,
          is_product_time:
            this.state.product.booking_mode === BOOKING_MODE.DATEANDTIME
              ? true
              : false,
        };
      } else if (packageType === PACKAGE_TYPES.OPENENDED) {
      } else if (
        packageType === PACKAGE_TYPES.GIFT ||
        packageType === PACKAGE_TYPES.PASSGIFT
      ) {
        cartItem = {
          ...cartItem,
          gift_message: options.message,
          delivery_recipient_first_name: options.firstName,
          delivery_recipient_last_name: options.lastName,
          delivery_recipient_email: options.email,
          delivery_recipient_phone_cc: options.countryCode,
          delivery_recipient_area_code: options.areaCode,
          delivery_recipient_phone_w_out_code: options.phone.replace(/\s/g, ""),
          delivery_recipient_phone:
            "+" + options.areaCode + "-" + options.phone.replace(/\s/g, ""),
          delivery_recipient_send_date: options.deliveryDate,
        };
      } else if (packageType === PACKAGE_TYPES.PASS) {
      } else {
        return false;
      }

      if (this.props.user.isLoggedIn) {
        const params = {
          ccy: currency.code,
          items: [
            {
              ...cartItem,
              selectedPackage: undefined,
              productPackages: undefined,
              product: undefined,
              temp_id: undefined,
              id: undefined,
            },
          ],
        };

        API.post("cart", params, {
          headers: {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          },
        })
          .then((response) => {
            if (response.data.status) {
              const { data: cart } = response.data;

              cart.forEach((data) => {
                if (
                  data.package_type === PACKAGE_TYPES.GIFT ||
                  data.package_type === PACKAGE_TYPES.GIFTCARD ||
                  data.package_type === PACKAGE_TYPES.PASSGIFT
                ) {
                  data.delivery_recipient_area_code =
                    data.delivery_recipient_phone.area_code;
                  data.delivery_recipient_phone_cc =
                    data.delivery_recipient_phone.country_code;
                  data.delivery_recipient_phone_w_out_code =
                    data.delivery_recipient_phone.number;
                }

                data.product.provider = product.provider;
                data.product.category = product.category;
              });

              this.props.updateCart([{ ...cartItem, ...cart[0] }]);

              setTimeout(
                () =>
                  this.setState(
                    {
                      addedItem: cartItem,
                    },
                    () => {
                      this.props.history.push(
                        global.summary.links[this.props.lang]
                      );
                    }
                  ),
                10
              );
            }
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({ isAddingToCart: false });
            return false;
          });
      } else {
        this.props.updateCart([cartItem]);

        setTimeout(
          () =>
            this.setState({ addedItem: cartItem }, () => {
              this.props.history.push(global.summary.links[this.props.lang]);
            }),
          10
        );
      }
    }
  };

  handleCloseAddedToCartModal = () => {
    this.setState({
      isAddedToCartModalOn: false,
      isAddingToCart: false,
    });
  };

  loadMoreComments = (e) => {
    if (e) e.preventDefault();

    this.setState({ isLoadingMore: true }, () => {
      const page = this.state.reviewsPage + 1;

      const allHeaders = this.props.user.isLoggedIn
        ? {
            ...headers,
            "Accept-Language": this.props.lang,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          }
        : {
            ...headers,
            "Accept-Language": this.props.lang,
          };

      API.get(
        `products/${this.state.product.id}/reviews?limit=${this.reviewsLimit}&page=${page}`,
        {
          headers: allHeaders,
        }
      )
        .then((response) => {
          const { data, meta } = response.data;
          const { current_page, last_page } = meta;

          const reviewsHasNext = current_page < last_page;

          const reviews = [...this.state.reviews, ...data];

          this.setState({
            reviews,
            reviewsPage: page,
            reviewsHasNext,
            isLoadingMore: false,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isLoadingMore: false });
        });
    });
  };

  tabs = [
    {
      id: 0,
      title: "detailed_info",
      property: (
        <DetailedInfo
          user={this.props.user}
          handleAddToCart={this.handleAddToCart}
          isAddingToCart={this.state.isAddingToCart}
          lang={this.props.lang}
          productAvailabilities={this.state.productAvailabilities}
        />
      ),
    },
    {
      id: 1,
      title: "location",
      property: (
        <Location
          center={{
            lat: 59.95,
            lng: 30.33,
          }}
        />
      ),
    },
    {
      id: 2,
      title: "comments",
      property: (
        <Comments
          rating={this.state.product.rating}
          stats={this.state.product.stats}
        />
      ),
    },
  ];

  render() {
    const mutualProps = {
      translate: this.props.translate,
      user: this.props.user,
      lang: this.props.lang,
    };
    return this.state.isNotFound ? (
      <NotFound translate={this.props.translate} lang={this.props.lang} />
    ) : this.state.wrongSlugRedirectTo ? (
      <PrerenderRedirect to={this.state.wrongSlugRedirectTo} />
    ) : this.state.isNotLiveAnymore ? (
      <NotLive {...mutualProps} />
    ) : (
      <div className="Experience">
        <SEO
          title={this.props.translate("page_title.experience", {
            experience: this.state.product?.title,
          })}
          description={this.props.translate("page_description.experience", {
            experience: this.state.product?.title,
          })}
          image={this.state.product?.cover_image_url}
          keywords={this.state.product?.tags}
          url={`${process.env.REACT_APP_URL}/${global.experience.links[
            this.props.lang
          ].replace(":name", this.state.product.slug)}`}
        />
        <Helmet>
          <link
            rel="x-default"
            href={`${process.env.REACT_APP_URL}${global.experience.links[
              "tr"
            ].replace(":name", this.state.product?.translations?.tr?.slug)}`}
            hrefLang="x-default"
          />
          {this.state.product.translations &&
            Object.keys(this.state.product.translations).map((key) => (
              <link
                key={key}
                rel="alternate"
                hrefLang={key}
                href={`${process.env.REACT_APP_URL}${global.experience.links[
                  key
                ].replace(":name", this.state.product.translations[key].slug)}`}
              />
            ))}

          {this.state.product && (
            <script type="application/ld+json">
              {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": ${JSON.stringify(this.state.product.title)},
              "image": [${
                this.state.product.images && this.state.product.images.length
                  ? this.state.product.images.map((img) =>
                      JSON.stringify(img.url)
                    )
                  : null
              }],
              "description": ${JSON.stringify(
                this.state.product?.meta_description
              )},
              "sku": ${JSON.stringify(this.state.product?.code)},
              "brand": {
                "@type": "Brand",
                "name": ${JSON.stringify(this.state.product?.provider?.name)}
              },
              "review": [${this.state.reviews.map((review) => {
                return `{
                  "@type": "Review",
                  "reviewRating": {
                    "@type": "Rating",
                      "ratingValue": ${JSON.stringify(String(review?.rate))},
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": ${JSON.stringify(review?.full_name)}
                    }
                  }`;
              })}],
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ${JSON.stringify(
                  String(this.state.product?.stats?.rate)
                )},
                "reviewCount": ${JSON.stringify(
                  String(this.state.product?.stats?.review)
                )}
              },
              "offers": {
                "@type": "Offer",
                "url": ${JSON.stringify(
                  process.env.REACT_APP_URL + this.props.location.pathname
                )},
                "priceCurrency": ${JSON.stringify(
                  this.state.product?.currency?.code?.toUpperCase()
                )},
                "price": ${JSON.stringify(
                  String(this.state.product?.price_options?.[0]?.price)
                )},
                "priceValidUntil": ${JSON.stringify(
                  String(dayjs().add(1, "months").format("YYYY-MM-DD"))
                )},
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
            </script>
          )}
        </Helmet>
        {this.state.isLoading ? (
          <Slider
            slides={[]}
            key={1}
            lang={this.props.lang}
            translate={this.props.translate}
          />
        ) : (
          <Slider
            slides={this.state.product.images}
            key={2}
            video_url={this.state.product.video_url}
            lang={this.props.lang}
            cover={this.state.product.cover_image_url}
            translate={this.props.translate}
          />
        )}
        <section className="page d-flex justify-content-center align-items-start">
          <div className="page-holder  d-flex justify-content-center align-items-start">
            <div className="info-area">
              {this.state.isLoading ? (
                <BLLoader />
              ) : (
                <Header
                  translate={this.props.translate}
                  handleToggleBucketlistStatus={
                    this.handleToggleBucketlistStatus
                  }
                  inBucketlist={this.state.inBucketlist}
                  isLoading={this.state.isLoading}
                  isAddingToBucketlist={this.state.isAddingToBucketlist}
                  product={this.state.product}
                  lang={this.props.lang}
                  provider={this.state.product.provider}
                />
              )}
              <div className="sidebar-area d-flex d-lg-none flex-column">
                <Sidebar
                  translate={this.props.translate}
                  handleScrollToPackageOptions={
                    this.handleScrollToPackageOptions
                  }
                />
              </div>
              {!this.state.isLoading ? (
                <>
                  <div className="experience-tabs-holder d-flex flex-column">
                    <div
                      className="tabs d-flex justify-content-start align-items-center w-100 position-relative"
                      data-active-tab={this.state.activeTab}
                    >
                      {this.state.tabs.map((data) => (
                        <button
                          key={data.id}
                          className={`tab d-flex justify-content-center align-items-center ${
                            this.state.activeTab === data.id ? `active` : ``
                          }`}
                          onClick={() => this.setState({ activeTab: data.id })}
                        >
                          {this.props.translate(`experience.${data.title}`)}
                        </button>
                      ))}
                    </div>
                    <div
                      className="tab-pages-holder d-flex justify-content-start align-items-start"
                      data-active-tab={this.state.activeTab}
                    >
                      <CSSTransition
                        in={this.state.activeTab === 0}
                        timeout={300}
                        classNames="tab-page"
                      >
                        <DetailedInfo
                          {...mutualProps}
                          handleBuyNow={this.handleBuyNow}
                          isAddingToCart={this.state.isAddingToCart}
                          product={this.state.product}
                          productAvailabilities={
                            this.state.productAvailabilities
                          }
                          productAvailabilitiesLoading={
                            this.state.productAvailabilitiesLoading
                          }
                          handleGetAvailabilities={(dates) =>
                            this.handleGetAvailabilities(dates)
                          }
                          handleSetSelectedAvailability={(availability) =>
                            this.setState({
                              selectedAvailability: availability,
                            })
                          }
                          selectedAvailability={this.state.selectedAvailability}
                          productPackages={this.state.productPackages}
                          productPackagesLoading={
                            this.state.productPackagesLoading
                          }
                          selectedPackage={this.state.selectedPackage}
                          handleSetSelectedPackage={(selectedPackage) => {
                            this.setState({ selectedPackage });
                          }}
                          productUnits={this.state.productUnits}
                          productUnitsLoading={this.state.productUnitsLoading}
                          selectedUnit={this.state.selectedUnit}
                          handleSetSelectedUnit={(selectedUnit) => {
                            this.setState({ selectedUnit });
                          }}
                        />
                      </CSSTransition>
                      <CSSTransition
                        in={this.state.activeTab === 1}
                        timeout={300}
                        classNames="tab-page"
                      >
                        <Location
                          {...mutualProps}
                          location={this.state.product.location}
                          center={{
                            lat: this.state.product?.location?.map_latitude,
                            lng: this.state.product?.location?.map_longitude,
                          }}
                        />
                      </CSSTransition>
                      <CSSTransition
                        in={this.state.activeTab === 2}
                        timeout={300}
                        classNames="tab-page"
                      >
                        <Comments
                          {...mutualProps}
                          rating={this.state.product.stats.rate}
                          rates={this.state.product.stats.reviews}
                          comments={this.state.reviews}
                          reviewsToView={this.state.reviewsToView}
                          loadMoreComments={this.loadMoreComments}
                          totalBookings={this.state.product.total_bookings}
                          completedBookings={
                            this.state.product.completed_bookings
                          }
                          pendingReviews={this.state.product.pending_reviews}
                          stats={this.state.product.stats}
                          reviewsHasNext={this.state.reviewsHasNext}
                          isLoadingMore={this.state.isLoadingMore}
                        />
                      </CSSTransition>
                    </div>
                  </div>

                  <div className="sidebar-area d-flex d-lg-none flex-column">
                    <GiftAnExperience
                      translate={this.props.translate}
                      lang={this.props.lang}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="sidebar-area sticky-top d-none d-lg-flex flex-column">
              <Sidebar
                translate={this.props.translate}
                handleScrollToPackageOptions={this.handleScrollToPackageOptions}
              />
              <GiftAnExperience
                translate={this.props.translate}
                lang={this.props.lang}
              />
            </div>
          </div>
        </section>

        {!!this.state.otherProducts?.length && (
          <PopularActivities
            customTitle={{
              title: this.props.translate("experience.other_experiences_title"),
              subTitle: this.props.translate(
                "experience.other_experiences_sub_title"
              ),
            }}
            translate={this.props.translate}
            listInfo={{
              type: global.experience.type,
              name: `Sevebileceğin Diğer Deneyimler`,
            }}
            products={this.state.otherProducts}
          />
        )}
        <CSSTransition
          in={this.state.isAddedToCartModalOn}
          timeout={300}
          unmountOnExit
        >
          <BLModal
            handleCloseModal={this.handleCloseAddedToCartModal}
            headerFix={true}
          >
            <AddedToCartModal
              currency={this.props.user.currencyPref}
              handleCloseAddedToCartModal={this.handleCloseAddedToCartModal}
              lang={this.props.activeLanguage.code}
              item={this.state.addedItem}
              product={this.state.product}
              translate={this.props.translate}
              user={this.props.user}
            />
          </BLModal>
        </CSSTransition>

        <BLButton
          type="pri"
          clicked={this.handleScrollToPackageOptions}
          classes="package-options-button mobile-extra d-flex d-md-none w-100 position-fixed"
        >
          {this.props.translate("package_options.package_options")}
        </BLButton>
        <SocialMediaShare title={this.state.product.title} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cartItem) => dispatch(update(cartItem)),
  };
};

export default connect(null, mapDispatchToProps)(Experience);
