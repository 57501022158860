import React, { useEffect, useState } from "react";
import BLDropdown from "../../../utils/components/BLDropdown";

import BLLoader from "../../../utils/components/BLLoader";
import { currencyFormatter } from "../../../utils/helper";
import Amounts from "./components/Amounts";
import { PACKAGE_TYPES } from "../../../utils/db/packageTypes";
import BOOKING_MODE from "../../../utils/constants";

const Pass = ({
  selectedPackage,
  product,
  handleSetSelectedPackage,
  translate,
  currency,
  isVoucher,
  isUpdate,
  units,
  lang,
  handleCartDataLayers,
  isUpdating,
  handleUpdatePackage,
  isAddingToCart,
  itemId,
  handleBuyNow,
}) => {
  const [counts, setCounts] = useState({});
  const [totalPrice, setTotalPrice] = useState(parseFloat(0.0).toFixed(2));
  const [isLoading, setIsLoading] = useState(true);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    const checkIfFilled = () => {
      if (!selectedPackage?.id) return false;

      const addedCount = Object.keys(counts).filter(
        (key) => counts[key].count > 0
      );

      if (!addedCount.length) return false;

      return true;
    };

    if (isFilled !== checkIfFilled()) {
      setIsFilled(checkIfFilled());
    }
  }, [counts, isFilled, selectedPackage?.id]);

  useEffect(() => {
    if (selectedPackage?.id) {
      setIsLoading(true);
    }
  }, [selectedPackage]);

  return (
    <div className="option-holder pick-a-date">
      <p className="desc">
        {translate("package_options.pick_a_date_tab.answer")}
      </p>
      <form
        action="#!"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="row">
          <div
            className={`col-12 ${
              product?.booking_mode !== BOOKING_MODE.DATEANDTIME
                ? `col-md-6`
                : ``
            }`}
          >
            <BLDropdown
              name="package"
              options={product?.packages?.map((item) => ({
                name: item.title,
                value: item.id,
              }))}
              placeholder={translate("experience.select_package")}
              default={selectedPackage?.id}
              setValue={(value) => {
                handleSetSelectedPackage(
                  product.packages.find(
                    (item) => String(item.id) === String(value)
                  )
                );
              }}
            />
          </div>
        </div>

        <Amounts
          isLoading={isLoading}
          units={units}
          isUpdate={isUpdate}
          isVoucher={isVoucher}
          currency={currency}
          selectedPackage={selectedPackage}
          handleUpdateCounts={(counts) => {
            setCounts(counts);
            setIsLoading(false);
          }}
          handleCartDataLayers={handleCartDataLayers}
          handleUpdateTotalPrice={(totalPrice) => setTotalPrice(totalPrice)}
          packageType={PACKAGE_TYPES.PASS}
        />

        <div className="row">
          <div className="col d-flex justify-content-end align-items-end">
            <div className="total selectable">
              {translate("global.total")}{" "}
              <span>{currencyFormatter(currency, totalPrice, lang)}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="disclaimer">
              {translate("package_options.fill_all_fields_to_continue")}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 offset-sm-6 offset-md-2 d-flex justify-content-end align-items-center">
            {isAddingToCart ? (
              <BLLoader />
            ) : isUpdate ? (
              <button
                className="add-to-cart-button w-100"
                onClick={() =>
                  handleUpdatePackage(itemId, {
                    priceOptions: { ...counts },
                    packageType: PACKAGE_TYPES.PASS,
                    totalPrice: totalPrice,
                    currency: currency,
                  })
                }
                disabled={!isFilled || isUpdating}
              >
                {isUpdating ? (
                  <div className="loader w-100 h-100 d-flex justify-content-center align-items-center">
                    <BLLoader loaderType="dots" />
                  </div>
                ) : (
                  translate("global.update")
                )}
              </button>
            ) : (
              <button
                className="add-to-cart-button w-100"
                onClick={() =>
                  handleBuyNow({
                    priceOptions: { ...counts },
                    packageType: PACKAGE_TYPES.PASS,
                    totalPrice: totalPrice,
                    currency: currency,
                  })
                }
                disabled={!isFilled}
              >
                {translate("global.buy_it_now")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Pass;
