import React from "react";
import { global } from "../../App/routes";
import { Link } from "react-router-dom";
import BLRating from "./BLRating";

export const BLSearchSuggestions = (props) => {
  return (
    <div className="bl-search-suggestions position-absolute">
      <div className="suggestions">
        {props.suggestions.map((data) => (
          <div
            className="suggestion d-flex align-items-start position-relative"
            key={data.id}
          >
            <div className="cover d-flex justify-content-center align-items-center overflow-hidden">
              <img
                src={data.cover_image_url}
                alt={`${data.title} Cover`}
                className="h-100"
              />
            </div>
            <div className="info-middle d-flex flex-column align-items-start">
              <h6 className="cat">{data.category[1].name}</h6>
              <h2 className="title">
                <Link
                  className="stretched-link"
                  to={global.experience.links[props.lang].replace(
                    ":name",
                    data.slug
                  )}
                >
                  {data.title}
                </Link>
              </h2>
              <h5 className="location">
                {data.location.town_name}, {data.location.city_name}
              </h5>
            </div>

            <div className="info-right d-flex flex-column justify-content-between align-items-start align-items-lg-end ml-auto">
              <div className="stats d-flex justify-content-start align-items-center mb-lg-4">
                <BLRating rating={data.stats.rate} />
                <div className="comments d-flex justify-content-start align-items-center">
                  <span className="info">
                    {data.stats.review} {props.translate("product.comment")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
