import React from "react";
import PropTypes from "prop-types";
import SingleFaq from "./SingleFaq";

const FAQs = (props) => {
  return (
    <>
      <section className="faq-holder position-relative">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="row">
                <div className="col-12 col-md-10 col-lg-11 col-xl-9 offset-md-1 offset-lg-1 offset-xl-3">
                  <div className="faqs d-flex flex-column align-items-center">
                    <SingleFaq
                      title={props.translate("voucher.faqs.2.title")}
                      content={Array.from({ length: 5 }, (_, index) =>
                        props.translate(`voucher.faqs.2.list.content_${index}`)
                      )}
                      extra={props.translate("voucher.faqs.2.link")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="row">
                <div className="col-12 col-md-10 col-lg-11 col-xl-9 offset-md-1 offset-lg-0">
                  <div className="faqs d-flex flex-column align-items-center">
                    <SingleFaq
                      title={props.translate("voucher.faqs.1.title")}
                      content={Array.from({ length: 4 }, (_, index) =>
                        props.translate(`voucher.faqs.1.list.content_${index}`)
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

FAQs.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default FAQs;
