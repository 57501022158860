import React, { useEffect, useState } from "react";
import { withLocalize } from "react-localize-redux";

import BLLoader from "./BLLoader";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CSSTransition } from "react-transition-group";
import dayjs from "dayjs";

const BLDatePicker = (props) => {
  const [selected, setSelected] = useState(props.selected);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <div className="bl-datepicker position-relative">
      {props.isLoading && (
        <div
          className="is-loading position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ zIndex: 9999 }}
        >
          <BLLoader loaderType="dots" />
        </div>
      )}
      <button
        type="button"
        className={`bl-datepicker-input position-relative d-flex justify-content-start ${
          selected ? `filled` : ``
        }`}
        onClick={() => setShow(!show)}
      >
        <span className="placeholder position-absolute">
          {props.placeholder}
        </span>

        <p className="m-0">
          {dayjs(selected)?.isValid()
            ? dayjs(selected).format("DD MMMM YYYY")
            : ``}
        </p>
      </button>
      <CSSTransition in={show} timeout={300} unmountOnExit>
        <div
          className="calendar-holder position-absolute"
          data-is-loading={props.isLoading}
        >
          {props.isLoading && (
            <div
              className="is-loading position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ zIndex: 9999 }}
            >
              <BLLoader loaderType="dots" />
            </div>
          )}
          <Calendar
            minDate={props?.minDate || null}
            maxDate={props?.maxDate || null}
            locale={props.lang}
            showNeighboringMonth={false}
            showNavigation={true}
            onChange={(date) => {
              props.setSelected && props.setSelected(date);
              setSelected && setSelected(date);
              setShow(false);
            }}
            onActiveStartDateChange={(date) => {
              if (
                date?.value &&
                dayjs(date?.activeStartDate).isSame(dayjs(date?.value), "month")
              )
                return false;

              props.onMonthChange && props.onMonthChange(date?.activeStartDate);
            }}
            tileClassName={({ date }) => {
              let className = "tile";
              if (dayjs(date).isSame(dayjs(props.selected).startOf("day")))
                return `${className} selected`;

              if (props?.includeDates) {
                if (
                  props?.includeDates?.find((item) => dayjs(item).isSame(date))
                )
                  return `${className} available`;

                return `${className} unavailable`;
              }

              return className;
            }}
            maxDetail="month"
            minDetail="month"
            tileDisabled={({ date }) => {
              if (props?.includeDates) {
                if (
                  props?.includeDates?.find((item) => dayjs(item).isSame(date))
                )
                  return false;

                return true;
              }
            }}
            defaultActiveStartDate={
              props.selected && dayjs(props.selected)?.isValid()
                ? dayjs(props.selected).toDate()
                : dayjs().toDate()
            }
          />
        </div>
      </CSSTransition>
    </div>
  );
};

export default withLocalize(BLDatePicker);
