import React from "react";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";

export const Banner = ({ breadcrumbs, cover, name }) => {
  return (
    <section
      className="banner position-relative"
      style={{
        backgroundImage: cover ? `url("${cover}")` : ``,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="cat-breadcrumbs-holder position-absolute w-100">
        <div className="container">
          <div className="row">
            <div className="col">
              <BLBreadcrumbs breadcrumbs={breadcrumbs} color="white" />
            </div>
          </div>
        </div>
      </div>
      <div className="overlay position-absolute d-flex justify-content-center align-items-center overflow-hidden"></div>
      <div className="container">
        <div className="row">
          <div className="col d-flex flex-column align-items-center justify-content-center">
            <h1 className="cat-title selectable">{name}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};
