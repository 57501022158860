import React, { useEffect, useState } from "react";
import { withLocalize } from "react-localize-redux";
import BLInput from "../utils/components/BLInput";
import BLButton from "../utils/components/BLButton";
import { Link } from "react-router-dom";
import { guest } from "../App/routes";
import API, { headers } from "../utils/API";
import {
  dataLayer__forgotPassword,
  dataLayer__passwordSent,
} from "../utils/DataLayerEvents";
import SEO from "../utils/components/SEO";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (emailError) setEmailError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    const page = {
      title: document.title,
      type: guest.resetPassword.type,
      lang: props.user.langPref,
      currenct: props.user.currencyPref.code,
    };

    dataLayer__forgotPassword(page);
  }, [props.user.currencyPref.code, props.user.langPref]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    setIsSending(true);

    const data = {
      email,
    };

    API.post("password/recovery", data, {
      headers: { ...headers, "Accept-Language": props.activeLanguage.code },
    })
      .then((response) => {
        setSuccess(true);
        setIsSending(false);

        const page = {
          title: document.title,
          type: guest.resetPassword.type,
          lang: props.user.langPref,
          currenct: props.user.currencyPref.code,
        };

        dataLayer__passwordSent(page);
      })
      .catch((err) => {
        if (err.response.data) {
          const { data } = err.response;

          setIsSending(false);

          if (data.errors.email) {
            setEmailError(data.errors.email[0]);
            return;
          } else setEmailError(data.info.message);
        }
      });
  };

  return (
    <div className="auth reset-password">
      <SEO
        title={props.translate("page_title.resetPassword")}
        description={props.translate("page_description.resetPassword")}
      />
      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center">
            <div className="auth-holder d-flex justify-content-center align-items-center flex-column">
              {success ? (
                <>
                  <h4 className="title">
                    {props.translate("auth.reset_password_success_title")}
                  </h4>
                  <p className="desc text-center">
                    {props.translate("auth.reset_password_success_content")}
                  </p>
                  <Link
                    to={guest.login.links[props.activeLanguage.code]}
                    className="cancel-link"
                  >
                    {props.translate("global.go_back")}
                  </Link>
                </>
              ) : (
                <>
                  <h4 className="title">
                    {props.translate("auth.reset_password_title")}
                  </h4>
                  <p className="desc text-center">
                    {props.translate("auth.reset_password_desc")}
                  </p>
                  <form
                    action="reset-password"
                    className="d-flex flex-column w-100"
                    onSubmit={handleSubmit}
                  >
                    <BLInput
                      type="email"
                      name="email"
                      value={email}
                      changed={(e) => setEmail(e.target.value)}
                      label={props.translate("global.email")}
                      error={emailError}
                      validationType="email"
                      inputProps={{ inputMode: "email" }}
                    />
                    <BLButton type="pri" action="submit" disabled={isSending}>
                      {props.translate("auth.reset_password")}
                    </BLButton>
                  </form>
                  <Link
                    to={guest.login.links[props.activeLanguage.code]}
                    className="cancel-link"
                  >
                    {props.translate("global.go_back")}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(ResetPassword);
