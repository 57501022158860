import { Translate } from "react-localize-redux";

const passwordRequirements = [
  {
    id: "length",
    infoText: <Translate id="auth.password_step_length" />,
    order: 1,
  },
  {
    id: "lowercase",
    infoText: <Translate id="auth.password_step_lowercase" />,
    order: 2,
  },
  {
    id: "uppercase",
    infoText: <Translate id="auth.password_step_uppercase" />,
    order: 3,
  },
  {
    id: "number",
    infoText: <Translate id="auth.password_step_number" />,
    order: 4,
  },
];

export default passwordRequirements;
