import React from "react";

export const DatePicked = (props) => {
  return (
    <div className="details-area">
      <h6 className="title">{props.translate("global.detailed_info")}</h6>
      <div className="info-holder d-flex flex-column">
        {!!props.selectedPackage && (
          <div className="single-info d-flex justify-content-between">
            <h6 className="info-title text-left">
              {props.translate("product.preferred_package")}:
            </h6>
            <p className="info-content text-right">
              {props.selectedPackage?.title}
            </p>
          </div>
        )}
        <div className="single-info d-flex justify-content-between">
          <h6 className="info-title text-left">
            {props.translate("product.preferred_date")}:
          </h6>
          <p className="info-content text-right">{props.date}</p>
        </div>
        {props.time ? (
          <div className="single-info d-flex justify-content-between">
            <h6 className="info-title text-left">
              {props.translate("product.preferred_time")}:
            </h6>
            <p className="info-content text-right">{props.time}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
