export const update = items => {
  return { type: "CART_UPDATE", items };
};

export const get = cart => {
  return { type: "GET" };
};

export const reset = () => {
  return { type: "RESET" };
};
