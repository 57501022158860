import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ cats, lang, match }) => {
  const [openedSidebar, setOpenedSidebar] = useState(match.params.cat);

  const handleChange = ({ target }) => setOpenedSidebar(target.value);

  useEffect(() => {
    setOpenedSidebar(match.params.cat);
  }, [match.params.cat]);

  return (
    <div className="content-sidebar">
      <div className="sidebar-lists">
        {cats.map((cat) => (
          <div className="sidebar-list" key={cat.slug}>
            <input
              type="radio"
              id={cat.slug}
              name="sidebar-checker"
              value={cat.slug}
              onChange={handleChange}
            />
            <label htmlFor={cat.slug}>
              <h4 className="list-title">
                <span
                  className="icon"
                  dangerouslySetInnerHTML={{ __html: cat.icon }}
                ></span>
                <span className="text">{cat.title}</span>
                <span className="arrow">
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1L5 5L1 1"
                      stroke="black"
                      strokeWidth="1.44"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </h4>
            </label>
            <CSSTransition
              in={cat.slug === openedSidebar}
              timeout={300}
              classNames="sidebar-content"
              unmountOnExit
            >
              <div className="sidebar-holder">
                <div className="sidebar">
                  {cat.contents.map((content) => (
                    <SidebarItem
                      key={content.slug}
                      title={content.title}
                      slug={content.slug}
                      cat={cat.slug}
                      lang={lang}
                    />
                  ))}
                </div>
              </div>
            </CSSTransition>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
