import React from "react";

import BLCollapsible from "../../utils/components/BLCollapsible";

import PackageOptions from "./PackageOptions";

const DetailedInfo = (props) => {
  return (
    <div className="detailed-info-page page-holder">
      <BLCollapsible
        summaryContents={[
          props.translate("experience.experience_content", {
            title: props.product?.title,
          }),
        ]}
        element="h2"
        classes="detailed-info-collapsibles"
        defaultOn={true}
      >
        <div
          dangerouslySetInnerHTML={{ __html: props.product.description }}
        ></div>
      </BLCollapsible>
      <BLCollapsible
        summaryContents={[props.translate("experience.important_info")]}
        classes="detailed-info-collapsibles sec"
        defaultOn={false}
        icon={
          <svg
            className="bi bi-info-circle-fill mr-2"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.product.important_information,
          }}
        ></div>
      </BLCollapsible>
      <PackageOptions
        user={props.user}
        handleBuyNow={props.handleBuyNow}
        isAddingToCart={props.isAddingToCart}
        product={props.product}
        lang={props.lang}
        currency={props.user.currencyPref}
        isUpdate={false}
        units={props.productUnits}
        selectedAvailability={props.selectedAvailability}
        handleGetAvailabilities={props.handleGetAvailabilities}
        handleSetSelectedAvailability={props.handleSetSelectedAvailability}
        productAvailabilities={props.productAvailabilities}
        productAvailabilitiesLoading={props.productAvailabilitiesLoading}
        selectedPackage={props.selectedPackage}
        handleSetSelectedPackage={props.handleSetSelectedPackage}
      />
    </div>
  );
};

export default DetailedInfo;
