import React, { Component } from "react";
import Banner from "./components/Banner";
import Bridge from "./components/Bridge";
import Contact from "./components/Contact";
import SEO from "../utils/components/SEO";

export default class CorporateVoucher extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.location.hash === "#contact") {
      document.addEventListener("load", this.scrollToRef, true);
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("load", this.scrollToRef, true);
  };

  scrollToRef = () => {
    if (document.scrollingElement.scrollTop !== this.myRef.current.offsetTop)
      window.scrollTo(0, this.myRef.current.offsetTop);
  };

  render() {
    return (
      <div className="corporate-voucher-page">
        <SEO
          title={this.props.translate("page_title.corporateVoucher")}
          description={this.props.translate(
            "page_description.corporateVoucher"
          )}
        />
        <Banner
          translate={this.props.translate}
          lang={this.props.lang}
          scrollToContact={this.scrollToRef}
        />
        <Bridge translate={this.props.translate} />
        <Contact
          translate={this.props.translate}
          currency={this.props.user.currencyPref}
          myRef={this.myRef}
        />
      </div>
    );
  }
}
