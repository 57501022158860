import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { setViewportHeight } from "../helper";

const BLModal = (props) => {
  const [isModalOn, setIsModalOn] = useState(false);

  setTimeout(() => {
    setIsModalOn(true);
  }, 0);

  useEffect(() => {
    setViewportHeight();

    window.addEventListener("resize", setViewportHeight);

    document.querySelector("html").classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");
    if (props.headerFix)
      document.querySelector("body").setAttribute("data-is-modal-on", "true");
    return () => {
      if (!props.keepFix) {
        document.querySelector("html").classList.remove("overflow-hidden");
        document.body.classList.remove("overflow-hidden");
        window.removeEventListener("resize", setViewportHeight);
      }

      if (props.headerFix && !props.keepFix) {
        document
          .querySelector("body")
          .setAttribute("data-is-modal-on", "false");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOn, props.headerFix]);

  return (
    <div className="bl-modal d-flex justify-content-center align-items-center overflow-hidden position-fixed w-100">
      <div
        className="overlay position-absolute w-100 h-100"
        onClick={!props.notClosable ? props.handleCloseModal : () => {}}
      ></div>
      <CSSTransition
        in={isModalOn}
        timeout={300}
        unmountOnExit
        classNames="bl-content"
      >
        <div
          className={`bl-content position-relative ${
            props.classes ? props.classes : ``
          }`}
        >
          {!props.notClosable ? (
            <button className="close-btn" onClick={props.handleCloseModal}>
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.7293 7.29716C21.3192 6.88711 20.6535 6.8862 20.2435 7.29625L14.5608 12.9789L8.7906 7.20877C8.38505 6.80323 7.72753 6.80323 7.32199 7.20877C6.91644 7.61432 6.91644 8.27183 7.32199 8.67738L13.0922 14.4476L7.40947 20.1302C6.99942 20.5403 7.00033 21.206 7.41038 21.6161C7.82043 22.0261 8.48616 22.027 8.89621 21.617L14.5789 15.9343L20.3491 21.7045C20.7546 22.11 21.4121 22.11 21.8177 21.7045C22.2232 21.2989 22.2232 20.6414 21.8177 20.2359L16.0475 14.4657L21.7302 8.78299C22.1402 8.37294 22.1393 7.70721 21.7293 7.29716Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : (
            ""
          )}
          {props.children}
        </div>
      </CSSTransition>
    </div>
  );
};

BLModal.propTypes = {
  children: PropTypes.any.isRequired,
  handleCloseModal: PropTypes.func,
  classes: PropTypes.string,
};

export default BLModal;
