import React from "react";

const ClarificationText = (props) => {
  return (
    <div className="page-holder cookie-policy" data-section="enlightmentText">
      <h1 className="p-title">
        {props.translate("static_pages.clarification_text.title")}
      </h1>
      {Array.from({ length: 3 }, (_, index) => (
        <p key={`c-intro-line-${index + 1}`}>
          {props.translate(
            `static_pages.clarification_text.intro.line_${index + 1}`
          )}
        </p>
      ))}
      <h4>
        {props.translate("static_pages.clarification_text.content_2.title")}
      </h4>
      {Array.from({ length: 3 }, (_, index) => (
        <p key={`c-2-line-${index + 1}`}>
          {props.translate(
            `static_pages.clarification_text.content_2.line_${index + 1}`
          )}
        </p>
      ))}
      <h4>
        {props.translate("static_pages.clarification_text.content_3.title")}
      </h4>
      <p>
        {props.translate(`static_pages.clarification_text.content_3.line_1`)}
      </p>
      <h4>
        {props.translate(
          "static_pages.clarification_text.content_3_addition.title"
        )}
      </h4>
      {Array.from({ length: 3 }, (_, index) => (
        <p key={`c-3-a-line-${index + 1}`}>
          {props.translate(
            `static_pages.clarification_text.content_3_addition.line_${
              index + 1
            }`
          )}
        </p>
      ))}
      <ul>
        {Array.from({ length: 7 }, (_, index) => (
          <li key={`c-3-a-list-1-${index + 1}`}>
            {props.translate(
              `static_pages.clarification_text.content_3_addition.list.item_${
                index + 1
              }`
            )}
          </li>
        ))}
      </ul>
      <h4>
        {props.translate("static_pages.clarification_text.content_4.title")}
      </h4>
      {Array.from({ length: 3 }, (_, index) => (
        <p key={`c-4-line-${index + 1}`}>
          {props.translate(
            `static_pages.clarification_text.content_4.line_${index + 1}`
          )}
        </p>
      ))}
      <h4>
        {props.translate("static_pages.clarification_text.content_5.title")}
      </h4>
      <p>
        {props.translate("static_pages.clarification_text.content_5.line_1")}
      </p>
      <h4>
        {props.translate("static_pages.clarification_text.content_6.title")}
      </h4>
      {Array.from({ length: 2 }, (_, index) => (
        <p key={`c-6-line-${index + 1}`}>
          {props.translate(
            `static_pages.clarification_text.content_6.line_${index + 1}`
          )}
        </p>
      ))}
      <ul>
        {Array.from({ length: 9 }, (_, index) => (
          <li key={`c-6-list-1-${index + 1}`}>
            {props.translate(
              `static_pages.clarification_text.content_6.list.item_${index + 1}`
            )}
          </li>
        ))}
      </ul>
      <h4>
        {props.translate("static_pages.clarification_text.content_7.title")}
      </h4>
      <p>
        {props.translate("static_pages.clarification_text.content_7.line_1")}
      </p>
      <ul>
        {Array.from({ length: 3 }, (_, index) => (
          <li key={`c-7-list-1-${index + 1}`}>
            {props.translate(
              `static_pages.clarification_text.content_7.list.item_${index + 1}`
            )}
          </li>
        ))}
      </ul>
      {Array.from({ length: 3 }, (_, index) => (
        <p key={`c-6-line-${index + 2}`}>
          {props.translate(
            `static_pages.clarification_text.content_7.line_${index + 2}`
          )}
        </p>
      ))}
    </div>
  );
};

export default ClarificationText;
