import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductSlider from "../PopularActivities/ProductSlider";
import API from "../../../utils/API";
import Axios from "axios";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { handleErrors } from "../../../utils/helper";

import ReactPlaceholder from "react-placeholder/lib";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";

import "react-placeholder/lib/reactPlaceholder.css";
import { dataLayer__itemListClick } from "../../../utils/DataLayerEvents";

const ProductPlaceholder = () => (
  <div className="product-placeholder">
    <RectShape
      className="cover-placeholder placeholder-item"
      style={{ height: "auto", margin: 0, marginBottom: 10 }}
    />
    <TextBlock
      className="cat-placeholder placeholder-item"
      style={{ width: "20%", marginBottom: 8 }}
    />
    <TextBlock
      className="name-placeholder placeholder-item"
      rows={2}
      style={{ width: "75%", marginBottom: 5 }}
    />
    <div className="stats-placeholder d-flex justify-content-start align-items-center">
      <RectShape
        className="stat-placeholder placeholder-item"
        style={{
          width: 49,
          height: 22,
          borderRadius: 2,
          marginRight: 12,
          marginTop: 10,
          marginBottom: 15,
        }}
      />
      <TextBlock
        className="price-placeholder placeholder-item"
        row={1}
        style={{ width: 50 }}
      />
    </div>
    <TextBlock
      className="price-placeholder placeholder-item"
      row={1}
      style={{ width: 50, marginBottom: 15 }}
    />
    <TextBlock
      className="availability-placeholder placeholder-item"
      row={1}
      style={{ width: 75, marginBottom: 15 }}
    />
  </div>
);

const placeholder = (
  <div className="product-slider-placeholder w-100 d-flex justify-content-center align-items-center overflow-hidden">
    <div className="products-holder d-flex d-md-none justify-content-center align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
    <div className="products-holder d-none d-md-flex justify-content-start align-items-center">
      {Array.from(Array(4).keys()).map((data) => (
        <ProductPlaceholder key={data} />
      ))}
    </div>
  </div>
);

class NewestActivities extends Component {
  state = { products: {}, isLoading: true };

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    this.handleGetProducts();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code ||
      prevProps.user.isLoggedIn !== this.props.user.isLoggedIn ||
      prevProps.user.langPref !== this.props.user.langPref
    )
      this.setState({ isLoading: true }, this.handleGetProducts);
  };

  componentWillUnmount = () => {
    this.cancelSource.cancel("Operation canceled by the user.");
  };

  handleGetProducts = () => {
    API.get(
      `products?currency=${this.props.user.currencyPref.code}&order_by=newest&limit=8&page=1`
    )
      .then((response) => {
        const { data } = response.data;

        // const isLoading = !Boolean(data.length);

        this.setState({ products: data }, () =>
          this.setState({ isLoading: false })
        );
      })
      .catch((err) => handleErrors(err));
  };

  handlePromotionClick = (product) => {
    const listInfo = {
      pageType: this.props.listInfo.type,
      listType: "Widget",
      pageName: this.props.listInfo.name,
      sortType: "",
    };

    dataLayer__itemListClick(product, listInfo);
  };

  render() {
    return (
      <section className="popular-activities w-border">
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center flex-column">
              <h4 className="section-title text-center">
                {this.props.customTitle
                  ? this.props.customTitle.title
                  : this.props.translate("home.newest_activities.title")}
              </h4>
              <p className="section-desc text-center swiper-fix">
                {this.props.customTitle
                  ? this.props.customTitle.subTitle
                  : this.props.translate("home.newest_activities.desc")}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ReactPlaceholder
                ready={!this.state.isLoading}
                customPlaceholder={placeholder}
                showLoadingAnimation
              >
                <ProductSlider
                  currency={this.props.user.currencyPref}
                  products={this.state.products}
                  user={this.props.user}
                  slidesPerView={this.props.slidesPerView}
                  handlePromotionClick={this.handlePromotionClick}
                  listInfo={this.props.listInfo}
                />
              </ReactPlaceholder>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Link
                to="#!"
                className="show-all-button d-none align-items-center"
              >
                {this.props.translate("global.show_all_products")}
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 6.5L1 12"
                    stroke="#E75B2B"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(NewestActivities));
