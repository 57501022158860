import React, { Component } from "react";
import BLInput from "../../utils/components/BLInput";
import BLDropdown from "../../utils/components/BLDropdown";
import BLButton from "../../utils/components/BLButton";
import {
  getCountries,
  getCities,
  getDistricts,
  getTurkeyOnTop,
} from "../../utils/helper";
import BLLoader from "../../utils/components/BLLoader";
import { BLRadio } from "../../utils/components/BLRadio";
import { Personal } from "../../Cart/components/paymentSections/invoiceTypes/Personal";
import { Corporate } from "../../Cart/components/paymentSections/invoiceTypes/Corporate";

export default class BillingInfo extends Component {
  state = {
    countries: [],
    cities: [],
    districts: [],
    selectedCountry: this.props.user.invoice.country_id,
    selectedCity: this.props.user.invoice.city_id,
    selectedDistrict: this.props.user.invoice.state_id,
  };
  componentDidMount = () => {
    getCountries(this.props.lang).then((response) => {
      const countries = getTurkeyOnTop(response.data.data);
      this.setState({ countries });
    });

    if (this.props.user.invoice.country_id) this.handleGetCities();
    if (this.props.user.invoice.city_id) this.handleGetDistricts();
  };

  componentDidUpdate = () => {
    if (this.props.user.invoice.country_id !== this.state.selectedCountry) {
      this.setState({
        selectedCountry: this.props.user.invoice.country_id,
      });

      setTimeout(() => {
        if (this.state.selectedCountry) this.handleGetCities();
      }, 10);
    }

    if (this.props.user.invoice.city_id !== this.state.selectedCity) {
      this.setState({
        selectedCity: this.props.user.invoice.city_id,
      });
      setTimeout(() => {
        if (this.state.selectedCity) this.handleGetDistricts();
      }, 10);
    }

    if (this.props.user.invoice.state_id !== this.state.selectedDistrict) {
      this.setState({
        selectedDistrict: this.props.user.invoice.state_id,
      });
    }
  };

  handleGetCities = () => {
    getCities(this.props.lang, this.props.user.invoice.country_id).then(
      (response) => {
        const { data } = response.data;

        this.setState({ cities: data, selectedDistrict: "" });
      }
    );
  };

  handleGetDistricts = () => {
    getDistricts(this.props.lang, this.props.user.invoice.city_id).then(
      (response) => {
        const { data } = response.data;

        this.setState({ districts: data });
      }
    );
  };
  render() {
    return (
      <div className="page-holder w-100">
        <div className="row">
          <div className="col">
            <h4 className="page-title">
              {this.props.translate("profile.billing_info_page.title")}
            </h4>
          </div>
        </div>
        {this.props.isLoading ? (
          <BLLoader />
        ) : (
          <div className="row">
            <div className="col">
              <section className="page-sec">
                <form action="#!" onSubmit={this.props.handleUpdateBillingInfo}>
                  <div className="row mt-2 mb-4">
                    <div className="col-12 col-md-6 d-flex align-items-center">
                      <h5 className="invoice-type-label mr-4 mb-0">
                        {this.props.translate(
                          "profile.billing_info_page.invoice_type"
                        )}
                        :
                      </h5>
                      <div className="d-flex align-items-center mr-3">
                        <BLRadio
                          label={this.props.translate(
                            "profile.billing_info_page.personal"
                          )}
                          name="invoiceType"
                          checked={this.props.user.invoice.invoiceType === "1"}
                          changed={(e) =>
                            this.props.handleChangeBillingInfo(
                              e.target.name,
                              e.target.value
                            )
                          }
                          value={"1"}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <BLRadio
                          label={this.props.translate(
                            "profile.billing_info_page.corporate"
                          )}
                          name="invoiceType"
                          checked={this.props.user.invoice.invoiceType === "2"}
                          changed={(e) =>
                            this.props.handleChangeBillingInfo(
                              e.target.name,
                              e.target.value
                            )
                          }
                          value={"2"}
                        />
                      </div>
                    </div>
                  </div>
                  {!this.props.user.invoice.invoiceType ||
                  this.props.user.invoice.invoiceType === "1" ? (
                    <Personal
                      invoiceInfo={this.props.user.invoice}
                      translate={this.props.translate}
                      handleUpdateInvoiceInfo={
                        this.props.handleChangeBillingInfo
                      }
                    />
                  ) : (
                    <Corporate
                      invoiceInfo={this.props.user.invoice}
                      translate={this.props.translate}
                      handleUpdateInvoiceInfo={
                        this.props.handleChangeBillingInfo
                      }
                    />
                  )}
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <BLInput
                        type="text"
                        label={this.props.translate(
                          "profile.billing_info_page.billing_address"
                        )}
                        name="address_line"
                        value={
                          this.props.user.invoice.address_line
                            ? this.props.user.invoice.address_line
                            : ""
                        }
                        changed={(e) =>
                          this.props.handleChangeBillingInfo(
                            e.target.name,
                            e.target.value
                          )
                        }
                        minChar={2}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <BLDropdown
                        name="country"
                        placeholder={this.props.translate("global.country")}
                        default={this.state.selectedCountry}
                        setValue={(value) =>
                          this.props.handleChangeBillingInfo(
                            "country_id",
                            value
                          )
                        }
                        options={
                          this.state.countries.length
                            ? this.state.countries.map((data) => {
                                return {
                                  value: data.id.toString(),
                                  name: data.name,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <BLDropdown
                        name="city"
                        placeholder={this.props.translate("global.city")}
                        default={this.state.selectedCity}
                        setValue={(value) =>
                          this.props.handleChangeBillingInfo("city_id", value)
                        }
                        disabled={!this.props.user.invoice.country_id}
                        options={
                          this.state.cities.length
                            ? this.state.cities.map((data) => {
                                return {
                                  value: data.id.toString(),
                                  name: data.name,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <BLDropdown
                        name="state"
                        placeholder={this.props.translate("global.district")}
                        default={this.state.selectedDistrict}
                        setValue={(value) =>
                          this.props.handleChangeBillingInfo("state_id", value)
                        }
                        disabled={!this.props.user.invoice.city_id}
                        options={
                          this.state.districts.length
                            ? this.state.districts.map((data) => {
                                return {
                                  value: data.id.toString(),
                                  name: data.name,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <BLInput
                        type="text"
                        label={this.props.translate("global.postal_code")}
                        name="zip"
                        validationType="number"
                        value={
                          this.props.user.invoice.zip
                            ? this.props.user.invoice.zip
                            : ""
                        }
                        changed={(e) =>
                          this.props.handleChangeBillingInfo(
                            e.target.name,
                            e.target.value
                          )
                        }
                        inputProps={{ inputMode: "numeric" }}
                      />
                      {this.state.selectedCountry === "212" && (
                        <p className="not-selectable post-code-info">
                          {this.props.translate(
                            "profile.billing_info_page.post_code_info",
                            {
                              link: (
                                <a
                                  href="https://www.turkiye.gov.tr/ptt-posta-kodu-sorgulama"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.props.translate("global.click_here")}
                                </a>
                              ),
                            }
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-6">
                      <BLButton
                        type="pri"
                        classes="update-button w-100"
                        action="submit"
                        disabled={
                          this.props.isSending || !this.props.isBillingInfoOk
                        }
                      >
                        {this.props.translate(
                          "profile.billing_info_page.update_billing_info"
                        )}
                      </BLButton>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        )}
      </div>
    );
  }
}
