import React from "react";
import Filters from "./Filters";

const Sidebar = (props) => (
  <section className="sidebar d-flex flex-column justify-content-center align-items-between">
    <Filters
      subCats={props.subCats}
      locations={props.locations}
      categories={props.categories}
      slug={props.slug}
      handleSelectCat={props.handleSelectCat}
      handleApplyFilter={props.handleApplyFilter}
      cat={props.cat}
      filters={props.filters}
      translate={props.translate}
      lang={props.lang}
      handleFilterDataLayers={props.handleFilterDataLayers}
    />
  </section>
);

export default Sidebar;
