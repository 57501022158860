import React, { Component } from "react";
import API, { headers } from "../utils/API";
import { handleErrors, getAccountDetails } from "../utils/helper";
import BLLoader from "../utils/components/BLLoader";
import { Link } from "react-router-dom";
import { global } from "../App/routes";
import { CSSTransition } from "react-transition-group";
import { ProductDetails } from "./components/ProductDetails";
import { ReservationSummary } from "./components/ReservationSummary";
import Reserve from "./components/Reserve";
import toastr from "toastr";
import Helmet from "react-helmet";
import PhoneConfirmation from "../Cart/components/PhoneConfirmation";
import dayjs from "dayjs";
import BOOKING_MODE from "../utils/constants";

class Reservation extends Component {
  state = {
    reservation: null,
    user: {},
    isLoading: true,
    activeTab: 1,
    isExpired: false,
    isSending: false,
    isConfirmPhoneOn: false,
    isConfirmingPhone: false,
    confirmationCode: "",
    confirmResponse: { status: false, success: false, message: "" },
    info: { phone: "" },
    productAvailabilities: [],
    productAvailabilitiesLoading: true,
    selectedAvailability: null,
  };
  componentDidMount = () => {
    const reservationTemp = sessionStorage.getItem("reservationTemp");

    if (reservationTemp) {
      const isExpired = this.checkIfExpired(
        JSON.parse(reservationTemp).expiry_date
      );

      this.setState({
        reservation: JSON.parse(reservationTemp),
        isLoading: false,
        isExpired,
      });
      setTimeout(() => sessionStorage.removeItem("reservationTemp"));
    } else {
      const configs = {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": `${this.props.lang}`,
        },
      };

      const code = this.props.match.params.id;

      API.get(`redeem/${code}`, configs)
        .then((response) => {
          const { data } = response.data;

          const isExpired = this.checkIfExpired(data.expiry_date);

          setTimeout(
            () =>
              this.setState({ reservation: data, isLoading: false, isExpired }),
            20
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.props.history.push("/");
        });
    }

    getAccountDetails(this.props.user._tokenType, this.props.user._token).then(
      (response) => {
        const { data } = response.data;
        const user = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone.number,
          areaCode: data.phone.area_code,
          countryCode: data.phone.country_code,
          is_phone_verified: data.is_phone_verified,
        };

        this.setState({ user });
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.isConfirmPhoneOn &&
      !this.state.isConfirmPhoneOn &&
      this.state.info.is_phone_verified
    )
      this.reserve();
    else if (
      prevState.isConfirmPhoneOn &&
      !this.state.isConfirmPhoneOn &&
      !this.state.info.is_phone_verified
    )
      this.setState({ isSending: false });

    if (
      !prevState?.reservation?.product?.slug &&
      this.state.reservation?.product?.slug &&
      !this.state.isExpired &&
      !this.state.reservation?.is_booked
    ) {
      this.handleGetAvailabilities();
    }
  };

  handleGetAvailabilities = (dates = null) => {
    if (
      !this.state.reservation?.product?.id ||
      !this.state.reservation?.package?.id
    )
      return;

    this.setState({ productAvailabilitiesLoading: true }, () => {
      let url = `products/${this.state.reservation.product.id}/availabilities?package_id=${this.state.reservation?.package.id}`;

      if (dates?.startDate && dates?.endDate) {
        const startDate = dayjs(dates.startDate).format("YYYY-MM-DD");
        const endDate = dayjs(dates.endDate).format("YYYY-MM-DD");
        url += `&from_date=${startDate}&to_date=${endDate}`;
      }

      API.get(url)
        .then(({ data: res }) => {
          const productAvailabilities = res.data
            .filter((item) => item)
            .map((availability) => {
              const availableSlots = availability
                ? availability?.availabilities.filter(
                    (a) => a && (!a.closed || !a.soldOut)
                  ) || []
                : [];

              return {
                ...availability,
                available: availableSlots.length > 0,
              };
            });

          const selectedAvailability = productAvailabilities.find(
            (availability) => availability.available
          );

          this.setState({
            productAvailabilities,
            productAvailabilitiesLoading: false,
            selectedAvailability,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ productAvailabilitiesLoading: false });
        });
    });
  };

  checkIfExpired = (expiry_date) => {
    const expiryDate = dayjs(expiry_date, "DD MMM YYYY HH:mm:ss");
    const checkIfExpired = expiryDate.diff(dayjs());

    let isExpired = false;

    if (checkIfExpired <= 0) isExpired = true;

    return isExpired;
  };

  sendConfirmationCode = () => {
    let phone = parseInt(
      this.state.info.phone.toString().replace(/[^0-9]+/gi, "")
    );

    if (!phone) {
      return false;
    }
    while (phone.toString().charAt(0) === "0") {
      phone = parseInt(phone.substring(1));
    }

    if (!phone) {
      return false;
    }

    this.setState({ isSending: true });

    const params = {
      phone:
        this.state.info.areaCode +
        "-" +
        this.state.info.phone.toString().replace(/\s/g, ""),
      phone_cc: this.state.info.countryCode,
    };

    const prefix = this.state.info.isUsingOwnInfo ? `account/` : `redeem/`;

    API.post(`${prefix}phone-verification/start`, params, {
      headers: {
        ...headers,
        "Accept-Language": this.props.lang,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    })
      .then((response) => {
        if (!response.data.info.is_phone_verified) {
          const confirmResponse = {
            status: false,
            success: false,
            message: "",
          };

          if (this.state.info.isUsingOwnInfo) {
            const user = { ...this.state.user };

            user["is_phone_verified"] = response.data.info.is_phone_verified;

            this.setState({ user });
          }

          const info = { ...this.state.info };

          info["is_phone_verified"] = response.data.info.is_phone_verified;

          this.setState(
            { confirmResponse, isConfirmingPhone: false, info },
            () => this.setState({ isConfirmPhoneOn: true })
          );
        } else {
          this.reserve();
        }
      })
      .catch((err) => handleErrors(err));
  };

  handleClosePhoneConfirmModal = (e) => {
    if (e) e.preventDefault();

    this.setState({ isConfirmPhoneOn: false, confirmationCode: "" });
  };

  handleConfirmPhone = (e) => {
    if (e) e.preventDefault();

    if (this.state.confirmationCode !== "") {
      this.setState({ isConfirmingPhone: true });

      const params = {
        phone:
          this.state.info.areaCode +
          "-" +
          this.state.info.phone.toString().replace(/\s/g, ""),
        phone_cc: this.state.info.countryCode,
        code: this.state.confirmationCode,
      };

      const prefix = this.state.info.isUsingOwnInfo ? `account/` : `redeem/`;

      API.post(`${prefix}phone-verification/verify`, params, {
        headers: {
          ...headers,
          "Accept-Language": this.props.lang,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      })
        .then((response) => {
          const confirmResponse = {
            status: true,
            success: response.data.status,
            message: response.data.info.message,
          };

          if (this.state.info.isUsingOwnInfo) {
            const user = { ...this.state.user };
            user.is_phone_verified = response.data.status;

            setTimeout(() => this.setState({ user }), 1200);
          }

          const info = { ...this.state.info };
          info.is_phone_verified = response.data.status;

          setTimeout(() => this.setState({ confirmResponse, info }), 1200);

          if (response.data.status) {
            setTimeout(() => {
              if (this.state.isConfirmPhoneOn)
                this.setState({ isConfirmPhoneOn: false });
            }, 4200);
          } else {
            setTimeout(
              () =>
                this.setState({ confirmResponse, isConfirmingPhone: false }),
              1200
            );
          }
        })
        .catch((err) => {
          handleErrors(err);
          setTimeout(() => this.setState({ isConfirmingPhone: false }), 20);
        });
    }
  };

  handleReserve = (e, info) => {
    e.preventDefault();

    this.setState(
      {
        info: {
          ...info,
          is_phone_verified: info.isUsingOwnInfo
            ? this.state.user.is_phone_verified
            : false,
        },
      },
      () => {
        if (
          this.state.info.isUsingOwnInfo &&
          this.state.info.phone === this.state.user.phone &&
          this.state.user.is_phone_verified
        )
          this.reserve();
        else this.sendConfirmationCode();
      }
    );
  };

  reserve = () => {
    const { info } = this.state;

    if (info.isUsageAgreementChecked) {
      const toCheck = [
        "preferred_date",
        "isUsingOwnInfo",
        "first_name",
        "last_name",
        "email",
        "phone",
      ];

      if (this.state.reservation.booking_mode === BOOKING_MODE.DATEANDTIME)
        toCheck.push("preferred_time");

      const check = toCheck.map((data) => (data ? true : false));

      if (!check.includes(false)) {
        this.setState({ isSending: true });
        const data = {
          code: this.state.reservation.coupon_code,
          preferred_date: info.preferred_date,
          preferred_time: info.preferred_time,
          is_product_time: info.preferred_time !== "" ? true : false,
          participant_use_my_details: info.isUsingOwnInfo,
          participant_first_name: info.first_name,
          participant_last_name: info.last_name,
          participant_email: info.email,
          participant_phone:
            "+" +
            info.areaCode.toString() +
            "-" +
            info.phone.toString().replace(/\s/g, ""),
          participant_phone_cc: info.countryCode,
          terms_of_use: true,
          privacy_policy: true,
          usage_specification: info.isUsageAgreementChecked,
        };

        const configs = {
          headers: {
            ...headers,
            Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
            "Accept-Language": `${this.props.lang}`,
          },
        };

        API.post("redeem", data, configs)
          .then((response) => {
            const reservation = response.data.data;
            toastr.clear();
            toastr.success(response.data.info.message);

            document.querySelector("header").scrollIntoView();

            this.setState({ reservation, isSending: false });
          })
          .catch((err) => {
            handleErrors(err);
            this.setState({ isSending: false });
          });
      }
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.translate("reservation.reservation")}</title>
        </Helmet>
        {this.state.reservation && this.state.reservation.product && (
          <div className="reservation">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="reservation-cover position-relative d-flex justify-content-center align-items-center"
                    style={
                      this.state.reservation &&
                      this.state.reservation.product &&
                      this.state.reservation.product.cover_image_url
                        ? {
                            background: `url("${this.state.reservation.product.cover_image_url}") 100% no-repeat`,

                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }
                        : { background: "transparent" }
                    }
                  >
                    {this.state.loading ? (
                      <BLLoader />
                    ) : (
                      <img
                        src={this.state.reservation.product.cover_image_url}
                        alt="Reservation Cover"
                        className="d-none"
                      />
                    )}
                    <div className="reservation-status d-flex justify-content-center align-items-center">
                      {this.state.reservation.is_booked ? (
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="33.8"
                            height="33.8"
                            rx="16.9"
                            fill="#E75B2B"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.1017 8.57773L14.257 20.5411L10.8674 15.8098L4.23232 16.0483C6.24044 18.1812 7.59272 19.4419 9.63812 21.5405C11.1761 23.1202 12.3967 25.2778 14.9613 24.441C17.0308 23.7656 24.8897 14.204 27.3888 11.4254L21.1017 8.57773Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      {this.state.reservation.status}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className="product-name">
                    <Link
                      to={global.experience.links[this.props.lang].replace(
                        ":name",
                        this.state.reservation.product.slug
                      )}
                    >
                      {this.state.reservation.product.title}
                    </Link>
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="info-area-holder">
                    <div className="row">
                      <div className="col-12">
                        <div className="top-info w-100">
                          <div className="row">
                            <div className="col-7 col-lg-4">
                              <div className="product-code">
                                {this.props.translate("product.product_code")}:{" "}
                                <span className="selectable">
                                  {this.state.reservation.product.code}
                                </span>
                              </div>
                              <div className="product-location selectable">
                                {
                                  this.state.reservation.product.location
                                    .district.name
                                }
                                ,{" "}
                                {
                                  this.state.reservation.product.location.city
                                    .name
                                }
                              </div>
                            </div>
                            <div className="col-5 col-lg-3">
                              <div className="units">
                                {this.state.reservation.units.map(
                                  (data, index) => (
                                    <div
                                      className="unit d-flex justify-content-between aling-items-center selectable"
                                      key={index}
                                    >
                                      <div className="label">{data.name}</div>
                                      <div className="quantity ">
                                        x
                                        {data.quantity +
                                          " " +
                                          this.props.translate(
                                            `global.piece${
                                              data.quantity > 0 ? `s` : ``
                                            }`
                                          )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-5">
                              <div className="reservation-expiry-date d-flex justify-content-between align-items-center">
                                <span className="label">
                                  {this.props.translate("global.expiry_date")}:
                                </span>
                                <span className="date selectable">
                                  {this.state.reservation.expiry_date}
                                </span>
                              </div>

                              <div className="selected-package d-flex justify-content-between align-items-center">
                                <span className="label">
                                  {this.props.translate("global.package")}:
                                </span>
                                <span className="package selectable">
                                  {this.state.reservation.package.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.reservation.is_booked ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="preferred-date d-flex justify-content-between justify-content-md-start align-items-center">
                            <span className="label mr-2">
                              {this.props.translate("product.preferred_date")}:
                            </span>
                            <span className="date selectable">
                              {this.state.reservation.preferred_date}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="tabs-area w-100">
                <div className="tabs-buttons-holder w-100">
                  <button
                    className="tab-button w-50 text-center position-relative"
                    data-active={this.state.activeTab === 1}
                    onClick={() => this.setState({ activeTab: 1 })}
                    disabled={
                      !this.state.reservation.is_booked && this.state.isExpired
                    }
                  >
                    {this.props.translate(
                      `reservation.${
                        this.state.reservation.is_booked
                          ? `reservation_summary`
                          : `reserve`
                      }`
                    )}
                  </button>
                  <button
                    className="tab-button w-50 text-center position-relative"
                    data-active={this.state.activeTab === 2}
                    onClick={() => this.setState({ activeTab: 2 })}
                  >
                    {this.props.translate("reservation.experience_details")}
                  </button>
                </div>

                <div className="tabs-holder">
                  <CSSTransition
                    in={
                      this.state.activeTab === 1 &&
                      this.state.reservation.is_booked
                    }
                    timeout={10}
                    unmountOnExit
                  >
                    <ReservationSummary
                      reservation={this.state.reservation}
                      translate={this.props.translate}
                    />
                  </CSSTransition>

                  <CSSTransition
                    in={
                      this.state.activeTab === 1 &&
                      !this.state.reservation.is_booked
                    }
                    timeout={10}
                    unmountOnExit
                  >
                    <Reserve
                      reservation={this.state.reservation}
                      translate={this.props.translate}
                      user={this.state.user}
                      handleSubmit={this.handleReserve}
                      isSending={this.state.isSending}
                      handleGetAvailabilities={this.handleGetAvailabilities}
                      productAvailabilities={this.state.productAvailabilities}
                      productAvailabilitiesLoading={
                        this.state.productAvailabilitiesLoading
                      }
                      selectedAvailability={this.state.selectedAvailability}
                      handleSetSelectedAvailability={(availability) =>
                        this.setState({ selectedAvailability: availability })
                      }
                    />
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === 2}
                    timeout={10}
                    unmountOnExit
                  >
                    <ProductDetails
                      product={this.state.reservation.product}
                      translate={this.props.translate}
                    />
                  </CSSTransition>
                </div>
              </div>
            </div>
            <PhoneConfirmation
              handleClosePhoneConfirmModal={this.handleClosePhoneConfirmModal}
              confirmationCode={this.state.confirmationCode}
              handleUpdate={(value) =>
                this.setState({ confirmationCode: value })
              }
              isConfirmPhoneOn={this.state.isConfirmPhoneOn}
              phone={this.state.info.phone}
              handleConfirmPhone={this.handleConfirmPhone}
              confirmResponse={this.state.confirmResponse}
              isConfirmingPhone={this.state.isConfirmingPhone}
              translate={this.props.translate}
              sendConfirmationCode={this.sendConfirmationCode}
              removeHeaderFix={true}
            />
          </div>
        )}
      </>
    );
  }
}

export default Reservation;
