import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import MobileNavCat from "./MobileNavCat";

const MobileNav = ({ translate, cats, content, lang, location }) => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  useEffect(() => {
    setIsMobileMenuActive(false);
  }, [location.pathname]);

  return (
    <div className="help-mobile-menu position-relative">
      <button
        type="button"
        className="nav-menu-mobile d-flex justify-content-between align-items-center d-md-none w-100"
        onClick={() => setIsMobileMenuActive((prevState) => !prevState)}
      >
        <span className="label d-flex justify-content-start align-items-center w-100">
          <span className="help-detail-title">
            {translate("help_details.category")}:
          </span>{" "}
          <span className="cat-title">
            {content.categories
              ? content.categories.length
                ? content.categories[0].title
                : ""
              : ""}
          </span>
        </span>
        <span className="curr-page d-flex align-items-center">
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={isMobileMenuActive ? "menu-active" : ""}
          >
            <path
              d="M8.98828 1.01119L4.99968 5.55664L1.01107 1.01119"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <CSSTransition
        in={isMobileMenuActive}
        timeout={300}
        unmountOnExit
        classNames="mobile-menu"
      >
        <ul className="nav-menu-mobile-content d-flex flex-column d-md-none">
          {cats.map((cat, index) => {
            return (
              <MobileNavCat
                key={`cat-${index}`}
                cat={cat}
                id={index}
                lang={lang}
                isActive={
                  content.categories
                    ? content.categories.length
                      ? content.categories[0].slug === cat.slug
                        ? true
                        : false
                      : ""
                    : ""
                }
              />
            );
          })}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default MobileNav;
