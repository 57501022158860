import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Swiper from "swiper";
import crypto from "crypto";
import Video from "./Feed/Video";
import Photo from "./Feed/Photo";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import YouTube from "react-youtube";

const FeedSlider = ({ feed, title, description, video, lang }) => {
  const [id] = useState(crypto.randomBytes(6).toString("hex"));
  const [swiper, setSwiper] = useState(null);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoId, setVideoId] = useState(null);

  const YOUTUBE_OPTIONS = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 1,
      loop: 1,
      modestbranding: 1,
      playsinline: 1,
      allowFullScreen: 1,
      iv_load_policy: 3,
      rel: 0,
      hl: lang,
    },
  };

  const constructSlider = () => {
    const swiper = new Swiper("#swiper-container-" + id, {
      slidesPerView: 2,
      spaceBetween: 5,
      loop: true,
      loopedSlides: 6,
      grabCursor: feed.length > 1,
      centeredSlides: true,
      keyboard: {
        enabled: true,
      },
      allowTouchMove: feed.length > 1,
      autoplay: feed.length > 1 ? true : false,
      on: {
        init: (swiper) => {
          const thumbs = document.querySelectorAll(
            `#swiper-container-${id} .thumbs .thumb`
          );
          if (thumbs.length) {
            thumbs.forEach((el) => {
              el.childNodes[0].addEventListener("animationend", () => {
                swiper.slideNext();
              });
            });
          }
        },
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 5,
        },
      },
    });

    setSwiper(swiper);
  };

  useEffect(() => {
    constructSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleControlSlider = (direction) => {
    if (direction === "next") {
      swiper.slideNext();
    } else if (direction === "prev") {
      swiper.slidePrev();
    }
  };

  return (
    <>
      <div className="feed-slider vw-100 overflow-hidden position-relative">
        <h4 className="section-title text-center mb-3">{title}</h4>
        <p className="section-desc text-center">{description}</p>
        <div className="swiper position-relative" id={`swiper-container-${id}`}>
          <div
            className="slider-controls d-flex align-items-center prev position-absolute h-100"
            onClick={() => handleControlSlider("prev")}
          >
            <button className="control d-flex justify-content-center align-items-center border-0 rounded-circle">
              <svg
                width="14"
                height="23"
                viewBox="0 0 14 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0001 2L1.56738 11.7854L12.0001 21.5707"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="swiper-wrapper h-100">
            {feed.map((item, index) => (
              <div className="swiper-slide h-100" key={index}>
                <div className="slide-holder w-100">
                  {video ? (
                    <Video
                      item={item}
                      playVideo={() => {
                        const videoId = item.link.split("v=")[1];

                        if (videoId) {
                          if (videoId.includes("&")) {
                            setVideoId(videoId.split("&")[0]);
                          } else {
                            setVideoId(videoId);
                          }

                          setIsVideoPlaying(true);
                        } else {
                          window.open(item.link, "_blank");
                        }
                      }}
                    />
                  ) : (
                    <Photo item={item} />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            className="slider-controls d-flex align-items-center next position-absolute h-100"
            onClick={() => handleControlSlider("next")}
          >
            <button className="control d-flex justify-content-center align-items-center border-0 rounded-circle">
              <svg
                width="14"
                height="23"
                viewBox="0 0 14 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.99991 2L12.4326 11.7854L1.99991 21.5707"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <CSSTransition in={isVideoPlaying} timeout={300} unmountOnExit>
        <BLModal handleCloseModal={() => setIsVideoPlaying(false)} headerFix>
          <YouTube
            videoId={videoId}
            opts={YOUTUBE_OPTIONS}
            containerClassName="video-player-holder"
            onEnd={() => setIsVideoPlaying(false)}
          />
        </BLModal>
      </CSSTransition>
    </>
  );
};

FeedSlider.propTypes = {
  feed: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FeedSlider;
