import React from "react";
import { NavLink } from "react-router-dom";

const Tabs = (props) => {
  return (
    <div className="tabs d-flex justify-content-center align-items-center">
      <NavLink
        to={props.guest.login.links[props.lang]}
        className={`tab-link text-center ${
          props.page === "login" ? `active` : ``
        }`}
        onClick={(e) => {
          props.handlePageChange(e, "login");
        }}
      >
        {props.translate("auth.login")}
      </NavLink>
      <NavLink
        to={props.guest.register.links[props.lang]}
        className={`tab-link text-center ${
          props.page === "register" ? `active` : ``
        }`}
        onClick={(e) => {
          props.handlePageChange(e, "register");
        }}
      >
        {props.translate("auth.register")}
      </NavLink>
    </div>
  );
};

export default Tabs;
