import React, { Component } from "react";
import toastr from "toastr";

import Profile from "./Profile";
import BLInput from "../utils/components/BLInput";
import BLButton from "../utils/components/BLButton";
import GiftCardsList from "./components/GiftCardsList";

import API, { headers } from "../utils/API";
import { handleErrors } from "../utils/helper";
import BLInfoArea from "../utils/components/BLInfoArea";
import Helmet from "react-helmet";
import BLLoader from "../utils/components/BLLoader";
import giftCardPlaceholder from "../assets/images/bl-gift-card-new.png";

export default class GiftCards extends Component {
  state = {
    giftCards: [],
    newGiftCardNumber: "",
    isLoading: true,
    isCardAdding: false,
    hasMore: false,
    isLoadingMore: false,
    page: 1,
  };

  limit = 15;

  componentDidMount = () => this.handleGetGiftCards();

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code ||
      prevProps.user.langPref !== this.props.user.langPref
    )
      this.setState({ page: 1, isLoading: true }, this.handleGetGiftCards);
  };

  handleLoadMore = () => {
    this.setState(
      (prevState) => ({ isLoadingMore: true, page: prevState.page + 1 }),
      this.handleGetGiftCards
    );
  };

  handleGetGiftCards = () => {
    API.get(
      `account/gift_cards?limit=${this.limit}&currency=${this.props.user.currencyPref.code}&page=${this.state.page}`,
      {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      }
    )
      .then((response) => {
        const { data, meta } = response.data;
        const { current_page, last_page } = meta;
        const hasMore = current_page < last_page;

        const giftCards =
          current_page > 1 ? [...this.state.giftCards, ...data] : data;

        this.setState({
          giftCards,
          isLoading: false,
          hasMore,
          isLoadingMore: false,
        });
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isLoadingMore: false, isLoading: false });
      });
  };

  handleAddGiftCard = (e) => {
    if (e) e.preventDefault();

    if (this.state.newGiftCardNumber) {
      this.setState({ isCardAdding: true });
      const card_number = this.state.newGiftCardNumber.replace(/\s/g, "");

      const params = { card_number };

      API.post("account/gift_cards/add", params, {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
          "Accept-Language": this.props.lang,
        },
      })
        .then((response) => {
          const { data } = response.data;

          if (!response.data.status) {
            toastr.clear();
            toastr.warning(response.data.info.message);
            this.setState({ isCardAdding: false });
            return false;
          }

          if (data) {
            const giftCards = [data, ...this.state.giftCards];

            this.setState({ giftCards, newGiftCardNumber: "" });
          }

          toastr.clear();
          toastr.success(response.data.info.message);

          this.setState({ isCardAdding: false });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isCardAdding: false });
        });
    }
  };

  render() {
    return (
      <Profile>
        <Helmet>
          <title>{this.props.translate("profile.gift_cards")}</title>
        </Helmet>
        <div className="page-content gift-cards">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="page-holder w-100">
                  <div className="row">
                    <div className="col">
                      <h4 className="page-title">
                        {this.props.translate("profile.gift_cards_page.title")}
                      </h4>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-5 d-none d-lg-flex">
                      <img
                        src={giftCardPlaceholder}
                        alt="Bucketlist gift card"
                        className="bl-gift-card w-100"
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <form action="#!" onSubmit={this.handleAddGiftCard}>
                        <div className="row">
                          <div className="col">
                            <p className="page-desc">
                              {this.props.translate(
                                "profile.gift_cards_page.desc"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-7 col-md-8">
                            <div className="gift-card-input w-100">
                              <BLInput
                                type="text"
                                label={this.props.translate(
                                  "profile.gift_cards_page.new_gift_card_input_placeholder"
                                )}
                                name="gift-card"
                                value={this.state.newGiftCardNumber}
                                changed={(e) =>
                                  this.setState({
                                    newGiftCardNumber: e.target.value,
                                  })
                                }
                                isMasked
                                mask={"9999 9999 9999 9999"}
                                inputProps={{
                                  inputMode: "numeric",
                                  alwaysShowMask: false,
                                  maskChar: " ",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-7 col-md-5">
                            <div className="gift-card-buttons">
                              <div className="row">
                                <div className="col-12 col-lg-6 pr-lg-1 mb-1 mb-lg-0">
                                  <BLButton
                                    type="pri"
                                    classes="w-100"
                                    action="submit"
                                    disabled={this.state.isCardAdding}
                                  >
                                    {this.props.translate(
                                      "profile.gift_cards_page.add_a_gift_card"
                                    )}
                                  </BLButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <section className="page-sec">
                        {this.state.isLoading ? (
                          <div className="mt-3">
                            <BLLoader />
                          </div>
                        ) : !this.state.giftCards.length ? (
                          <BLInfoArea type="info">
                            {this.props.translate(
                              "profile.gift_cards_page.empty_text"
                            )}
                          </BLInfoArea>
                        ) : (
                          <GiftCardsList
                            translate={this.props.translate}
                            giftCards={this.state.giftCards}
                            hasMore={this.state.hasMore}
                            isLoadingMore={this.state.isLoadingMore}
                            handleLoadMore={this.handleLoadMore}
                          />
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Profile>
    );
  }
}
