import React from "react";
// import PropTypes from "prop-types";
import { ratingStars } from "../helper";

const BLRatingWStars = (props) => {
  const stars = ratingStars(props.rating);
  return (
    <div className="bl-ratings-w-stars w-100 d-flex justify-content-between align-items-center">
      {stars.map((data) => (
        <span
          key={data.key}
          className="d-inline-block"
          style={{ width: "23px", height: "22px" }}
        >
          {data.star}
        </span>
      ))}
    </div>
  );
};

BLRatingWStars.propTypes = {
  // rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default BLRatingWStars;
