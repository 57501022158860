import { Helmet } from "react-helmet";

const PrerenderRedirect = ({ to, code = 301, ...props }) => {
  return (
    <Helmet>
      <meta name="prerender-status-code" content={code || 301} />
      {to ? (
        <meta
          name="prerender-header"
          content={`Location: ${process.env.REACT_APP_URL}${to}`}
        />
      ) : (
        ``
      )}
    </Helmet>
  );
};

export default PrerenderRedirect;
