import React, { Component } from "react";
import giftCardIcon from "../../assets/images/gift-card-cart-icon.svg";

export default class Voucher extends Component {
  render() {
    return (
      <div className="experience gift-card d-flex align-items-start">
        <div className="cover d-flex justify-content-center align-items-center overflow-hidden">
          <img src={giftCardIcon} alt="Experience Cover" />
        </div>
        <div className="info">
          <div className="exp-title selectable">
            {this.props.translate("voucher.voucher_gift")}
          </div>
          <div className="gift-detail">
            {this.props.translate("cart.receiver_name")}{" "}
            <span>{this.props.gift.name}</span>
          </div>
          <div className="gift-detail">
            {this.props.translate("cart.receiver_email")}{" "}
            <span>{this.props.gift.email}</span>
          </div>
          <div className="gift-detail">
            {this.props.translate("cart.receiver_phone")}{" "}
            <span>{this.props.gift.phone}</span>
          </div>
          <button
            className="edit-button d-none d-lg-flex"
            onClick={this.props.handleUpdate}
          >
            {this.props.translate("global.edit")}
          </button>
        </div>
      </div>
    );
  }
}
