import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

const BLCollapsible = (props) => {
  const [isDetailsOn, setIsDetailsOn] = useState(
    props.defaultOn ? true : false
  );

  useEffect(() => {
    setIsDetailsOn(props.defaultOn ? true : false);
  }, [props.defaultOn]);

  useEffect(() => {
    props.callback && props.callback(isDetailsOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailsOn]);

  const summary = props.summaryContents.map((data, index) =>
    props.element ? (
      <props.element
        key={index}
        className="info d-flex justify-content-start align-items-center"
      >
        {props.icon ? props.icon : ""}
        {data}
      </props.element>
    ) : (
      <div
        key={index}
        className="info d-flex justify-content-start align-items-center"
      >
        {props.icon ? props.icon : ""}
        {data}
      </div>
    )
  );

  return (
    <div
      className={`collapsible-holder w-100 ${
        props.hideArrow ? `arrow-hidden` : ``
      } ${props.classes ? props.classes : ""}`}
      data-is-disabled={props.disabled}
    >
      <button
        className={`show-button position-relative w-100 d-flex justify-content-between align-items-center ${
          isDetailsOn ? `active` : ``
        }`}
        onClick={() => !props.disabled && setIsDetailsOn(!isDetailsOn)}
      >
        <div className="header-titles w-100 align-items-center">{summary}</div>
        {!props.hideArrow && (
          <div className="arrow">
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1836 1.62074L6.59955 7.98438L1.0155 1.62074"
                stroke="black"
                strokeWidth="1.89"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </button>
      <CSSTransition
        in={isDetailsOn}
        timeout={300}
        classNames="details"
        unmountOnExit
      >
        <div className="details overflow-hidden">{props.children}</div>
      </CSSTransition>
    </div>
  );
};

BLCollapsible.propTypes = {
  children: PropTypes.any.isRequired,
  summaryContents: PropTypes.array.isRequired,
  classes: PropTypes.string,
  defaultOn: PropTypes.bool,
  icon: PropTypes.any,
  hideArrow: PropTypes.bool,
  callback: PropTypes.func,
};

export default BLCollapsible;
