import Axios from "axios";
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import NotFound from "../App/NotFound";
import { global } from "../App/routes";
import API, { headers } from "../utils/API";
import BLButton from "../utils/components/BLButton";
import BLDrawer from "../utils/components/BLDrawer";
import BLLoader from "../utils/components/BLLoader";
import Product from "../utils/components/Product";
import SEO from "../utils/components/SEO";
import {
  dataLayer__itemListClick,
  dataLayer__itemListView,
} from "../utils/DataLayerEvents";
import { getCitiesWithProduct, handleErrors } from "../utils/helper";
import { Banner } from "./components/Banner";
import { MobileButtons } from "./components/MobileButtons";
import MobileFilters from "./components/MobileFilters";
import MobileSort from "./components/MobileSort";
import Sidebar from "./components/Sidebar";
import Sort from "./components/Sort";

export default class Location extends Component {
  state = {
    location: {},
    products: [],
    locations: [],
    categories: [],
    breadcrumbs: [],
    catType: "",
    isLoading: true,
    hasMore: false,
    page: 1,
    isLoadingMore: false,
    filters: {
      locations: [],
      sort: "hot",
      keywords: "",
    },
    isMobileFiltersOn: false,
    isMobileSortOn: false,
    isNotFound: false,
    isProductsLoading: true,
  };

  productsLimit = 18;

  cancelSource = Axios.CancelToken.source();

  componentDidMount = () => {
    this.handleGetLocation();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      (prevProps.location.pathname !== this.props.location.pathname &&
        JSON.stringify(prevState.filters) ===
          JSON.stringify(this.state.filters) &&
        prevProps.match.params.location !== this.props.match.params.location) ||
      (prevState.isNotFound === true && this.state.isNotFound === false)
    ) {
      this.setState({ page: 1 }, this.handleGetLocation);
    }

    if (
      prevProps.user.currencyPref.code !== this.props.user.currencyPref.code
    ) {
      this.setState({ page: 1 }, this.handleGetLocation);
    }

    if (prevProps.lang !== this.props.lang) {
      if (!this.state.isNotFound) {
        let url = global.activity.links[this.props.lang].replace(
          ":location",
          this.state.location.slug
        );

        setTimeout(() => this.props.history.push(url), 60);
      }
    }
  };

  handleGetLocation = () => {
    if (!this.props.location.search)
      this.setState({ filters: { locations: [], sort: "hot", keywords: "" } });

    this.setState({ isProductsLoading: true }, () => {
      let url = `locations/${this.props.match.params.location}?display=1`;

      API.get(url, {
        headers: { ...headers, "Accept-Language": this.props.lang },
        cancelToken: this.cancelSource.token,
      })
        .then(({ data: r }) => {
          const { data: location } = r;
          const filters = { ...this.state.filters };
          filters["locations"] = [location.id];

          this.setState(
            { location, filters, isLoading: false, isNotFound: false },
            () => {
              this.handleGetBreadcrumbs();
              this.handleGetCategories();
              this.handleGetLocations();
              this.handleGetProducts();
            }
          );
        })
        .catch((err) => {
          if (err?.response?.data?.code === 404)
            this.setState({ isNotFound: true, isLoading: false });
          else handleErrors(err);
        });
    });
  };

  handleGetCategories = () => {
    API.get(`categories`, {
      headers: { ...headers, "Accept-Language": this.props.lang },
    })
      .then(({ data: r }) => {
        const { data: categories } = r;
        this.setState({ categories });
      })
      .catch((err) => handleErrors(err));
  };

  handleGetLocations = () => {
    getCitiesWithProduct(this.props.lang, 212)
      .then(({ data: r }) => {
        const { data: locations } = r;
        this.setState({ locations });
      })
      .catch((err) => handleErrors(err));
  };

  handleGetProducts = (next = false) => {
    this.setState({ isProductsLoading: !next, isLoadingMore: next }, () => {
      const locationFilter = `&location_ids[]=${this.state.location.id}`;

      let filtersRequestUrl = "";
      if (window.location.search) {
        const filtersInUrl = decodeURIComponent(window.location.search).split(
          ":"
        );

        if (filtersInUrl.length > 1) {
          const filters = filtersInUrl[1].split(";");

          const filter = filters.map((data) => {
            return data.split("=");
          });

          let locations = [this.state.location.id];
          let sort = "";

          filter.forEach((data) => {
            if (data[0] === "sort") {
              filtersRequestUrl += "&order_by=" + data[1];
              sort = data[1];
            }
          });

          this.setState({
            filters: { ...this.state.filters, locations, sort },
          });
        }
      }

      let reqHeaders = { ...headers, "Accept-Language": this.props.lang };
      if (this.props.user.isLoggedIn)
        reqHeaders = {
          ...reqHeaders,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        };

      API.get(
        `products?currency=${this.props.user.currencyPref.code}${locationFilter}${filtersRequestUrl}&limit=${this.productsLimit}&page=${this.state.page}`,
        {
          headers: reqHeaders,
          cancelToken: this.cancelSource.token,
        }
      )
        .then(({ data: r }) => {
          const { data, meta } = r;

          const products = next ? [...this.state.products, ...data] : data;

          const hasMore = meta.current_page < meta.last_page;

          this.setState(
            {
              products,
              hasMore,
              isProductsLoading: false,
              isLoadingMore: false,
            },
            () => {
              const dataLayerProducts = data.map((product, index) => ({
                ...product,
                index: index + 1,
              }));

              const sortTypes = {
                hot: "Çok sevilenler",
                newest: "En yeniler",
                lowest: "En düşük fiyat",
                highest: "En yüksek fiyat",
              };

              const listInfo = {
                pageType: global.activity.type,
                listType: this.state.filters.locations.length
                  ? `Filtre`
                  : `Genel`,
                pageName: document.title,
                sortType: sortTypes[this.state.filters.sort],
              };

              dataLayer__itemListView(
                dataLayerProducts,
                listInfo,
                this.props.user.currencyPref.code
              );
            }
          );
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isProductsLoading: false, isLoadingMore: false });
        });
    });
  };

  handleGetBreadcrumbs = () => {
    const breadcrumbs = [
      {
        id: 0,
        name: this.props.translate("global.homepage"),
        route: global.home.links[this.props.lang],
      },
      {
        id: 1,
        name: this.props.translate("category.locations"),
        route: global.activities.links[this.props.lang],
      },
    ];

    breadcrumbs.push({
      id: 2,
      name: this.state.location.name,
    });

    this.setState({ breadcrumbs });
  };

  handleSelectCat = (slug) => {
    let redirectUrl = global.activity.links[this.props.lang].replace(
      ":location",
      slug
    );

    if (redirectUrl) {
      this.props.history.push(redirectUrl);
      this.setState({ isMobileSortOn: false, isMobileFiltersOn: false });
    }
  };

  handleApplyFilter = (e, filters) => {
    if (e) e.preventDefault();

    let locations = this.state.filters.locations;
    let sort = this.state.filters.sort;
    let keyword = this.state.filters.keyword;

    if (filters && filters.length) {
      this.setState({ isMobileFiltersOn: false, isMobileSortOn: false });
      filters.forEach((data) => {
        if (data.type === "locations") {
          locations = data.value && data.value.length ? data.value : [];
        }

        if (data.type === "sort") {
          sort = data.value;
        }
      });

      let filtersUrl = "?filters:";
      if (locations.length) {
        filtersUrl += "locations=";
        locations.forEach((data, index) => {
          filtersUrl += index === 0 ? data : `,${data}`;
        });
      }

      if (sort) {
        filtersUrl += locations.length ? `;sort=${sort}` : `sort=${sort}`;
      }

      if (keyword) {
        filtersUrl +=
          locations.length || sort
            ? `;keyword=${keyword}`
            : `keyword=${keyword}`;
      }

      if (!locations.length && !sort && !keyword) filtersUrl = "";

      this.props.history.push(this.props.location.pathname + filtersUrl);

      this.setState(
        {
          isProductsLoading: true,
          filters: { locations, sort, keyword },
          page: 1,
        },
        () => this.handleGetProducts()
      );
    }
  };

  handleShowMobileFilter = (type) => {
    if (type === "sort") this.setState({ isMobileSortOn: true });
    if (type === "filters") this.setState({ isMobileFiltersOn: true });
  };

  handleCloseMobileFilter = (type) => {
    if (type === "sort") this.setState({ isMobileSortOn: false });
    if (type === "filters") this.setState({ isMobileFiltersOn: false });
  };

  handleLoadMore = () =>
    this.setState(
      (prevState) => ({ page: prevState.page + 1 }),
      () => this.handleGetProducts(true)
    );

  handleItemClick = (product) => {
    const sortTypes = {
      hot: "Çok sevilenler",
      newest: "En yeniler",
      lowest: "En düşük fiyat",
      highest: "En yüksek fiyat",
    };

    const listInfo = {
      pageType: global.activity.type,
      listType: this.state.filters.locations.length ? `Filtre` : `Genel`,
      pageName: document.title,
      sortType: sortTypes[this.state.filters.sort],
    };

    dataLayer__itemListClick(product, listInfo);
  };

  render() {
    return this.state.isLoading ? (
      ``
    ) : this.state.isNotFound ? (
      <NotFound translate={this.props.translate} lang={this.props.lang} />
    ) : (
      <div className="cat-page">
        <SEO
          title={this.props.translate("page_title.activity", {
            location: this.state.location.name,
          })}
          description={this.props.translate("page_description.activity", {
            location: this.state.location.name,
          })}
        />
        <Banner
          name={this.state.location.name}
          cover={this.state.location.cover_image}
          breadcrumbs={this.state.breadcrumbs}
        />
        <div className="container">
          <div className="row">
            <div className="col">
              <section className="cat-content d-flex flex-column flex-lg-row justify-content-between align-items-start">
                <div className="d-none d-lg-flex">
                  <Sidebar
                    translate={this.props.translate}
                    subCats={this.state.location.siblings}
                    locations={this.state.locations}
                    categories={this.state.categories}
                    handleApplyFilter={this.handleApplyFilter}
                    handleSelectCat={this.handleSelectCat}
                    cat={{
                      ...this.state.location,
                      name: this.state.location.name,
                      slug: this.state.location.slug,
                      type: "location",
                    }}
                    filters={this.state.filters}
                    lang={this.props.lang}
                  />
                </div>
                <section className="list-side">
                  <div className="d-none d-lg-flex">
                    <Sort
                      handleApplyFilter={this.handleApplyFilter}
                      selectedSort={this.state.filters.sort}
                      translate={this.props.translate}
                      location={this.props.location}
                    />
                  </div>
                  <div className="row">
                    <MobileButtons
                      translate={this.props.translate}
                      handleShowMobileFilter={this.handleShowMobileFilter}
                    />
                  </div>
                  <div className="results d-flex justify-content-start align-items-start flex-wrap">
                    {this.state.isLoading || this.state.isProductsLoading ? (
                      <div className="mx-auto">
                        <BLLoader />
                      </div>
                    ) : this.state.products && this.state.products.length ? (
                      this.state.products.map((data, index) => (
                        <Product
                          key={data.id}
                          product={data}
                          currency={this.props.user.currencyPref}
                          onClick={(product) =>
                            this.handleItemClick({
                              ...product,
                              index: index + 1,
                            })
                          }
                        />
                      ))
                    ) : (
                      <h4 className="text-center w-100">
                        {this.props.translate(
                          "global.no_product_category_text"
                        )}
                      </h4>
                    )}
                  </div>
                  <CSSTransition
                    in={
                      !this.state.isLoading &&
                      !this.state.isProductsLoading &&
                      this.state.hasMore
                    }
                    timeout={0}
                    unmountOnExit
                  >
                    <div className="load-more-button-holder d-flex justify-content-center mt-4">
                      {this.state.isLoadingMore ? (
                        <BLLoader />
                      ) : (
                        <BLButton
                          type="pri"
                          classes="px-5 mx-auto"
                          clicked={this.handleLoadMore}
                        >
                          {this.props.translate("global.load_more")}
                        </BLButton>
                      )}
                    </div>
                  </CSSTransition>
                </section>
              </section>
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.isMobileSortOn}
          timeout={300}
          unmountOnExit
          classNames="bl-drawer"
        >
          <BLDrawer
            title={this.props.translate("category.sort")}
            handleCloseDrawer={() => this.handleCloseMobileFilter("sort")}
          >
            <MobileSort
              handleApplyFilter={this.handleApplyFilter}
              selectedSort={this.state.filters.sort}
              translate={this.props.translate}
              handleCloseMobileFilter={this.handleCloseMobileFilter}
            />
          </BLDrawer>
        </CSSTransition>
        <CSSTransition
          in={this.state.isMobileFiltersOn}
          timeout={300}
          unmountOnExit
          classNames="bl-drawer"
        >
          <BLDrawer
            title={this.props.translate("category.filter")}
            handleCloseDrawer={() => this.handleCloseMobileFilter("filters")}
          >
            <MobileFilters
              translate={this.props.translate}
              subCats={this.state.location.siblings}
              locations={this.state.locations}
              categories={this.state.categories}
              handleApplyFilter={this.handleApplyFilter}
              handleSelectCat={this.handleSelectCat}
              cat={{
                ...this.state.location,
                name: this.state.location.name,
                slug: this.state.location.slug,
                type: "location",
              }}
              filters={this.state.filters}
              handleCloseMobileFilter={this.handleCloseMobileFilter}
              lang={this.props.lang}
            />
          </BLDrawer>
        </CSSTransition>
      </div>
    );
  }
}
