import React from "react";
import PropTypes from "prop-types";

const SinglePaymentMethod = (props) => {
  return props.disabled ? (
    false
  ) : (
    <div className={`single-payment-method ${props.active ? `active` : ``}`}>
      <div
        className="header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
        onClick={() => props.handleSetPaymentMethod(props.type)}
      >
        <div className="left-side d-flex align-items-center">
          <div className="choice active rounded-circle d-flex justify-content-center align-items-center overflow-hidden"></div>
          <h5 className="title m-0">{props.title}</h5>
        </div>
        {props.icon && (
          <div className="right-side">
            <img src={props.icon} alt={`${props.title} Icon`} />
          </div>
        )}
      </div>
      <div className="payment-information-area overflow-hidden">
        {props.children}
      </div>
    </div>
  );
};

SinglePaymentMethod.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  type: PropTypes.number.isRequired,
  handleSetPaymentMethod: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

export default SinglePaymentMethod;
