import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";

import GiftCard from "../GiftCard";
import BLButton from "../../utils/components/BLButton";

import changeCard from "../../assets/images/change-card.png";
import API, { headers } from "../../utils/API";
import { auth } from "../../App/routes";
import { handleErrors } from "../../utils/helper";

import toastr from "toastr";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import Auth from "../../Auth/Auth";

import { confirmAlert } from "react-confirm-alert";

const Change = (props) => {
  const [giftCard, setGiftCard] = useState("");
  const [isAuthModalOn, setIsAuthModalOn] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const infos = [];

  for (let i = 1; i <= 3; i++) {
    infos.push({
      item: (
        <p className="content" key={i}>
          {`${i})`} {props.translate(`gift_card.change_page.list_${i}_content`)}
        </p>
      ),
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (giftCard) {
      if (!props.user.isLoggedIn) {
        setIsAuthModalOn(true);
        return false;
      }

      // confirmAlert({
      //   customUI: ({ onClose }) => (
      //     <BLConfirmAlert
      //       onClose={onClose}
      //       acceptFunc={() => alert("Yes!")}
      //       acceptButton={props.translate("global.yes")}
      //       declineButton={props.translate("global.no")}
      //     >
      //       {props.translate("gift_card.change_page.confirm_text")}
      //     </BLConfirmAlert>
      //   ),
      // });

      confirmAlert({
        message: props.translate("gift_card.change_page.confirm_text"),
        buttons: [
          {
            className: "bl-button bl-empty-button",
            label: props.translate("global.no"),
            onClick: () => false,
          },
          {
            className: "bl-button bl-sec-button",
            label: props.translate("global.yes"),
            onClick: () => {
              setIsSending(true);

              const configs = {
                headers: {
                  ...headers,
                  Authorization: `${props.user._tokenType} ${props.user._token}`,
                  "Accept-Language": `${props.lang}`,
                },
              };

              API.get(`redeem/exchange/${giftCard}`, configs)
                .then((response) => {
                  const { data, status } = response.data;

                  if (status) {
                    sessionStorage.setItem(
                      "giftCardTemp",
                      JSON.stringify(data)
                    );

                    props.history.push(auth.changedGiftCard.links[props.lang]);
                  } else {
                    toastr.clear();
                    toastr.error(response.data.info.message);
                  }
                })
                .catch((err) => {
                  handleErrors(err);
                  setIsSending(false);
                });
            },
          },
        ],
        overlayClassName: "bl-confirm-alert",
      });
    }
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOn(false);
  };

  return (
    <GiftCard>
      <section className="page-sec change">
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <h4 className="page-title text-center">
                {props.translate("gift_card.change_page.page_title")}
              </h4>
              <h6 className="page-subtext text-center">
                {props.translate("gift_card.change_page.page_subtext")}
              </h6>
              <div className="page-holder d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <img
                  src={changeCard}
                  alt={props.translate("gift_card.bucketlist_coupon")}
                  className="coupon"
                />
                <form action="#!" onSubmit={handleSubmit} className="info-area">
                  <div className="desc">{infos.map((data) => data.item)}</div>
                  <input
                    type="text"
                    className="gift-card-input"
                    value={giftCard}
                    onChange={(e) => setGiftCard(e.target.value)}
                  />
                  <div className="buttons d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <BLButton type="pri" action="submit" disabled={isSending}>
                      {props.translate("gift_card.change_page.use_button")}
                    </BLButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!props.user.isLoggedIn && props.lang ? (
        <CSSTransition
          in={isAuthModalOn}
          timeout={3000}
          classNames="bl-modal"
          unmountOnExit
        >
          <section>
            <BLModal handleCloseModal={handleCloseAuthModal} headerFix={true}>
              <Auth lang={props.lang} />
            </BLModal>
          </section>
        </CSSTransition>
      ) : (
        ""
      )}
    </GiftCard>
  );
};

export default withLocalize(Change);
