import React, { useEffect, useState } from "react";
import "../assets/CookiesError.scss";
import logo from "../assets/images/logo.svg";
import translation from "../utils/i18n/cookie.json";
import icon from "../assets/images/gingerbread-man.png";

const CookiesError = () => {
  const [lang, setLang] = useState("tr");

  useEffect(() => {
    let userLang = navigator.language || navigator.userLanguage;

    if (userLang.includes("-")) userLang = userLang.split("-")[0];

    if (userLang !== "tr" && userLang !== "en") userLang = "tr";

    setLang(userLang);
  }, []);

  return (
    <div className="cookies-error-page d-flex justify-content-center align-items-center vw-100 vh-100">
      <div className="holder d-flex flex-column flex-md-row justify-content-center align-items-center">
        <img src={icon} alt="Cookie error" />
        <div className="text-area">
          <h6 className="text-center text-md-left">
            {translation[lang].title}
          </h6>
          <p className="text-center text-md-left">
            {translation[lang].content}
          </p>
        </div>
      </div>
      <img src={logo} alt="Bucketlist" className="logo position-absolute" />
    </div>
  );
};

export default CookiesError;
