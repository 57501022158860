import React, { useEffect } from "react";

import Blog from "./Blog";

import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder/lib";

import "react-placeholder/lib/reactPlaceholder.css";
import Swiper from "swiper";

import "swiper/swiper.scss";

const placeholder = (
  <div className="blog-post-placeholder w-100 d-flex justify-content-start align-items-center overflow-hidden">
    <div className="blogs-placeholder-holder d-flex justify-content-start align-items-center">
      {Array.from(Array(3).keys()).map((data) => (
        <div key={data} className="placeholder">
          <RectShape
            key={data}
            className="cover-placeholder placeholder-item"
            style={{ width: "100%", height: "inherit", marginBottom: 15 }}
          />
          <TextBlock
            rows={1}
            className="cat-placeholder placeholder-item"
            style={{ width: 75, marginBottom: 10 }}
          />
          <TextBlock
            rows={2}
            className="title-placeholder placeholder-item"
            style={{ marginBottom: 20 }}
          />
          <TextBlock
            rows={1}
            className="info-placeholder placeholder-item"
            style={{ width: "75%" }}
          />
        </div>
      ))}
    </div>
  </div>
);

export default function FollowUs(props) {
  const [swiper, setSwiper] = React.useState(null);
  const [blogs, setBlogs] = React.useState([]);

  useEffect(() => {
    if ((props.isReady && props.blogs?.length, blogs?.length)) {
      const options = {
        slidesPerView: 3,
        spaceBetween: 15,
        slidesPerGroup: 3,
        loop: true,
        grabCursor: true,
        on: {
          init: (swiper) => {
            swiper.update();
          },
          slideChangeTransitionStart: (swiper) => {
            let $wrapperEl = swiper.$wrapperEl;
            let params = swiper.params;
            $wrapperEl
              .children(
                "." + params.slideClass + "." + params.slideDuplicateClass
              )
              .each(function () {
                let idx = this.getAttribute("data-swiper-slide-index");
                this.innerHTML = $wrapperEl
                  .children(
                    "." +
                      params.slideClass +
                      '[data-swiper-slide-index="' +
                      idx +
                      '"]:not(.' +
                      params.slideDuplicateClass +
                      ")"
                  )
                  .html();
              });
          },
          slideChangeTransitionEnd: (swiper) => {
            swiper.slideToLoop(swiper.realIndex, 0, false);
          },
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          576: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      };

      const swiper = new Swiper(`#swiper-container-blog`, options);

      setSwiper(swiper);
    }
  }, [props.isReady, props.blogs, blogs]);

  useEffect(() => {
    if (props.isReady && props.blogs?.length) {
      setBlogs(props.blogs);
    }
  }, [props.isReady, props.blogs]);

  const controlSlider = (e, dir) => {
    e.preventDefault();
    if (swiper) {
      if (dir === "prev" && typeof swiper.slidePrev === "function") {
        swiper.slidePrev();
      }
      if (dir === "next" && typeof swiper.slideNext === "function") {
        swiper.slideNext();
      }
    }
  };

  return (
    <section className="follow-us overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center flex-column">
            <h4 className="section-title text-center">
              {props.translate("home.follow_us.title")}
            </h4>
            <p className="section-desc text-center">
              {props.translate("home.follow_us.desc")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col blogs-holder">
            <div
              className="blog-holder overflow-hidden"
              id={`swiper-container-blog`}
            >
              <ReactPlaceholder
                ready={props.isReady}
                customPlaceholder={placeholder}
                showLoadingAnimation
              >
                <div className="swiper-wrapper">
                  {blogs.map((data, index) => (
                    <div className="swiper-slide" key={index}>
                      <Blog translate={props.translate} blog={data} />
                    </div>
                  ))}
                </div>
              </ReactPlaceholder>
            </div>
            <div className="controls-holder">
              <a
                href="#!"
                className="control d-flex justify-content-center align-items-center position-absolute prev rounded-circle"
                onClick={(e) => controlSlider(e, "prev")}
              >
                <svg
                  width="14"
                  height="23"
                  viewBox="0 0 14 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0001 2L1.56738 11.7854L12.0001 21.5707"
                    stroke="#454C5E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
              <a
                href="#!"
                className="control d-flex justify-content-center align-items-center position-absolute next rounded-circle"
                onClick={(e) => controlSlider(e, "next")}
              >
                <svg
                  width="14"
                  height="23"
                  viewBox="0 0 14 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.99991 2L12.4326 11.7854L1.99991 21.5707"
                    stroke="#454C5E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <a
              href="https://blog.bucketlist.com.tr/"
              className="show-all-button d-flex align-items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.translate("home.follow_us.view_all_blog_posts")}
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 6.5L1 12"
                  stroke="#E75B2B"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
