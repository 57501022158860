import React from "react";

export const ReservationSummary = (props) => {
  return (
    <div className="reservation-summary-page">
      {props.reservation.gift.is_gift ? (
        <div className="gift-info-holder d-flex flex-column justify-content-center align-items-center">
          <svg
            width="108"
            height="102"
            viewBox="0 0 108 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M101.25 27.3718H79.893C81.4252 26.3061 82.7415 25.2471 83.6595 24.2983C89.1068 18.7218 89.1068 9.64538 83.6595 4.06891C78.3675 -1.36318 69.147 -1.34942 63.8618 4.06891C60.9323 7.05999 53.1698 19.2375 54.243 27.3718H53.757C54.8235 19.2375 47.061 7.05999 44.1382 4.06891C38.8462 -1.34942 29.6257 -1.34942 24.3405 4.06891C18.9 9.64538 18.9 18.7218 24.3337 24.2983C25.2585 25.2471 26.5748 26.3061 28.107 27.3718H6.75C3.03075 27.3718 0 30.4592 0 34.2479V44.562C0 46.4598 1.512 48 3.375 48H47.25V34.2479H60.75V48H104.625C106.488 48 108 46.4598 108 44.562V34.2479C108 30.4592 104.976 27.3718 101.25 27.3718ZM46.8517 27.1449C46.8517 27.1449 46.5682 27.3718 45.603 27.3718C40.9387 27.3718 32.0355 22.4348 29.1262 19.4506C26.298 16.5489 26.298 11.8182 29.1262 8.91652C30.4965 7.51381 32.3122 6.74369 34.2427 6.74369C36.1665 6.74369 37.9823 7.51381 39.3525 8.91652C43.902 13.5785 48.4245 25.4878 46.8517 27.1449ZM61.1482 27.1449C61.1482 27.1518 61.4317 27.3718 62.3902 27.3718C67.0612 27.3718 75.9645 22.4348 78.8737 19.4506C81.7087 16.5489 81.7087 11.8182 78.8737 8.91652C76.1265 6.10422 71.3677 6.11797 68.6475 8.91652C64.098 13.5785 59.5755 25.4878 61.1482 27.1449ZM7 54V95.1429C7 98.928 10.0682 102 13.8333 102H48V54H7ZM61 102V54H102V95.1429C102 98.928 98.9387 102 95.1667 102H61Z"
              fill="white"
            />
          </svg>
          <div className="who-gifted">
            {props.translate("reservation.gift_page.who_gifted", {
              name: props.reservation.gift.from_name,
            })}
          </div>
          <p className="gift-info">{props.reservation.gift.message}</p>
        </div>
      ) : (
        ""
      )}
      <div className="reservation-info-holder">
        <div className="reservation-info d-flex flex-column align-items-start">
          <div className="label">{props.translate("global.full_name")}:</div>
          <div className="content">
            {props.reservation.participant.first_name +
              " " +
              props.reservation.participant.last_name}
          </div>
        </div>
        <div className="reservation-info d-flex flex-column align-items-start">
          <div className="label">{props.translate("global.email")}:</div>
          <div className="content" title={props.reservation.participant.email}>
            {props.reservation.participant.email}
          </div>
        </div>
        <div className="reservation-info d-flex flex-column align-items-start">
          <div className="label">{props.translate("global.phone")}:</div>
          <div className="content">
            {props.reservation.participant.phone &&
              `+${props.reservation.participant.phone.formatted}`}
          </div>
        </div>
        {props.reservation.gift.is_gift ? (
          <div className="reservation-info d-flex flex-column align-items-start">
            <div className="label">
              {props.translate("global.delivery_date")}:
            </div>
            <div className="content">{props.reservation.gift.send_date}</div>
          </div>
        ) : (
          ""
        )}
      </div>
      {props.reservation.gift.is_gift ? (
        <div className="gift-message d-flex flex-column align-items-start">
          <div className="label">{props.translate("global.gift_message")}:</div>
          <div className="content">{props.reservation.gift.message}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
