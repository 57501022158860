import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const BLBreadcrumbs = ({ breadcrumbs, color }) => {
  return breadcrumbs?.length ? (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                ${breadcrumbs.map((breadcrumb, index) => {
                  const item = {
                    "@type": "ListItem",
                    position: index + 1,
                    name: breadcrumb.name,
                  };

                  return breadcrumb.route
                    ? JSON.stringify({
                        ...item,
                        item: process.env.REACT_APP_URL + breadcrumb.route,
                      })
                    : JSON.stringify(item);
                })}
              ]
            }
          `}
        </script>
      </Helmet>
      <div
        className={`bl-breadcrumbs d-none d-md-flex justify-content-start align-items-center ${color}`}
      >
        {breadcrumbs
          .map((breadcrumb, index) => (
            <div
              key={breadcrumb.id}
              className="bl-breadcrumb-item d-flex justify-content-start align-items-center"
            >
              <Link
                to={breadcrumb.route || "#"}
                className={`bl-breadcrumb-content ${
                  index === breadcrumbs.length - 1 ? `last` : ``
                } ${breadcrumb.route ? "link" : ""}`}
              >
                {breadcrumb.name}
              </Link>
            </div>
          ))
          .reduce((prev, curr, index) => [
            prev,
            <span key={`d-${index}`} className="divider">
              {" "}
              &gt;{" "}
            </span>,
            curr,
          ])}
      </div>
    </>
  ) : (
    ""
  );
};

BLBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      route: PropTypes.string,
    })
  ).isRequired,
  color: PropTypes.string,
  onMobile: PropTypes.bool,
};
