import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import BLButton from "../../utils/components/BLButton";

class Counter extends Component {
  state = {
    count: this.props.count,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.count !== prevState.count) {
      this.props.set(
        null,
        this.props.experience,
        this.props.type,
        this.state.count
      );
    } else if (this.state.count !== this.props.count)
      this.setState({ count: this.props.count });
  };

  render() {
    return (
      <div className="bl-counter">
        <div
          className={`counter-holder d-flex justify-content-between align-items-center position-relative`}
        >
          <div className="counter-controls d-flex justify-content-center align-items-center">
            <BLButton
              type="sec"
              clicked={(e) => {
                this.state.count > 1
                  ? this.setState((prevState) => {
                      return { count: prevState.count - 1 };
                    })
                  : e.preventDefault();
              }}
            >
              <svg
                width="12"
                height="3"
                viewBox="0 0 12 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.9811 2.03774H1.01887C0.45283 2.03774 0 1.58491 0 1.01887C0 0.45283 0.45283 0 1.01887 0H10.9811C11.5472 0 12 0.45283 12 1.01887C12 1.58491 11.5472 2.03774 10.9811 2.03774Z"
                  fill="white"
                />
              </svg>
            </BLButton>
          </div>
          <div className="content">
            {this.props.count}{" "}
            {this.props.translate(
              `global.${this.props.count > 1 ? `pieces` : `piece`}`
            )}
          </div>
          <div className="counter-controls d-flex justify-content-center align-items-center">
            <BLButton
              type="sec"
              clicked={(e) => {
                this.state.count < this.props.limits.end
                  ? this.setState((prevState) => {
                      return { count: prevState.count + 1 };
                    })
                  : e.preventDefault();
              }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 6.11321C12 6.67924 11.5472 7.13208 10.9811 7.13208H6.90566V10.9811C6.90566 11.5472 6.45283 12 5.88679 12C5.32076 12 4.86792 11.5472 4.86792 10.9811V7.13208H1.01887C0.45283 7.13208 0 6.67924 0 6.11321C0 5.54717 0.45283 5.09434 1.01887 5.09434H4.86792V1.01887C4.86792 0.45283 5.32076 0 5.88679 0C6.45283 0 6.90566 0.45283 6.90566 1.01887V5.09434H10.9811C11.5472 5.09434 12 5.54717 12 6.11321Z"
                  fill="white"
                />
              </svg>
            </BLButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(Counter);
