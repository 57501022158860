import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";

export const BLLightbox = (props) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    index: props.index,
  });

  useEffect(() => {
    if (props.isOn !== lightboxController.toggler)
      setLightboxController({ toggler: props.isOn, index: props.index });

    const bodyOldStyles = document.querySelector("body").style;
    const mainOldStyles = document.querySelector("main").style;

    document.querySelector("body").style.overflow = "hidden";
    document.querySelector("body").style.maxHeight = "100vh";
    document.querySelector("main").style.zIndex = 999;

    return () => {
      document.querySelector("main").style = mainOldStyles;
      document.querySelector("body").style = bodyOldStyles;
    };
  }, [props.isOn, lightboxController, props.index]);

  const types = props.images.map((image) =>
    image.includes("youtube") || image.includes("youtu.be")
      ? "youtube"
      : "image"
  );

  return (
    <FsLightbox
      toggler={lightboxController.toggler}
      slide={lightboxController.index}
      sources={props.images}
      type="image"
      types={types}
      onClose={props.handleCloseLightbox}
    />
  );
};

BLLightbox.propTypes = {
  isOn: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  handleCloseLightbox: PropTypes.func.isRequired,
};
