import React, { Component } from "react";
import { Link } from "react-router-dom";
import { global } from "../../App/routes";
import { PACKAGE_TYPES } from "../../utils/db/packageTypes";

export default class Experience extends Component {
  state = {
    type: "",
  };

  render() {
    return (
      <div className="experience d-flex align-items-start">
        <div className="cover d-flex justify-content-center align-items-center overflow-hidden">
          <img src={this.props.cover} alt="Experience Cover" />
        </div>
        <div className="info">
          <div className="exp-title selectable">
            <Link
              to={global.experience.links[this.props.lang].replace(
                ":name",
                this.props.slug
              )}
            >
              {this.props.name}
            </Link>
          </div>
          <div className="exp-code">
            {this.props.translate("product.product_code")}:{" "}
            <span className="selectable ml-1 ml-md-0">{this.props.code}</span>
          </div>
          {this.props.packageType === PACKAGE_TYPES.PICKADATE && (
            <div className="preferred-date">
              <p className="date">{this.props.selectedPackage?.title}</p>
              <p className="date">{this.props.preferredDate}</p>
            </div>
          )}
          {(this.props.packageType === PACKAGE_TYPES.GIFT ||
            this.props.packageType === PACKAGE_TYPES.PASSGIFT) && (
            <>
              <div className="gift-detail">
                {this.props.translate("cart.receiver_name")}{" "}
                <span>{this.props.gift.name}</span>
              </div>
              <div className="gift-detail">
                {this.props.translate("cart.receiver_email")}{" "}
                <span>{this.props.gift.email}</span>
              </div>
              <div className="gift-detail">
                {this.props.translate("cart.receiver_phone")}{" "}
                <span>{this.props.gift.phone}</span>
              </div>
            </>
          )}
          {this.props.packageType !== PACKAGE_TYPES.OPENENDED ? (
            <button
              className="edit-button d-none d-lg-flex"
              onClick={this.props.handleUpdate}
            >
              {this.props.translate("global.edit")}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
