import React from "react";
import { Translate } from "react-localize-redux";
import { CSSTransition } from "react-transition-group";
import passwordRequirements from "../db/passwordRequirements";
import { passwordRequirementsStepsCheck } from "../helper";

const BLPasswordRequirements = ({ isPasswordInfoOn, value }) => {
  const [passwordSteps, setPasswordSteps] = React.useState([]);

  React.useEffect(() => {
    setPasswordSteps(passwordRequirementsStepsCheck(value));
  }, [value]);

  return (
    <CSSTransition
      in={isPasswordInfoOn}
      timeout={200}
      unmountOnExit
      classNames="password-info-area"
    >
      <div className="BLPasswordRequirements password-info-area">
        <h6 className="req-title">
           {<Translate id={"auth.password_needs_title"} />}
        </h6>
        <ul>
          {passwordRequirements
            .sort((a, b) => a.order - b.order)
            .map(({ id, infoText }) => (
              <li key={id} className={passwordSteps.includes(id) ? `done` : ``}>
                {infoText}
              </li>
            ))}
        </ul>
      </div>
    </CSSTransition>
  );
};

export default BLPasswordRequirements;
