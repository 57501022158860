import React from "react";
import BLDropdown from "../../utils/components/BLDropdown";

const options = [
  { id: "hot", type: "sort", value: "hot", name: "category.top_selling" },
  {
    id: "newest",
    type: "sort",
    value: "newest",
    name: "category.recently_added",
  },
  {
    id: "lowest",
    type: "sort",
    value: "lowest",
    name: "category.lowest_price",
  },
  {
    id: "highest",
    type: "sort",
    value: "highest",
    name: "category.highest_price",
  },
];

const Sort = (props) => {
  return (
    <div className="sort-options-holder w-100 d-flex justify-content-end align-items-center pb-3">
      <span className="sort-label mr-3">
        {props.translate("category.sort")}:
      </span>
      <BLDropdown
        name="sort"
        options={options.map(({ value, name }) => ({
          name: props.translate(name),
          value,
        }))}
        default={props.selectedSort}
        setValue={(value) =>
          props.handleApplyFilter(null, [{ type: "sort", value }])
        }
        classes="w-auto m-0"
      />
      <div
        className={`sort-option d-none ${
          props.selectedSort === "other" ? `selected` : ""
        }`}
      >
        <select name="other-sort" defaultValue={0} id="other-sort">
          <option value={0} hidden>
            Diğer
          </option>
        </select>
      </div>
    </div>
  );
};

export default Sort;
