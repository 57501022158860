import React, { Component } from "react";
import Profile from "./Profile";
import Product from "../utils/components/Product";
import BLLoader from "../utils/components/BLLoader";
import API, { headers } from "../utils/API";

import toastr from "toastr";
import BLInfoArea from "../utils/components/BLInfoArea";
import Helmet from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";

class WishList extends Component {
  state = {
    isLoading: true,
    isLoadingMore: false,
    products: [],
    page: 0,
    hasMore: false,
  };

  products = [];
  limit = 8;

  componentDidMount = () => this.getProducts();

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.langPref !== this.props.user.langPref)
      this.setState(
        { isLoading: true, products: [], page: 0 },
        this.getProducts
      );
  };

  handleRemoveFromBucketlist = (pid) => {
    const products = this.state.products.filter((data) => data.id !== pid);

    this.setState({ products });
  };

  loadMore = () => this.getProducts(true);

  getProducts = (nextPage = false) => {
    let endpoint = `bucket_list?limit=${this.limit}`;

    endpoint += nextPage ? `&page=${parseInt(this.state.page) + 1}` : ``;

    if (nextPage) this.setState({ isLoadingMore: true });

    API.get(endpoint, {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        "Accept-Language": this.props.activeLanguage.code,
      },
    })
      .then((response) => {
        const { data, meta } = response.data;

        const products = this.state.products.length
          ? [...this.state.products, ...data]
          : data;

        this.setState(
          {
            products,
            page: meta.current_page,
            hasMore: meta.current_page < meta.last_page,
          },
          () => {
            if (nextPage) this.setState({ isLoadingMore: false });
            else this.setState({ isLoading: false });
          }
        );
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.info) {
            toastr.clear();
            toastr.error(err.response.info.message);
          }
        }
      });
  };

  render() {
    return (
      <Profile>
        <Helmet>
          <title>{this.props.translate("profile.wish_list")}</title>
        </Helmet>
        <div className="page-content wish-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <div className="page-holder w-100">
                  <div className="row">
                    <div className="col">
                      <h4 className="page-title">
                        {this.props.translate("profile.wish_list_page.title")}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <section className="page-sec">
                        <div className="products-holder d-flex justify-content-start align-items-start flex-wrap">
                          {this.state.isLoading ? (
                            <BLLoader />
                          ) : !this.state.products.length ? (
                            <BLInfoArea type="info">
                              {this.props.translate(
                                "profile.wish_list_page.empty_text"
                              )}
                            </BLInfoArea>
                          ) : (
                            <InfiniteScroll
                              dataLength={this.state.products.length}
                              next={this.loadMore}
                              hasMore={this.state.hasMore}
                              loader={
                                <div className="w-100 d-flex justify-content-center mt-4">
                                  <BLLoader />
                                </div>
                              }
                              style={{ width: "100%" }}
                              className="d-flex justify-content-start align-items-start flex-wrap"
                            >
                              {this.state.products.map((data) => (
                                <Product
                                  key={data.id}
                                  product={data}
                                  currency={this.props.user.currencyPref}
                                  handleRemoveFromBucketlist={
                                    this.handleRemoveFromBucketlist
                                  }
                                />
                              ))}
                            </InfiniteScroll>
                          )}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Profile>
    );
  }
}

export default WishList;
