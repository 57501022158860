import PropTypes from "prop-types";

const BLSwitch = ({ name, checked, onChange, label }) => {
  // generate random id
  const [id] = `bl-switch-${
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  }`.split(".");

  return (
    <div className="BLSwitch d-flex justify-content-start align-items-center">
      <input
        type="checkbox"
        className="input"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label className="holder" htmlFor={id} />
      <label className="label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

BLSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BLSwitch;
