import React from "react";

const SocialMediaButton = (props) => {
  return (
    <button
      className={`login-link d-flex justify-content-start align-items-center ${props.type}`}
      onClick={(e) => props.handleLogin(e, props.type)}
    >
      <img src={props.icon} alt={`${props.name} Icon`} />
      <span>{props.name}</span>
    </button>
  );
};

export default SocialMediaButton;
