import React, { useEffect, useRef, useState } from "react";
import { global } from "../../App/routes";
import { Link } from "react-router-dom";
import TrendLocations from "../../Home/components/TrendLocations";
import PopularActivities from "../../Home/components/PopularActivities/PopularActivities";
import { BLBreadcrumbs } from "../../utils/components/BLBreadcrumbs";
import {
  dataLayer__promotionClick,
  dataLayer__promotionView,
} from "../../utils/DataLayerEvents";
import API, { headers } from "../../utils/API";
import Axios from "axios";
import { checkIfOnScreen, handleErrors } from "../../utils/helper";

export const NoResult = (props) => {
  const [trendLocations, setTrendLocations] = useState({
    isOnScreen: false,
    isReady: false,
    items: [],
  });

  const breadcrumbs = [
    {
      id: 0,
      name: props.translate("global.homepage"),
      route: global.home.links[props.lang],
    },
    {
      id: 1,
      name: props.translate("category.search_results"),
    },
  ];

  const locationsRef = useRef(null);

  useEffect(() => {
    const cancelSource = Axios.CancelToken.source();

    API.get(`trend_locations`, {
      headers: {
        ...headers,
        "Accept-Language": props.lang,
        cancelToken: cancelSource.token,
      },
    })
      .then(({ data: r }) => {
        setTrendLocations({ isReady: true, items: r.data });
      })
      .catch((err) => handleErrors(err));
  }, [props.lang]);

  const handlePromotionClick = (item, id) => {
    const promotion = {
      id,
      name: `Arama - ${id}`,
    };

    dataLayer__promotionClick(item, promotion);
  };

  const checkIfPromotionVisible = () => {
    const isOnScreen = checkIfOnScreen(locationsRef.current);

    if (isOnScreen && !trendLocations.isOnScreen && trendLocations.isReady) {
      setTrendLocations({ ...trendLocations, isOnScreen: true });
      handleLocationsPromotionVisible();
    }
  };

  const handleLocationsPromotionVisible = () => {
    if (trendLocations.isReady) {
      const items = trendLocations.items.map((location, index) => ({
        title: location.name,
        index: index + 1,
      }));

      const promotion = {
        id: "En Favori Lokasyonlar",
        name: "Arama - En Favori Lokasyonlar",
      };

      dataLayer__promotionView(items, promotion);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", checkIfPromotionVisible, false);
    return () => {
      document.removeEventListener("scroll", checkIfPromotionVisible, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendLocations]);

  return (
    <div className="cat-page no-result position-relative">
      <div className="cat-breadcrumbs-holder position-absolute w-100">
        <div className="container">
          <div className="row">
            <div className="col">
              <BLBreadcrumbs breadcrumbs={breadcrumbs} color="black" />
            </div>
          </div>
        </div>
      </div>
      <div className="no-result-holder d-flex flex-column justify-content-center align-items-center">
        <svg
          width="89"
          height="89"
          viewBox="0 0 89 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.967 13.033C93.3443 30.4103 93.3443 58.5897 75.967 75.967C58.5897 93.3443 30.4103 93.3443 13.033 75.967C-4.34433 58.5897 -4.34433 30.4103 13.033 13.033C30.4103 -4.34433 58.5897 -4.34433 75.967 13.033ZM42.1876 59.3472C32.6998 59.3472 25.0312 51.6248 25.0312 42.1892C25.0312 32.7537 32.753 25.0312 42.1876 25.0312C51.6223 25.0312 59.344 32.7537 59.344 42.1892C59.344 51.6248 51.6755 59.3472 42.1876 59.3472ZM42.1863 28.1434C34.4721 28.1434 28.1929 34.4757 28.1929 42.1906C28.1929 49.9055 34.4721 56.1852 42.1863 56.1852C49.9005 56.1852 56.2322 49.9055 56.2322 42.1906C56.2322 34.4757 49.9005 28.1434 42.1863 28.1434ZM60.7253 63.4143L54.8056 57.5342C54.0487 56.7772 54.0487 55.5654 54.8056 54.8084C55.5625 54.0514 56.7743 54.0514 57.5312 54.8084L63.4011 60.6388C64.158 61.3957 64.158 62.6076 63.4011 63.3645C63.0956 63.7662 62.591 63.9688 62.0864 63.9688C61.5818 63.9688 61.0772 63.7662 60.7253 63.4143Z"
            fill="#E7E8E9"
          />
        </svg>
        <p className="no-result-text text-center">
          {props.translate("category.no_result_found_for", {
            keyword: (
              <span className="keyword selectable">"{props.keyword}"</span>
            ),
          })}
        </p>
        <Link
          to={global.home.links[props.lang]}
          className="go-back-home d-flex justify-content-center align-items-center"
        >
          {props.translate("global.go_back_home")}
        </Link>
      </div>

      <TrendLocations
        trendLocations={trendLocations.items}
        isReady={trendLocations.isReady}
        lang={props.lang}
        translate={props.translate}
        handlePromotionClick={handlePromotionClick}
        propRef={locationsRef}
        user={props.user}
      />

      <PopularActivities
        lang={props.lang}
        translate={props.translate}
        listInfo={{ type: global.search.type, name: "En Sevilen Deneyimler" }}
      />
    </div>
  );
};
