import axios from "axios";
import Cookies from "universal-cookie";
import toastr from "toastr";
import store from "../store";
import { logout } from "../Auth/authActions";
import { isCrawler } from "./helper";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setupInterceptors: (history) => {
    instance.interceptors.request.use(
      function (config) {
        const user = new Cookies().get("user");
        const preferences = localStorage.getItem("preferences");

        if (user?._token) {
          config.headers["Authorization"] = `Bearer ${user._token}`;
        }

        let lang = "en";

        if (preferences) {
          lang = JSON.parse(preferences)?.langPref || lang;
        }

        config.headers["Accept-Language"] = lang;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.config.url.includes("redirect-check"))
          return Promise.reject(error);

        if (
          error.response &&
          error.response.status >= 300 &&
          error.response.status <= 500
        ) {
          if (error.response.status === 401) {
            const { dispatch } = store;
            dispatch(logout());
          }

          if (error.response.status === 301) {
            if (!isCrawler()) {
              const { previous_slug, slug } = error.response.data.header;

              if (previous_slug && slug)
                history.push(
                  window.location.pathname.replace(previous_slug, slug)
                );
              else history.push("/");
            } else console.log("add 301 response for crawlers");
          }

          if (error.response.status === 302) {
            return Promise.reject(error);
          }

          if (error.response.status === 404) {
            return Promise.reject(error);
          }

          if (error.response.data) {
            toastr.clear();
            if (
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              Object.keys(error.response.data.errors).forEach((key) => {
                toastr.error(error.response.data.errors[key][0]);
              });
            }
          }

          if (error.response.data.info) {
            toastr.error(error.response.data.info.message);
          }

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  },
  get: (url, configs) =>
    instance({
      method: "GET",
      url,
      ...configs,
    }),
  post: (url, data = {}, configs) =>
    instance({
      method: "POST",
      url,
      data,
      ...configs,
    }),
  put: (url, data = {}, configs) =>
    instance({
      method: "PUT",
      url,
      data,
      ...configs,
    }),
  delete: (url, configs) =>
    instance({
      method: "DELETE",
      url,
      ...configs,
    }),
  patch: (url, data = {}, configs) =>
    instance({
      method: "PATCH",
      url,
      data,
      ...configs,
    }),
};

export const headers = {
  "Content-Type": "application/json",
  "Accept-Language": "tr",
};
