import React, { Component } from "react";
import Experience from "./Experience";
import Counter from "./Counter";
import { CSSTransition } from "react-transition-group";
import BLModal from "../../utils/components/BLModal";
import PackageOptions from "../../Experience/components/PackageOptions";
import Voucher from "./Voucher";
import {
  currencyFormatter,
  handleErrors,
  MAX_GIFT_AMOUNT,
  MIN_GIFT_AMOUNT,
} from "../../utils/helper";

import toastr from "toastr";
import dayjs from "dayjs";
import API from "../../utils/API";
import { PACKAGE_TYPES } from "../../utils/db/packageTypes";
import BOOKING_MODE from "../../utils/constants";

export default class CartItem extends Component {
  state = {
    isUpdatingOn: false,
    balance: "",
    productAvailabilities: [],
    productPackages: [],
    productAvailabilitiesLoading: true,
    productPackagesLoading: true,
    selectedPackage: null,
    selectedAvailability: null,
    productUnits: [],
  };

  componentDidMount = () => {
    if (this.props.item.package_type === PACKAGE_TYPES.GIFTCARD) {
      this.setState({
        balance: parseInt(this.props.item.units[0].gift_amount),
      });
    }

    this.setState({
      selectedPackage: this.props.item.selectedPackage,
      productPackages: this.props.item.productPackages,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.isUpdatingPackage &&
      !this.props.isUpdatingPackage &&
      this.props.isUpdatingSuccessful
    ) {
      this.setState({ isUpdatingOn: false });
    }

    if (prevState?.selectedPackage?.id !== this.state?.selectedPackage?.id) {
      this.handleGetUnits();
      this.handleGetAvailabilities();
    }
  };

  setBalanceTimeout = "";
  handleUpdateGiftBalance = ({ target }) => {
    const balance =
      target.value && parseInt(target.value)
        ? parseInt(target.value)
        : MIN_GIFT_AMOUNT;
    clearTimeout(this.setBalanceTimeout);

    this.setState({ balance }, () => {
      this.setBalanceTimeout = setTimeout(this.handleUpdateBalance, 600);
    });
  };

  handleUpdateBalance = () => {
    const { balance } = this.state;
    toastr.clear();
    if (typeof balance !== "number" || balance === 0) {
      return false;
    }

    if (balance > 0) {
      if (balance < MIN_GIFT_AMOUNT) {
        toastr.error(
          this.props.translate("voucher.min_amount_warning", {
            amount: currencyFormatter(
              this.props.currency,
              MIN_GIFT_AMOUNT,
              this.props.lang
            ),
          })
        );
        return false;
      } else if (balance > MAX_GIFT_AMOUNT) {
        toastr.error(
          this.props.translate("voucher.max_amount_warning", {
            amount: currencyFormatter(
              this.props.currency,
              MAX_GIFT_AMOUNT,
              this.props.lang
            ),
          })
        );
        return false;
      }
    }

    debugger;
    this.props.handleUpdateGiftPackage(this.props.item.id, {
      priceOptions: [
        {
          count: 1,
          gift_amount: balance,
          id: this.props.item.units[0].id ? this.props.item.units[0].id : 0,
        },
      ],
      packageType: PACKAGE_TYPES.GIFTCARD,
      firstName: this.props.item.delivery_recipient_first_name,
      lastName: this.props.item.delivery_recipient_last_name,
      email: this.props.item.delivery_recipient_email,
      areaCode: this.props.item.delivery_recipient_area_code,
      countryCode: this.props.item.delivery_recipient_phone_cc,
      phone: this.props.item.delivery_recipient_phone_w_out_code,
      deliveryDate: this.props.item.delivery_recipient_send_date,
      message: this.props.item.gift_message,
      totalPrice: this.state.balance,
      currency: this.props.currency,
    });
  };

  checkBalanceValue = () => {
    if (this.state.balance > MAX_GIFT_AMOUNT)
      this.setState({ balance: MAX_GIFT_AMOUNT }, this.handleUpdateBalance);
    if (this.state.balance < MIN_GIFT_AMOUNT)
      this.setState({ balance: MIN_GIFT_AMOUNT }, this.handleUpdateBalance);
  };

  handleGetAvailabilities = (dates = null) => {
    if (this.props.item.package_type !== PACKAGE_TYPES.PICKADATE) return false;

    this.setState({ productAvailabilitiesLoading: true }, () => {
      let url = `products/${this.props.item.product.id}/availabilities?package_id=${this.state.selectedPackage.id}`;

      let startDate = "";
      let endDate = "";

      if (dates?.startDate && dates?.endDate) {
        startDate = dayjs(dates.startDate);
        endDate = dayjs(dates.endDate);
      } else {
        const preferredDate = dayjs(
          this.props.item.preferred_date,
          "DD/MM/YYYY"
        );

        if (preferredDate?.month() === dayjs().month()) {
          startDate = dayjs();
        } else {
          startDate = dayjs(preferredDate)?.startOf("month");
        }

        endDate = dayjs(preferredDate)?.endOf("month");
      }

      if (!startDate || !endDate) return false;

      url += `&from_date=${startDate.format(
        "YYYY-MM-DD"
      )}&to_date=${endDate.format("YYYY-MM-DD")}`;

      API.get(url)
        .then(({ data: res }) => {
          const productAvailabilities = res.data
            .filter((item) => item)
            .map((availability) => {
              const availableSlots = availability
                ? availability?.availabilities.filter(
                    (a) => a && (!a.closed || !a.soldOut)
                  ) || []
                : [];

              return {
                ...availability,
                available: availableSlots.length > 0,
              };
            });

          const selectedAvailability = productAvailabilities.find(
            (availability) =>
              availability.date.formatted.startsWith(
                dayjs(this.props.item.preferred_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              )
          );

          this.setState({
            productAvailabilities,
            productAvailabilitiesLoading: false,
            selectedAvailability,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ productAvailabilitiesLoading: false });
        });
    });
  };

  handleGetUnits = () => {
    if (this.props.item.package_type !== PACKAGE_TYPES.PICKADATE) return false;

    this.setState({ productUnitsLoading: true }, () => {
      API.get(
        `products/${this.props.item.product.id}/units?package_id=${this.state.selectedPackage.id}&currency=${this.props.user.currencyPref.code}`
      )
        .then(({ data: res }) => {
          this.setState({
            productUnits: res.data,
            productUnitsLoading: false,
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ productUnitsLoading: false });
        });
    });
  };

  render() {
    const packageOptionsProps =
      this.props.item.package_type === PACKAGE_TYPES.GIFTCARD
        ? {
            isVoucher: true,
            selectedTab: PACKAGE_TYPES.GIFT,
            handleUpdatePackage: this.props.handleUpdateGiftPackage,
            quantity: this.props.item.units[0].quantity,
          }
        : {
            isVoucher: false,
            selectedTab: this.props.item.package_type,
            handleUpdatePackage: this.props.handleUpdateCart,
            product: this.props.item.product,
          };
    return (
      <div
        className={`content-holder d-flex flex-column flex-md-row align-items-start ${
          this.props.item.package_type === PACKAGE_TYPES.GIFTCARD &&
          `align-items-md-end align-items-lg-center`
        }`}
      >
        {this.props.item.package_type === PACKAGE_TYPES.GIFTCARD ? (
          <Voucher
            translate={this.props.translate}
            participantFirstName={this.props.item.delivery_recipient_first_name}
            participantLastName={this.props.item.delivery_recipient_last_name}
            amount={currencyFormatter(
              this.props.currency,
              parseFloat(this.props.item.amount).toFixed(2),
              this.props.lang
            )}
            handleUpdate={() => this.setState({ isUpdatingOn: true })}
            gift={{
              name: `${this.props.item.delivery_recipient_first_name} ${this.props.item.delivery_recipient_last_name}`,
              phone: `+${this.props.item.delivery_recipient_area_code}${this.props.item.delivery_recipient_phone_w_out_code}`,
              email: this.props.item.delivery_recipient_email,
            }}
          />
        ) : (
          <Experience
            name={this.props.item.product.title}
            slug={this.props.item.product.slug}
            code={this.props.item.product.code}
            cover={this.props.item.product.cover_image_url}
            translate={this.props.translate}
            lang={this.props.lang}
            preferredDate={
              this.props.item?.booking_mode === BOOKING_MODE.DATEANDTIME
                ? dayjs(
                    this.props.item.preferred_date +
                      " " +
                      this.props.item.preferred_time,
                    "DD/MM/YYYY HH:mm"
                  ).format("DD MMMM YYYY HH:mm")
                : dayjs(this.props.item.preferred_date, "DD/MM/YYYY").format(
                    "DD MMMM YYYY"
                  )
            }
            packageType={this.props.item.package_type}
            handleUpdate={() => this.setState({ isUpdatingOn: true })}
            gift={{
              name: `${this.props.item.delivery_recipient_first_name} ${this.props.item.delivery_recipient_last_name}`,
              phone: `+${this.props.item.delivery_recipient_area_code}${this.props.item.delivery_recipient_phone_w_out_code}`,
              email: this.props.item.delivery_recipient_email,
            }}
            selectedPackage={this.state.selectedPackage}
          />
        )}

        <div
          className={`other-info-holder ${
            this.props.item.package_type === PACKAGE_TYPES.GIFTCARD
              ? `voucher-fix`
              : ``
          } d-flex flex-column align-items-end`}
        >
          <div
            className={`amounts-holder d-flex flex-column ${
              this.props.item.package_type !== PACKAGE_TYPES.GIFTCARD
                ? `d-none d-lg-flex`
                : ``
            }`}
          >
            {this.props.item.package_type === PACKAGE_TYPES.GIFTCARD ? (
              <div className="amount align-items-center">
                <span className="label">
                  {this.props.item.units[0].quantity +
                    " " +
                    this.props.translate(`global.piece`)}
                </span>
                <div className="balance d-flex justify-content-center">
                  <label className="holder d-flex justify-content-center align-items-center">
                    <span className="currency">
                      {this.props.currency.symbol}
                    </span>
                    <input
                      name="balance"
                      value={this.state.balance}
                      onChange={this.handleUpdateGiftBalance}
                      max={MAX_GIFT_AMOUNT}
                      min={MIN_GIFT_AMOUNT}
                      maxLength="4"
                      minLength="3"
                      onBlur={this.checkBalanceValue}
                    />
                  </label>
                </div>
                <div className="price-info d-flex justify-content-start justify-content-md-center">
                  {currencyFormatter(
                    this.props.currency,
                    parseFloat(this.props.item.amount).toFixed(2),
                    this.props.lang
                  )}
                </div>
              </div>
            ) : (
              this.props.item.units.map((data) => (
                <div className="amount align-items-center" key={data.id}>
                  <span className="label">{data.name}</span>
                  <Counter
                    count={data.quantity}
                    set={this.props.handleUpdateAmount}
                    experience={this.props.item.id}
                    type={data.price_id}
                    limits={{
                      start: 1,
                      end:
                        this.props.item?.selectedPackage?.max_pax_per_booking ||
                        10,
                    }}
                  />
                  <div className="price-info d-flex justify-content-end justify-content-lg-center">
                    {currencyFormatter(
                      this.props.currency,
                      parseFloat(data.amount).toFixed(2),
                      this.props.lang
                    )}
                  </div>
                </div>
              ))
            )}
          </div>

          <div className="options-holder d-flex justify-content-between align-items-start align-items-md-center">
            <button
              className="remove-button d-none d-lg-block"
              onClick={(e) => this.props.handleRemoveFromCart(e, true)}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 0C19.4034 0 25 5.59657 25 12.5C25 19.4034 19.4034 25 12.5 25C5.59657 25 0 19.4034 0 12.5C0 5.59657 5.59657 0 12.5 0ZM17.0204 8.00121C17.3133 8.29411 17.3133 8.76898 17.0204 9.06187L13.5823 12.5L17.0204 15.9381C17.3133 16.231 17.3133 16.7059 17.0204 16.9988L16.9988 17.0204C16.7059 17.3133 16.231 17.3133 15.9381 17.0204L12.5 13.5823L9.06187 17.0204C8.76898 17.3133 8.29411 17.3133 8.00121 17.0204L7.97957 16.9988C7.68667 16.7059 7.68667 16.231 7.97957 15.9381L11.4177 12.5L7.97957 9.06187C7.68667 8.76898 7.68667 8.29411 7.97957 8.00121L8.00121 7.97957C8.29411 7.68667 8.76898 7.68667 9.06187 7.97957L12.5 11.4177L15.9381 7.97957C16.231 7.68667 16.7059 7.68667 16.9988 7.97957L17.0204 8.00121Z"
                  fill="#979897"
                />
              </svg>
            </button>
          </div>
          <div className="mobile-option-buttons d-flex d-lg-none align-items-center">
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ isUpdatingOn: true });
              }}
            >
              {this.props.translate("global.edit")}
            </a>
            <a
              href="#!"
              onClick={(e) => this.props.handleRemoveFromCart(e, true)}
            >
              {this.props.translate("global.remove")}
            </a>
          </div>
        </div>
        <CSSTransition in={this.state.isUpdatingOn} timeout={300} unmountOnExit>
          <BLModal
            handleCloseModal={() => this.setState({ isUpdatingOn: false })}
            headerFix={true}
          >
            <div className="Experience modal-fix">
              <PackageOptions
                currency={this.props.currency}
                lang={this.props.lang}
                isUpdatingPackage={this.props.isUpdatingPackage}
                isUpdating={this.props.isUpdating}
                isUpdate={true}
                item={this.props.item}
                user={this.props.user}
                selectedAvailability={this.state.selectedAvailability}
                handleGetAvailabilities={this.handleGetAvailabilities}
                handleSetSelectedAvailability={(availability) =>
                  this.setState({
                    selectedAvailability: availability,
                  })
                }
                productAvailabilities={this.state.productAvailabilities}
                productAvailabilitiesLoading={
                  this.state.productAvailabilitiesLoading
                }
                selectedPackage={this.state.selectedPackage}
                handleSetSelectedPackage={(selectedPackage) => {
                  this.setState({ selectedPackage });
                }}
                {...packageOptionsProps}
              />
            </div>
          </BLModal>
        </CSSTransition>
      </div>
    );
  }
}
